import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";

import { OpportunityType, Reference } from "features/opportunities/models";

export type MousePosition = {
  x: number;
  y: number;
};

type TooltipType = {
  openTooltip: boolean;
  setOpenTooltip: Dispatch<SetStateAction<boolean>>;
  mousePosition: MousePosition;
  setMousePosition: Dispatch<SetStateAction<MousePosition>>;
  referenceData: Reference | null;
  setReferenceData: Dispatch<SetStateAction<Reference | null>>;
  opportunity: OpportunityType | null;
  setOpportunity: Dispatch<SetStateAction<OpportunityType | null>>;
};

export const TooltipContext = createContext<TooltipType>({
  openTooltip: false,
  setOpenTooltip: () => {},
  mousePosition: { x: 0, y: 0 },
  setMousePosition: () => {},
  referenceData: null,
  setReferenceData: () => {},
  opportunity: null,
  setOpportunity: () => {},
});

export const useTooltipContext = (): TooltipType => {
  return useContext(TooltipContext);
};

interface Props {
  children: ReactNode;
}

export function TooltipContextProvider({ children }: Props) {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [referenceData, setReferenceData] = useState<Reference | null>(null);
  const [opportunity, setOpportunity] = useState<OpportunityType | null>(null);
  const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });

  return (
    <TooltipContext.Provider
      value={{
        openTooltip,
        setOpenTooltip,
        mousePosition,
        setMousePosition,
        referenceData,
        setReferenceData,
        opportunity,
        setOpportunity,
      }}
    >
      {children}
    </TooltipContext.Provider>
  );
}
