import { useMemo, useState } from "react";
import { Box, Paper } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";

import { Mode } from "@constants";
import { useReferenceDataContext } from "context";
import { useDocumentTitle } from "hooks";
import {
  AlertsPane,
  CardBodyCreateEdit,
  CardBodySkeleton,
  CardBodyView,
  CardHeader,
  CardHeaderSkeleton,
  CommentsPane,
} from "features/opportunities/components";
import { OpportunityContextType, useOpportunityContext } from "features/opportunities/context";
import { useComment, useGetOpportunityAndReplaceForm, useSetScreenMode } from "features/opportunities/hooks";
import { useAlert } from "features/opportunities/hooks/useAlert";
import { SearchResultView } from "features/search/components";
import { useSearchQueryDispatcher } from "features/search/hooks";

type SearchOpportunityProps = Pick<OpportunityContextType, "mode"> & {
  initialOpenAlertsPane?: boolean;
  initialOpenCommentsPane?: boolean;
};

export function SearchOpportunity({ mode, initialOpenAlertsPane, initialOpenCommentsPane }: SearchOpportunityProps) {
  const {
    isLoading,
    openOpportunityView,
    handleSearchResultCloseButton,
    handleEditOpportunity,
    handleCloseOpportunity,
    handleDeleteOpportunity,
    handleSubmittedOpportunity,
  } = useSearchQueryDispatcher();

  useDocumentTitle("Search Results");
  const [showAllFields, setShowAllFields] = useState(false);
  const [openAlertsPane, setOpenAlertsPane] = useState(initialOpenAlertsPane || false);
  const [openCommentsPane, setOpenCommentsPane] = useState(initialOpenCommentsPane || false);
  useSetScreenMode(mode);
  useGetOpportunityAndReplaceForm();
  const { opportunity } = useOpportunityContext();
  const { referenceData } = useReferenceDataContext();
  const { toggleCommentsPath } = useComment();
  const { toggleAlertsPath } = useAlert();
  const navigate = useNavigate();

  return useMemo(
    () => {
      return (
        <>
          <SearchResultView isLoading={isLoading} closeButtonHandler={handleSearchResultCloseButton} />
          {openOpportunityView && (
            <Box sx={{ width: 512 }}>
              <Paper
                square
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {Object.keys(referenceData).length === 0 && !opportunity.id ? (
                  <>
                    <CardHeaderSkeleton />
                    <CardBodySkeleton />
                  </>
                ) : (
                  <>
                    {mode === Mode.Create && (
                      <CardHeader
                        mode={mode}
                        showAllFields={showAllFields}
                        setShowAllFields={setShowAllFields}
                        onClose={handleCloseOpportunity}
                        onSubmitted={handleSubmittedOpportunity}
                      />
                    )}
                    {mode === Mode.Edit && (
                      <CardHeader
                        mode={mode}
                        showAllFields={showAllFields}
                        setShowAllFields={setShowAllFields}
                        setOpenAlertsPane={setOpenAlertsPane}
                        setOpenCommentsPane={setOpenCommentsPane}
                        onClose={handleCloseOpportunity}
                        onDeleted={handleDeleteOpportunity}
                        onSubmitted={handleSubmittedOpportunity}
                      />
                    )}
                    {mode === Mode.View && (
                      <CardHeader
                        mode={mode}
                        showAllFields={showAllFields}
                        setShowAllFields={setShowAllFields}
                        setOpenAlertsPane={setOpenAlertsPane}
                        setOpenCommentsPane={setOpenCommentsPane}
                        onClose={handleCloseOpportunity}
                        onDeleted={handleDeleteOpportunity}
                        onEdit={handleEditOpportunity}
                      />
                    )}
                    {mode === Mode.Edit && <CardBodyCreateEdit />}
                    {mode === Mode.View && <CardBodyView />}
                  </>
                )}
              </Paper>
            </Box>
          )}
          {openAlertsPane && (
            <AlertsPane
              toggleAlertsPane={() => {
                setOpenAlertsPane((prevOpenAlertsPane) => !prevOpenAlertsPane);
                navigate(
                  generatePath(toggleAlertsPath(openAlertsPane, window.location.pathname), {
                    opportunityId: opportunity.id,
                  })
                );
              }}
            />
          )}
          {openCommentsPane && (
            <CommentsPane
              toggleCommentsPane={() => {
                setOpenCommentsPane((prevOpenCommentsPane) => !prevOpenCommentsPane);
                const newPath = generatePath(toggleCommentsPath(openCommentsPane, window.location.pathname), {
                  opportunityId: opportunity.id,
                });
                window.history.pushState({}, "", newPath);
              }}
            />
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, openOpportunityView, mode, referenceData, opportunity.id, openAlertsPane, openCommentsPane]
  );
}
