import { useEffect, useState } from "react";

import { DateFieldType } from "types";
import { initializeDateField } from "services";

export type OwnershipFieldsType = {
  id?: number;
  ownerName?: string;
  ownerId?: number;
  ownerType?: string;
  ownerShareRate?: number;
  ownershipFromDate: DateFieldType;
  ownershipToDate: DateFieldType;
};

export const initialOwnershipState: OwnershipFieldsType = {
  id: undefined,
  ownerName: undefined,
  ownerId: undefined,
  ownerType: undefined,
  ownerShareRate: undefined,
  ownershipFromDate: null,
  ownershipToDate: null,
};

export function useOwnershipFields(
  id?: number,
  ownerName?: string,
  ownerId?: number,
  ownerType?: string,
  ownerShareRate?: number,
  ownershipFromDate?: string,
  ownershipToDate?: string
) {
  const [ownershipFields, setOwnershipFields] = useState<OwnershipFieldsType>(initialOwnershipState);

  useEffect(() => {
    setOwnershipFields((prevState) => ({
      ...prevState,
      id: id,
      ownerName: ownerName,
      ownerId: ownerId,
      ownerType: ownerType,
      ownerShareRate: ownerShareRate,
      ownershipToDate: initializeDateField(ownershipToDate),
      ownershipFromDate: initializeDateField(ownershipFromDate),
    }));
  }, [id, ownerName, ownerId, ownerType, ownerShareRate, ownershipFromDate, ownershipToDate]);

  return [ownershipFields, setOwnershipFields] as const;
}
