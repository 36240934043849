import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import "./LegendControl.css";

import { LegendConstants } from "@constants";
import { getCustomMarker, getSettings } from "services";
import { useReferenceDataContext } from "context";
import {
  CommodityWithColor,
  LegendControlData,
  LegendShape,
  LegendShapeAndSizeByOpportunities,
  LegendSize,
} from "components/models";
import {
  createLegendControl,
  filterLegendShapes,
  filterLegendSizes,
  getUniqueLegendShapes,
  getUniqueLegendSizes,
  sortLegendSizesByWidth,
  getFilteredCommoditiesWithColor,
  sortLegendShapesById,
} from "components/utils";
import { OpportunityType, Reference, ReferenceOpportunityType } from "features/opportunities/models";
import { useSearchResultContext } from "features/search/context/SearchResultContext";

const LegendControl = () => {
  const map = useMap();
  const { referenceData } = useReferenceDataContext();
  const { searchOpportunityResult } = useSearchResultContext();
  const [commoditiesWithColor, setCommoditiesWithColor] = useState<CommodityWithColor[]>();
  const [opportuntiyTypeLegendShapes, setopportuntiyTypeLegendShapes] = useState<LegendShape[]>();
  const [opportunityTypeSizes, setopportuntiyTypeSizes] = useState<LegendSize[]>();

  const getUniqueShapesAndSizesFromOpportunities = (
    searchList: OpportunityType[],
    referenceData: Reference
  ): LegendShapeAndSizeByOpportunities | null => {
    if (!searchList || searchList.length === 0) {
      return null;
    }

    const opportunitiesWithLegends = getFilteredRefOpportunityTypes(searchList, referenceData);
    const filteredLegendShapes = filterLegendShapes(opportunitiesWithLegends, referenceData);
    const filteredLegendSizes = filterLegendSizes(opportunitiesWithLegends, referenceData);
    const uniqueLegendShapes = getUniqueLegendShapes(filteredLegendShapes);
    const uniqueLegendSizes = getUniqueLegendSizes(filteredLegendSizes);
    sortLegendShapesById(uniqueLegendShapes);
    sortLegendSizesByWidth(uniqueLegendSizes);

    return {
      opportunitiesByShapes: uniqueLegendShapes,
      opportunitiesBySizes: uniqueLegendSizes,
    };
  };

  const getFilteredRefOpportunityTypes = (
    opportunities: OpportunityType[],
    referenceData: Reference
  ): LegendShape[] => {
    return opportunities.map((e: OpportunityType) => {
      const expStage = referenceData.explorationStages.find((x) => x.id === e.explorationStageId);
      const icon = getCustomMarker(e, LegendConstants.customMarkerShapeColor);
      const size = getSettings(e, "");
      const referenceOpportunityType = { id: parseInt(e.id) } as ReferenceOpportunityType;
      const legendSize = {
        size,
        explorationStage: expStage?.name,
      };

      return {
        opportunity: e,
        icon,
        referenceOpportunityType,
        legendSize,
      };
    });
  };

  useEffect(() => {
    const legendControlData: LegendControlData = {
      commoditiesWithColor,
      opportuntiyTypeLegendShapes,
      opportunityTypeSizes,
    };
    const legendControl = createLegendControl(legendControlData);
    legendControl.addTo(map);

    return () => {
      map.removeControl(legendControl);
    };
  }, [map, commoditiesWithColor, opportuntiyTypeLegendShapes, opportunityTypeSizes]);

  useEffect(() => {
    if (searchOpportunityResult && referenceData && referenceData.commodityGroups !== undefined) {
      const filteredCommoditiesWithColor = getFilteredCommoditiesWithColor(referenceData, searchOpportunityResult);
      setCommoditiesWithColor(filteredCommoditiesWithColor);
      const legendShapeAndSizeByOpportunities = getUniqueShapesAndSizesFromOpportunities(
        searchOpportunityResult,
        referenceData
      );
      if (legendShapeAndSizeByOpportunities) {
        const { opportunitiesByShapes, opportunitiesBySizes } = legendShapeAndSizeByOpportunities;
        setopportuntiyTypeLegendShapes(opportunitiesByShapes);
        setopportuntiyTypeSizes(opportunitiesBySizes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpportunityResult, referenceData]);

  return null;
};

export default LegendControl;
