import { useCallback, useEffect, useRef } from "react";
import { useBeforeUnload, unstable_useBlocker as useBlocker, useLocation, useParams } from "react-router-dom";

import { PATH } from "@constants";
import { useSearchResultContext } from "features/search/context/SearchResultContext";

function usePrompt(message: string | null | undefined | false, { beforeUnload }: { beforeUnload?: boolean } = {}) {
  const { setSelectedOpportunityId } = useSearchResultContext();
  const { opportunityId } = useParams();
  const { pathname } = useLocation();

  const blocker = useBlocker(
    useCallback(() => (typeof message === "string" ? !window.confirm(message) : false), [message])
  );

  const prevState = useRef(blocker.state);
  useEffect(() => {
    if (blocker.state === "blocked") {
      if (pathname.startsWith(PATH.SEARCH) && opportunityId) {
        setSelectedOpportunityId(opportunityId);
      }
      blocker.reset();
    }
    prevState.current = blocker.state;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload && typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload]
    ),
    { capture: true }
  );
}

export function Prompt({ when, message, ...props }: PromptProps) {
  usePrompt(when ? message : false, props);
  return null;
}

interface PromptProps {
  when: boolean;
  message: string;
  beforeUnload?: boolean;
}
