import ReactDOM from "react-dom/client";

import { getRootContainer } from "root";
import { router } from "routes/routes";

import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENABLE_MSW === "true") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(getRootContainer(router));

reportWebVitals();
