import { useMemo, useState } from "react";
import { List, TextField, Typography, useTheme } from "@mui/material";

import { SearchResultLocators, BasicSearchLocators } from "@constants";
import { useReferenceDataContext } from "context";
import { CollapsableCard } from "components";
import { getAllCommoditiesToString, getCommodityById } from "features/opportunities/constants/commodityUtils";
import { OpportunityType } from "features/opportunities/models";
import { useSearchResultContext } from "features/search/context/SearchResultContext";

import { ExportOpportunityListButton } from "./ExportOpportunityListButton";
import { SearchResultListItem } from "./SearchResultListItem";
import { SortButtonMenu, SortOption } from "./SortButtonMenu";

interface SearchResultViewProps {
  closeButtonHandler: () => void;
  isLoading: boolean;
}

export const SEARCH_SORT_OPTIONS: Array<SortOption<OpportunityType>> = [
  { key: "opportunityName", value: "Name", default: true },
  { key: "primaryCommodityId", value: "Primary Commodity" },
  { key: "country", value: "Country" },
  { key: "stateProvince", value: "Region" },
];

export function SearchResultView({ closeButtonHandler, isLoading }: SearchResultViewProps) {
  const { referenceData } = useReferenceDataContext();
  const { setSelectedOpportunity, searchOpportunityResult, filterValue, setFilterValue } = useSearchResultContext();
  const theme = useTheme();
  const [sortValue, setSortValue] = useState<SortOption<OpportunityType>>(
    () => SEARCH_SORT_OPTIONS.find((x) => x.default) ?? SEARCH_SORT_OPTIONS[0]
  );

  const filteredOpportunities = useMemo(() => {
    if (isLoading) {
      return [];
    }

    return [...searchOpportunityResult].filter((opportunity) => {
      const filter = filterValue.toLowerCase();
      const opportunityName = opportunity.opportunityName.toLowerCase();
      const country = opportunity.country?.toLowerCase();
      const commodities = getAllCommoditiesToString(opportunity, referenceData?.commodityGroups).toLowerCase();
      const stateProvince = opportunity.stateProvince?.toLowerCase();

      return (
        opportunityName.includes(filter) ||
        (country && country.includes(filter)) ||
        (stateProvince && stateProvince.includes(filter)) ||
        (commodities && commodities.includes(filter))
      );
    });
  }, [isLoading, filterValue, searchOpportunityResult, referenceData?.commodityGroups]);

  const sortedOpportunities = useMemo(() => {
    return [...filteredOpportunities].sort((a, b) => {
      let aValue, bValue;

      switch (sortValue.key) {
        case "primaryCommodityId":
          aValue = getCommodityById(a[sortValue.key], referenceData?.commodityGroups)?.name;
          bValue = getCommodityById(b[sortValue.key], referenceData?.commodityGroups)?.name;
          break;
        default:
          aValue = a[sortValue.key];
          bValue = b[sortValue.key];
          break;
      }

      aValue = typeof aValue === "string" ? aValue.toLowerCase() : aValue;
      bValue = typeof bValue === "string" ? bValue.toLowerCase() : bValue;

      if (aValue && bValue) {
        if (aValue > bValue) {
          return 1;
        }
        if (bValue > aValue) {
          return -1;
        }
      }

      return 0;
    });
  }, [filteredOpportunities, sortValue, referenceData?.commodityGroups]);

  const sortButtonMenu = (
    <SortButtonMenu
      testIdPrefix={SearchResultLocators.SearchResultsPrefix}
      sortOptions={SEARCH_SORT_OPTIONS}
      currentSort={sortValue}
      onSort={(sort) => setSortValue(sort)}
    />
  );

  const exportOpportunityListButton = <ExportOpportunityListButton opportunityList={filteredOpportunities} />;

  const additionalControls = (
    <>
      {sortButtonMenu}
      {exportOpportunityListButton}
    </>
  );

  return useMemo(
    () => {
      return (
        <CollapsableCard
          cardId={SearchResultLocators.SearchResultsDrawer}
          cardHeaderId={SearchResultLocators.SearchResults}
          cardHeaderText="Search Result"
          collapsedHeaderId={SearchResultLocators.SearchResultsCollapseHeader}
          collapseButtonId={SearchResultLocators.CollapseButton}
          closeButtonId={SearchResultLocators.SearchResultsCloseButton}
          closeButtonHandler={closeButtonHandler}
          additionalControls={additionalControls}
        >
          <TextField
            id={BasicSearchLocators.FilterField}
            data-testid={BasicSearchLocators.FilterField}
            type="text"
            autoComplete="off"
            placeholder="Filter by Commodity, Country, Region, or Name"
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            variant="standard"
            sx={{ padding: "16px" }}
          />
          <List
            data-testid={SearchResultLocators.SearchResultsList}
            sx={{
              width: "100%",
              bgcolor: theme.palette.background.default,
            }}
          >
            {isLoading ? (
              <Typography id={SearchResultLocators.LoadingResults} data-testid={SearchResultLocators.LoadingResults}>
                Searching...
              </Typography>
            ) : sortedOpportunities.length > 0 ? (
              sortedOpportunities.map((opportunity) => (
                <SearchResultListItem
                  key={opportunity.id}
                  onClick={() => setSelectedOpportunity(opportunity)}
                  referenceData={referenceData?.commodityGroups}
                  opportunity={opportunity}
                />
              ))
            ) : (
              <Typography id={SearchResultLocators.NoResults} data-testid={SearchResultLocators.NoResults}>
                No opportunities found
              </Typography>
            )}
          </List>
        </CollapsableCard>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      closeButtonHandler,
      filterValue,
      sortValue,
      theme.palette.background.default,
      isLoading,
      sortedOpportunities,
      referenceData?.commodityGroups,
      setSelectedOpportunity,
    ]
  );
}
