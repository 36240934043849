import { useMemo } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { useReferenceDataContext } from "context";
import { useOpportunityContext } from "features/opportunities/context";
import { useLocationSectionHooks } from "features/opportunities/hooks";

import {
  CardBodySectionBase,
  ExplorationStage,
  ExplorationType,
  NamedEntityField,
  OpportunityCode,
  OpportunityName,
  OpportunityStatus,
  OpportunityTypeSelect,
  Origin,
  PrimaryCommodity,
  ResponsiblePerson,
  ConditionalGrid,
} from "./";
import { getSummaryTooltipFields } from "../services/utils";

export function CardBodyCreateEditSummary() {
  useLocationSectionHooks();
  const { referenceData } = useReferenceDataContext();
  const { opportunityAttributes } = useOpportunityContext();
  const tooltipFields = useMemo(
    () => getSummaryTooltipFields(referenceData?.opportunityAttributes),
    [referenceData?.opportunityAttributes]
  );

  return useMemo(() => {
    return (
      <Box
        sx={{
          overflowX: "auto",
          height: "100px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "1rem",
          gap: "1rem",
          overflowY: "overlay",
        }}
      >
        <CardBodySectionBase
          id="summary-header"
          ariaControls="summary-content"
          title="General Details"
          tooltipFields={tooltipFields}
        >
          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityCode"] ?? true}>
                <OpportunityCode />
              </ConditionalGrid>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityName"] ?? true}>
                <OpportunityName />
              </ConditionalGrid>
            </Grid>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityTypeId"] ?? true}>
                <OpportunityTypeSelect />
              </ConditionalGrid>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityStatusId"] ?? true}>
                <OpportunityStatus />
              </ConditionalGrid>
            </Grid>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["responsiblePersonId"] ?? true}>
                <ResponsiblePerson />
              </ConditionalGrid>
              <Grid xs={6} />
            </Grid>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["explorationStageId"] ?? true}>
                <ExplorationStage />
              </ConditionalGrid>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["explorationTypeId"] ?? true}>
                <ExplorationType />
              </ConditionalGrid>
            </Grid>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["primaryCommodityId"] ?? true}>
                <PrimaryCommodity />
              </ConditionalGrid>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["originId"] ?? true}>
                <Origin />
              </ConditionalGrid>
            </Grid>
            <Grid container xs={12}>
              <ConditionalGrid xs={6} displayGrid={opportunityAttributes["companyId"] ?? true}>
                <NamedEntityField
                  labelName="Company"
                  selectionField={CardBodyLocators.CompanySelect}
                  selectionFieldIdName={CardBodyLocators.CompanyId}
                  isRequired
                />
              </ConditionalGrid>
            </Grid>
          </Grid>
        </CardBodySectionBase>
      </Box>
    );
  }, [opportunityAttributes, tooltipFields]);
}
