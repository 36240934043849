import { Fragment, useMemo } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";

import { CardBodyLocators } from "@constants";
import { useReferenceDataContext } from "context";
import { referenceDataMenuItems } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useSearchNamedEntities } from "features/opportunities/hooks";
import { OpportunityType, NamedEntity } from "features/opportunities/models";

export function NamedEntityField(props: {
  labelName: string;
  selectionField: string;
  selectionFieldIdName: string;
  isRequired?: boolean;
}) {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors } = useOpportunityContext();
  const { selectionField, selectionFieldIdName, labelName, isRequired = false } = props;
  const {
    namedEntityOptionValue,
    namedEntityOptions,
    dialogValue,
    formErrors: dialogFormErrors,
    setNamedEntityInputValue,
    filterOptions,
    handleClose,
    open,
    handleSubmit,
    handleInputChange,
    handleSelectionChange,
    handleEntityChange,
  } = useSearchNamedEntities(selectionFieldIdName);

  const selectionFieldType = selectionFieldIdName as keyof OpportunityType;

  const formatNamedEntityOptionValue = (optionValue: NamedEntity | null) => {
    if (!optionValue) {
      return;
    }
    return `${optionValue?.name} [${optionValue?.namedEntityType}]`;
  };

  return useMemo(() => {
    return (
      <Fragment>
        <Autocomplete
          id={selectionField}
          getOptionLabel={(option) =>
            option.namedEntityType ? `${option.name} [${option.namedEntityType}]` : option.name
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={filterOptions}
          options={namedEntityOptions}
          autoComplete
          value={namedEntityOptionValue}
          autoHighlight
          noOptionsText="Start typing to search"
          onChange={handleEntityChange}
          onInputChange={(_event, newInputValue) => {
            setNamedEntityInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <Tooltip title={formatNamedEntityOptionValue(namedEntityOptionValue)} placement="top">
              <TextField
                required={isRequired}
                {...params}
                inputProps={{ ...params.inputProps, "data-testid": selectionField }}
                fullWidth
                label={labelName}
                error={Boolean(Number(opportunity[selectionFieldType]) > -1 && formErrors[selectionFieldType])}
                helperText={
                  Number(opportunity[selectionFieldType]) > -1 && formErrors[selectionFieldType]
                    ? formErrors[selectionFieldType]
                    : undefined
                }
              />
            </Tooltip>
          )}
          PaperComponent={(props) => {
            return <Paper id={selectionField} data-testid={selectionField} {...props} />;
          }}
        />
        <Dialog open={open} onClose={handleClose} id="create-new-named-entity-dialog">
          <DialogTitle>Add a new named entity</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginBottom: "30px" }}>
              Did you miss any Named Entity in our list? Please, add it
            </DialogContentText>
            <FormControl required error={Boolean(dialogFormErrors.namedEntityTypeId)}>
              <InputLabel id="namedEntityTypeLabel" data-testid="namedEntityTypeLabel">
                Named Entity Type
              </InputLabel>
              <Select
                labelId="namedEntityTypeLabel"
                label="Named Entity Type"
                id="namedEntityTypeId"
                data-testid={CardBodyLocators.NamedEntityTypeAdd}
                name="Named Entity Type"
                onChange={handleSelectionChange}
                value={dialogValue.namedEntityTypeId}
                style={{ marginBottom: "5px" }}
              >
                {referenceDataMenuItems(referenceData.namedEntityTypes, "opp-named-entity-")}
              </Select>
              {dialogFormErrors.namedEntityTypeId && (
                <FormHelperText
                  id={CardBodyLocators.NamedEntityTypeHelperTextAdd}
                  data-testid={CardBodyLocators.NamedEntityTypeHelperTextAdd}
                >
                  {dialogFormErrors.namedEntityTypeId}
                </FormHelperText>
              )}
            </FormControl>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              data-testid={CardBodyLocators.NamedEntityNameAdd}
              value={dialogValue.name}
              onChange={handleInputChange}
              label="Name"
              type="text"
              error={Boolean(dialogFormErrors.name)}
              helperText={dialogFormErrors.name}
            />
            <TextField
              autoFocus
              margin="dense"
              id="address"
              data-testid={CardBodyLocators.NamedEntityAddressAdd}
              value={dialogValue.address}
              onChange={handleInputChange}
              label="Address"
              type="text"
            />
            <TextField
              autoFocus
              margin="dense"
              id="contactInfo"
              data-testid={CardBodyLocators.NamedEntityContactInfoAdd}
              value={dialogValue.contactInfo}
              onChange={handleInputChange}
              label="Contact Info"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              id={CardBodyLocators.NamedEntityButtonCancel}
              data-testid={CardBodyLocators.NamedEntityButtonCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit(selectionFieldIdName)}
              id={CardBodyLocators.NamedEntityButtonAdd}
              data-testid={CardBodyLocators.NamedEntityButtonAdd}
              variant="contained"
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namedEntityOptionValue, namedEntityOptions, formErrors[selectionFieldType], dialogFormErrors, dialogValue, open]);
}
