import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { Box, Divider, Fade, Paper, Popper, Typography } from "@mui/material";
import { VirtualElement } from "@popperjs/core";

import { TooltipLocators } from "@constants";
import { mapIdsToName } from "services";
import { useTooltipContext } from "context";
import { chips, getFullLocation } from "features/opportunities/constants";

export function Tooltip() {
  const { openTooltip, opportunity, referenceData, mousePosition } = useTooltipContext();
  const [anchorEl, setAnchorEl] = useState<VirtualElement | undefined>();

  function generateGetBoundingClientRect(x = 0, y = 0): () => DOMRect {
    return () => ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
      x: x,
      y: y,
      toJSON() {
        return this;
      },
    });
  }

  const open = openTooltip && Boolean(anchorEl);
  useEffect(() => {
    const virtualElement: VirtualElement = {
      getBoundingClientRect: generateGetBoundingClientRect(mousePosition.x, mousePosition.y),
    };
    setAnchorEl(virtualElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mousePosition]);

  return (
    <div>
      {opportunity && referenceData && (
        <Popper
          id={TooltipLocators.Popper}
          open={open}
          anchorEl={anchorEl}
          placement="auto"
          modifiers={[
            {
              name: "offset",
              options: {
                offset: [0, 15],
              },
            },
          ]}
          sx={{ zIndex: 1000, width: "400px" }}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                id={TooltipLocators.Container}
                data-testid={TooltipLocators.Container}
                sx={{ display: "flex", flexDirection: "column", padding: "1rem", zIndex: 1 }}
                elevation={3}
              >
                <Box sx={{ display: "flex", gap: ".5rem" }}>
                  <Typography
                    id={TooltipLocators.OpportunityName}
                    data-testid={TooltipLocators.OpportunityName}
                    variant="h5"
                    flexGrow={1}
                    sx={{ paddingTop: ".25rem", overflow: "hidden", textOverflow: "ellipsis" }}
                  >
                    {opportunity.opportunityName || "New Opportunity"}
                  </Typography>
                </Box>
                <Typography
                  id={TooltipLocators.OpportunityId}
                  data-testid={TooltipLocators.OpportunityId}
                  variant="body2"
                >
                  {opportunity.id ?? ""}
                </Typography>
                <Typography
                  variant="body2"
                  color={"primary.main"}
                  sx={{ marginTop: ".5rem" }}
                  id={TooltipLocators.OpportunityLocation}
                  data-testid={TooltipLocators.OpportunityLocation}
                >
                  {getFullLocation(opportunity.country, opportunity.stateProvince) || "[Location Missing]"}
                </Typography>
                <Divider sx={{ margin: ".5rem 0" }} />
                <Box sx={{ display: "flex", gap: ".5rem" }}>{chips(opportunity, referenceData?.commodityGroups)}</Box>
                <Divider sx={{ margin: ".5rem 0" }} />
                <Box sx={{ display: "flex", gap: "1rem", marginTop: ".5rem", marginBottom: "1rem" }}>
                  <PersonIcon color="action" />
                  <Typography
                    variant="body1"
                    id={TooltipLocators.ResponsiblePerson}
                    data-testid={TooltipLocators.ResponsiblePerson}
                  >
                    {`${opportunity.responsiblePerson?.firstName} ${opportunity.responsiblePerson?.lastName}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem" }}>
                  <Typography
                    variant="body2"
                    id={TooltipLocators.OpportunityTypeAndStatus}
                    data-testid={TooltipLocators.OpportunityTypeAndStatus}
                  >
                    {`${mapIdsToName(referenceData.opportunityTypes, opportunity.opportunityTypeId)},  
                ${mapIdsToName(referenceData.opportunityStatuses, opportunity.opportunityStatusId)}`}
                  </Typography>{" "}
                </Box>
                <Box sx={{ display: "flex", gap: ".5rem" }}>
                  <Typography
                    variant="body2"
                    id={TooltipLocators.ExplorationStage}
                    data-testid={TooltipLocators.ExplorationStage}
                  >
                    {`Exploration Stage: ${mapIdsToName(
                      referenceData.explorationStages,
                      opportunity.explorationStageId
                    )}`}
                  </Typography>
                </Box>
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
}
