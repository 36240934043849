import { PropsWithChildren, createContext, useContext, useMemo, useReducer, useState } from "react";

import { OpportunityModel } from "types";
import { responsesToFormModels } from "services";
import { OpportunityType } from "features/opportunities/models/opportunity";

enum ActionEnums {
  SET_FILTERED_OPPORTUNITY = "SET_FILTERED_OPPORTUNITY",
  SET_SELECTED_OPPORTUNITY = "SET_SELECTED_OPPORTUNITY",
  SET_SELECTED_OPPORTUNITY_ID = "SET_SELECTED_OPPORTUNITY_ID",
}

export type ActionType =
  | { type: ActionEnums.SET_FILTERED_OPPORTUNITY; payload: OpportunityType[] }
  | { type: ActionEnums.SET_SELECTED_OPPORTUNITY; payload: OpportunityType | null }
  | { type: ActionEnums.SET_SELECTED_OPPORTUNITY_ID; payload: string };

type SearchResultContextType = {
  searchOpportunityResult: OpportunityType[];
  selectedOpportunity: OpportunityType | null;
  filterValue: string;
  setSearchOpportunityResult: (opportunities: OpportunityModel[]) => void;
  setSelectedOpportunity: (opportunities: OpportunityType | null) => void;
  setSelectedOpportunityId: (opportunities: string) => void;
  setFilterValue: (filterValue: string) => void;
};

const searchResultContextInitialValue: SearchResultContextType = {
  searchOpportunityResult: [],
  selectedOpportunity: null,
  filterValue: "",
  setSearchOpportunityResult: () => {},
  setSelectedOpportunity: () => {},
  setSelectedOpportunityId: () => {},
  setFilterValue: () => {},
};

export const SearchResultContext = createContext<SearchResultContextType>(searchResultContextInitialValue);

export const useSearchResultContext = (): SearchResultContextType => {
  return useContext(SearchResultContext);
};

export function SearchResultContextProvider({ children }: PropsWithChildren) {
  const [searchResultState, dispatchSearchResultState] = useReducer(
    searchResultReducer,
    searchResultContextInitialValue
  );

  const [filterValue, setFilterValue] = useState("");

  const setSearchOpportunityResult = (opportunities: OpportunityModel[]) => {
    dispatchSearchResultState({
      type: ActionEnums.SET_FILTERED_OPPORTUNITY,
      payload: responsesToFormModels(opportunities),
    });
    dispatchSearchResultState({ type: ActionEnums.SET_SELECTED_OPPORTUNITY, payload: null });
  };

  const setSelectedOpportunity = (opportunity: OpportunityType | null) =>
    dispatchSearchResultState({ type: ActionEnums.SET_SELECTED_OPPORTUNITY, payload: opportunity });

  const setSelectedOpportunityId = (opportunityId: string) =>
    dispatchSearchResultState({ type: ActionEnums.SET_SELECTED_OPPORTUNITY_ID, payload: opportunityId });

  const value = useMemo(
    () => ({
      searchOpportunityResult: searchResultState.searchOpportunityResult,
      selectedOpportunity: searchResultState.selectedOpportunity,
      filterValue,
      setSearchOpportunityResult,
      setSelectedOpportunity,
      setSelectedOpportunityId,
      setFilterValue,
    }),
    [filterValue, searchResultState.searchOpportunityResult, searchResultState.selectedOpportunity]
  );

  return <SearchResultContext.Provider value={value}>{children}</SearchResultContext.Provider>;
}

const searchResultReducer = (state: SearchResultContextType, action: ActionType): SearchResultContextType => {
  switch (action.type) {
    case ActionEnums.SET_FILTERED_OPPORTUNITY:
      return {
        ...state,
        searchOpportunityResult: action.payload,
      };
    case ActionEnums.SET_SELECTED_OPPORTUNITY:
      return {
        ...state,
        selectedOpportunity: action.payload,
      };
    case ActionEnums.SET_SELECTED_OPPORTUNITY_ID:
      return {
        ...state,
        selectedOpportunity:
          state.searchOpportunityResult.find((opportunity) => opportunity.id === action.payload) || null,
      };
  }
};
