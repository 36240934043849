import { Suspense } from "react";
import { RouteObject } from "react-router-dom";

import { PATH, Mode } from "@constants";
import { Loading } from "components";
import { AuthorizedComponent } from "components/Security/AuthorizedRoute";
import { MapLayout } from "layouts";
import { AdvancedSearch, SearchOpportunity } from "pages";

export const SearchRoutes: RouteObject = {
  path: PATH.SEARCH,
  element: <MapLayout />,
  children: [
    {
      path: PATH.SEARCH,
      element: (
        <Suspense fallback={<Loading />}>
          <SearchOpportunity mode={Mode.View} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_SEARCH_OPPORTUNITY,
      element: (
        <Suspense fallback={<Loading />}>
          <SearchOpportunity mode={Mode.View} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_SEARCH_OPPORTUNITY_WITH_ID,
      element: (
        <Suspense fallback={<Loading />}>
          <SearchOpportunity mode={Mode.View} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_SEARCH_OPPORTUNITY_WITH_ID_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <SearchOpportunity mode={Mode.View} initialOpenAlertsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_SEARCH_OPPORTUNITY_WITH_ID_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <SearchOpportunity mode={Mode.View} initialOpenCommentsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.UPDATE_SEARCH_OPPORTUNITY,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <SearchOpportunity mode={Mode.Edit} />} />
        </Suspense>
      ),
    },
    {
      path: PATH.UPDATE_SEARCH_OPPORTUNITY_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <SearchOpportunity mode={Mode.Edit} initialOpenAlertsPane={true} />} />
        </Suspense>
      ),
    },
    {
      path: PATH.UPDATE_SEARCH_OPPORTUNITY_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent
            component={() => <SearchOpportunity mode={Mode.Edit} initialOpenCommentsPane={true} />}
          />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH,
      element: (
        <Suspense fallback={<Loading />}>
          <AdvancedSearch mode={Mode.View} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_WITH_ID,
      element: (
        <Suspense fallback={<Loading />}>
          <AdvancedSearch mode={Mode.View} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_WITH_ID_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AdvancedSearch mode={Mode.View} initialOpenAlertsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_WITH_ID_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AdvancedSearch mode={Mode.View} initialOpenCommentsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_UPDATE,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <AdvancedSearch mode={Mode.Edit} />} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_UPDATE_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <AdvancedSearch mode={Mode.Edit} initialOpenAlertsPane={true} />} />
        </Suspense>
      ),
    },
    {
      path: PATH.ADVANCED_SEARCH_UPDATE_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <AdvancedSearch mode={Mode.Edit} initialOpenCommentsPane={true} />} />
        </Suspense>
      ),
    },
  ],
};
