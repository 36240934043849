import { Dialog, DialogContent, DialogContentText } from "@mui/material";

import { useSimpleDialogContext } from "context/SimpleDialogContext";

export function SimpleDialog() {
  const simpleDialogContext = useSimpleDialogContext();
  const handleClose = () => {
    simpleDialogContext.setOpenSimpleDialog(false);
  };

  return (
    <Dialog
      data-testid="simpleDialog"
      open={simpleDialogContext.openSimpleDialog}
      onClose={handleClose}
      sx={{ overflowWrap: "break-word" }}
    >
      <DialogContent>
        <DialogContentText data-testid="simpleDialogBody">{simpleDialogContext.dialogBody}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
}
