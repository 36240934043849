import { useMemo } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import { PropsWithTestId } from "types";
import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";
import { AdvancedSearchModelType, SearchOperatorType } from "features/search/models";

type Props = {
  id: string;
  propKey: keyof AdvancedSearchModelType;
  value: SearchOperatorType;
} & ({ type: "textfield" } | { type: "autocomplete" });

export function AdvancedSearchCriteriaOperator({ id, propKey, type, value }: Props) {
  const { setOperatorValue } = useAdvancedSearchContext();
  const operatorId = `advanced-search-${id}-operator`;
  const operatorSelectId = `advanced-search-${id}-operator-select`;

  const defaultOperator = type === "textfield" ? SearchOperatorType.Contains : SearchOperatorType.In;

  const updateOperator = (name: keyof AdvancedSearchModelType, value: SearchOperatorType | string) => {
    const operator: SearchOperatorType = !value || typeof value === "string" ? SearchOperatorType.Equal : value;
    setOperatorValue({ name, value: operator });
  };

  return useMemo(() => {
    return (
      <FormControl>
        <InputLabel id={operatorId} data-testid={operatorId}>
          Operation
        </InputLabel>
        <Select
          labelId={operatorId}
          id={operatorSelectId}
          label="Operation"
          onChange={(event) => updateOperator(propKey, event.target.value)}
          value={value || defaultOperator}
          SelectDisplayProps={{ "data-testid": operatorSelectId } as PropsWithTestId}
        >
          {type === "textfield" ? searchOperatorTextFieldItems : searchOperatorAutocompleteItems}
        </Select>
      </FormControl>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
}

const searchOperatorTextFieldItems = [
  <MenuItem key={SearchOperatorType.Equal} value={SearchOperatorType.Equal}>
    Equals
  </MenuItem>,
  <MenuItem key={SearchOperatorType.NotEqual} value={SearchOperatorType.NotEqual}>
    Not Equal
  </MenuItem>,
  <MenuItem key={SearchOperatorType.Contains} value={SearchOperatorType.Contains}>
    Contains
  </MenuItem>,
  <MenuItem key={SearchOperatorType.NotContain} value={SearchOperatorType.NotContain}>
    Not Contain
  </MenuItem>,
  <MenuItem key={SearchOperatorType.IsNull} value={SearchOperatorType.IsNull}>
    Null
  </MenuItem>,
  <MenuItem key={SearchOperatorType.IsNotNull} value={SearchOperatorType.IsNotNull}>
    Not Null
  </MenuItem>,
];

const searchOperatorAutocompleteItems = [
  <MenuItem key={SearchOperatorType.In} value={SearchOperatorType.In}>
    In
  </MenuItem>,
  <MenuItem key={SearchOperatorType.NotIn} value={SearchOperatorType.NotIn}>
    Not In
  </MenuItem>,
  <MenuItem key={SearchOperatorType.IsNull} value={SearchOperatorType.IsNull}>
    Null
  </MenuItem>,
  <MenuItem key={SearchOperatorType.IsNotNull} value={SearchOperatorType.IsNotNull}>
    Not Null
  </MenuItem>,
];
