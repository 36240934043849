import { useEffect, useState } from "react";
import { Buffer } from "buffer";
import pako from "pako";
import {
  createSearchParams,
  generatePath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { PATH } from "@constants";
import { ValidationErrors } from "models";
import { useAsync } from "hooks";
import { advancedSearchOpportunity } from "features/opportunities/services";
import { AdvancedSearchModelType, convertAdvancedSearchModel } from "features/search/models";

import { useAdvancedSearchContext } from "../context/AdvancedSearchContext";
import { useSearchResultContext } from "../context/SearchResultContext";
import { initialState } from "./useAdvancedSearchReducer";

export function useAdvancedSearch() {
  const [formErrors, setFormErrors] = useState<ValidationErrors>({});
  const [response, run] = useAsync(advancedSearchOpportunity);
  const { opportunityId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { selectedOpportunity, searchOpportunityResult, setSearchOpportunityResult, setSelectedOpportunity } =
    useSearchResultContext();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { state } = useLocation();
  const [openSearchResults, setOpenSearchResults] = useState(
    state?.preserveSearchResults && searchOpportunityResult.length > 0
  );
  const [openOpportunityView, setOpenOpportunityView] = useState(selectedOpportunity != null);
  const [isLoading, setIsLoading] = useState(false);

  const { searchCriteria, replaceSearchCriteria, clearSearchCriteria } = useAdvancedSearchContext();
  const navigate = useNavigate();

  useEffect(() => {
    setSelectedOpportunity(null);
    setIsInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParam = searchParams.get("q");

    if (!searchParam) {
      return;
    }

    const searchCriteria: AdvancedSearchModelType = uncompress(searchParam) || initialState;
    replaceSearchCriteria(searchCriteria);

    if (!state?.preserveSearchResults) {
      setIsLoading(true);
      setOpenSearchResults(true);
      run(convertAdvancedSearchModel(searchCriteria));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state !== null && state.preserveSearchResults === false) {
      setSearchOpportunityResult([]);
      setOpenSearchResults(false);
    } else {
      // Ensures latest data is fetched on page reload.
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setOpenOpportunityView(!!opportunityId);

    if (!opportunityId) {
      setSelectedOpportunity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId]);

  useEffect(() => {
    if (response.data) {
      setSearchOpportunityResult(response.data);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  useEffect(() => {
    if (response.status !== "error") {
      return;
    }

    if (response.validationErrors) {
      setFormErrors(response.validationErrors);
      return;
    }

    console.error("ERROR", response.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.error, response.validationErrors, response.status]);

  const searchButtonHandler = () => {
    setOpenSearchResults(true);
    const query = compress(searchCriteria);

    setSearchParams({ q: query });
    setIsLoading(true);
    setOpenSearchResults(true);
    run(convertAdvancedSearchModel(searchCriteria));
    navigate({
      pathname: PATH.ADVANCED_SEARCH,
      search: createSearchParams({ q: query }).toString(),
    });
  };

  const clearButtonHandler = () => {
    if (searchParams.has("q")) {
      searchParams.delete("q");
      setSearchParams(searchParams);
    }

    clearSearchCriteria();
  };

  const handleCloseOpportunity = () => {
    const query = searchParams.get("q");

    if (!query) {
      navigate(PATH.ADVANCED_SEARCH);
      return;
    }

    navigate(
      {
        pathname: PATH.ADVANCED_SEARCH,
        search: createSearchParams({ q: query }).toString(),
      },
      {
        state: { preserveSearchResults: true },
      }
    );
  };

  const handleDeleteOpportunity = () => {
    handleCloseOpportunity();
    setIsLoading(true);
    setOpenSearchResults(true);
    run(convertAdvancedSearchModel(searchCriteria));
  };

  const handleEditOpportunity = () => {
    const query = searchParams.get("q");

    navigate({
      pathname: generatePath(PATH.ADVANCED_SEARCH_WITH_ID, { opportunityId }),
      search: query ? createSearchParams({ q: query }).toString() : "",
    });

    setIsLoading(true);
    setOpenSearchResults(true);
    run(convertAdvancedSearchModel(searchCriteria));
  };

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }

    const query = searchParams.get("q");
    if (!query) {
      return;
    }

    if (!selectedOpportunity || !selectedOpportunity.id || selectedOpportunity.id === opportunityId) {
      return;
    }

    const path = generatePath(PATH.ADVANCED_SEARCH_WITH_ID, { opportunityId: selectedOpportunity?.id });
    navigate({
      pathname: path,
      search: createSearchParams({ q: query }).toString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoad, selectedOpportunity?.id]);

  const handleSearchResultCloseButton = () => {
    setSearchOpportunityResult([]);
    setOpenSearchResults(false);
    navigate({ pathname: PATH.ADVANCED_SEARCH }, { state: { preserveSearchResults: false } });
  };

  const onEdit = () => {
    const query = searchParams.get("q");

    if (query) {
      navigate(
        {
          pathname: generatePath(PATH.ADVANCED_SEARCH_UPDATE, { opportunityId }),
          search: createSearchParams({ q: query }).toString(),
        },
        {
          state: {
            preserveSearchResults: true,
          },
        }
      );
      return;
    }

    if (opportunityId) {
      navigate(generatePath(PATH.ADVANCED_SEARCH_UPDATE, { opportunityId }), {
        state: {
          preserveSearchResults: true,
        },
      });
    }
  };

  return {
    isLoading,
    openSearchResults,
    openOpportunityView,
    formErrors,
    searchButtonHandler,
    clearButtonHandler,
    setOpenSearchResults,
    handleCloseOpportunity,
    handleDeleteOpportunity,
    handleEditOpportunity,
    handleSearchResultCloseButton,
    onEdit,
  };
}

const compress = <T>(input: T) => {
  const str = JSON.stringify(input);
  const compressedData = pako.gzip(str);
  return Buffer.from(compressedData).toString("base64");
};

const uncompress = <T>(str: string) => {
  if (!str) {
    return undefined;
  }

  const decompressedData = pako.inflate(Buffer.from(str, "base64"), { to: "string" });

  if (!decompressedData) {
    return undefined;
  }

  return JSON.parse(decompressedData) as T;
};
