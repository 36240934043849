import { z } from "zod";

import { OptionalString } from "types";
import { OpportunityType } from "features/opportunities/models";

export const zeroToUndefined = (value: number | undefined, _ctx: z.RefinementCtx): number | undefined =>
  Number(value) > 0 ? value : undefined;

export const emptyStringToUndefined = (value: OptionalString, _ctx: z.RefinementCtx): OptionalString =>
  value || undefined;

export const convertToUndefined = (obj: any) => {
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) {
      continue;
    }

    if (obj[key] === null) {
      obj[key] = undefined;
      continue;
    }

    if (typeof obj[key] === "object") {
      convertToUndefined(obj[key]);
    }
  }
  return obj;
};

export const nameof = <T>(name: keyof T) => name;

export const nameOfFactory =
  <T>() =>
  (name: keyof T) =>
    name;

export type Nullable<T> = T | null;

export const delimiterSeparatedStringToArray = (delimiter: string, item?: string) =>
  !item
    ? []
    : item
        .split(delimiter)
        .map((subitem) => subitem.trim())
        .filter((subitem) => subitem); // Remove empty entries

export const trimToScale = (num: unknown, scale: number): number | undefined => {
  const number = num as number;
  if (!number) {
    return undefined;
  }

  const factor = Math.pow(10, scale);
  return Math.round(number * factor) / factor;
};

export const areCoordinatesValid = (opportunity: OpportunityType) => {
  const areCoordinatesProvided = Boolean(opportunity.latitude && opportunity.longitude);
  return areCoordinatesProvided && !isNaN(opportunity.latitude!) && !isNaN(opportunity.longitude!);
};
