import { MenuItem } from "@mui/material";

import {
  IdName,
  OpportunityStatusType,
  ReferenceOpportunityType,
  ReferenceCommodityGroups,
} from "features/opportunities/models";

const defaultMenuItem = (
  <MenuItem key={0} value={0}>
    --Select--
  </MenuItem>
);

export function referenceDataMenuItems(referenceDataElements: IdName[] | undefined, prefixId: string) {
  const menuItems = referenceDataElements?.map((element) => (
    <MenuItem key={prefixId + element.id} data-testid={prefixId + element.id} value={element.id}>
      {element.name}
    </MenuItem>
  ));
  return [defaultMenuItem, ...(menuItems || [])];
}

export function referenceDataOpportunityStatusMenuItems(
  typeId: number,
  referenceDataElements: OpportunityStatusType[] | undefined,
  prefixId: string
) {
  const menuItems = referenceDataElements
    ?.filter((element) => element.opportunityTypeId === typeId)
    ?.map((element) => (
      <MenuItem key={prefixId + element.id} data-testid={prefixId + element.id} value={element.id}>
        {element.name}
      </MenuItem>
    ));
  return [defaultMenuItem, ...(menuItems || [])];
}

export function referenceDataOpportunitySubTypeMenuItems(
  typeId: number,
  referenceDataElements: ReferenceOpportunityType[] | undefined,
  prefixId: string
) {
  const menuItems = referenceDataElements
    ?.find((element) => element.id === typeId)
    ?.opportunitySubTypes?.map((opportunitySubType) => (
      <MenuItem
        key={prefixId + opportunitySubType.id}
        data-testid={prefixId + opportunitySubType.id}
        value={opportunitySubType.id}
      >
        {opportunitySubType.name}
      </MenuItem>
    ));
  return [defaultMenuItem, ...(menuItems || [])];
}

export const getAllCommodities = (referenceDataElements?: ReferenceCommodityGroups[]) =>
  referenceDataElements?.flatMap((commodityClass) =>
    commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
  ) ?? [];

export function referenceDataCommodityItems(
  referenceDataElements: ReferenceCommodityGroups[] | undefined,
  prefixId: string
) {
  const menuItems = getAllCommodities(referenceDataElements)?.map((element) => (
    <MenuItem key={prefixId + element.id} data-testid={prefixId + element.id} value={element.id}>
      {element.name}
    </MenuItem>
  ));
  return [defaultMenuItem, ...(menuItems || [])];
}

export function referenceDataCommodityItemString(
  referenceDataElements: ReferenceCommodityGroups[] | undefined,
  prefixId: string
) {
  const allCommodities = referenceDataElements?.flatMap((commodityClass) =>
    commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
  );

  const menuItems = allCommodities?.map((element) => (
    <MenuItem key={prefixId + element.id} data-testid={prefixId + element.id} value={element.id.toString()}>
      {element.name}
    </MenuItem>
  ));
  return [defaultMenuItem, ...(menuItems || [])];
}
