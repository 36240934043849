import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useMemo, useReducer, useState } from "react";

import { Mode } from "@constants";
import { useConfirmationModalContext, useReferenceDataContext } from "context";
import {
  AlertType,
  CommentType,
  FilteredOpportunityAttribute,
  NamedEntity,
  opportunityInitialState,
  OpportunityType,
  OwnershipType,
  ReferenceDocumentType,
  ResponsibleGroupType,
} from "features/opportunities/models";
import { ActionTypes, FormActionType, opportunityReducer } from "features/opportunities/reducers";
import { getFieldsToDisplay, getSelectedOpportunityAttribute } from "features/opportunities/services";

export type ErrorType = {
  [Property in
    | keyof OpportunityType
    | keyof OwnershipType
    | keyof ResponsibleGroupType
    | keyof NamedEntity
    | keyof ReferenceDocumentType
    | keyof AlertType
    | keyof CommentType]: string;
};

export const NoErrors = {} as ErrorType;

export type OpportunityContextType = {
  opportunity: OpportunityType;
  mode: Mode;
  parsedForm: OpportunityType;
  formErrors: ErrorType;
  showAllFields: boolean;
  opportunityAttributes: FilteredOpportunityAttribute;
  updateField: (name: string, value: unknown, suppressChanges?: boolean) => void;
  replaceForm: (newState: OpportunityType) => void;
  setViewMode: (mode: Mode) => void;
  setParsedForm: Dispatch<SetStateAction<OpportunityType>>;
  setFormErrors: Dispatch<SetStateAction<ErrorType>>;
  dispatch: Dispatch<FormActionType>;
  setShowAllFields: Dispatch<SetStateAction<boolean>>;
};

export const OpportunityContext = createContext<OpportunityContextType | null>(null);

export const useOpportunityContext = () => {
  return useContext(OpportunityContext) as OpportunityContextType;
};

interface Props {
  children: ReactNode;
}

export function OpportunityProvider({ children }: Props) {
  const [state, dispatch] = useReducer(opportunityReducer, opportunityInitialState);
  const [mode, setMode] = useState<Mode>(Mode.View);
  const [parsedForm, setParsedForm] = useState(state);
  const [formErrors, setFormErrors] = useState<ErrorType>({} as ErrorType);
  const confirmationModalContext = useConfirmationModalContext();
  const { referenceData } = useReferenceDataContext();
  const [showAllFields, setShowAllFields] = useState(true);

  const opportunityAttributes = useMemo(() => {
    const selectedAttribute = getSelectedOpportunityAttribute(state.opportunityTypeId);
    return getFieldsToDisplay(referenceData?.opportunityAttributes, selectedAttribute);
  }, [referenceData, state.opportunityTypeId]);

  const updateField = (name: string, value: unknown, suppressChanges?: boolean) => {
    dispatch({ type: ActionTypes.UPDATE_FORM_ACTION, payload: { name, value } });

    if (!suppressChanges) {
      confirmationModalContext.setCanShow(true);
    }
  };

  const replaceForm = (newState: typeof state) => {
    dispatch({ type: ActionTypes.REPLACE_FORM_ACTION, payload: newState });
    confirmationModalContext.setCanShow(false);
  };

  const setViewMode = (mode: Mode) => setMode(mode);

  const contextValue = useMemo<OpportunityContextType>(
    () => ({
      opportunity: state,
      mode,
      formErrors,
      parsedForm,
      showAllFields,
      opportunityAttributes,
      updateField,
      replaceForm,
      setViewMode,
      setParsedForm,
      setFormErrors,
      dispatch,
      setShowAllFields,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, mode, formErrors, parsedForm, showAllFields]
  );

  return <OpportunityContext.Provider value={contextValue}>{children}</OpportunityContext.Provider>;
}
