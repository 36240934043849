import { ChangeEvent, useMemo } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IconButton, InputAdornment, TextField, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { nameOfFactory } from "services";
import { useOpportunityContext } from "features/opportunities/context";
import { useLandfolio } from "features/opportunities/hooks";
import { OpportunityType } from "features/opportunities/models";
import theme from "theme";

export function IntegrationSection() {
  const { opportunity, formErrors, updateField } = useOpportunityContext();
  const { isLandfolioIdValid, runValidateLandfolio, setIsLandfolioIdValid, invalidLandfolioIdText } = useLandfolio();

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateField(event.target.name, event.target.value);
    setIsLandfolioIdValid(undefined);
  };

  const handleOnValidateClick = () => {
    if (opportunity.landfolioId) {
      runValidateLandfolio(opportunity.landfolioId);
    }
  };

  return useMemo(
    () => {
      const nameof = nameOfFactory<OpportunityType>();
      const iconAdornment =
        opportunity.landfolioId && isLandfolioIdValid !== undefined
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  {isLandfolioIdValid ? (
                    <CheckCircleIcon
                      id={CardBodyLocators.LandfolioIdValidIcon}
                      data-testid={CardBodyLocators.LandfolioIdValidIcon}
                      sx={{ color: theme.palette.primary.main }}
                    />
                  ) : (
                    <CancelIcon
                      id={CardBodyLocators.LandfolioIdInvalidIcon}
                      data-testid={CardBodyLocators.LandfolioIdInvalidIcon}
                      sx={{ color: theme.palette.warning.dark }}
                    />
                  )}
                </InputAdornment>
              ),
            }
          : {};

      return (
        <Grid container spacing={2} alignItems={"flex-start"}>
          <Grid xs={6}>
            <TextField
              id={CardBodyLocators.LandfolioId}
              label="Landfolio ID"
              name={nameof("landfolioId")}
              onChange={handleInputChange}
              value={opportunity.landfolioId || ""}
              InputProps={iconAdornment}
              inputProps={{ "data-testid": CardBodyLocators.LandfolioId }}
              error={Boolean(opportunity.landfolioId && formErrors.landfolioId) || isLandfolioIdValid === false}
              helperText={
                opportunity.landfolioId && formErrors.landfolioId ? formErrors.landfolioId : invalidLandfolioIdText
              }
            />
          </Grid>
          <Grid xs={6}>
            <Tooltip
              title="Validate Landfolio ID"
              id={CardBodyLocators.LandfolioIdValidateButtonTooltip}
              data-testid={CardBodyLocators.LandfolioIdValidateButtonTooltip}
            >
              <IconButton
                id={CardBodyLocators.LandfolioIdValidateButton}
                data-testid={CardBodyLocators.LandfolioIdValidateButton}
                onClick={handleOnValidateClick}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opportunity.landfolioId, formErrors.landfolioId, isLandfolioIdValid]
  );
}
