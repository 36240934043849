import { OpportunityModel } from "types";
import { OpportunityType } from "features/opportunities/models";

import { initializeDateField } from "./dateHelpers";

export const responseToFormModel = (opportunity: OpportunityModel): OpportunityType => {
  const transformedData = {
    ...opportunity,
    createdDate: initializeDateField(opportunity.createdDate),
    updatedDate: initializeDateField(opportunity.updatedDate),
    startDate: initializeDateField(opportunity.startDate),
    endDate: initializeDateField(opportunity.endDate),
    testDate: initializeDateField(opportunity.testDate),
    thirdPartyNotifiedDate: initializeDateField(opportunity.thirdPartyNotifiedDate),
    ownerships: opportunity.ownerships?.map((ownership) => ({
      ...ownership,
      ownershipFromDate: initializeDateField(ownership.ownershipFromDate),
      ownershipToDate: initializeDateField(ownership.ownershipToDate),
    })),
  };

  return transformedData as OpportunityType;
};

export const responsesToFormModels = (opportunities: OpportunityModel[]): OpportunityType[] =>
  opportunities.map((opportunity) => responseToFormModel(opportunity));

export const formToRequestModel = ({
  createdDate,
  updatedDate,
  startDate,
  endDate,
  testDate,
  thirdPartyNotifiedDate,
  ownerships,
  ...opportunity
}: OpportunityType): OpportunityModel => {
  const transformedData = {
    ...opportunity,
    createdDate: createdDate?.toISOString(),
    updatedDate: updatedDate?.toISOString(),
    startDate: startDate?.toISOString(),
    endDate: endDate?.toISOString(),
    testDate: testDate?.toISOString(),
    thirdPartyNotifiedDate: thirdPartyNotifiedDate?.toISOString(),
    ownerships: ownerships?.map((ownership) => ({
      ...ownership,
      ownershipFromDate: ownership.ownershipFromDate?.toISOString(),
      ownershipToDate: ownership.ownershipToDate?.toISOString() ?? null,
    })),
  };

  return transformedData as OpportunityModel;
};
