import { useEffect } from "react";
import { z } from "zod";

import { Mode } from "@constants";
import { useReferenceDataContext } from "context";
import { ErrorType, NoErrors, useOpportunityContext } from "features/opportunities/context";
import {
  addCustomRefinements,
  CreateOpportunitySchema,
  EditOpportunitySchema,
  OpportunityAttributes,
  OpportunityType,
  OwnershipType,
  ReferenceDocumentType,
  ResponsibleGroupType,
} from "features/opportunities/models";
import { getSelectedOpportunityAttribute } from "features/opportunities/services";

export const formatZodErrors = (issues: z.ZodIssue[]) =>
  issues.reduce<ErrorType>((acc, item) => {
    acc[
      item.path[0].toString() as
        | keyof OpportunityType
        | keyof OwnershipType
        | keyof ResponsibleGroupType
        | keyof ReferenceDocumentType
    ] = item.message;
    return acc;
  }, {} as ErrorType);

export function useValidation() {
  const { referenceData } = useReferenceDataContext();
  const { mode, opportunity, setFormErrors, setParsedForm } = useOpportunityContext();

  useEffect(() => {
    const result = validateOpportunity(opportunity, mode, referenceData?.opportunityAttributes);

    if (result.success) {
      setFormErrors(NoErrors);
      setParsedForm(result.data as OpportunityType);
      return;
    }

    const formattedErrors = formatZodErrors(result.error.issues);
    setFormErrors(formattedErrors);
    setParsedForm({} as OpportunityType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, opportunity, referenceData?.opportunityAttributes, referenceData?.opportunityTypes]);
}

export const validateOpportunity = (
  opportunity: OpportunityType,
  mode: Mode,
  opportunityAttributes?: OpportunityAttributes
) => {
  const schema = mode === Mode.Create ? CreateOpportunitySchema : EditOpportunitySchema;
  const filteredOutSchema = omitSchema(schema, opportunity, opportunityAttributes);
  return addCustomRefinements(filteredOutSchema).safeParse(opportunity);
};

export const omitSchema = <T extends z.ZodObject<any>, K extends keyof z.TypeOf<T>>(
  schema: T,
  opportunity: OpportunityType,
  opportunityAttributes?: OpportunityAttributes
): z.ZodObject<Omit<z.TypeOf<T>, K>> => {
  const selectedOpportunityTypeAttribute = getSelectedOpportunityAttribute(opportunity?.opportunityTypeId || 0);

  const opportunityAttributesKeys = Object.keys(opportunityAttributes || {}) as Array<keyof OpportunityType>;

  if (!opportunity || !opportunityAttributes || opportunityAttributesKeys.length === 0) {
    return schema.omit({}) as z.ZodObject<Omit<z.TypeOf<T>, K>>;
  }

  const filteredOutKeys = opportunityAttributesKeys.reduce<{ [x: string]: true | undefined }>((acc, curr) => {
    const attribute = opportunityAttributes[curr];

    if (!attribute || attribute[selectedOpportunityTypeAttribute] === true) {
      return acc;
    }

    return { ...acc, [curr]: true };
  }, {});

  return schema.omit(filteredOutKeys) as z.ZodObject<Omit<z.TypeOf<T>, K>>;
};
