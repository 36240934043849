import { useEffect, useState } from "react";

import { useAsync } from "hooks";

import { validateLandfolioId } from "../services";

export function useLandfolio() {
  const [validateLandfolioResponse, runValidateLandfolio] = useAsync(validateLandfolioId);
  const [isLandfolioIdValid, setIsLandfolioIdValid] = useState<boolean>();

  const invalidLandfolioIdText = isLandfolioIdValid === false ? "ID not found in Landfolio" : undefined;

  useEffect(() => {
    if (validateLandfolioResponse.data !== undefined) {
      setIsLandfolioIdValid(validateLandfolioResponse.data);
    }
  }, [validateLandfolioResponse.data]);

  return { isLandfolioIdValid, runValidateLandfolio, setIsLandfolioIdValid, invalidLandfolioIdText };
}
