import { splitIdsAndMapToName } from "services";
import { OpportunityType, ReferenceCommodityGroups } from "features/opportunities/models";

export function getCommoditiesArrayFromOpportunity(
  opportunity: OpportunityType,
  referenceData: ReferenceCommodityGroups[] | undefined
) {
  const allCommodities = referenceData?.flatMap((commodityClass) =>
    commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
  );
  var commodityArray = [];

  if (opportunity.primaryCommodityId > 0) {
    const commodity = allCommodities?.find((item) => item.id === opportunity.primaryCommodityId);

    if (commodity) {
      commodityArray.push(commodity.name);
    }
  }

  if (opportunity.secondaryCommodityId && opportunity.secondaryCommodityId > 0) {
    const commodity = allCommodities?.find((item) => item.id === opportunity.secondaryCommodityId);

    if (commodity) {
      commodityArray.push(commodity.name);
    }
  }
  return commodityArray;
}

export function getAllCommoditiesToString(
  opportunity: OpportunityType,
  referenceData: ReferenceCommodityGroups[] | undefined
) {
  const commoditiesCommaSeparated = getCommoditiesArrayFromOpportunity(opportunity, referenceData).join(", ");
  return opportunity.otherCommodities === undefined
    ? commoditiesCommaSeparated
    : `${commoditiesCommaSeparated}, ${splitIdsAndMapToName(referenceData, opportunity.otherCommodities)}`;
}

export function getFullLocation(country?: string, stateProvince?: string) {
  if (!country) {
    return undefined;
  }

  return stateProvince ? `${country}, ${stateProvince}` : country;
}

export function getCommodityById(commodityId: number, referenceData: ReferenceCommodityGroups[] | undefined) {
  const allCommodities = referenceData?.flatMap((commodityClass) =>
    commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
  );

  return allCommodities?.find((x) => x.id === commodityId);
}
