import { useMemo, useState } from "react";
import { Box, Paper } from "@mui/material";
import { generatePath, useNavigate } from "react-router-dom";

import { useReferenceDataContext } from "context";
import { useDocumentTitle } from "hooks";
import { Mode } from "constants/modes";
import { AlertsPane, CardBodyView, CardHeader, CommentsPane } from "features/opportunities/components";
import { CardBodyCreateEdit } from "features/opportunities/components/CardBodyCreateEdit";
import { CardBodySkeleton } from "features/opportunities/components/CardBodySkeleton";
import { CardHeaderSkeleton } from "features/opportunities/components/CardHeaderSkeleton";
import { OpportunityContextType, useOpportunityContext } from "features/opportunities/context";
import { useSetScreenMode, useComment } from "features/opportunities/hooks";
import { useAlert } from "features/opportunities/hooks/useAlert";
import { useGetOpportunityAndReplaceForm } from "features/opportunities/hooks/useGetOpportunityAndReplaceForm";
import { AdvancedSearchCriteria } from "features/search/components";
import { SearchResultView } from "features/search/components/SearchResult/SearchResultView";
import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";
import { useAdvancedSearch } from "features/search/hooks";

type AdvancedSearchOpportunityProps = Pick<OpportunityContextType, "mode"> & {
  initialOpenAlertsPane?: boolean;
  initialOpenCommentsPane?: boolean;
};

export function AdvancedSearch({
  mode,
  initialOpenAlertsPane,
  initialOpenCommentsPane,
}: AdvancedSearchOpportunityProps) {
  useDocumentTitle("Advanced Search");
  const [showAllFields, setShowAllFields] = useState(false);
  const [openAlertsPane, setOpenAlertsPane] = useState(initialOpenAlertsPane ?? false);
  const [openCommentsPane, setOpenCommentsPane] = useState(initialOpenCommentsPane || false);
  const { referenceData } = useReferenceDataContext();
  const { opportunity } = useOpportunityContext();
  const { searchCriteria } = useAdvancedSearchContext();
  const {
    isLoading,
    openOpportunityView,
    openSearchResults,
    formErrors,
    searchButtonHandler,
    clearButtonHandler,
    handleSearchResultCloseButton,
    handleCloseOpportunity,
    handleEditOpportunity,
    handleDeleteOpportunity,
    onEdit,
  } = useAdvancedSearch();
  const { toggleCommentsPath } = useComment();
  const { toggleAlertsPath } = useAlert();
  const navigate = useNavigate();

  useSetScreenMode(mode);
  useGetOpportunityAndReplaceForm();

  return useMemo(
    () => {
      return (
        <>
          <AdvancedSearchCriteria
            formErrors={formErrors}
            searchButtonHandler={searchButtonHandler}
            clearButtonHandler={clearButtonHandler}
          />
          {openSearchResults && (
            <SearchResultView isLoading={isLoading} closeButtonHandler={handleSearchResultCloseButton} />
          )}
          {openOpportunityView && (
            <Box sx={{ width: 512 }}>
              <Paper
                square
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {Object.keys(referenceData).length === 0 && !Boolean(opportunity.id) ? (
                  <>
                    <CardHeaderSkeleton />
                    <CardBodySkeleton />
                  </>
                ) : (
                  <>
                    {mode === Mode.Edit && (
                      <CardHeader
                        mode={mode}
                        showAllFields={showAllFields}
                        setShowAllFields={setShowAllFields}
                        setOpenAlertsPane={setOpenAlertsPane}
                        setOpenCommentsPane={setOpenCommentsPane}
                        onClose={handleCloseOpportunity}
                        onDeleted={handleDeleteOpportunity}
                        onSubmitted={handleEditOpportunity}
                      />
                    )}
                    {mode === Mode.View && (
                      <CardHeader
                        mode={mode}
                        showAllFields={showAllFields}
                        setShowAllFields={setShowAllFields}
                        setOpenAlertsPane={setOpenAlertsPane}
                        setOpenCommentsPane={setOpenCommentsPane}
                        onClose={handleCloseOpportunity}
                        onDeleted={handleDeleteOpportunity}
                        onEdit={onEdit}
                      />
                    )}
                    {mode === Mode.Edit && <CardBodyCreateEdit />}
                    {mode === Mode.View && <CardBodyView />}
                  </>
                )}
              </Paper>
            </Box>
          )}
          {openAlertsPane && (
            <AlertsPane
              toggleAlertsPane={() => {
                setOpenAlertsPane((prevOpenAlertsPane) => !prevOpenAlertsPane);
                navigate({
                  pathname: generatePath(toggleAlertsPath(openAlertsPane, window.location.pathname), {
                    opportunityId: opportunity.id,
                  }),
                  search: window.location.search,
                });
              }}
            />
          )}
          {openCommentsPane && (
            <CommentsPane
              toggleCommentsPane={() => {
                setOpenCommentsPane((prevOpenCommentsPane) => !prevOpenCommentsPane);
                const newPath = generatePath(toggleCommentsPath(openCommentsPane, window.location.pathname), {
                  opportunityId: opportunity.id,
                });
                navigate({
                  pathname: newPath,
                  search: window.location.search,
                });
              }}
            />
          )}
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLoading,
      formErrors,
      openSearchResults,
      openOpportunityView,
      referenceData,
      opportunity.id,
      showAllFields,
      mode,
      searchCriteria,
      openAlertsPane,
      openCommentsPane,
    ]
  );
}
