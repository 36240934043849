import { useState } from "react";
import { Sort, Done } from "@mui/icons-material";
import { Menu, IconButton, MenuItem, Grid, Typography, Tooltip } from "@mui/material";

import { SearchResultLocators } from "@constants";

export interface SortOption<T> {
  key: keyof T;
  value: string;
  default?: boolean;
}

interface SortButtonProps<T> {
  sortOptions: SortOption<T>[];
  onSort: (criteria: SortOption<T>) => void;
  currentSort?: SortOption<T>;
  testIdPrefix?: string;
}

export function SortButtonMenu<T>({ sortOptions, onSort, currentSort, testIdPrefix }: SortButtonProps<T>) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Tooltip
        title="Sort search results"
        id={SearchResultLocators.SortButtonTooltip}
        data-testid={SearchResultLocators.SortButtonTooltip}
      >
        <IconButton
          id={`${testIdPrefix ? `${testIdPrefix}-` : ""}sort-button`}
          data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ""}sort-button`}
          size="large"
          aria-label="sort results"
          aria-controls={menuOpen ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Sort />
        </IconButton>
      </Tooltip>
      <Menu
        id={`${testIdPrefix ? `${testIdPrefix}-` : ""}sort-menu`}
        data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ""}sort-menu`}
        open={menuOpen}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
      >
        {sortOptions.map((option, index) => {
          return (
            <MenuItem
              key={`sort-by-${option.value}-${index}`}
              data-testid={`${testIdPrefix ? `${testIdPrefix}-` : ""}sort-menu-option`}
              onClick={() => onSort(option)}
            >
              <Grid container direction="row" alignItems="center">
                <Grid
                  item
                  sx={{ width: "1.5rem", height: "1.5rem", marginRight: "1rem" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  {currentSort === option ? <Done /> : null}
                </Grid>
                <Grid item>
                  <Typography>{option.value}</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
