import { Box, Paper, Skeleton } from "@mui/material";

export function CardBodySkeleton() {
  return (
    <>
      <Paper square sx={{ display: "flex", flexDirection: "column", padding: "1rem", margin: "1rem" }}>
        <Skeleton variant="rounded" width={"15rem"} height={"2rem"} sx={{ marginBottom: "1rem" }} />
        <Box display={"flex"} gap={"1rem"} flexDirection={"column"}>
          {Array.from(Array(5).keys()).map((skeletonRow) => (
            <Box key={`skeleton-${skeletonRow}`} display={"flex"} gap={"1rem"}>
              <Skeleton variant="rounded" width={"15rem"} height={"2rem"} />
              <Skeleton variant="rounded" width={"15rem"} height={"2rem"} />
            </Box>
          ))}
        </Box>
      </Paper>
    </>
  );
}
