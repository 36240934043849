import { useMemo } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Autocomplete, Box, IconButton, TextField, Tooltip, Typography, styled } from "@mui/material";
import MuiPopper, { PopperProps } from "@mui/material/Popper";

import { AdvancedSearchLocators } from "@constants";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";
import { useSavedSearch } from "features/search/hooks";

export function SaveSearch() {
  const {
    userSavedSearchOptions,
    saveSearchName,
    selectedSavedSearch,
    showEmptyErrorMessage,
    submitUserSavedSearch,
    setSaveSearchName,
    filteredOptions,
    handleSavedSearchChange,
    handleDeleteUserSavedSearch,
  } = useSavedSearch();

  const Popper = styled(MuiPopper)(() => ({
    "& .MuiAutocomplete-noOptions": { display: "none" },
  }));

  return useMemo(() => {
    return (
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Box sx={{ display: "flex", flexGrow: 2, marginRight: ".5rem" }}>
          <Autocomplete
            id={AdvancedSearchLocators.UserSearchName}
            data-testid={AdvancedSearchLocators.UserSearchName}
            isOptionEqualToValue={(option, value) => value.id === 0 || option.id === value.id}
            getOptionLabel={(option) => option.inputValue ?? option.savedSearchName}
            filterOptions={filteredOptions}
            value={selectedSavedSearch}
            autoSelect
            autoHighlight
            renderInput={(params) => (
              <Tooltip
                title={selectedSavedSearch ? selectedSavedSearch.inputValue ?? selectedSavedSearch.savedSearchName : ""}
                placement="top"
              >
                <TextField
                  {...params}
                  id={AdvancedSearchLocators.UserSearchNameTextfield}
                  inputProps={{ ...params.inputProps, "data-testid": AdvancedSearchLocators.UserSearchNameTextfield }}
                  label="Saved searches"
                  error={showEmptyErrorMessage}
                  helperText={
                    showEmptyErrorMessage ? VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK : "Enter name to save a new search"
                  }
                />
              </Tooltip>
            )}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props} key={option.id}>
                  <Typography variant="body2" color="text.primary" display="block">
                    {option.inputValue ?? option.savedSearchName}
                  </Typography>
                </Box>
              );
            }}
            PopperComponent={(props: PopperProps) => <Popper {...props} />}
            onInputChange={(_event, newInputValue) => {
              setSaveSearchName(newInputValue);
            }}
            onChange={handleSavedSearchChange}
            options={userSavedSearchOptions}
          />
        </Box>
        <Box sx={{ marginTop: ".25rem" }}>
          <IconButton
            id={AdvancedSearchLocators.SaveUserSearchButton}
            data-testid={AdvancedSearchLocators.SaveUserSearchButton}
            size="small"
            onClick={submitUserSavedSearch}
          >
            <SaveIcon />
          </IconButton>
          <IconButton
            id={AdvancedSearchLocators.DeleteUserSearchButton}
            data-testid={AdvancedSearchLocators.DeleteUserSearchButton}
            size="small"
            onClick={handleDeleteUserSavedSearch}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSavedSearchOptions, saveSearchName, filteredOptions, showEmptyErrorMessage]);
}
