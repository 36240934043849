import { useEffect, useState } from "react";

import { useReferenceDataContext } from "context";
import { useAsync } from "hooks";
import { GeocodeResult, isErrorGeocodeResult } from "features/arcgis/models";
import { reverseGeocode } from "features/arcgis/services";
import { useOpportunityContext } from "features/opportunities/context";
import { Country } from "features/opportunities/models";

export function useLocationSectionHooks() {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, updateField } = useOpportunityContext();
  const [response, run] = useAsync(reverseGeocode);

  useEffect(() => {
    setIsInitialLoad(false);
  }, []);

  useEffect(() => {
    const isCoordinateValid =
      opportunity.longitude &&
      opportunity.latitude &&
      !isNaN(opportunity.longitude) &&
      !isNaN(opportunity.latitude) &&
      !formErrors.latitude &&
      !formErrors.longitude;

    if (isInitialLoad && opportunity.country && opportunity.stateProvince) {
      return;
    }

    if (!isCoordinateValid && isInitialLoad) {
      return;
    }
    if (isCoordinateValid) {
      run(opportunity.longitude!, opportunity.latitude!);
      return;
    }
    cleanLocationInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.latitude, opportunity.longitude, formErrors.latitude, formErrors.longitude]);

  useEffect(() => {
    if (response.status === "error" || isErrorGeocodeResult(response.data)) {
      cleanLocationInformation();
      return;
    }

    if (!response.data) {
      return;
    }

    setLocationInformation(response.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  const cleanLocationInformation = () => {
    updateField("country", undefined);
    updateField("countryId", undefined);
    updateField("stateProvince", undefined);
    updateField("rtxRegion", undefined);
    updateField("rtxSubRegion", undefined);
  };

  const setLocationInformation = (responseData: GeocodeResult) => {
    const country = getCountryByCode(referenceData.countries, responseData.address.CountryCode);

    const newCountryName = country?.name || responseData.address.CountryCode;
    if (shouldUpdateField(opportunity.country, newCountryName)) {
      updateField("country", newCountryName);
    }
    if (opportunity.countryId !== country?.id) {
      updateField("countryId", country?.id);
    }
    if (opportunity.rtxRegion !== country?.rtxRegion) {
      updateField("rtxRegion", country?.rtxRegion);
    }
    if (opportunity.rtxSubRegion !== country?.rtxSubRegion) {
      updateField("rtxSubRegion", country?.rtxSubRegion);
    }
    if (shouldUpdateField(opportunity.stateProvince, responseData.address.Region)) {
      updateField("stateProvince", responseData.address.Region);
    }
  };

  const shouldUpdateField = (initialValue: string | undefined, newValue: string | undefined) => {
    if (initialValue !== newValue && (newValue || (initialValue && newValue === ""))) {
      return true;
    }
    return false;
  };
}

const getCountryByCode = (countries: Country[], countryCode?: string): Country | undefined => {
  return countries && countryCode ? countries.find((country) => country.countryCode === countryCode) : undefined;
};
