import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";

import { CardBodyLocators, ReferenceDocumentLocators } from "@constants";
import { PropsWithTestId } from "types";
import { nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import { referenceDataMenuItems } from "features/opportunities/components";
import { useReferenceDocument } from "features/opportunities/hooks";
import { ReferenceDocumentType } from "features/opportunities/models";

interface ReferenceDocumentDialogProps {
  initialRefDoc: ReferenceDocumentType | null;
  shouldOpen: boolean;
  confirmButtonText: string;
  onSave: () => void;
  onClose: () => void;
}

export function AddEditReferenceDocument({
  initialRefDoc,
  shouldOpen,
  confirmButtonText,
  onSave,
  onClose,
}: ReferenceDocumentDialogProps) {
  const { referenceData } = useReferenceDataContext();
  const {
    refDocFormErrors,
    selectedReferenceDocument,
    isReferenceDocumentValid,
    handleInputChange,
    handleTypeChange,
    handleSubmit,
    resetErrors,
    setSelectedReferenceDocument,
  } = useReferenceDocument(initialRefDoc);

  const [open, toggleOpen] = useState(false);

  const handleSaveRefDoc = () => {
    if (selectedReferenceDocument && !isReferenceDocumentValid(selectedReferenceDocument)) {
      return;
    }

    handleSubmit();
    onSave();
  };

  const handleCloseRefDoc = () => {
    onClose();
    resetErrors();
  };

  useEffect(() => {
    toggleOpen(shouldOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpen]);

  useEffect(() => {
    setSelectedReferenceDocument(initialRefDoc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRefDoc]);

  return useMemo(
    () => {
      const nameof = nameOfFactory<ReferenceDocumentType>();

      return (
        <Dialog
          open={open}
          onClose={handleCloseRefDoc}
          id={ReferenceDocumentLocators.NewReferenceDialog}
          data-testid={ReferenceDocumentLocators.NewReferenceDialog}
        >
          <DialogTitle>{selectedReferenceDocument?.isNew ? "New Reference" : "Edit Reference"}</DialogTitle>
          <DialogContent sx={{ width: "600px" }}>
            <Box
              id={ReferenceDocumentLocators.NewDocument}
              data-testid={ReferenceDocumentLocators.NewDocument}
              sx={{ flexGrow: 1, display: "flex", gap: "1rem", flexDirection: "column", paddingTop: ".5rem" }}
            >
              <TextField
                id={ReferenceDocumentLocators.NewTitle}
                inputProps={{ "data-testid": ReferenceDocumentLocators.NewTitle }}
                required
                variant="outlined"
                fullWidth
                label="Title"
                value={selectedReferenceDocument?.title ?? ""}
                name={nameof("title")}
                onChange={handleInputChange}
                error={Boolean(selectedReferenceDocument?.title !== undefined && refDocFormErrors.title)}
                helperText={
                  selectedReferenceDocument?.title !== undefined && refDocFormErrors.title
                    ? refDocFormErrors.title
                    : undefined
                }
              />
              <FormControl required error={Boolean(refDocFormErrors.referenceDocumentTypeId)}>
                <InputLabel
                  id={ReferenceDocumentLocators.NewTypeLabel}
                  data-testid={ReferenceDocumentLocators.NewTypeLabel}
                >
                  Type
                </InputLabel>
                <Select
                  labelId={ReferenceDocumentLocators.NewTypeLabel}
                  id={ReferenceDocumentLocators.NewTypeSelect}
                  label="Type"
                  name={nameof("referenceDocumentTypeId")}
                  onChange={handleTypeChange}
                  value={selectedReferenceDocument?.referenceDocumentTypeId ?? 0}
                  SelectDisplayProps={{ "data-testid": ReferenceDocumentLocators.NewTypeSelect } as PropsWithTestId}
                  error={Boolean(
                    selectedReferenceDocument?.referenceDocumentTypeId === 0 && refDocFormErrors.referenceDocumentTypeId
                  )}
                >
                  {referenceDataMenuItems(referenceData?.referenceDocumentTypes, "opp-reference-document-")}
                </Select>
                {refDocFormErrors.referenceDocumentTypeId && (
                  <FormHelperText>{refDocFormErrors.referenceDocumentTypeId}</FormHelperText>
                )}
              </FormControl>
              <TextField
                id={ReferenceDocumentLocators.NewUrl}
                inputProps={{ "data-testid": ReferenceDocumentLocators.NewUrl }}
                variant="outlined"
                fullWidth
                label="URL"
                name={nameof("url")}
                onChange={handleInputChange}
                value={selectedReferenceDocument?.url ?? ""}
                error={Boolean(selectedReferenceDocument?.url && refDocFormErrors.url)}
                helperText={selectedReferenceDocument?.url && refDocFormErrors.url ? refDocFormErrors.url : undefined}
              />
              <TextField
                id={ReferenceDocumentLocators.NewNotes}
                inputProps={{ "data-testid": ReferenceDocumentLocators.NewNotes }}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                label="Notes"
                name={nameof("notes")}
                onChange={handleInputChange}
                value={selectedReferenceDocument?.notes ?? ""}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseRefDoc}
              variant="outlined"
              id={CardBodyLocators.NamedEntityButtonCancel}
              data-testid={CardBodyLocators.NamedEntityButtonCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveRefDoc}
              id={CardBodyLocators.NamedEntityButtonAdd}
              data-testid={CardBodyLocators.NamedEntityButtonAdd}
              variant="contained"
            >
              {confirmButtonText}
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialRefDoc, confirmButtonText, open, selectedReferenceDocument, refDocFormErrors]
  );
}
