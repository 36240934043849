import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { useReferenceDataContext } from "context";
import { referenceDataMenuItems } from "features/opportunities/components";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

export function Origin() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, updateField } = useOpportunityContext();

  return useMemo(() => {
    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    return (
      <FormControl required>
        <InputLabel id={CardBodyLocators.Origin} data-testid={CardBodyLocators.Origin}>
          Origin
        </InputLabel>
        <Select
          labelId={CardBodyLocators.Origin}
          id={CardBodyLocators.OriginSelect}
          label="Origin"
          name={opportunityTypeOfFactory("originId")}
          onChange={handleSelectionChange}
          value={opportunity.originId || 0}
          SelectDisplayProps={{ "data-testid": CardBodyLocators.OriginSelect } as PropsWithTestId}
          error={Boolean(opportunity.originId && formErrors.originId)}
        >
          {referenceDataMenuItems(referenceData?.opportunityOriginTypes, "opp-origin-type-")}
        </Select>
        {opportunity.originId > 0 && formErrors.originId && <FormHelperText>{formErrors.originId}</FormHelperText>}
      </FormControl>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.originId, referenceData?.opportunityOriginTypes, formErrors.originId]);
}
