import { ChangeEvent, useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import { ConditionalGrid, referenceDataMenuItems } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useLocationSectionHooks } from "features/opportunities/hooks";
import { OpportunityType } from "features/opportunities/models";

export function LocationSection() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();
  useLocationSectionHooks();

  return useMemo(() => {
    const nameof = nameOfFactory<OpportunityType>();

    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.value);
    };

    const bothCoordinatesRequired = opportunity.longitude || opportunity.latitude;

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["longitude"] ?? true}>
            <TextField
              id={CardBodyLocators.Longitude}
              label="Longitude"
              name={nameof("longitude")}
              onChange={handleInputChange}
              value={opportunity.longitude || ""}
              inputProps={{ "data-testid": CardBodyLocators.Longitude, maxLength: 15 }}
              error={Boolean(bothCoordinatesRequired && formErrors.longitude)}
              helperText={bothCoordinatesRequired && formErrors.longitude ? formErrors.longitude : undefined}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["latitude"] ?? true}>
            <TextField
              id={CardBodyLocators.Latitude}
              label="Latitude"
              name={nameof("latitude")}
              onChange={handleInputChange}
              value={opportunity.latitude || ""}
              inputProps={{ "data-testid": CardBodyLocators.Latitude, maxLength: 15 }}
              error={Boolean(bothCoordinatesRequired && formErrors.latitude)}
              helperText={bothCoordinatesRequired && formErrors.latitude ? formErrors.latitude : undefined}
            />
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["locationAccuracyId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.LocationAccuracy} data-testid={CardBodyLocators.LocationAccuracy}>
                Accuracy
              </InputLabel>
              <Select
                labelId={CardBodyLocators.LocationAccuracy}
                id={CardBodyLocators.LocationAccuracySelect}
                label="Accuracy"
                name={nameof("locationAccuracyId")}
                onChange={handleSelectionChange}
                value={opportunity.locationAccuracyId || 0}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.LocationAccuracySelect } as PropsWithTestId}
                error={Boolean(opportunity.locationAccuracyId && formErrors.locationAccuracyId)}
              >
                {referenceDataMenuItems(referenceData?.locationAccuracies, "opp-location-accurracy-")}
              </Select>
              {Number(opportunity.locationAccuracyId) > 0 && formErrors.locationAccuracyId && (
                <FormHelperText>{formErrors.locationAccuracyId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["countryId"] ?? true}>
            <TextField
              disabled
              id={CardBodyLocators.Country}
              label="Country"
              value={opportunity.country || ""}
              inputProps={{ "data-testid": CardBodyLocators.Country }}
              error={Boolean(opportunity.countryId && formErrors.countryId)}
              helperText={opportunity.countryId && formErrors.countryId ? formErrors.countryId : undefined}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["stateProvince"] ?? true}>
            <TextField
              disabled
              id={CardBodyLocators.StateProvince}
              label="State / Province"
              name={nameof("stateProvince")}
              onChange={handleInputChange}
              value={opportunity.stateProvince || ""}
              inputProps={{ "data-testid": CardBodyLocators.StateProvince }}
              error={Boolean(opportunity.stateProvince && formErrors.stateProvince)}
              helperText={opportunity.stateProvince && formErrors.stateProvince ? formErrors.stateProvince : undefined}
            />
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["rtxRegion"] ?? true}>
            <TextField
              disabled
              id={CardBodyLocators.RtxRegion}
              label="RTX Region"
              name={nameof("rtxRegion")}
              onChange={handleInputChange}
              value={opportunity.rtxRegion || ""}
              inputProps={{ "data-testid": CardBodyLocators.RtxRegion }}
              error={Boolean(opportunity.rtxRegion && formErrors.rtxRegion)}
              helperText={opportunity.rtxRegion && formErrors.rtxRegion ? formErrors.rtxRegion : undefined}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["rtxSubRegion"] ?? true}>
            <TextField
              disabled
              id={CardBodyLocators.RtxSubRegion}
              label="RTX Subregion"
              name={nameof("rtxSubRegion")}
              onChange={handleInputChange}
              value={opportunity.rtxSubRegion || ""}
              inputProps={{ "data-testid": CardBodyLocators.RtxSubRegion }}
              error={Boolean(opportunity.rtxSubRegion && formErrors.rtxSubRegion)}
              helperText={opportunity.rtxSubRegion && formErrors.rtxSubRegion ? formErrors.rtxSubRegion : undefined}
            />
          </ConditionalGrid>
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.latitude,
    opportunity.locationAccuracyId,
    referenceData.locationAccuracies,
    opportunity.longitude,
    opportunity.rtxRegion,
    opportunity.rtxSubRegion,
    opportunity.country,
    opportunity.stateProvince,
    formErrors.longitude,
    formErrors.latitude,
    formErrors.locationAccuracyId,
    formErrors.countryId,
    formErrors.stateProvince,
    formErrors.rtxRegion,
    formErrors.rtxSubRegion,
    opportunityAttributes,
  ]);
}
