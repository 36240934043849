import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import { responseToFormModel } from "services";
import { useAsync } from "hooks";
import { useOpportunityContext } from "features/opportunities/context";
import {
  getAlertsByOpportunityId,
  getCommentByOpportunityId,
  getOpportunityById,
} from "features/opportunities/services";
import { isSearchPath } from "features/search/services";

import { ActionTypes } from "../reducers/opportunityReducer";

export function useGetOpportunityAndReplaceForm() {
  const { opportunityId } = useParams();
  const location = useLocation();
  const [response, run] = useAsync(getOpportunityById);
  const [opportunityCommentResponse, runGetOpportunityComment] = useAsync(getCommentByOpportunityId);
  const [opportunityAlertResponse, runGetOpportunityAlert] = useAsync(getAlertsByOpportunityId);
  const { replaceForm, dispatch } = useOpportunityContext();

  useEffect(() => {
    if (response.status === "error") {
      console.error("Error on retrieving the opportunity.");
      return;
    }

    if (opportunityCommentResponse.status === "error") {
      console.error("Error on retrieving the opportunity comments.");
      return;
    }

    if (opportunityAlertResponse.status === "error") {
      console.error("Error on retrieving the opportunity alerts.");
      return;
    }

    if (response.data) {
      const opportunityFormModel = responseToFormModel(response.data);
      replaceForm(opportunityFormModel);

      if (opportunityCommentResponse.data) {
        dispatch({
          type: ActionTypes.REPLACE_COMMENTS,
          payload: opportunityCommentResponse.data,
        });
      }

      if (opportunityAlertResponse.data) {
        dispatch({
          type: ActionTypes.REPLACE_ALERTS,
          payload: opportunityAlertResponse.data,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    response.data,
    response.status,
    opportunityCommentResponse.data,
    opportunityCommentResponse.status,
    opportunityAlertResponse.data,
    opportunityAlertResponse.status,
  ]);

  useEffect(() => {
    if (opportunityId) {
      run(opportunityId);
      runGetOpportunityComment(opportunityId);
      runGetOpportunityAlert(opportunityId);
      return;
    }

    if (!isSearchPath(location)) {
      console.error("No ID provided as URL route parameter");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId, location.pathname]);
}
