import { useMemo } from "react";
import { TextField } from "@mui/material";

import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";
import { AdvancedSearchModelType } from "features/search/models";

type Props = {
  id: string;
  label: string;
  propKey: keyof AdvancedSearchModelType;
  value: string;
  errorText?: string;
};

export function AdvancedSearchCriteriaTextfield({ id, label, propKey, value, errorText }: Props) {
  const { setFieldValue } = useAdvancedSearchContext();
  const textfieldId = `advanced-search-${id}`;

  const updateField = (name: keyof AdvancedSearchModelType, value: string) => {
    setFieldValue({ name, value });
  };

  return useMemo(() => {
    return (
      <TextField
        id={textfieldId}
        label={label}
        onChange={(event) => updateField(propKey, event.target.value)}
        value={value || ""}
        inputProps={{
          "data-testid": textfieldId,
        }}
        error={!!errorText}
        helperText={errorText}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, errorText]);
}
