import { useMemo } from "react";
import { Autocomplete, Box, Paper, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { useAuthorization } from "context";
import { ConditionalGrid } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useSearchParentOpportunities } from "features/opportunities/hooks";
import { ParentOpportunityType } from "features/opportunities/models";

import { NamedEntityField } from "./Commercial/NamedEntityField";
import { Origin } from "./Lineage/Origin";

export function LineageSection() {
  const { opportunity, formErrors, opportunityAttributes } = useOpportunityContext();
  const { parentOptions, parentOptionValue, parentInputValue, onParentValueChange, setParentInputValue } =
    useSearchParentOpportunities();
  const { isPrivileged, isAdmin } = useAuthorization();
  const hasRestrictedAccess = isPrivileged || isAdmin;

  return useMemo(() => {
    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["originId"] ?? true}>
            <Origin />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["originNameId"] ?? true}>
            <NamedEntityField
              labelName="Origin Name"
              selectionField={CardBodyLocators.OriginNameSelect}
              selectionFieldIdName={CardBodyLocators.OriginNameId}
            />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["parentOpportunityId"] ?? true}>
          <Autocomplete
            id={CardBodyLocators.ParentOpportunity}
            getOptionLabel={(option) => `${option.opportunityName} [${option.id}]`}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={(x) => x}
            options={parentOptions}
            autoComplete
            value={parentOptionValue}
            inputValue={
              !hasRestrictedAccess && parentOptionValue?.restrictedFlag ? "=== Restricted ===" : parentInputValue
            }
            autoHighlight
            noOptionsText={parentInputValue ? "No opportunities found" : "Start typing to search"}
            onChange={(_event: any, newValue: ParentOpportunityType | null) => {
              onParentValueChange(newValue);
            }}
            onInputChange={(_event, newInputValue) => {
              setParentInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.ParentOpportunity }}
                label="Parent Opportunity"
                fullWidth
                error={Boolean(opportunity.parentOpportunityId && formErrors.parentOpportunityId)}
                helperText={
                  opportunity.parentOpportunityId && formErrors.parentOpportunityId
                    ? formErrors.parentOpportunityId
                    : undefined
                }
              />
            )}
            PaperComponent={(props) => {
              return (
                <Paper
                  id={CardBodyLocators.ParentOpportunitySelect}
                  data-testid={CardBodyLocators.ParentOpportunitySelect}
                  {...props}
                />
              );
            }}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props} key={option.id}>
                  <Typography variant="body2" color="text.primary" display="block">
                    {option.opportunityName} [{option.id}]
                  </Typography>
                </Box>
              );
            }}
            disabled={!hasRestrictedAccess && parentOptionValue?.restrictedFlag}
          />
        </ConditionalGrid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.originNameId,
    opportunity.parentOpportunityId,
    parentOptions,
    parentOptionValue,
    formErrors.originNameId,
    formErrors.parentOpportunityId,
    opportunityAttributes,
  ]);
}
