import { useState, useEffect } from "react";
import { SelectChangeEvent } from "@mui/material";
import { saveAs } from "file-saver";

import { BulkUploadTotalResult } from "types";
import { uploadFile, getCsvTemplate } from "services";
import { useReferenceDataContext } from "context";
import { useAsync } from "hooks";
import { IdName } from "features/opportunities/models";

export function useBulkUpload() {
  const [currentFile, setCurrentFile] = useState<File>();
  const [currentFilename, setCurrentFilename] = useState("");
  const [uploadResponse, runUpload] = useAsync(uploadFile);
  const [templateResponse, runGetTemplate] = useAsync(getCsvTemplate);
  const [uploadErrors, setUploadErrors] = useState<BulkUploadTotalResult>();
  const [selectedTableNameId, setSelectedTableNameId] = useState(0);
  const [selectedTableName, setSelectedTableName] = useState("");
  const [apiErrorMessage, setApiErrorMessage] = useState<string | undefined>();
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isFileValid, setIsFileValid] = useState(true);
  const { referenceData } = useReferenceDataContext();
  const [bulkUploadEntities, setBulkUploadEntities] = useState<IdName<number>[]>();

  const handleTableNameChange = (event: SelectChangeEvent<number>) => {
    setSelectedTableNameId(parseInt(event.target.value as string));

    if (bulkUploadEntities === undefined) {
      return;
    }

    const selectedEntity = bulkUploadEntities.find((x) => x.id === event.target.value);
    if (selectedEntity) {
      setSelectedTableName(selectedEntity.name);
      setDisabledSubmit(currentFile === undefined);
      return;
    }

    setDisabledSubmit(true);
  };

  const handleDownload = async () => {
    runGetTemplate((selectedTableNameId - 1).toString());
  };

  useEffect(() => {
    if (referenceData && referenceData.bulkUploadList) {
      let bulkUploadEntities = referenceData.bulkUploadList.map((item, index) => ({ id: index + 1, name: item }));
      setBulkUploadEntities(bulkUploadEntities);
    }
  }, [referenceData]);

  useEffect(() => {
    if (templateResponse.data) {
      const blob = templateResponse.data as unknown as Blob;
      saveAs(blob, `${selectedTableName}Template.csv`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateResponse.data]);

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isCsv = isCsvFile(event.target.value);
    setIsFileValid(isCsv);

    const { files } = event.target;
    const selectedFiles = files as FileList;
    setCurrentFile(selectedFiles?.[0]);
    setCurrentFilename(event.target.value);
    setDisabledSubmit(selectedTableNameId === 0 || selectedFiles?.[0] === undefined || !isCsv);
    setUploadErrors(undefined);
    setApiErrorMessage(undefined);
  };

  const submitButtonHandler = () => {
    if (currentFile) {
      let formData = new FormData();
      formData.append("file", currentFile);
      formData.append("tableName", selectedTableName);
      setDisabledSubmit(true);
      runUpload(formData);
    }
  };

  useEffect(() => {
    if (uploadResponse.data) {
      setUploadErrors(uploadResponse.data);
      resetFileAndButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResponse.data]);

  useEffect(() => {
    if (uploadResponse.error?.response) {
      setApiErrorMessage(uploadResponse.error?.response?.data.detail);
      resetFileAndButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResponse.error]);

  const resetFileAndButton = () => {
    setCurrentFile(undefined);
    setCurrentFilename("");
    setDisabledSubmit(true);
    setIsFileValid(true);
  };

  const isCsvFile = (filePath: string) => {
    return filePath === "" || filePath.endsWith(".csv");
  };

  return {
    currentFile,
    currentFilename,
    apiErrorMessage,
    uploadErrors,
    selectedTableNameId,
    disabledSubmit,
    isFileValid,
    handleTableNameChange,
    selectFile,
    submitButtonHandler,
    handleDownload,
    bulkUploadEntities,
  };
}
