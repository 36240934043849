import { Suspense } from "react";
import { RouteObject } from "react-router-dom";

import { PATH } from "@constants";
import { Loading } from "components";
import { MainLayout } from "layouts";
import { BulkUpload } from "pages";

export const AdminRoutes: RouteObject = {
  path: PATH.BULK_UPLOAD,
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<Loading />}>
          <BulkUpload />
        </Suspense>
      ),
    },
  ],
};
