import { z } from "zod";

import { DateFieldType } from "types";
import { zodEmptyDateRefinement, zodInvalidDateRefinement } from "services";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const OwnershipSchema = z
  .object({
    id: z.number(),
    ownerId: z.number().refine((val) => {
      return val !== undefined && val > 0;
    }, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK),
    ownerName: z.string().optional(),
    ownerType: z.string().optional(),
    ownershipFromDate: z
      .custom<DateFieldType>()
      .superRefine(zodEmptyDateRefinement)
      .superRefine(zodInvalidDateRefinement),
    ownershipToDate: z.custom<DateFieldType>().superRefine(zodInvalidDateRefinement),
    ownerShareRate: z.coerce
      .number()
      .min(1, VALIDATION_MESSAGES.NUMERIC_MIN_MAX(1, 100))
      .max(100, VALIDATION_MESSAGES.NUMERIC_MIN_MAX(1, 100))
      .refine((val) => {
        return val !== undefined;
      }, VALIDATION_MESSAGES.NUMERIC_MIN_MAX(1, 100)),
    isDeleted: z.boolean().optional(),
    isNew: z.boolean().optional(),
  })
  .refine(
    ({ ownershipFromDate, ownershipToDate }) => {
      if (ownershipFromDate && ownershipFromDate.isValid() && ownershipToDate && ownershipToDate.isValid()) {
        return ownershipFromDate <= ownershipToDate;
      }

      return true;
    },
    { message: VALIDATION_MESSAGES.FROM_TO_DATE, path: ["ownershipToDate"] }
  );

export interface OwnershipType extends z.infer<typeof OwnershipSchema> {}

export const validateOwnership = (ownership: OwnershipType) => {
  const isValidData = OwnershipSchema.safeParse(ownership);
  return isValidData;
};
