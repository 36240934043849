export const SharedMessages = {
  ComingSoon: "This feature will be coming soon.",
  AddFavorite: "Mark Opportunity as favorite.",
  RemoveFavorite: "Remove Opportunity from favorites.",
  SomethingWentWrong: "Something went wrong. See console for details.",
  SomethingWentWrongContactAdmin: "Something went wrong. Please contact the administrators.",
  SomethingWentWrongSeeErrors: "Something went wrong. See errors below:",
  FileProcessedSuccessfully: "The file was processed successfully!",
  ValidationError: "Validation error:",
};
