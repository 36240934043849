import { useEffect, useState } from "react";

import { removeSelfReference, nameOfFactory } from "services";
import { useAuthorization } from "context";
import { useAsync } from "hooks";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType, ParentOpportunityType } from "features/opportunities/models";
import { ActionTypes, FormActionType } from "features/opportunities/reducers";
import { searchForParentOpportunities } from "features/opportunities/services";

import { useFetch } from "./useFetch";

export function useSearchParentOpportunities() {
  const { opportunity, dispatch, updateField } = useOpportunityContext();
  const [response, run] = useAsync(searchForParentOpportunities);
  const { isPrivileged, isAdmin } = useAuthorization();
  const hasRestrictedAccess = isPrivileged || isAdmin;

  const [parentOptionValue, setParentOptionValue] = useState<ParentOpportunityType | null>(null);
  const [parentReset, setParentReset] = useState(false);
  const [parentInputValue, setParentInputValue] = useState("");
  const [parentOptions, setParentOptions] = useState<ParentOpportunityType[]>([]);
  const nameof = nameOfFactory<OpportunityType>();
  const fetch = useFetch(run);

  useEffect(() => {
    if (response.data) {
      setParentOptions(
        removeSelfReference(response.data, opportunity.id).filter((opportunity) =>
          hasRestrictedAccess ? opportunity : !opportunity.restrictedFlag
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  useEffect(() => {
    if (opportunity.parentOpportunity && !parentReset) {
      setParentOptions([opportunity.parentOpportunity]);
      setParentOptionValue(opportunity.parentOpportunity);
    } else {
      setParentOptions(parentOptionValue ? [parentOptionValue] : []);
    }
    fetch(parentInputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentOptionValue, parentInputValue, fetch, parentReset, opportunity.parentOpportunity]);

  const onParentValueChange = (newValue: ParentOpportunityType | null) => {
    updateField(nameof("parentOpportunityId"), newValue?.id);

    const action: FormActionType = newValue
      ? { type: ActionTypes.ADD_PARENT_OPPORTUNITY_ACTION, payload: newValue }
      : { type: ActionTypes.REMOVE_PARENT_OPPORTUNITY_ACTION };
    dispatch(action);

    setParentOptions(newValue ? [newValue, ...parentOptions] : parentOptions);
    setParentReset(true);
    setParentOptionValue(newValue);
  };

  return { parentOptions, parentOptionValue, parentInputValue, onParentValueChange, setParentInputValue };
}
