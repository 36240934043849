import z from "zod";

import { DateFieldType } from "types";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const zodEmptyDateRefinement = (val: DateFieldType, ctx: z.RefinementCtx) => {
  if (val === null) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ctx.path,
      message: VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK,
    });
  }
};

export const zodInvalidDateRefinement = (val: DateFieldType, ctx: z.RefinementCtx) => {
  if (val && !val.isValid()) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: ctx.path,
      message: VALIDATION_MESSAGES.INVALID_DATE,
    });
  }
};
