import L from "leaflet";

import { OpportunityType } from "features/opportunities/models";

export type IconSetting = {
  width: number;
  height: number;
  color: string;
  anchorWidth: number;
  anchorHeight: number;
};

export const getCustomMarker = (opportunity: OpportunityType, color: string) => {
  switch (opportunity.opportunityTypeId) {
    case 1:
      return getGenerativeIcon(getSettings(opportunity, color));
    case 2:
      return getProjectIcon(getSettings(opportunity, color));
    case 3:
      return getTargetIcon(getSettings(opportunity, color));
    case 4:
      return getProgrammeIcon(getSettings(opportunity, color));
    default:
  }
};

const getTemplateSetting = (color: string, size: { x: number; y: number }, anchorSize: { x: number; y: number }) => {
  return {
    width: size.x,
    height: size.y,
    anchorWidth: anchorSize.x,
    anchorHeight: anchorSize.y,
    color: color,
  } as IconSetting;
};

export const getSettings = (opportunity: OpportunityType, color: string): IconSetting => {
  switch (opportunity.explorationStageId) {
    case 5:
      return getTemplateSetting(color, { x: 35, y: 35 }, { x: 17, y: 17 });
    case 4:
      return getTemplateSetting(color, { x: 30, y: 30 }, { x: 15, y: 15 });
    case 3:
      return getTemplateSetting(color, { x: 25, y: 25 }, { x: 12, y: 12 });
    case 2:
      return getTemplateSetting(color, { x: 20, y: 20 }, { x: 10, y: 10 });
    case 1:
      return getTemplateSetting(color, { x: 15, y: 15 }, { x: 7, y: 7 });
    default:
      return getTemplateSetting(color, { x: 40, y: 40 }, { x: 20, y: 20 });
  }
};

const getLeafletIcon = (settings: IconSetting, svg: string) => {
  return L.divIcon({
    html: svg,
    className: "",
    iconSize: [settings.width, settings.height],
    iconAnchor: [settings.width / 2, settings.width / 2],
  });
};

const getGenerativeIcon = (settings: IconSetting) => {
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${settings.width}" height="${settings.height}" viewBox="0 0 448 512">
  <path fill="${settings.color}" d="M384 32C419.3 32 448 60.65 448 96V416C448 451.3 419.3 480 384 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H384zM384 80H64C55.16 80 48 87.16 48 96V416C48 424.8 55.16 432 64 432H384C392.8 432 400 424.8 400 416V96C400 87.16 392.8 80 384 80z"/></svg>`;
  return getLeafletIcon(settings, svg);
};

const getTargetIcon = (settings: IconSetting) => {
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${settings.width}" height="${settings.height}" viewBox="0 0 512 512">
  <path fill="${settings.color}" d="M160 256C160 202.1 202.1 160 256 160C309 160 352 202.1 352 256C352 309 309 352 256 352C202.1 352 160 309 160 256zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg>`;
  return getLeafletIcon(settings, svg);
};

const getProjectIcon = (settings: IconSetting) => {
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${settings.width}" height="${settings.height}" viewBox="0 0 576 512"><path fill="${settings.color}" d="M287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0L287.9 0zM287.9 78.95L235.4 187.2C231.9 194.3 225.1 199.3 217.3 200.5L98.98 217.9L184.9 303C190.4 308.5 192.9 316.4 191.6 324.1L171.4 443.7L276.6 387.5C283.7 383.7 292.2 383.7 299.2 387.5L404.4 443.7L384.2 324.1C382.9 316.4 385.5 308.5 391 303L476.9 217.9L358.6 200.5C350.7 199.3 343.9 194.3 340.5 187.2L287.9 78.95z"/></svg>`;
  return getLeafletIcon(settings, svg);
};

const getProgrammeIcon = (settings: IconSetting) => {
  var svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${settings.width}" height="${settings.height}" viewBox="0 0 512 512">
  <path fill="${settings.color}" d="M177.1 228.6L207.9 320h96.5l29.62-91.38L256 172.1L177.1 228.6zM255.1 0C114.6 0 .0001 114.6 .0001 256S114.6 512 256 512s255.1-114.6 255.1-255.1S397.4 0 255.1 0zM435.2 361.1l-103.9-1.578l-30.67 99.52C286.2 462.2 271.3 464 256 464s-30.19-1.773-44.56-4.93L180.8 359.6L76.83 361.1c-14.93-25.35-24.79-54.01-27.8-84.72L134.3 216.4L100.7 118.1c19.85-22.34 44.32-40.45 72.04-52.62L256 128l83.29-62.47c27.72 12.17 52.19 30.27 72.04 52.62L377.7 216.4l85.23 59.97C459.1 307.1 450.1 335.8 435.2 361.1z"/></svg>`;
  return getLeafletIcon(settings, svg);
};
