import { ChangeEvent, useMemo } from "react";
import { TextField } from "@mui/material";

import { CardBodyLocators, Mode } from "@constants";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

export function OpportunityCode() {
  const { opportunity, mode, formErrors, updateField } = useOpportunityContext();

  return useMemo(() => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.value);
    };

    return (
      <TextField
        required
        id={CardBodyLocators.OpportunityCode}
        label="Opportunity Code"
        name={opportunityTypeOfFactory("opportunityCode")}
        onChange={handleInputChange}
        value={opportunity.opportunityCode}
        disabled={mode === Mode.Edit}
        inputProps={{ "data-testid": CardBodyLocators.OpportunityCode, style: { textTransform: "uppercase" } }}
        error={Boolean(opportunity.opportunityCode && formErrors.opportunityCode)}
        helperText={opportunity.opportunityCode && formErrors.opportunityCode ? formErrors.opportunityCode : undefined}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.opportunityCode, mode, formErrors.opportunityCode]);
}
