import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField, Paper, InputAdornment, Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";

import { Mode, OwnershipLocators } from "@constants";
import { DateFieldType } from "types";
import { nameOfFactory } from "services";
import { useOpportunityContext } from "features/opportunities/context";
import { OwnershipFieldsType, useSearchNamedEntities } from "features/opportunities/hooks";
import { NamedEntity, OwnershipType } from "features/opportunities/models";

interface AddEditOwnershipProps {
  ownershipMode: string;
  fields: OwnershipFieldsType;
  setFields: Dispatch<SetStateAction<OwnershipFieldsType>>;
}

export function AddEditOwnership({ ownershipMode, fields, setFields }: AddEditOwnershipProps) {
  const { formErrors, setFormErrors } = useOpportunityContext();
  const {
    namedEntityOptions,
    namedEntityOptionValue,
    namedEntityInputValue,
    onValueChange,
    setNamedEntityInputValue,
    setNamedEntityValue,
  } = useSearchNamedEntities();

  const [componentFieldsState, setComponentFieldsState] = useState<OwnershipFieldsType>({
    ownerName: undefined,
    ownerId: undefined,
    ownerType: undefined,
    ownerShareRate: undefined,
    ownershipFromDate: null,
    ownershipToDate: null,
  });

  const nameof = nameOfFactory<OwnershipType>();

  useEffect(() => {
    if (ownershipMode !== Mode.Edit) {
      return;
    }
    const ownerName: NamedEntity = {
      id: fields.ownerId ?? 0,
      namedEntityTypeId: 1,
      name: fields?.ownerName ?? "",
      namedEntityType: fields?.ownerType ?? "",
    };
    setNamedEntityValue(ownerName);
    setComponentFieldsState(fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields]);

  return useMemo(
    () => {
      const handleOwnerChange = (_event: any, newValue: NamedEntity | null) => {
        handleFieldChange(nameof("ownerName"), newValue?.name);
        handleFieldChange(nameof("ownerId"), newValue?.id);
        handleFieldChange(nameof("ownerType"), newValue?.namedEntityType);
        onValueChange(newValue);
      };

      const handleShareRateChange = (event: any) => {
        const numeric = event.target.value.replace(/\D/g, "");
        handleFieldChange(nameof("ownerShareRate"), numeric);
      };

      const handleFieldChange = (name: string, value: number | string | DateFieldType | undefined) => {
        setComponentFieldsState((prevState) => ({ ...prevState, [name]: value }));
        setFields((prevState) => ({ ...prevState, [name]: value }));
        setFormErrors((prevState) => ({ ...prevState, [name]: "" }));
      };

      return (
        <Box
          id={OwnershipLocators.OwnershipFieldsBox}
          data-testid={OwnershipLocators.OwnershipFieldsBox}
          sx={{ flexGrow: 1, display: "flex", gap: "1rem", flexDirection: "column", paddingTop: ".5rem" }}
        >
          <Grid container spacing={2} columns={12} alignItems={"flex-start"}>
            <Grid xs={6}>
              <Autocomplete
                id={OwnershipLocators.OwnerName}
                getOptionLabel={(option) =>
                  option.namedEntityType ? `${option.name} [${option.namedEntityType}]` : option.name
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                filterOptions={(x) => x}
                options={namedEntityOptions}
                autoComplete
                value={namedEntityOptionValue}
                autoHighlight
                noOptionsText={namedEntityInputValue ? "No results found" : "Start typing to search"}
                onChange={handleOwnerChange}
                onInputChange={(_event, newInputValue) => {
                  setNamedEntityInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{ ...params.inputProps, "data-testid": OwnershipLocators.OwnerName }}
                    fullWidth
                    label="Owner name"
                    error={Boolean(componentFieldsState.ownerId === undefined && formErrors.ownerId)}
                    helperText={
                      componentFieldsState.ownerId === undefined && formErrors.ownerId ? formErrors.ownerId : undefined
                    }
                  />
                )}
                PaperComponent={(props) => {
                  return (
                    <Paper
                      id={OwnershipLocators.OwnerNameSelect}
                      data-testid={OwnershipLocators.OwnerNameSelect}
                      {...props}
                    />
                  );
                }}
              />
            </Grid>
            <Grid xs={6}>
              <TextField
                required
                label="Ownership share"
                id={OwnershipLocators.OwnershipShareRate}
                name={nameof("ownerShareRate")}
                value={componentFieldsState.ownerShareRate ?? ""}
                onChange={handleShareRateChange}
                InputProps={{
                  endAdornment: <InputAdornment position="start">%</InputAdornment>,
                }}
                inputProps={{
                  "data-testid": OwnershipLocators.OwnershipShareRate,
                }}
                error={Boolean(formErrors.ownerShareRate)}
                helperText={formErrors.ownerShareRate ? formErrors.ownerShareRate : undefined}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                label="From"
                inputFormat="YYYY/MM/DD"
                value={componentFieldsState.ownershipFromDate}
                onChange={(newValue) => handleFieldChange(nameof("ownershipFromDate"), newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    id={OwnershipLocators.OwnershipFromDate}
                    inputProps={{ ...params.inputProps, "data-testid": OwnershipLocators.OwnershipFromDate }}
                    error={Boolean(formErrors.ownershipFromDate)}
                    helperText={formErrors.ownershipFromDate}
                  />
                )}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                label="To"
                inputFormat="YYYY/MM/DD"
                value={componentFieldsState.ownershipToDate}
                onChange={(newValue) => handleFieldChange(nameof("ownershipToDate"), newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id={OwnershipLocators.OwnershipToDate}
                    inputProps={{ ...params.inputProps, "data-testid": OwnershipLocators.OwnershipToDate }}
                    error={Boolean(formErrors.ownershipToDate)}
                    helperText={formErrors.ownershipToDate}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [namedEntityOptions, namedEntityOptionValue, componentFieldsState, fields, setFields, formErrors]
  );
}
