import { IdName, OpportunityType, ReferenceDepositTypeGroup } from "features/opportunities/models";

export interface ReferenceCommodityGroups {
  commodityClasses: {
    commodities: {
      displaySequence: number;
      id: number;
      name: string;
    }[];
    displaySequence: number;
    id: number;
    name: string;
  }[];
}

export const mapIdsToName = (collection?: Array<IdName>, id?: number | null) => {
  if (id && id >= 0 && collection) {
    const match = collection.find((item) => item.id === id);
    if (match) {
      return match.name;
    }
  }
  return "";
};

export const mapIdToValue = (collection?: Array<IdName>, id?: number) => {
  return id !== undefined && id >= 0 && collection ? collection.find((item) => item.id === id)?.name : "";
};

export const splitIdsAndMapToName = (collection?: Array<ReferenceCommodityGroups>, ids?: string): string => {
  if (!collection || !ids) {
    return "";
  }
  const allCommodities = collection?.flatMap((commodityClass) =>
    commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
  );

  return ids.split(",").reduce((accumulator, currentValue) => {
    const id = parseInt(currentValue.trim());
    const collectionItem = allCommodities.find((item) => item.id === id);

    if (!accumulator) {
      return collectionItem?.name ?? "";
    }

    return collectionItem ? `${accumulator}, ${collectionItem.name}` : accumulator;
  }, "");
};

export const getDepositTypeGroupLabel = (
  opportunity: OpportunityType,
  collection?: Array<ReferenceDepositTypeGroup>
): string => {
  if (!collection && !opportunity.depositTypeGroupId) {
    return "";
  }

  return mapIdsToName(collection, opportunity.depositTypeGroupId);
};

export const getDepositTypeClassLabel = (
  opportunity: OpportunityType,
  collection?: Array<ReferenceDepositTypeGroup>
): string => {
  if (!collection && !opportunity.depositTypeClassId) {
    return "";
  }
  return mapIdsToName(
    collection?.flatMap((group) => group.depositTypeClasses),
    opportunity.depositTypeClassId
  );
};

export const getDepositTypeSubclassLabel = (
  opportunity: OpportunityType,
  collection?: Array<ReferenceDepositTypeGroup>
): string => {
  if (!collection && !opportunity.depositTypeSubClassId) {
    return "";
  }
  return mapIdsToName(
    collection?.flatMap((cl) => cl.depositTypeClasses.flatMap((x) => x.depositTypeSubClasses)),
    opportunity.depositTypeSubClassId
  );
};
