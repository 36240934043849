import { z } from "zod";

import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const NamedEntitySchema = z.object({
  id: z.number(),
  namedEntityTypeId: z.number().refine((val) => {
    return val !== undefined && val > 0;
  }, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK),
  namedEntityType: z.string().optional(),
  name: z.string().min(2).max(128),
  contactInfo: z.string().optional(),
  address: z.string().optional(),
  isNew: z.boolean().optional(),
});

export interface NamedEntity extends z.infer<typeof NamedEntitySchema> {
  inputValue?: string;
}

export const initialState: NamedEntity = {
  id: 0,
  namedEntityTypeId: 0,
  name: "",
};

export const validateNamedEntity = (inputs: NamedEntity) => {
  const isValidData = NamedEntitySchema.safeParse(inputs);
  return isValidData;
};
