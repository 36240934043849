import { useEffect, useMemo, useRef } from "react";
import { debounce } from "@mui/material/utils";
import * as L from "leaflet";
import { useMap, FeatureGroup } from "react-leaflet";
import { GeoJSONPolygon } from "wellknown";

import { Mode, UTILS } from "@constants";
import { trimToScale, areCoordinatesValid } from "services";
import { parseAndPopulatePolygons } from "components/utils";
import { useOpportunityContext } from "features/opportunities/context";

import { EditControl } from "./EditControl";

L.Icon.Default.imagePath = "/leaflet_images/";

export function MapEditor() {
  const { opportunity, mode, updateField } = useOpportunityContext();
  const featureGroupRef = useRef<L.FeatureGroup>(new L.FeatureGroup());
  const markerRef = useRef<L.Marker | null>(null);
  const drawRef = useRef<L.Control.Draw>();
  const map = useMap();

  const onMounted = (e: L.Control.Draw) => {
    drawRef.current = e;
  };

  const cleanMarkerRef = () => {
    if (markerRef.current) {
      markerRef.current.removeFrom(map);
      markerRef.current = null;
    }
  };

  const panTo = useMemo(
    () =>
      debounce(
        (map: L.Map, latitude: number, longitude: number) => map.panTo([latitude, longitude]),
        UTILS.DEBOUNCETIME
      ),
    []
  );

  useEffect(() => {
    return () => {
      panTo.clear();
      drawRef.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isValidCoordinate = areCoordinatesValid(opportunity);
    const currentPolygons = featureGroupRef.current!.getLayers();

    if (opportunity.spatialExtents && !currentPolygons.length) {
      parseAndPopulatePolygons(opportunity.spatialExtents, featureGroupRef);
    }

    if (!isValidCoordinate) {
      markerRef.current?.removeFrom(map);
      markerRef.current = null;
      return;
    }

    if (!markerRef.current) {
      markerRef.current = new L.Marker([opportunity.latitude!, opportunity.longitude!]);
      markerRef.current.addTo(featureGroupRef.current!);
      panTo(map, opportunity.latitude!, opportunity.longitude!);
      return;
    }

    const markerCoordinate = markerRef.current.getLatLng();
    panTo(map, markerCoordinate.lat, markerCoordinate.lng);

    const wrappedCoordinate = markerCoordinate.wrap();
    const isLongitudeEqual = trimToScale(wrappedCoordinate.lng, 6) === opportunity.longitude!;
    const isLatitudeEqual = trimToScale(wrappedCoordinate.lat, 6) === opportunity.latitude!;

    if (isLongitudeEqual && isLatitudeEqual) {
      return;
    }

    markerRef.current?.setLatLng([opportunity.latitude!, opportunity.longitude!]);
    panTo(map, opportunity.latitude!, opportunity.longitude!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.latitude, opportunity.longitude, opportunity.spatialExtents]);

  const createMarker = (event: L.DrawEvents.Created) => {
    cleanMarkerRef();
    markerRef.current = event.layer as L.Marker;
    const latLng = markerRef.current.getLatLng().wrap();
    updateField("longitude", trimToScale(latLng.lng, 6));
    updateField("latitude", trimToScale(latLng.lat, 6));
  };

  const createPolygon = () => {
    const polygons = featureGroupRef.current
      .getLayers()
      .filter((layer) => layer instanceof L.Polygon)
      .map((layer) => (layer as L.Polygon).toGeoJSON().geometry);

    if (polygons.length === 1) {
      const polygon = polygons[0] as GeoJSONPolygon;
      updateField("spatialExtents", JSON.stringify(polygon.coordinates));
    } else {
      const polygonSeries = polygons.map((polygon) => polygon.coordinates);
      updateField("spatialExtents", JSON.stringify(polygonSeries));
    }
  };

  const deleteMarker = () => {
    cleanMarkerRef();
    updateField("longitude", undefined);
    updateField("latitude", undefined);
  };

  const deletePolygon = (_e: any) => {
    const polygons = featureGroupRef.current
      .getLayers()
      .filter((layer) => layer instanceof L.Polygon)
      .map((layer) => (layer as L.Polygon).toGeoJSON().geometry);
    if (!polygons.length) {
      updateField("spatialExtents", undefined);
      return;
    }

    createPolygon();
  };

  const handleCreate = (event: L.DrawEvents.Created) => {
    if (event.layerType === "marker") {
      createMarker(event);
    }
    if (event.layerType === "polygon") {
      createPolygon();
    }
  };

  const handleEdit = (event: L.DrawEvents.Edited) => {
    featureGroupRef.current ??= new L.FeatureGroup();
    event.layers.eachLayer((layer: any) => {
      if (layer instanceof L.Marker) {
        const marker = layer as L.Marker;
        const latLng = marker.getLatLng().wrap();
        updateField("longitude", trimToScale(latLng.lng, 6));
        updateField("latitude", trimToScale(latLng.lat, 6));
        return;
      } else if (layer instanceof L.Polygon) {
        createPolygon();
      }
    });
  };

  const handleDelete = (e: L.DrawEvents.Deleted) => {
    featureGroupRef.current ??= new L.FeatureGroup();
    e.layers.eachLayer((layer: any) => {
      if (layer instanceof L.Marker) {
        deleteMarker();
        return;
      } else if (layer instanceof L.Polygon) {
        featureGroupRef.current.removeLayer(layer);
        deletePolygon(e);
      }
    });
  };

  const isEditModeEnabled = mode === Mode.Create || mode === Mode.Edit;

  return (
    <FeatureGroup ref={featureGroupRef}>
      {isEditModeEnabled && (
        <EditControl
          position="bottomright"
          onMounted={onMounted}
          onCreated={handleCreate}
          onEdited={handleEdit}
          onDeleted={handleDelete}
          draw={{
            marker: {},
            polygon: {},
            polyline: false,
            rectangle: false,
            circle: false,
            circlemarker: false,
          }}
        />
      )}
    </FeatureGroup>
  );
}
