import { AxiosResponse } from "axios";

import { convertToUndefined } from "./utils";

export const undefinedResponseInterceptor = (response: AxiosResponse<any, any>): AxiosResponse<any, any> => {
  if (response.data) {
    response.data = convertToUndefined(response.data);
  }
  return response;
};
