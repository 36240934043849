import L from "leaflet";

import { LegendConstants, LegendControlLocators } from "@constants";
import { CommodityWithColor } from "components/models";
import { LegendControlData, LegendShape, LegendSize } from "components/models/legendcontrol";
import { Reference } from "features/opportunities/models";

export const filterLegendShapes = (
  filteredRefOpportunityTypes: LegendShape[],
  referenceData: Reference
): LegendShape[] => {
  return filteredRefOpportunityTypes.map((e) => {
    const matchedOpportunityType = referenceData.opportunityTypes.find(
      (oType) => oType.id === e.opportunity.opportunityTypeId
    );
    return {
      ...e,
      referenceOpportunityType: matchedOpportunityType ?? e.referenceOpportunityType,
    };
  });
};

export const filterLegendSizes = (
  filteredRefOpportunityTypes: LegendShape[],
  referenceData: Reference
): LegendSize[] => {
  return filteredRefOpportunityTypes.map((e) => {
    const matchedExplorationStage = referenceData.explorationStages.find(
      (oStage) => oStage.id === e.opportunity.explorationStageId
    );
    return {
      explorationStage: matchedExplorationStage?.name ?? "",
      size: e.legendSize.size,
    } as LegendSize;
  });
};

export const getUniqueLegendShapes = (filteredLegendShapes: LegendShape[]): LegendShape[] => {
  return filteredLegendShapes.filter(
    (item: LegendShape, index, self) =>
      self.findIndex((obj) => obj.referenceOpportunityType.id === item.referenceOpportunityType.id) === index
  );
};

export const getUniqueLegendSizes = (filteredLegendSizes: LegendSize[]): LegendSize[] => {
  return filteredLegendSizes.filter(
    (item: LegendSize, index, self) => self.findIndex((obj) => obj.explorationStage === item.explorationStage) === index
  );
};

export const sortLegendSizesByWidth = (legendSizes: LegendSize[]): void => {
  legendSizes.sort((a, b) => a.size.width - b.size.width);
};

export const sortLegendShapesById = (legendShapes: LegendShape[]): void => {
  legendShapes.sort((a, b) => a.referenceOpportunityType.id - b.referenceOpportunityType.id);
};

export const createDiv = (): HTMLDivElement => {
  const div = L.DomUtil.create("div", LegendControlLocators.LegendLocator);
  L.DomEvent.disableClickPropagation(div);

  return div;
};

export const createButton = (): HTMLButtonElement => {
  const button = L.DomUtil.create("button", LegendControlLocators.LegendBtnLocator);
  button.title = LegendConstants.BtnTitle;
  const mapLegendIcon = createImageElement(
    LegendConstants.mapLegend,
    LegendConstants.IconWidth,
    LegendConstants.IconHeight
  );
  button.appendChild(mapLegendIcon);
  return button;
};

export const createPopup = (): HTMLDivElement => {
  const popup = document.createElement("div");
  popup.className = LegendControlLocators.LegendUlWrapperLocator;
  popup.style.display = "none";

  return popup;
};

export const createUL = (): HTMLUListElement => {
  const ul = document.createElement("ul");
  ul.className = LegendControlLocators.LegendUlLocator;

  return ul;
};

export const setupPopupToggle = (button: HTMLButtonElement, popup: HTMLDivElement): void => {
  button.onclick = function () {
    popup.style.display = popup.style.display === "none" ? "block" : "none";
  };
};

export const createDivider = (ul: HTMLUListElement) => {
  const divider = document.createElement("hr");
  const li = document.createElement("li");
  li.append(divider);
  ul.appendChild(li);
};

export const populateUl = (ul: HTMLUListElement, legendControlData: LegendControlData): void => {
  const { commoditiesWithColor, opportuntiyTypeLegendShapes, opportunityTypeSizes } = legendControlData;
  if (commoditiesWithColor && commoditiesWithColor.length > 0) {
    populateCommodityColors(ul, commoditiesWithColor);

    if (opportuntiyTypeLegendShapes && opportuntiyTypeLegendShapes.length > 0) {
      createDivider(ul);
      populateOpportunityTypeShapes(ul, opportuntiyTypeLegendShapes);
    }

    if (opportunityTypeSizes && opportunityTypeSizes.length > 0) {
      createDivider(ul);
      populateOpportunityTypeSizes(ul, opportunityTypeSizes);
    }

    return;
  }

  populateNoLegendsMessage(ul);
};

const populateCommodityColors = (ul: HTMLUListElement, commodityColors: CommodityWithColor[]): void => {
  commodityColors.forEach((x: CommodityWithColor) => {
    const li = document.createElement("li");
    const i = document.createElement("i");
    i.style.background = x.color!;
    li.appendChild(i);
    const span = document.createElement("span");
    span.textContent = x.name;
    li.appendChild(span);
    ul.appendChild(li);
  });
};

const populateOpportunityTypeShapes = (ul: HTMLUListElement, opportuntiyTypeLegendShapes: LegendShape[]): void => {
  opportuntiyTypeLegendShapes.forEach((item: LegendShape) => {
    const li = document.createElement("li");
    li.className = LegendControlLocators.LegendshapeClassName;
    const img = createImageElement(
      item.icon?.options?.html?.toString() ?? "",
      LegendConstants.legendShapeWidth,
      LegendConstants.legendShapeHeight
    );
    const span = document.createElement("span");
    span.textContent = item.referenceOpportunityType.name;
    li.appendChild(img);
    li.appendChild(span);
    ul.appendChild(li);
  });
};

const populateOpportunityTypeSizes = (ul: HTMLUListElement, opportunityTypeSizes: LegendSize[]): void => {
  opportunityTypeSizes.forEach((item: LegendSize) => {
    const li = document.createElement("li");
    li.className = LegendControlLocators.LegendSizeClassName;
    const div = document.createElement("div");
    const img = createImageElement(LegendConstants.legendSizeShape, item.size.width, item.size.height);
    const span = document.createElement("span");
    span.textContent = item.explorationStage ?? "";
    div.appendChild(img);
    li.appendChild(div);
    li.appendChild(span);
    ul.appendChild(li);
  });
};

const createImageElement = (imageSvg: string, width: number, height: number): HTMLImageElement => {
  const svgBlob = new Blob([imageSvg], {
    type: "image/svg+xml",
  });
  const imageUrl = URL.createObjectURL(svgBlob);
  const img = document.createElement("img");
  img.src = imageUrl;
  img.width = width;
  img.height = height;
  return img;
};

const populateNoLegendsMessage = (ul: HTMLUListElement): void => {
  const li = document.createElement("li");
  const span = document.createElement("span");
  span.textContent = LegendConstants.NoLegendsToDisplay;
  li.appendChild(span);
  ul.appendChild(li);
};

export const createLegendControl = (legendControlData: LegendControlData) => {
  const legendControl = new L.Control({ position: LegendConstants.ControlPosition });

  legendControl.onAdd = function () {
    const div = createDiv();
    const button = createButton();
    const popup = createPopup();
    const ul = createUL();
    populateUl(ul, legendControlData);
    div.appendChild(button);
    div.appendChild(popup);
    popup.appendChild(ul);
    setupPopupToggle(button, popup);
    return div;
  };

  return legendControl;
};
