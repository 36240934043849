import { OpportunityType } from "./opportunity";

export interface Reference {
  opportunityTypes: ReferenceOpportunityType[];
  opportunityStatuses: OpportunityStatusType[];
  explorationStages: IdName[];
  explorationTypes: IdName[];
  depositStages: IdName[];
  commodityGroups: ReferenceCommodityGroups[];
  locationAccuracies: IdName[];
  opportunityOriginTypes: IdName[];
  fundingTypes: IdName[];
  commercialStatuses: IdName[];
  testMethods: IdName[];
  namedEntityTypes: IdNameWithSequence[];
  countries: Country[];
  commodityColors: CommodityColor[];
  depositTypeGroups: ReferenceDepositTypeGroup[];
  opportunityAttributes: OpportunityAttributes;
  referenceDocumentTypes: IdName[];
  rtXfootprintsConfigs: RTXfootprintsConfig[];
  bulkUploadList: string[];
}

export type IdName<TId = number> = {
  id: TId;
  name: string;
};

export interface IdNameWithSequence extends IdName {
  displaySequence: number;
}

export interface OpportunityStatusType extends IdNameWithSequence {
  opportunityTypeId: number;
}

export interface ReferenceOpportunityType extends IdName {
  opportunitySubTypes: IdName[];
}

export interface ReferenceCommodityGroups {
  id: number;
  name: string;
  commodityClasses: {
    commodities: IdNameWithSequence[];
    displaySequence: number;
    id: number;
    name: string;
  }[];
}

export interface ReferenceCommodityClass extends IdNameWithSequence {
  commodities: IdNameWithSequence[];
}

export interface ReferenceCommodityGroups extends IdName {
  commodityClasses: ReferenceCommodityClass[];
}

export interface Country extends IdName {
  countryCode: string;
  continent?: string;
  rtxRegion?: string;
  rtxSubRegion?: string;
}

export interface CommodityColor {
  commodityId: Number;
  commodityHexColor: string;
}

export interface ReferenceDepositTypeClass extends IdNameWithSequence {
  depositTypeSubClasses: IdNameWithSequence[];
}

export interface ReferenceDepositTypeGroup extends IdNameWithSequence {
  depositTypeClasses: ReferenceDepositTypeClass[];
}

export type OpportunityAttributes = {
  [Property in keyof OpportunityType]?: OpportunityAttribute;
};

export type FilteredOpportunityAttribute = {
  [Property in keyof OpportunityType]?: boolean;
};

export enum OpportunityAttributeEnum {
  Generative = "generative",
  Programme = "programme",
  Project = "project",
  Target = "target",
}

export enum OpportunityTypeEnum {
  generative = 1,
  project = 2,
  target = 3,
  programme = 4,
}

export type OpportunityAttribute = {
  [OpportunityAttributeEnum.Generative]: boolean;
  [OpportunityAttributeEnum.Programme]: boolean;
  [OpportunityAttributeEnum.Project]: boolean;
  [OpportunityAttributeEnum.Target]: boolean;
  fieldGroupSequence: number;
  fieldSequence: number;
  label?: string;
  description?: string;
};

export type RTXfootprintsConfig = {
  id: number;
  settingName: string;
  settingValue: string;
};
