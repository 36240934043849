import { z } from "zod";

import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const responsibleGroupSchema = z.object({
  id: z.number(),
  name: z
    .string()
    .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
    .max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80)),
});

export interface ResponsibleGroupType extends z.infer<typeof responsibleGroupSchema> {
  inputValue?: string;
}
export const validateResponsibleGroup = (inputs: ResponsibleGroupType) => {
  const isValidData = responsibleGroupSchema.safeParse(inputs);
  return isValidData;
};
