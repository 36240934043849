export const PATH = {
  HOME: "/",
  OPPORTUNITY: "/opportunity",
  OPPORTUNITY_WITH_TYPE: "/opportunity/:opportunityType/new",
  VIEW_OPPORTUNITY: "/opportunity/:opportunityId",
  VIEW_OPPORTUNITY_ALERTS: "/opportunity/:opportunityId/alerts",
  VIEW_OPPORTUNITY_COMMENTS: "/opportunity/:opportunityId/comments",
  EDIT_OPPORTUNITY: "/opportunity/:opportunityId/edit",
  EDIT_OPPORTUNITY_ALERTS: "/opportunity/:opportunityId/edit/alerts",
  EDIT_OPPORTUNITY_COMMENTS: "/opportunity/:opportunityId/edit/comments",
  LOGIN: "/login",
  SEARCH: "/search",
  SEARCH_OPPORTUNITY: "/opportunity/search/:searchQuery",
  VIEW_SEARCH_OPPORTUNITY: "/search/:searchQuery",
  VIEW_SEARCH_OPPORTUNITY_WITH_ID: "/search/:searchQuery/:opportunityId",
  VIEW_SEARCH_OPPORTUNITY_WITH_ID_ALERTS: "/search/:searchQuery/:opportunityId/alerts",
  VIEW_SEARCH_OPPORTUNITY_WITH_ID_COMMENTS: "/search/:searchQuery/:opportunityId/comments",
  UPDATE_SEARCH_OPPORTUNITY: "/search/:searchQuery/:opportunityId/edit",
  UPDATE_SEARCH_OPPORTUNITY_ALERTS: "/search/:searchQuery/:opportunityId/edit/alerts",
  UPDATE_SEARCH_OPPORTUNITY_COMMENTS: "/search/:searchQuery/:opportunityId/edit/comments",
  ADVANCED_SEARCH: "/search/advanced",
  ADVANCED_SEARCH_WITH_ID: "/search/advanced/:opportunityId",
  ADVANCED_SEARCH_WITH_ID_ALERTS: "/search/advanced/:opportunityId/alerts",
  ADVANCED_SEARCH_WITH_ID_COMMENTS: "/search/advanced/:opportunityId/comments",
  ADVANCED_SEARCH_UPDATE: "/search/advanced/:opportunityId/edit",
  ADVANCED_SEARCH_UPDATE_ALERTS: "/search/advanced/:opportunityId/edit/alerts",
  ADVANCED_SEARCH_UPDATE_COMMENTS: "/search/advanced/:opportunityId/edit/comments",
  BULK_UPLOAD: "/bulkupload",
};
