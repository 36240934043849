import CloseIcon from "@mui/icons-material/Close";
import { Box, Divider, IconButton, Paper, Skeleton, useTheme } from "@mui/material";
import { useNavigate } from "react-router";

import { CardHeaderLocators, PATH } from "@constants";

export function CardHeaderSkeleton() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <>
      <Paper square sx={{ display: "flex", flexDirection: "column", padding: "1rem" }}>
        <Box sx={{ display: "flex" }}>
          <Skeleton variant="rectangular" width={"18rem"} height={"2rem"} sx={{ margin: "0.25rem" }} />
          <Skeleton variant="circular" width={"2rem"} height={"2rem"} sx={{ margin: "0.25rem" }} />
          <Skeleton variant="circular" width={"2rem"} height={"2rem"} sx={{ margin: "0.25rem" }} />
          <Skeleton variant="circular" width={"2rem"} height={"2rem"} sx={{ margin: "0.25rem", marginLeft: "1rem" }} />
          <IconButton
            size="small"
            id={CardHeaderLocators.CloseButton}
            data-testid={CardHeaderLocators.CloseButton}
            sx={{ marginLeft: "1rem" }}
            onClick={() => {
              navigate(PATH.HOME);
              return;
            }}
          >
            <CloseIcon sx={{ fill: theme.palette.primary.main }} />
          </IconButton>
        </Box>
        <Skeleton variant="rectangular" width={"7rem"} height={"1rem"} sx={{ margin: "0.25rem" }} />
        <Skeleton variant="rectangular" width={"7rem"} height={"1rem"} sx={{ margin: "0.25rem" }} />
        <Divider sx={{ margin: ".5rem 0" }} />
        <Skeleton variant="rounded" width={"7rem"} height={"1rem"} sx={{ margin: "0.25rem" }} />
        <Divider sx={{ margin: ".5rem 0" }} />
        <Box sx={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
          <Skeleton variant="rounded" width={"6rem"} height={"2.5rem"} sx={{ margin: "0.25rem" }} />
          <Skeleton variant="rounded" width={"6rem"} height={"2.5rem"} sx={{ margin: "0.25rem" }} />
        </Box>
      </Paper>
    </>
  );
}
