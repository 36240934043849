import { useEffect, useState } from "react";
import { createFilterOptions, FilterOptionsState } from "@mui/material";

import { Mode } from "@constants";
import { nameOfFactory } from "services";
import { useAsync } from "hooks";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType, ResponsibleGroupType, validateResponsibleGroup } from "features/opportunities/models";
import { ActionTypes } from "features/opportunities/reducers";
import { searchForResponsibleGroups } from "features/opportunities/services";

import { formatZodErrors } from "./useValidation";

export function useSearchResponsibleGroups() {
  const { opportunity, mode, updateField, setFormErrors, dispatch } = useOpportunityContext();
  const [response, run] = useAsync(searchForResponsibleGroups);
  const [responsibleGroupValue, setResponsibleGroupValue] = useState<ResponsibleGroupType | null>(null);
  const [responsibleGroupOptions, setResponsibleGroupOptions] = useState<ResponsibleGroupType[]>([]);
  const [open, toggleOpen] = useState(false);
  const nameof = nameOfFactory<OpportunityType>();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const responsibleGroupInitialValue = {
    name: "",
  };
  const [dialogValue, setDialogValue] = useState(responsibleGroupInitialValue);

  const handleClose = () => {
    setDialogValue(responsibleGroupInitialValue);
    toggleOpen(false);
  };

  useEffect(() => {
    if (response.data) {
      setResponsibleGroupOptions(response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  useEffect(() => {
    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (opportunity.responsibleGroup) {
      setResponsibleGroupValue(opportunity.responsibleGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleGroupValue, opportunity.responsibleGroup]);

  const onValueChange = (newValue: ResponsibleGroupType | null) => {
    setResponsibleGroupValue(newValue);
    updateField(nameof("responsibleGroupId"), newValue?.id);
    if (newValue) {
      dispatch({ type: ActionTypes.ADD_RESPONSIBLE_GROUP_ACTION, payload: newValue });
    } else {
      dispatch({ type: ActionTypes.REMOVE_RESPONSIBLE_GROUP_ACTION });
    }
  };

  const filter = createFilterOptions<ResponsibleGroupType>();

  const filterOptions = (options: ResponsibleGroupType[], params: FilterOptionsState<ResponsibleGroupType>) => {
    const filtered = filter(options, params);
    if (params.inputValue) {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const handleSubmit = () => {
    const newValue: ResponsibleGroupType = {
      id: 0,
      name: dialogValue.name,
    };

    const result = validateResponsibleGroup(newValue);

    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setFormErrors(formattedErrors);
      return;
    }
    onValueChange(newValue);
    handleClose();
  };

  useEffect(() => {
    if ((mode === Mode.Edit || mode === Mode.View) && opportunity.responsibleGroupId) {
      setResponsibleGroupOptions(responsibleGroupOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsibleGroupValue, opportunity.responsibleGroup, opportunity.responsibleGroupId]);

  return {
    responsibleGroupOptions,
    responsibleGroupValue,
    open,
    onValueChange,
    toggleOpen,
    handleSubmit,
    handleClose,
    dialogValue,
    setDialogValue,
    filterOptions,
  };
}
