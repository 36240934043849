import { useEffect, useState } from "react";

import { Mode } from "@constants";
import { nameOfFactory } from "services";
import { useAsync } from "hooks";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType, ResponsiblePersonType } from "features/opportunities/models";
import { ActionTypes } from "features/opportunities/reducers";
import { searchForResponsiblePersons } from "features/opportunities/services";

import { useFetch } from "./useFetch";

export function useSearchResponsiblePersons() {
  const { opportunity, mode, updateField, dispatch } = useOpportunityContext();
  const [response, run] = useAsync(searchForResponsiblePersons);
  const [responsiblePersonValue, setResponsiblePersonValue] = useState<ResponsiblePersonType | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<ResponsiblePersonType[]>([]);
  const nameof = nameOfFactory<OpportunityType>();
  const fetch = useFetch(run);

  useEffect(() => {
    if (response.data) {
      setOptions(response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  useEffect(() => {
    if (opportunity.responsiblePerson) {
      setResponsiblePersonValue(opportunity.responsiblePerson);
    }
    setOptions(responsiblePersonValue ? [responsiblePersonValue] : []);

    if (inputValue !== "") {
      fetch(inputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiblePersonValue, inputValue, fetch, opportunity.responsiblePerson]);

  const onValueChange = (newValue: ResponsiblePersonType | null) => {
    updateField(nameof("responsiblePersonId"), newValue?.id);
    if (newValue) {
      dispatch({ type: ActionTypes.ADD_RESPONSIBLE_PERSON_ACTION, payload: newValue });
    } else {
      dispatch({ type: ActionTypes.REMOVE_RESPONSIBLE_PERSON_ACTION });
    }
    setOptions(newValue ? [newValue, ...options] : options);
    setResponsiblePersonValue(newValue);
  };

  useEffect(() => {
    if (
      (mode === Mode.Edit || mode === Mode.View) &&
      opportunity.responsiblePersonId &&
      opportunity.responsiblePerson
    ) {
      if (opportunity.responsiblePerson) {
        setOptions(options);
        setResponsiblePersonValue(opportunity.responsiblePerson);
      } else {
        setOptions(responsiblePersonValue ? [responsiblePersonValue] : []);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responsiblePersonValue, inputValue, fetch]);

  return { options, responsiblePersonValue, inputValue, onValueChange, setInputValue };
}
