import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import ForumIcon from "@mui/icons-material/Forum";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Switch,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { generatePath, useLocation, useParams } from "react-router-dom";

import { CardHeaderLocators, Mode, PATH, SharedMessages } from "@constants";
import { PropsWithTestId, UserFavoriteModel } from "types";
import { mapIdsToName, nameOfFactory } from "services";
import { useReferenceDataContext, useConfirmationModalContext, useSnackBarContext } from "context";
import { Prompt, useAsync } from "hooks";
import { ConfirmationModal } from "components";
import { useAuthorization } from "context/AuthorizationContext";
import { EditOpportunityButton, SubmitOpportunityButton } from "features/opportunities/components";
import { getFullLocation, chips } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";
import { useDeleteOpportunityDispatcher, useAddRemoveFavoriteDispatcher } from "features/opportunities/hooks";
import { opportunityIsParent } from "features/opportunities/services";

import { OpportunityType } from "../models";

type CardHeaderProps = {
  showAllFields: boolean;
  setShowAllFields: Dispatch<SetStateAction<boolean>>;
  setOpenAlertsPane?: Dispatch<SetStateAction<boolean>>;
  setOpenCommentsPane?: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
} & (
  | { mode: Mode.Create; onSubmitted: (id?: string) => void }
  | { mode: Mode.Edit; onSubmitted: (id?: string) => void; onDeleted: (id: string) => void }
  | { mode: Mode.View; onEdit: (id: string) => void; onDeleted: (id: string) => void }
);

export function CardHeader(props: CardHeaderProps) {
  const { showAllFields, mode, setShowAllFields, setOpenAlertsPane, setOpenCommentsPane, onClose } = props;
  const { opportunity, updateField } = useOpportunityContext();
  const { referenceData } = useReferenceDataContext();
  const confirmationModalContext = useConfirmationModalContext();
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogBody, setDialogBody] = useState<string | ReactNode>("");
  const [confirmButtonText, setConfirmButtonText] = useState<string>("");
  const [cancelButtonText, setCancelButtonText] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDeleteAction, setIsDeleteAction] = useState<boolean>(false);
  const [preventDefaultConfirm, setPreventDefaultConfirm] = useState<boolean>(true);
  const { opportunityId } = useParams();
  const [isParentResponse, getIsParent] = useAsync(opportunityIsParent);
  const theme = useTheme();
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { isReadOnly, isAdmin, isPrivileged } = useAuthorization();
  const [isRestrictAction, setIsRestrictAction] = useState<boolean>(false);
  const location = useLocation();

  const nameof = nameOfFactory<OpportunityType>();
  const userCanAccessRestricted: boolean = isAdmin || isPrivileged;

  const handleClose = () => {
    onClose();
  };

  const handleEdit = (id?: string) => {
    if (id && mode === Mode.View) {
      props.onEdit(id);
    }
  };

  const handleSubmitted = (id?: string) => {
    if (mode === Mode.Create || mode === Mode.Edit) {
      props.onSubmitted(id);
    }
  };

  const handleDeleted = (id: string) => {
    if (mode === Mode.View || mode === Mode.Edit) {
      props.onDeleted(id);
    }
  };

  const { status: deleteStatus, runDelete } = useDeleteOpportunityDispatcher(handleDeleted);

  const onCloseDialog = () => {
    setShowModal(false);
    setIsRestrictAction(false);
    setPreventDefaultConfirm(true);
  };

  const onConfirmDialog = () => {
    setPreventDefaultConfirm(false);
    if (isRestrictAction) {
      updateField(nameof("restrictedFlag"), !opportunity.restrictedFlag);
      setIsRestrictAction(false);
      setShowModal(false);
      return;
    }
    if (!isDeleteAction) {
      setShowModal(false);
      handleClose();
      return;
    }

    runDelete(opportunityId || "");
    setIsDeleteAction(false);
    setShowModal(false);
  };

  const onDeleteClick = () => {
    if (opportunityId) {
      getIsParent(opportunityId);
    }
  };

  const onCopyClick = () => {
    const opportunityUrl = generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId });
    navigator.clipboard.writeText(window.location.origin + opportunityUrl);
    setSnackBarContent({
      message: "Opportunity link copied to clipboard.",
      severity: "success",
    });

    setOpen(true);
  };

  useEffect(() => {
    if (isParentResponse.status === "error") {
      console.error("Error on retrieving the opportunity.");
      return;
    }

    if (isParentResponse.data !== undefined) {
      const isParent = isParentResponse.data;
      if (isParent) {
        setDialogTitle(
          `This ${mapIdsToName(
            referenceData.opportunityTypes,
            opportunity.opportunityTypeId
          )} has been assigned as a parent for other opportunities.`
        );
        setDialogBody("If you delete it, those links will be lost. Are you sure you want to proceed?");
      } else {
        setDialogTitle("Do you want to delete the record?");
        setDialogBody("");
      }
      setCancelButtonText("Cancel");
      setConfirmButtonText("Delete");
      setIsDeleteAction(true);
      setShowModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParentResponse.data, isParentResponse.status]);

  Prompt({
    message: "Unsaved changes, do you want to navigate away?",
    when: preventDefaultConfirm && confirmationModalContext.canShow,
    beforeUnload: true,
  });

  const sxFunc = (isOpportunityRestricted: boolean): SxProps<Theme> =>
    isOpportunityRestricted && userCanAccessRestricted
      ? { backgroundColor: theme.palette.warning.light, padding: ".5rem" }
      : { padding: ".5rem" };

  const handleRestrictedChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setIsRestrictAction(true);
    if (event.target.checked) {
      setDialogTitle("Set restricted access?");
      setDialogBody("Only privileged users will be able to find and view this opportunity.");
      setCancelButtonText("Cancel");
      setConfirmButtonText("Restrict");
      setShowModal(true);
      return;
    } else {
      setDialogTitle("Remove restricted access?");
      setDialogBody(
        <>
          <Typography>All users will be able to find and view this opportunity.</Typography>
          <Typography sx={{ paddingTop: "1rem" }}>This may expose sensitive information.</Typography>
        </>
      );
      setCancelButtonText("Cancel");
      setConfirmButtonText("Remove");
      setShowModal(true);
      return;
    }
  };

  const { runAddFavorite, runRemoveFavorite } = useAddRemoveFavoriteDispatcher();

  const markAsFavorite = () => {
    updateField(nameof("userFavoriteFlag"), !opportunity.userFavoriteFlag, true);
    if (opportunity.userFavoriteFlag) {
      runRemoveFavorite(opportunity.id);
      return;
    }

    const favorite: UserFavoriteModel = {
      opportunityId: opportunity.id,
    };

    runAddFavorite(favorite);
  };

  const handleOpenAlerts = () => {
    if (!setOpenAlertsPane) {
      return;
    }
    if (!setOpenCommentsPane) {
      return;
    }

    setOpenAlertsPane(true);
    setOpenCommentsPane(false);

    handleExtendedPanelPath("alerts");
  };

  function handleCommentsButtonClick() {
    if (setOpenCommentsPane !== undefined) {
      if (!setOpenAlertsPane) {
        return;
      }
      setOpenCommentsPane(true);
      setOpenAlertsPane(false);

      handleExtendedPanelPath("comments");
    }
  }

  const handleExtendedPanelPath = (panel: string) => {
    const search = location.search;
    const currentPath = location.pathname.replace(/[/](alerts|comments)/g, "");
    const newPath = currentPath.concat(`/${panel.toLowerCase()}`);
    const newUrl = `${newPath}${search}`;
    window.history.pushState({}, "", newUrl);
  };

  return useMemo(
    () => (
      <>
        <Paper sx={{ display: "flex", flexDirection: "column", padding: "1rem", zIndex: 1 }}>
          <Box sx={{ display: "flex", gap: ".5rem" }}>
            <Typography
              id={CardHeaderLocators.OpportunityName}
              data-testid={CardHeaderLocators.OpportunityName}
              variant="h5"
              flexGrow={1}
              sx={{ paddingTop: ".25rem", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {opportunity.opportunityName || "New Opportunity"}
            </Typography>
            <Box sx={{ display: "flex" }}>
              {(mode === Mode.Edit || mode === Mode.View) && (
                <>
                  <Tooltip
                    title={opportunity.userFavoriteFlag ? SharedMessages.RemoveFavorite : SharedMessages.AddFavorite}
                    id={CardHeaderLocators.FavoriteTooltip}
                    data-testid={CardHeaderLocators.FavoriteTooltip}
                  >
                    <IconButton
                      id={CardHeaderLocators.FavoriteButton}
                      data-testid={CardHeaderLocators.FavoriteButton}
                      size="small"
                      onClick={markAsFavorite}
                    >
                      {opportunity.userFavoriteFlag ? <StarIcon color="secondary" /> : <StarOutlineIcon />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Open comments pane"
                    id={CardHeaderLocators.CommentsButtonTooltip}
                    data-testid={CardHeaderLocators.CommentsButtonTooltip}
                  >
                    <IconButton
                      id={CardHeaderLocators.CommentsButton}
                      data-testid={CardHeaderLocators.CommentsButton}
                      size="small"
                      onClick={handleCommentsButtonClick}
                    >
                      <Badge
                        id={CardHeaderLocators.CommentsBadge}
                        data-testid={CardHeaderLocators.CommentsBadge}
                        badgeContent={opportunity.comments?.length}
                        color="error"
                      >
                        <ForumIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={SharedMessages.ComingSoon}
                    id={CardHeaderLocators.ReviewsComingSoon}
                    data-testid={CardHeaderLocators.ReviewsComingSoon}
                  >
                    <IconButton id="reviews-button" data-testid="reviews-button" size="small">
                      <AssignmentTurnedInIcon sx={{ fill: theme.palette.grey[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Open alerts pane"
                    id={CardHeaderLocators.AlertsButtonTooltip}
                    data-testid={CardHeaderLocators.AlertsButtonTooltip}
                  >
                    <IconButton
                      id={CardHeaderLocators.AlertsButton}
                      data-testid={CardHeaderLocators.AlertsButton}
                      size="small"
                      onClick={handleOpenAlerts}
                    >
                      <Badge
                        id={CardHeaderLocators.AlertsBadge}
                        data-testid={CardHeaderLocators.AlertsBadge}
                        badgeContent={opportunity.alerts?.length}
                        color="error"
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <IconButton
                size="small"
                id={CardHeaderLocators.CloseButton}
                data-testid={CardHeaderLocators.CloseButton}
                sx={{ marginLeft: "1rem" }}
                onClick={() => {
                  if (!confirmationModalContext.canShow) {
                    handleClose();
                    return;
                  }
                  setDialogTitle("Unsaved changes");
                  setDialogBody("Do you still want to navigate away?");
                  setCancelButtonText("Cancel");
                  setConfirmButtonText("Confirm");
                  setShowModal(true);
                  setPreventDefaultConfirm(false);
                }}
              >
                <CloseIcon sx={{ fill: theme.palette.primary.main }} />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: ".5rem" }}>
            <Box>
              <Typography
                id={CardHeaderLocators.OpportunityId}
                data-testid={CardHeaderLocators.OpportunityId}
                variant="body2"
              >
                {opportunity.id ?? ""}

                {[Mode.View, Mode.Edit].includes(mode) ? (
                  <Tooltip
                    title="Copy link"
                    id={CardHeaderLocators.CopyLinkButtonTooltip}
                    data-testid={CardHeaderLocators.CopyLinkButtonTooltip}
                  >
                    <IconButton
                      id={CardHeaderLocators.CopyLinkButton}
                      data-testid={CardHeaderLocators.CopyLinkButton}
                      size="small"
                      onClick={onCopyClick}
                      sx={{ padding: "0", marginLeft: "5px" }}
                    >
                      <ContentCopyIcon color="action" sx={{ height: "0.875rem", width: "0.875rem" }} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </Typography>
              <Typography
                variant="body2"
                color={"primary.main"}
                sx={{ marginTop: ".5rem" }}
                id={CardHeaderLocators.OpportunityLocation}
                data-testid={CardHeaderLocators.OpportunityLocation}
              >
                {getFullLocation(opportunity.country, opportunity.stateProvince) || "[Location Missing]"}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ margin: ".5rem 0" }} />
          <Box sx={{ display: "flex", gap: ".5rem" }}>{chips(opportunity, referenceData?.commodityGroups)}</Box>
          <Divider sx={{ margin: ".5rem 0" }} />
          {!isReadOnly && (
            <Paper elevation={0} sx={{ ...sxFunc(opportunity.restrictedFlag), display: "flex", gap: ".5rem" }}>
              <Box sx={{ display: "flex" }} flexGrow={1}>
                {mode === Mode.Create ? (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          id={CardHeaderLocators.ShowAllFields}
                          checked={showAllFields}
                          onChange={(event) => setShowAllFields(event.target.checked)}
                          inputProps={{ "data-testid": CardHeaderLocators.ShowAllFields } as PropsWithTestId}
                        />
                      }
                      label="All fields"
                    />
                  </FormGroup>
                ) : (
                  <Box sx={{ display: "flex", justifyContent: "left" }}>
                    <IconButton
                      id="delete-opportunity"
                      data-testid="delete-opportunity"
                      disabled={deleteStatus === "loading"}
                      onClick={onDeleteClick}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
                {userCanAccessRestricted && (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} flexGrow={1}>
                    {[Mode.Create, Mode.Edit].includes(mode) ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={CardHeaderLocators.RestrictedCheckbox}
                              name={nameof("restrictedFlag")}
                              onChange={handleRestrictedChange}
                              checked={opportunity.restrictedFlag ?? false}
                              inputProps={{ "data-testid": CardHeaderLocators.RestrictedCheckbox } as PropsWithTestId}
                            />
                          }
                          label="Restricted"
                        />
                      </FormGroup>
                    ) : (
                      opportunity.restrictedFlag && (
                        <Box
                          id={CardHeaderLocators.RestrictedWarning}
                          data-testid={CardHeaderLocators.RestrictedWarning}
                          sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                          flexGrow={1}
                        >
                          <IconButton disabled>
                            <WarningAmberIcon sx={{ color: theme.palette.warning.dark }} />
                          </IconButton>
                          <Typography
                            id={CardHeaderLocators.RestrictedLabel}
                            data-testid={CardHeaderLocators.RestrictedLabel}
                            variant="subtitle1"
                            sx={{ fontWeight: "bold" }}
                          >
                            Restricted
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                )}
              </Box>
              <Box sx={{ display: "flex", gap: "1rem" }}>
                {mode === Mode.View ? (
                  <EditOpportunityButton onEdit={handleEdit} />
                ) : (
                  <SubmitOpportunityButton onSubmitted={handleSubmitted} />
                )}
              </Box>
            </Paper>
          )}
        </Paper>
        <ConfirmationModal
          dialogTitle={dialogTitle}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          dialogBody={dialogBody}
          show={showModal}
          onClose={onCloseDialog}
          onConfirm={onConfirmDialog}
        />
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mode,
      opportunity.id,
      opportunity.opportunityName,
      opportunity.primaryCommodityId,
      opportunity.secondaryCommodityId,
      opportunity.country,
      opportunity.stateProvince,
      opportunity.restrictedFlag,
      opportunity.userFavoriteFlag,
      opportunity.alerts?.length,
      opportunity.comments?.length,
      referenceData.commodityGroups,
      confirmationModalContext.canShow,
      showAllFields,
      showModal,
      isDeleteAction,
    ]
  );
}
