import { PublicClientApplication } from "@azure/msal-browser";
import axios from "axios";

import { undefinedResponseInterceptor } from "./interceptors";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID || "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  },
});

apiClient.interceptors.request.use(async (config) => {
  const token = await getToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export async function getToken() {
  try {
    const scopes = process.env.REACT_APP_AUTH_APISCOPES?.split(",") || [];
    const msalResponse = await msalInstance.acquireTokenSilent({ scopes });
    return msalResponse.accessToken;
  } catch (error) {
    console.error(error);
  }
}

apiClient.interceptors.response.use(undefinedResponseInterceptor);

export { apiClient };
