import { ChangeEvent, useMemo } from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId, DateFieldType } from "types";
import { nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import {
  ConditionalGrid,
  NamedEntityField,
  OwnershipSection,
  referenceDataMenuItems,
} from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType } from "features/opportunities/models";

export function CommercialSection() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();

  return useMemo(() => {
    const nameof = nameOfFactory<OpportunityType>();

    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleDateChange = (name: string, value?: DateFieldType) => {
      updateField(name, value);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.checked);
    };

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["fundingTypeId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.FundingType} data-testid={CardBodyLocators.FundingType}>
                Funding type
              </InputLabel>
              <Select
                labelId={CardBodyLocators.FundingType}
                id={CardBodyLocators.FundingTypeSelect}
                label="Funding type"
                name={nameof("fundingTypeId")}
                onChange={handleSelectionChange}
                value={opportunity.fundingTypeId || 0}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.FundingTypeSelect } as PropsWithTestId}
                error={Boolean(opportunity.fundingTypeId && formErrors.fundingTypeId)}
              >
                {referenceDataMenuItems(referenceData?.fundingTypes, "opp-funding-types-")}
              </Select>
              {Number(opportunity.fundingTypeId) > 0 && formErrors.fundingTypeId && (
                <FormHelperText>{formErrors.fundingTypeId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["companyId"] ?? true}>
            <NamedEntityField
              labelName="Company"
              selectionField={CardBodyLocators.CompanySelect}
              selectionFieldIdName={CardBodyLocators.CompanyId}
              isRequired
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["commercialStatusId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.CommercialStatus} data-testid={CardBodyLocators.CommercialStatus}>
                Commercial Status
              </InputLabel>
              <Select
                labelId={CardBodyLocators.CommercialStatus}
                id={CardBodyLocators.CommercialStatusSelect}
                label="Commercial Status"
                name={nameof("commercialStatusId")}
                onChange={handleSelectionChange}
                value={opportunity.commercialStatusId || 0}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.CommercialStatusSelect } as PropsWithTestId}
                error={Boolean(opportunity.commercialStatusId && formErrors.commercialStatusId)}
              >
                {referenceDataMenuItems(referenceData?.commercialStatuses, "opp-commercial-status-")}
              </Select>
              {Number(opportunity.commercialStatusId) > 0 && formErrors.commercialStatusId && (
                <FormHelperText>{formErrors.commercialStatusId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["thirdPartyNameId"] ?? true}>
            <NamedEntityField
              labelName="Third Party"
              selectionField={CardBodyLocators.ThirdPartyNameSelect}
              selectionFieldIdName={CardBodyLocators.ThirdPartyNameId}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["thirdPartyNotifiedDate"] ?? true}>
            <DatePicker
              label="Date notified"
              inputFormat="YYYY/MM/DD"
              value={opportunity.thirdPartyNotifiedDate}
              onChange={(newValue) => handleDateChange(nameof("thirdPartyNotifiedDate"), newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={CardBodyLocators.ThirdPartyNotifiedDate}
                  inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.ThirdPartyNotifiedDate }}
                  error={Boolean(opportunity.thirdPartyNotifiedDate && formErrors.thirdPartyNotifiedDate)}
                  helperText={
                    opportunity.thirdPartyNotifiedDate && formErrors.thirdPartyNotifiedDate
                      ? formErrors.thirdPartyNotifiedDate
                      : undefined
                  }
                />
              )}
            />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["confidentialityAgreement"] ?? true}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id={CardBodyLocators.ConfidentialityAgreement}
                  name={nameof("confidentialityAgreement")}
                  onChange={handleCheckboxChange}
                  checked={opportunity.confidentialityAgreement ?? false}
                  inputProps={{ "data-testid": CardBodyLocators.ConfidentialityAgreement } as PropsWithTestId}
                />
              }
              label="Confidentiality agreement"
            />
          </FormGroup>
        </ConditionalGrid>
        <OwnershipSection />
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.commercialStatusId,
    opportunity.confidentialityAgreement,
    opportunity.fundingTypeId,
    opportunity.thirdPartyNameId,
    opportunity.thirdPartyNotifiedDate,
    referenceData.commercialStatuses,
    referenceData.fundingTypes,
    formErrors.fundingTypeId,
    formErrors.commercialStatusId,
    formErrors.thirdPartyNameId,
    formErrors.thirdPartyNotifiedDate,
    formErrors.confidentialityAgreement,
    opportunityAttributes,
  ]);
}
