import L from "leaflet";
import wk, { GeoJSONPolygon } from "wellknown";

import { geoCoordinatesLatLngs, identifyArrayDimensions } from "@constants";

export const parseAndPopulatePolygons = (
  polygonData: string,
  featureGroupRef: React.MutableRefObject<L.FeatureGroup<any>>
) => {
  if (polygonData) {
    if (!polygonData.includes("POLY")) {
      const spatialExtentsDimension = identifyArrayDimensions(JSON.parse(polygonData));

      if (spatialExtentsDimension == null) {
        return;
      }

      const coordinatesObj = JSON.parse(polygonData);

      if (spatialExtentsDimension === "3D") {
        coordinatesObj.forEach((coordinates: number[][][]) => {
          var latLngs: L.LatLng[][] = [];
          latLngs.push(geoCoordinatesLatLngs(coordinates, "3D"));
          const polygon = L.polygon(latLngs);
          featureGroupRef.current!.addLayer(polygon);
        });
        return;
      }

      const latLngs = geoCoordinatesLatLngs(coordinatesObj, "2D");
      featureGroupRef.current!.addLayer(L.polygon(latLngs));
      return;
    }

    const spatialExtentsFromDb = wk.parse(polygonData);

    if (spatialExtentsFromDb?.type === "MultiPolygon") {
      spatialExtentsFromDb.coordinates.forEach(function (shapeCoords) {
        var polygon: GeoJSONPolygon = { type: "Polygon", coordinates: shapeCoords };
        L.geoJson(polygon, {
          onEachFeature: function (_feature, layer) {
            featureGroupRef.current!.addLayer(layer);
          },
        });
      });
      return;
    }

    const polygon = spatialExtentsFromDb as GeoJSONPolygon;
    L.geoJson(polygon, {
      onEachFeature: function (_feature, layer) {
        featureGroupRef.current!.addLayer(layer);
      },
    });
  }
};
