import { BulkUploadTotalResult } from "types";
import { apiClient as client } from "services";

export const uploadFile = (formData: FormData) =>
  client.post<BulkUploadTotalResult>(`/BulkUpload`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getCsvTemplate = (id: string) =>
  client.get<[]>(`CsvTemplate/download?tableName=${id}`, {
    responseType: "blob",
  });
