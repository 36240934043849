import { ReactNode, useMemo } from "react";
import { Box, Divider, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { CardBodySectionsLocators, OwnershipLocators, UTILS, ViewOpportunitySectionsLocators } from "@constants";
import { useAuthorization, useSimpleDialogContext, useReferenceDataContext } from "context";
import { SimpleDialog } from "components";
import { CardBodyTooltip, ReferencesSection } from "features/opportunities/components";
import {
  getGeneralDetailsSection,
  getGeologicalSection,
  getLineageSection,
  getLocationSection,
  getStatusSection,
  getCommercialSection,
  getAdditionalDetailsSection,
  getIntegrationSection,
  getChangeLogSection,
  OwnershipColumns,
} from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

import { FieldsGroup, getAllTooltipFields } from "../services/utils";
import { CardBodyViewSectionBase } from "./CardBodyViewSectionBase";

export function CardBodyView() {
  const simpleDialogContext = useSimpleDialogContext();
  const { referenceData } = useReferenceDataContext();
  const { opportunity, mode } = useOpportunityContext();
  const tooltipFields = useMemo(
    () => getAllTooltipFields(referenceData?.opportunityAttributes),
    [referenceData?.opportunityAttributes]
  );
  const { isPrivileged, isAdmin } = useAuthorization();
  const hasRestrictedAccess = isPrivileged || isAdmin;

  const generalDetailsSection = getGeneralDetailsSection(
    opportunity,
    simpleDialogContext,
    referenceData.opportunityTypes
  );
  const statusSection = getStatusSection(opportunity, referenceData);
  const geologicalSection = getGeologicalSection(opportunity, referenceData);
  const locationSection = getLocationSection(opportunity, referenceData.locationAccuracies);
  const lineageSection = getLineageSection(opportunity, referenceData.opportunityOriginTypes, hasRestrictedAccess);
  const commercialSection = getCommercialSection(opportunity, referenceData);
  const additionalDetailsSection = getAdditionalDetailsSection(opportunity, simpleDialogContext);
  const integrationSection = getIntegrationSection(opportunity);
  const changeLogSection = getChangeLogSection(opportunity);

  const sectionHasFieldsToShow = (collection: Array<{ labelDataTestId: string; name: string; value: ReactNode }>) => {
    return !collection.every((item) => item.value === undefined || item.value === null || item.value === "");
  };

  return (
    <Box
      sx={{
        overflowX: "auto",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        padding: "1rem",
        gap: "1rem",
        overflowY: "overlay",
      }}
    >
      <Paper
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
        }}
      >
        {sectionHasFieldsToShow(generalDetailsSection) && (
          <CardBodyViewSectionBase
            sectionId={CardBodySectionsLocators.GeneralDetails}
            sectionNameId={ViewOpportunitySectionsLocators.GeneralDetails}
            sectionName="General Details"
            collection={generalDetailsSection}
            tooltipFields={tooltipFields[FieldsGroup.GeneralDetails]}
          />
        )}

        {sectionHasFieldsToShow(statusSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Status}
              sectionNameId={ViewOpportunitySectionsLocators.Status}
              sectionName="Status"
              collection={statusSection}
              tooltipFields={tooltipFields[FieldsGroup.Status]}
            />
          </>
        )}

        {sectionHasFieldsToShow(geologicalSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Geological}
              sectionNameId={ViewOpportunitySectionsLocators.Geological}
              sectionName="Geological"
              collection={geologicalSection}
              tooltipFields={tooltipFields[FieldsGroup.GeologicalDetails]}
            />
          </>
        )}

        {sectionHasFieldsToShow(locationSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Location}
              sectionNameId={ViewOpportunitySectionsLocators.Location}
              sectionName="Location"
              collection={locationSection}
              tooltipFields={tooltipFields[FieldsGroup.Location]}
            />
          </>
        )}

        {sectionHasFieldsToShow(lineageSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Lineage}
              sectionNameId={ViewOpportunitySectionsLocators.Lineage}
              sectionName="Lineage"
              collection={lineageSection}
              tooltipFields={tooltipFields[FieldsGroup.Lineage]}
            />
          </>
        )}

        {sectionHasFieldsToShow(commercialSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Commercial}
              sectionNameId={ViewOpportunitySectionsLocators.Commercial}
              sectionName="Commercial"
              collection={commercialSection}
              tooltipFields={tooltipFields[FieldsGroup.Commercial]}
            />
          </>
        )}

        {opportunity.ownerships && opportunity.ownerships.length > 0 && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <Box data-testid={CardBodySectionsLocators.Ownership}>
              <Typography
                data-testid={OwnershipLocators.ViewOwnershipSection}
                variant="subtitle2"
                sx={{ paddingBottom: "1rem" }}
              >
                Ownership
              </Typography>
              <DataGrid
                autoHeight
                rows={opportunity.ownerships ?? []}
                columns={OwnershipColumns(mode)}
                pageSize={UTILS.PAGESIZE}
                rowsPerPageOptions={[UTILS.PAGESIZE]}
                headerHeight={32}
                rowHeight={32}
                disableColumnFilter
                disableSelectionOnClick
                getRowClassName={(_) => OwnershipLocators.ViewOwnershipRow}
              />
            </Box>
          </>
        )}

        {sectionHasFieldsToShow(additionalDetailsSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.AdditionalDetails}
              sectionNameId={ViewOpportunitySectionsLocators.AdditionalDetails}
              sectionName="Additional Details"
              collection={additionalDetailsSection}
              tooltipFields={tooltipFields[FieldsGroup.AdditionalDetails]}
            />
          </>
        )}

        {opportunity.referenceDocuments && opportunity.referenceDocuments.length > 0 && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <Box data-testid={CardBodySectionsLocators.References}>
              <Box display="flex" alignItems="center">
                <Typography
                  data-testid={ViewOpportunitySectionsLocators.ReferenceDocuments}
                  variant="subtitle2"
                  sx={{ paddingBottom: "1rem", flexGrow: 1 }}
                >
                  References
                </Typography>
                <CardBodyTooltip
                  id={CardBodySectionsLocators.References}
                  fields={tooltipFields[FieldsGroup.References]}
                />
              </Box>
              <ReferencesSection />
            </Box>
          </>
        )}

        {sectionHasFieldsToShow(integrationSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Integration}
              sectionNameId={ViewOpportunitySectionsLocators.Integration}
              sectionName="Integration"
              collection={integrationSection}
              tooltipFields={tooltipFields[FieldsGroup.Integration]}
            />
          </>
        )}

        {sectionHasFieldsToShow(changeLogSection) && (
          <>
            <Divider sx={{ margin: ".5rem 0" }} />
            <CardBodyViewSectionBase
              sectionId={CardBodySectionsLocators.Changelog}
              sectionNameId={ViewOpportunitySectionsLocators.Changelog}
              sectionName="Change Log"
              collection={changeLogSection}
              tooltipFields={tooltipFields[FieldsGroup.Changelog]}
            />
          </>
        )}
      </Paper>
      <SimpleDialog />
    </Box>
  );
}
