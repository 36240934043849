import {
  AdvancedSearchModelType,
  AdvancedSearchModelCriteriaType,
  SearchOperatorType,
  SpatialSearchBound,
} from "features/search/models";

export enum ActionTypes {
  Clear = "CLEAR",
  SetFieldValue = "SetFieldValue",
  SetOperatorValue = "SetOperatorValue",
  ReplaceModel = "ReplaceModel",
  SetBounds = "SetBounds",
}

export const initialState: AdvancedSearchModelType = {
  opportunityCode: {
    operator: SearchOperatorType.Contains,
    value: "",
  },
  opportunityName: {
    operator: SearchOperatorType.Contains,
    value: "",
  },
  opportunityType: {
    operator: SearchOperatorType.In,
    value: [],
  },
  opportunityStatus: {
    operator: SearchOperatorType.In,
    value: [],
  },
  explorationType: {
    operator: SearchOperatorType.In,
    value: [],
  },
  explorationStage: {
    operator: SearchOperatorType.In,
    value: [],
  },
  country: {
    operator: SearchOperatorType.In,
    value: [],
  },
  rtxRegion: {
    operator: SearchOperatorType.In,
    value: [],
  },
  rtxSubRegion: {
    operator: SearchOperatorType.In,
    value: [],
  },
  primaryCommodity: {
    operator: SearchOperatorType.In,
    value: [],
  },
  secondaryCommodity: {
    operator: SearchOperatorType.In,
    value: [],
  },
  otherCommodities: {
    operator: SearchOperatorType.In,
    value: [],
  },
};

export type ValueTypeFromCriteria<T> = T extends AdvancedSearchModelCriteriaType<infer U> ? U : T;
export type OperatorTypeFromCriteria<T> = T extends AdvancedSearchModelCriteriaType<unknown>
  ? SearchOperatorType
  : never;

export interface CriteriaValuePayload<
  TKey extends keyof AdvancedSearchModelType,
  TValue = ValueTypeFromCriteria<AdvancedSearchModelType[TKey]>
> {
  name: TKey;
  value: TValue;
}

export interface CriteriaOperatorPayload<TKey extends keyof AdvancedSearchModelType> {
  name: TKey;
  value: SearchOperatorType;
}

export type ActionType =
  | { type: ActionTypes.Clear }
  | { type: ActionTypes.SetFieldValue; payload: CriteriaValuePayload<keyof AdvancedSearchModelType> }
  | { type: ActionTypes.SetOperatorValue; payload: CriteriaOperatorPayload<keyof AdvancedSearchModelType> }
  | { type: ActionTypes.ReplaceModel; payload: AdvancedSearchModelType }
  | { type: ActionTypes.SetBounds; payload: SpatialSearchBound | undefined };

export const advancedSearchReducer = (state: AdvancedSearchModelType, action: ActionType): AdvancedSearchModelType => {
  switch (action.type) {
    case ActionTypes.Clear:
      return initialState;
    case ActionTypes.SetFieldValue:
      const { name, value } = action.payload;
      return { ...state, [name]: { ...state[name], value } };
    case ActionTypes.SetOperatorValue:
      return { ...state, [action.payload.name]: { ...state[action.payload.name], operator: action.payload.value } };
    case ActionTypes.ReplaceModel:
      return action.payload;
    case ActionTypes.SetBounds:
      return { ...state, bounds: action.payload };
  }
};
