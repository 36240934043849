import { Fragment, useEffect, useState } from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link as RouterLink, generatePath } from "react-router-dom";

import { PATH, LandingPageLocators, SharedMessages } from "@constants";
import { OpportunityModel } from "types";
import { formatAsShortDate } from "services";
import { useReferenceDataContext } from "context";
import { useAsyncEffect, useDocumentTitle } from "hooks";
import { ReactComponent as StarMinusIcon } from "assets/StarMinus.svg";
import { useAuthorization } from "context/AuthorizationContext";
import { getAllCommodities } from "features/opportunities/components";
import { OpportunityProvider } from "features/opportunities/context";
import { useAddRemoveFavoriteDispatcher } from "features/opportunities/hooks";
import { OpportunityAttributeEnum } from "features/opportunities/models";
import { getRecentOpportunities, getUserAlerts, getUserFavorites } from "features/opportunities/services";

interface Template {
  Id: string;
  Name: string;
  Description: string;
  Link: string;
}

const templates: Template[] = [
  {
    Id: "create-generative-opportunity",
    Name: "Generative",
    Description:
      "An exploration study or opportunity in its early stages. Types of generative opportunities include 3rd party reviews, submittals, concepts, and organic project generation.",
    Link: generatePath(PATH.OPPORTUNITY_WITH_TYPE, { opportunityType: OpportunityAttributeEnum.Generative }),
  },
  {
    Id: "create-target-opportunity",
    Name: "Target",
    Description:
      "A cohesive geophysical, geological, and/or geochemical feature that warrants further exploration activities. Targets exist and can be tested through all phases of the exploration process.",
    Link: generatePath(PATH.OPPORTUNITY_WITH_TYPE, { opportunityType: OpportunityAttributeEnum.Target }),
  },
  {
    Id: "create-project-opportunity",
    Name: "Project",
    Description:
      "Projects have a defined mineral tenure, start point, and specific objectives that, when attained, signify completion. These objectives includes geochemical and geophysical testing, mapping within an area defined by secured mineral title, or drilling.",
    Link: generatePath(PATH.OPPORTUNITY_WITH_TYPE, { opportunityType: OpportunityAttributeEnum.Project }),
  },
  {
    Id: "create-programme-opportunity",
    Name: "Programme",
    Description:
      "A group of related projects, or a regional generative focus area. Programmes are managed in a coordinated way to obtain benefits not available from managing the projects or generative initiatives individually.",
    Link: generatePath(PATH.OPPORTUNITY_WITH_TYPE, { opportunityType: OpportunityAttributeEnum.Programme }),
  },
];

export function Home() {
  useDocumentTitle("Home");

  return (
    <OpportunityProvider>
      <HomePageComponent />
    </OpportunityProvider>
  );
}

function HomePageComponent() {
  const { isReadOnly } = useAuthorization();
  const theme = useTheme();
  const [{ data }] = useAsyncEffect(getRecentOpportunities, [], []);
  const [{ data: userAlerts }] = useAsyncEffect(getUserAlerts, [], []);
  const [showFavoriteButton, setShowFavoriteButton] = useState<number | null>(null);
  const { referenceData } = useReferenceDataContext();
  const [favorites, setFavorites] = useState<OpportunityModel[]>([]);
  const isDataLoading = (...objects: any[]) => objects.some((obj) => Object.keys(obj).length === 0);
  const templateFunc = (template: Template, index: number) => (
    <Fragment key={template.Name}>
      <Box display="flex" flexDirection="column" gap=".5rem">
        <Typography fontSize=".875rem" lineHeight="1.080625rem" letterSpacing=".25px">
          {template.Name}
        </Typography>
        <Typography fontSize=".75rem" lineHeight="123.5%" letterSpacing=".25px">
          {template.Description}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Button
          startIcon={<AddOutlinedIcon />}
          id={"add-new-" + template.Name}
          data-testid={"add-new-" + template.Name}
          variant="outlined"
          disableRipple
          component={RouterLink}
          to={template.Link}
          sx={{
            fontSize: ".8125rem",
            fontWeight: "400",
            letterSpacing: ".30px",
            textTransform: "initial",
            justifyContent: "center",
          }}
        >
          Add {template.Name}
        </Button>
      </Box>
      {index < templates.length - 1 && <Divider orientation="vertical" flexItem />}
    </Fragment>
  );
  const opportunitiesDisplay = (opportunities: OpportunityModel[], section: string) => (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        flex: "1 1 auto",
        height: 0,
        overflowY: "overlay",
      }}
      id={section + "-opportunities"}
      data-testid={section + "-opportunities"}
    >
      {opportunities.map((opportunity, index) => (
        <ListItem
          key={LandingPageLocators.OpportunityKeyLocator + index}
          id={`${section}-${LandingPageLocators.OpportunityKeyLocator}${index}`}
          data-testid={`${section}-${LandingPageLocators.OpportunityKeyLocator}${index}`}
          alignItems="flex-start"
          disablePadding
          onMouseOver={section === "favorites" ? () => setShowFavoriteButton(index) : undefined}
          onMouseLeave={section === "favorites" ? () => setShowFavoriteButton(null) : undefined}
        >
          <ListItemButton
            id={LandingPageLocators.OpportunityLocator + opportunity.id}
            data-testid={LandingPageLocators.OpportunityLocator + opportunity.id}
            disableRipple
            component={RouterLink}
            to={generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId: opportunity.id })}
          >
            <Box
              sx={{
                flex: "1 1 auto",
                display: "flex",
                gap: "0.75rem",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ flex: "1 1 auto", width: 0 }}>
                <Tooltip
                  title={opportunity.opportunityName}
                  id={LandingPageLocators.OpportunityNameTooltipLocator + opportunity.id}
                  data-testid={LandingPageLocators.OpportunityNameTooltipLocator + opportunity.id}
                >
                  <Typography
                    id={LandingPageLocators.OpportunityNameLocator + opportunity.id}
                    data-testid={LandingPageLocators.OpportunityNameLocator + opportunity.id}
                    noWrap
                    sx={{
                      fontSize: "1.5rem",
                      fontStyle: "normal",
                      fontWeight: "300",
                      lineHeight: "1.75rem",
                      letterSpacing: "0.15px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      color: "#3d3d3d",
                    }}
                  >
                    {opportunity.opportunityName}
                  </Typography>
                </Tooltip>
                <Typography
                  id={LandingPageLocators.OpportunityCountry + opportunity.id}
                  data-testid={LandingPageLocators.OpportunityCountry + opportunity.id}
                  noWrap
                  sx={{
                    fontStyle: "normal",
                    fontSize: "0.875rem",
                    fontWeight: "400",
                    lineHeight: "1.375rem",
                    letterSpacing: "0.1px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textTransform: "capitalize",
                    color: theme.palette.primary.main,
                    minWidth: "10px",
                    minHeight: "22px",
                  }}
                >
                  {opportunity.country}
                </Typography>
              </Box>
              <Box sx={{ flex: "0 1 5rem", width: "5rem" }}>
                <Tooltip
                  title={getCommodityName(opportunity.primaryCommodityId)}
                  id={LandingPageLocators.OpportunityCommodityTooltip + opportunity.id}
                  data-testid={LandingPageLocators.OpportunityCommodityTooltip + opportunity.id}
                >
                  <Chip
                    id={LandingPageLocators.OpportunityCommodity + opportunity.id}
                    data-testid={LandingPageLocators.OpportunityCommodity + opportunity.id}
                    sx={{
                      fontStyle: "normal",
                      fontSize: "0.813rem",
                      fontWeight: "400",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineHeight: "1.125rem",
                      letterSpacing: "0.16px",
                      textTransform: "capitalize",
                    }}
                    label={getCommodityName(opportunity.primaryCommodityId)}
                  />
                </Tooltip>
              </Box>
              {section === "favorites" && showFavoriteButton === index && (
                <Tooltip
                  title={SharedMessages.RemoveFavorite}
                  id={LandingPageLocators.OpportunityFavoriteTooltip}
                  data-testid={LandingPageLocators.OpportunityFavoriteTooltip}
                >
                  <IconButton
                    id={LandingPageLocators.OpportunityFavoriteButton}
                    data-testid={LandingPageLocators.OpportunityFavoriteButton}
                    size="small"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeFavorite(opportunity);
                    }}
                  >
                    <StarMinusIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
  const opportunitiesDisplaySkeleton = (
    <Box
      sx={{
        flex: "1 1 auto",
        height: 0,
        overflowY: "auto",
        gap: ".5rem",
        display: "flex",
        flexDirection: "column",
        paddingRight: "0.5rem",
      }}
    >
      {Array.from(Array(10).keys()).map((skeletonRow) => (
        <Box key={`skeleton-${skeletonRow}`} display={"flex"} gap={".5rem"}>
          <Skeleton variant="rounded" width={100} height={40} />
          <Skeleton variant="rounded" height={40} sx={{ flexGrow: 1 }} />
        </Box>
      ))}
    </Box>
  );

  const getCommodityName = (primaryCommodityId: number) => {
    const allCommodities = getAllCommodities(referenceData.commodityGroups);
    const commodity = allCommodities.find((commodity) => commodity.id === primaryCommodityId);
    return commodity ? commodity.name : "";
  };

  const { runRemoveFavorite } = useAddRemoveFavoriteDispatcher();

  const removeFavorite = async (opportunity: OpportunityModel) => {
    runRemoveFavorite(opportunity.id);
    setFavorites((prevFavorites) => prevFavorites.filter((favorite) => favorite.id !== opportunity.id));
  };

  useEffect(() => {
    getUserFavorites().then(({ data }) => {
      setFavorites(data);
    });
  }, []);

  return (
    <>
      {!isReadOnly ? (
        <Box
          sx={{
            padding: "3rem 0 0 0",
            flex: "0 1 auto",
            justifyContent: "space-between",
            display: "flex",
            gap: "1rem",
          }}
        >
          {templates.map((template, index) => templateFunc(template, index))}
        </Box>
      ) : null}
      <Box sx={{ padding: "3rem 0 2rem", flex: "0 1 auto" }}>
        <Divider />
      </Box>
      <Box sx={{ paddingBottom: ".5rem", height: "100%" }}>
        <Grid container spacing={2} sx={{ flex: "1 1 auto", paddingBottom: ".5rem", height: "100%" }}>
          <Grid xs={4} display={"flex"} gap={".6rem"} maxHeight={"100%"} rowGap={1}>
            <Box sx={{ height: "100%", display: "flex", flexDirection: "column", gap: "1rem", flexGrow: 1 }}>
              <Box sx={{ flex: "0 1 auto", display: "flex" }}>
                <AccessTimeFilledIcon sx={{ fill: theme.palette.grey[500] }} />
                <Typography ml={".6rem"}>Recent Activity</Typography>
              </Box>
              {data && !isDataLoading(referenceData)
                ? opportunitiesDisplay(data, "recent-activity")
                : opportunitiesDisplaySkeleton}
            </Box>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid xs={4} display={"flex"} gap={".6rem"}>
            <Box display={"flex"} flexDirection={"column"} gap={"1.5rem"} flexGrow={1}>
              <Box display={"flex"}>
                <StarIcon sx={{ fill: theme.palette.grey[500] }} />
                <Typography ml={".6rem"}>Favourites</Typography>
              </Box>
              {favorites ? opportunitiesDisplay(favorites, "favorites") : opportunitiesDisplaySkeleton}
            </Box>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Grid xs={4} display={"flex"} gap={".6rem"}>
            <Box display={"flex"} flexDirection={"column"} gap={"1.5rem"} flexGrow={1}>
              <Box display={"flex"}>
                <NotificationsIcon sx={{ fill: theme.palette.grey[500] }} />
                <Typography ml={".6rem"}>Alerts</Typography>
              </Box>
              {userAlerts ? (
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    flex: "1 1 auto",
                    height: 0,
                    overflowY: "overlay",
                  }}
                  id={LandingPageLocators.AlertsSectionLocator}
                  data-testid={LandingPageLocators.AlertsSectionLocator}
                >
                  {userAlerts.map((alert, index) => (
                    <ListItem
                      key={LandingPageLocators.OpportunityKeyLocator + index}
                      id={`alerts-${LandingPageLocators.OpportunityKeyLocator}${index}`}
                      data-testid={`alerts-${LandingPageLocators.OpportunityKeyLocator}${index}`}
                      alignItems="flex-start"
                      disablePadding
                    >
                      <ListItemButton
                        id={LandingPageLocators.OpportunityLocator + alert.id}
                        data-testid={LandingPageLocators.OpportunityLocator + alert.id}
                        disableRipple
                        component={RouterLink}
                        to={generatePath(PATH.VIEW_OPPORTUNITY_ALERTS, { opportunityId: alert.opportunityId })}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          alignSelf: "stretch",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "flex-end", alignSelf: "stretch", gap: "16px" }}>
                          <Typography
                            id={LandingPageLocators.OpportunityAlertDate + alert.id}
                            data-testid={LandingPageLocators.OpportunityAlertDate + alert.id}
                            noWrap
                            sx={{
                              fontSize: "1.5rem",
                              fontStyle: "normal",
                              fontWeight: "300",
                              lineHeight: "1.75rem",
                              letterSpacing: "0.15px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              color: "#3d3d3d",
                              flex: "1 0 auto",
                            }}
                          >
                            {formatAsShortDate(alert.alertDate)}
                          </Typography>
                          <Tooltip
                            title={alert.opportunity?.opportunityName}
                            id={LandingPageLocators.OpportunityNameTooltipLocator + alert.id}
                            data-testid={LandingPageLocators.OpportunityNameTooltipLocator + alert.id}
                          >
                            <Typography
                              id={LandingPageLocators.OpportunityNameLocator + alert.id}
                              data-testid={LandingPageLocators.OpportunityNameLocator + alert.id}
                              noWrap
                              sx={{
                                fontSize: "1.1255rem",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "1.75rem",
                                letterSpacing: "0.1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: theme.palette.primary.main,
                                flex: "0 1 auto",
                              }}
                            >
                              {alert.opportunity?.opportunityName}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "flex-end", alignSelf: "stretch", gap: "16px" }}>
                          <Tooltip
                            title={alert.title}
                            id={LandingPageLocators.OpportunityAlertTitle + alert.id}
                            data-testid={LandingPageLocators.OpportunityAlertTitle + alert.id}
                          >
                            <Typography
                              id={LandingPageLocators.OpportunityAlertTitle + alert.id}
                              data-testid={LandingPageLocators.OpportunityAlertTitle + alert.id}
                              noWrap
                              sx={{
                                fontStyle: "normal",
                                fontSize: "0.875rem",
                                fontWeight: "400",
                                lineHeight: "1.375rem",
                                letterSpacing: "0.1px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                color: "#3d3d3d",
                                minWidth: "10px",
                                minHeight: "22px",
                                flex: "1 0 0",
                              }}
                            >
                              {alert.title}
                            </Typography>
                          </Tooltip>
                          <Typography
                            id={LandingPageLocators.OpportunityCountry + alert.id}
                            data-testid={LandingPageLocators.OpportunityCountry + alert.id}
                            noWrap
                            sx={{
                              fontStyle: "normal",
                              fontSize: "0.875rem",
                              fontWeight: "400",
                              lineHeight: "1.375rem",
                              letterSpacing: "0.1px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textTransform: "capitalize",
                              color: "#3d3d3d",
                              minWidth: "10px",
                              minHeight: "22px",
                            }}
                          >
                            {alert.opportunity?.country}
                          </Typography>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              ) : (
                opportunitiesDisplaySkeleton
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
