export const VALIDATION_MESSAGES = {
  OUT_OF_RANGE: "Out of range.",
  NOT_EMPTY_OR_BLANK: "The value cannot be empty or blank.",
  SELECTED_OPTION_OUT_OF_RANGE: "Selected option is out of range.",
  AT_LEAST_N_CHARACTERS: (num: number) => `Enter at least ${num} characters.`,
  NO_MORE_THAN_N_CHARACTERS: (num: number) => `Enter no more than ${num} characters.`,
  SCALE_ERROR: "Enter 6 decimals maximum.",
  ALPHANUMERIC: "Must be alphanumeric.",
  NUMERIC_MIN_MAX: (min: number, max: number) => `Must be numeric between ${min} and ${max}.`,
  FROM_TO_DATE: "To date should be greater than From Date.",
  START_END_DATE: "Should be greater than Start Date.",
  OWNERSHIP_SHARE_RATE: "The total ownership share rates for a given date range should never be more than 100%.",
  MISSING_LATITUDE: "Missing Latitude.",
  MISSING_LONGITUDE: "Missing Longitude.",
  INVALID_DATE: "Enter a valid date.",
  INVALID_URL: "Enter a valid URL.",
  PAST_ALERT_DATE: "Alert Date must not be in the past.",
  TABLE_NAME_REQUIRED: "Table name is required.",
  INVALID_ALERT_RECIPIENT_EMAIL: "One or more emails provided are not part of RioTinto email domain.",
  INVALID_FILE_EXTENSION: "Invalid file extension.",
};
