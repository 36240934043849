import { ReactNode } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import { CardBodyTooltip, CardTooltipType } from ".";

interface SectionProps {
  id: string;
  ariaControls: string;
  title: string;
  children: ReactNode;
  tooltipFields?: CardTooltipType;
}

export function CardBodySectionBase({ id, ariaControls, title, children, tooltipFields }: SectionProps) {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={ariaControls} id={id} data-testid={id}>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>
        <CardBodyTooltip id={id} fields={tooltipFields} />
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
