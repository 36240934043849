import { useMemo } from "react";
import { Box, CardHeader, TextField, Button, FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { BulkUploadLocators } from "@constants";
import { PropsWithTestId } from "types";
import { useDocumentTitle } from "hooks";
import { useBulkUpload } from "features/admin/hooks";
import { referenceDataMenuItems } from "features/opportunities/components";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

import { UploadResultList } from "../UploadResult/UploadResultList";

export function UploadFile() {
  useDocumentTitle("Admin - Bulk Upload");
  const {
    apiErrorMessage,
    currentFilename,
    uploadErrors,
    selectedTableNameId,
    currentFile,
    disabledSubmit,
    isFileValid,
    selectFile,
    submitButtonHandler,
    handleTableNameChange,
    handleDownload,
    bulkUploadEntities,
  } = useBulkUpload();

  return useMemo(() => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid xs={3} paddingLeft={"1em"}>
            <CardHeader
              id={BulkUploadLocators.HeaderLabel}
              data-testid={BulkUploadLocators.HeaderLabel}
              title="Bulk upload tool"
              sx={{ display: "inline", flexGrow: 1, padding: "1 em" }}
            />
          </Grid>
          <Button
            id={BulkUploadLocators.TemplateButton}
            data-testid={BulkUploadLocators.TemplateButton}
            type="button"
            variant="contained"
            onClick={handleDownload}
            sx={{ marginLeft: "3em" }}
            disabled={selectedTableNameId === 0}
          >
            Download Csv Template
          </Button>
        </Box>
        <Box display="block" padding=".5rem 1rem" id="testing">
          <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid xs={3}>
              <FormControl required fullWidth>
                <InputLabel id={BulkUploadLocators.TableNameLabel} data-testid={BulkUploadLocators.TableNameLabel}>
                  Table to update
                </InputLabel>
                <Select
                  labelId={BulkUploadLocators.TableNameLabel}
                  id={BulkUploadLocators.TableNameSelect}
                  SelectDisplayProps={{ "data-testid": BulkUploadLocators.TableNameSelect } as PropsWithTestId}
                  value={selectedTableNameId}
                  label="Table to update"
                  onChange={handleTableNameChange}
                  error={selectedTableNameId === 0}
                >
                  {referenceDataMenuItems(bulkUploadEntities, BulkUploadLocators.TableNameOption)}
                </Select>
                {selectedTableNameId === 0 && (
                  <FormHelperText>{VALIDATION_MESSAGES.TABLE_NAME_REQUIRED}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid xs={6}>
              <TextField
                id={BulkUploadLocators.FilePicker}
                inputProps={{ "data-testid": BulkUploadLocators.FilePicker }}
                type="file"
                onChange={selectFile}
                value={currentFilename}
                error={!isFileValid}
                helperText={!isFileValid ? VALIDATION_MESSAGES.INVALID_FILE_EXTENSION : undefined}
              />
            </Grid>
            <Grid xs={3}>
              <Button
                id={BulkUploadLocators.UploadButton}
                data-testid={BulkUploadLocators.UploadButton}
                fullWidth
                type="submit"
                variant="contained"
                onClick={submitButtonHandler}
                sx={{ width: "7.5em" }}
                disabled={disabledSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
        <UploadResultList uploadResult={uploadErrors} apiMessage={apiErrorMessage} />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    apiErrorMessage,
    selectedTableNameId,
    selectFile,
    currentFilename,
    currentFile,
    uploadErrors,
    disabledSubmit,
    isFileValid,
  ]);
}
