import { Fragment, useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { Box, Checkbox, List, ListItemButton, ListItemText } from "@mui/material";

import { Criteria, DataObject } from "./NestedListAutoComplete";

export interface ObjectWithLevel {
  indexes: number[];
  name: string;
  id: number;
  level: number;
  displaySequence: number;
  parentId: number[];
  checked?: boolean;
}

export function flattenJson<T>(
  data: T[],
  criteria: Criteria,
  currentLevel: number[] = [],
  currentIds: number[],
  flattenedArray: ObjectWithLevel[] = [],
  visited: string[] = []
) {
  const { identifier, labelProperty, sortProperty } = criteria;
  data.forEach((item: any, i) => {
    if (item && typeof item === "object") {
      const itemId = item[identifier];

      if (!visited.includes(itemId)) {
        flattenedArray.push({
          indexes: [...currentLevel, i],
          id: itemId,
          name: item[labelProperty],
          level: currentLevel.length,
          displaySequence: item[sortProperty],
          parentId: [...currentIds, itemId],
        });

        visited.push(itemId);
      }

      Object.values(item).forEach((value) => {
        if (Array.isArray(value)) {
          flattenJson(value, criteria, [...currentLevel, i], [...currentIds, itemId], flattenedArray, visited);
        }
      });
    }
  });

  flattenedArray.sort((a: ObjectWithLevel, b: ObjectWithLevel) =>
    a.displaySequence < b.displaySequence ? -1 : a.displaySequence > b.displaySequence ? 1 : 0
  );

  flattenedArray.forEach((element: ObjectWithLevel) => {
    element.parentId = element.parentId.filter((x) => x !== element.id);
    if (element.parentId.length > 0) {
      element.parentId = [element.parentId[element.parentId.length - 1]];
    }
  });

  return flattenedArray;
}

export interface NestedListData<T> {
  data: DataObject<T>;
  jsonData: T[];
  searchResult: (data: LabelTypeResultTextData) => void;
  searchResultData: LabelTypeResultTextData | null;
  searchFreeText: string;
  flattenJsonData: ObjectWithLevel[];
}

export interface ChildrenValidator {
  item: any;
  key: string;
  level: number;
}

export interface LabelTypeResultTextData {
  labelTypeText: string[];
  idsToCheck: number[];
}
export function NestedList<T>(props: NestedListData<T>) {
  const { data, jsonData, searchResultData, flattenJsonData } = props;
  const flattenedJsonObjData = flattenJsonData;
  const labelTypeIds: number[] = flattenedJsonObjData.map((e) => e.id);
  const [checkBoxState, updateCheckBoxState] = useState(new Array<boolean>(labelTypeIds.length).fill(false));
  const { identifier, labelProperty, children } = data.criteria;
  const childrenArr = [...children];
  childrenArr.shift();
  const idsByLevels: number[][] = Array.from(children, (_) => []);
  const includedIds: number[] = [];
  const [freeSearchSelected, updateFreeSearchSelected] = useState(new Array<boolean>(labelTypeIds.length).fill(false));

  useEffect(
    () => {
      let newCheckBoxState = checkBoxState.fill(false);
      let indexesToCheck = props.searchResultData?.idsToCheck.map((e) =>
        flattenedJsonObjData.findIndex((x) => x.id === e)
      ) as number[];
      indexesToCheck?.forEach((index: number) => {
        newCheckBoxState[index] = true;
      });
      updateCheckBoxState([...newCheckBoxState]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchResultData]
  );

  useEffect(
    () => {
      const freeTextFilteredLTIdIndexes: number[] = [];
      const freeSearchSelectedValues: boolean[] = [...freeSearchSelected].fill(true);
      let freeTextFilteredLTIds: number[];
      if (props.searchFreeText.length > 0) {
        (async () => {
          const result = await filterLabelTypeIdsByFreeText(props.searchFreeText, flattenedJsonObjData);
          freeTextFilteredLTIds = result.length > 0 ? result[0].idsToCheck : [];
          if (freeTextFilteredLTIds.length > 0) {
            freeTextFilteredLTIdIndexes.push(...freeTextFilteredLTIds.map((e) => labelTypeIds.indexOf(e)));
          }
          if (freeTextFilteredLTIdIndexes.length > 0) {
            freeTextFilteredLTIdIndexes.forEach((e) => (freeSearchSelectedValues[e] = false));
          }
          updateFreeSearchSelected(freeSearchSelectedValues);
        })();
      } else {
        updateFreeSearchSelected([...freeSearchSelected].fill(false));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.searchFreeText]
  );

  const filterLabelTypeIdsByFreeText = async (searchText: string, _flattendData: ObjectWithLevel[]) => {
    const freeSearchResults = flattenedJsonObjData.filter((x) =>
      x.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
    );
    const freeTextResultData: LabelTypeResultTextData = { idsToCheck: [], labelTypeText: [] };
    const labelTypesSearchFreeTextResults = await getFreeTextSearchResultIds(freeSearchResults, freeTextResultData);
    return labelTypesSearchFreeTextResults;
  };

  const getFreeTextSearchResultIds = async (
    freeSearchResults: ObjectWithLevel[],
    freeTextResultData: LabelTypeResultTextData
  ) => {
    return await Promise.all(
      freeSearchResults.map((e) => getLabelTypeResultText(e, freeTextResultData as LabelTypeResultTextData))
    );
  };

  children.forEach((_e, i) => {
    const ids = flattenedJsonObjData.filter((obj) => obj.level === i).map((obj) => obj.id);
    ids.forEach((id) => {
      idsByLevels[i].push(id);
    });
  });

  const handleClick = async (item: ObjectWithLevel) => {
    let newCheckBoxState = checkBoxState.map((_) => false);
    const { labelTypeText, idsToCheck } = await getLabelTypeResultText(item, { labelTypeText: [], idsToCheck: [] });
    if (idsToCheck.length > 0) {
      idsToCheck.forEach((id) => {
        const idIndex = labelTypeIds.indexOf(id);
        newCheckBoxState[idIndex] = !newCheckBoxState[idIndex];
      });
      props.searchResult({ labelTypeText, idsToCheck } as LabelTypeResultTextData);
    }
    updateCheckBoxState(newCheckBoxState);
  };

  const getLabelTypeResultText = async (item: ObjectWithLevel, labelTypeTextData: LabelTypeResultTextData) => {
    const data: ObjectWithLevel = flattenedJsonObjData.find((x) => x.id === item.id) as ObjectWithLevel;
    labelTypeTextData.idsToCheck.push(data.id);
    if (data.parentId.length > 0) {
      labelTypeTextData.labelTypeText.push(data.name);
      const parentItem = flattenedJsonObjData.find((x) => x.id === data.parentId[0]) as ObjectWithLevel;
      labelTypeTextData = await getLabelTypeResultText(parentItem, labelTypeTextData);
    } else {
      labelTypeTextData.labelTypeText.push(data.name);
    }
    return labelTypeTextData;
  };

  const childrenCheck = (item: any) => {
    var level: number = 0;
    for (const [key, value] of Object.entries(item)) {
      if (Array.isArray(value) && childrenArr.includes(key)) {
        level = childrenArr.indexOf(key);
        return { item: value, key: key, level: level } as ChildrenValidator;
      }
    }
    return { item: item, key: "", level: level } as ChildrenValidator;
  };

  const checkForChildItems = (lItem: any) => {
    includedIds.push(lItem[identifier]);
    const { item, key, level } = childrenCheck(lItem);
    if (key.length > 0) {
      return (
        <List disablePadding component="div" key={"ListSubItem" + item[identifier]}>
          {item.map((subItem: any) => (
            <Fragment key={"Listwrapper" + subItem[identifier]}>
              {freeSearchSelected[labelTypeIds.indexOf(subItem[identifier])] === false ? (
                <>
                  <ListItemButton
                    key={"ListSubItemButton" + subItem[identifier]}
                    onClick={() => handleClick(subItem)}
                    sx={{ pl: (level + 1) * 3 }}
                  >
                    <ExpandMore />
                    <Checkbox
                      style={{
                        transform: "scale(0.75)",
                        padding: 0,
                        paddingLeft: "16px",
                      }}
                      edge="start"
                      checked={checkBoxState[labelTypeIds.indexOf(subItem[identifier])]}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": subItem[identifier] }}
                      key={"ListSubItemCheckBox" + subItem[identifier]}
                    />
                    <ListItemText
                      primaryTypographyProps={{ fontSize: "14px" }}
                      primary={subItem[labelProperty]}
                      sx={{ fontSize: "8px" }}
                      data-testid={subItem[identifier]}
                      key={"ListSubItemText" + subItem[identifier]}
                    ></ListItemText>
                  </ListItemButton>
                  {checkForChildItems(subItem)}
                </>
              ) : null}
            </Fragment>
          ))}
        </List>
      );
    }
  };

  return (
    <Box
      key={props.searchResultData?.idsToCheck.length}
      sx={{ width: "100%", maxWidth: "431px", bgcolor: "background.paper", maxHeight: "300px", overflow: "auto" }}
    >
      <List disablePadding component="div" key={"ListParent"}>
        {jsonData.map((dp: any) => (
          <Fragment key={"ListItemButton" + dp[identifier]}>
            {freeSearchSelected[labelTypeIds.indexOf(dp[identifier])] === false ? (
              <>
                <ListItemButton key={"ListItemButton" + dp[identifier]} onClick={() => handleClick(dp)}>
                  <ExpandMore />
                  <Checkbox
                    style={{
                      transform: "scale(0.75)",
                      padding: 0,
                      paddingLeft: "16px",
                    }}
                    edge="start"
                    checked={checkBoxState[labelTypeIds.indexOf(dp[identifier])]}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": dp[identifier] }}
                    key={"ListItemCheckBox" + dp[identifier]}
                  />
                  <ListItemText
                    key={"ListItemButton" + dp[identifier]}
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={dp[labelProperty]}
                    sx={{ fontSize: "8px" }}
                    data-testid={dp[identifier]}
                  ></ListItemText>
                </ListItemButton>
                {checkForChildItems(dp)}
              </>
            ) : null}
          </Fragment>
        ))}
      </List>
    </Box>
  );
}
