import L from "leaflet";

export const LegendConstants = {
  ControlPosition: "topright" as L.ControlPosition,
  BtnTitle: "Map Legends",
  IconWidth: 31,
  IconHeight: 31,
  NoLegendsToDisplay: "No legends to display",
  customMarkerShapeColor: "#22223b",
  legendSizeShape:
    "<svg xmlns='http://www.w3.org/2000/svg' height='48' viewBox='0 -960 960 960' width='48'><path d='M480-277 277-480l203-203 203 203-203 203Zm43 176q-9 9-20 13.5T481-83q-11 0-22.5-4.5T438-101L101-438q-9-9-13.5-20T83-480q0-11 4.5-22.5T101-523l338-338q8-8 19-12.5t22-4.5q11 0 22 4.5t19 12.5l338 338q9 9 13.5 20.333Q877-491.333 877-480q0 11.25-4.696 22.5Q867.609-446.25 859-438L523-101Zm-43-66 313-313-313-313-313 313 313 313Z'/></svg>",
  mapLegend:
    "<svg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#868686'><path d='M0 0h24v24H0V0z' fill='none'/><path d='M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9c-.21.07-.36.25-.36.48V20.5c0 .28.22.5.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9c.21-.07.36-.25.36-.48V3.5c0-.28-.22-.5-.5-.5zM10 5.47l4 1.4v11.66l-4-1.4V5.47zm-5 .99l3-1.01v11.7l-3 1.16V6.46zm14 11.08l-3 1.01V6.86l3-1.16v11.84z'/></svg>",
  legendShapeWidth: 20,
  legendShapeHeight: 20,
};
