import { z } from "zod";

export const ParentOpportunitySchema = z.object({
  id: z.string(),
  opportunityName: z.string(),
  restrictedFlag: z.boolean(),
});

export interface ParentOpportunityType extends z.infer<typeof ParentOpportunitySchema> {
  inputValue?: string;
}

export const parentOpportunityInitialState: ParentOpportunityType = {
  id: "",
  opportunityName: "",
  restrictedFlag: false,
};
