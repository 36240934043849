import { useMemo } from "react";
import { debounce } from "@mui/material";

import { UTILS } from "@constants";

export function useFetch(run: (searchQuery: string) => void) {
  return useMemo(
    () =>
      debounce((input: string) => {
        if (input !== "") {
          run(input);
        }
      }, UTILS.DEBOUNCETIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
}
