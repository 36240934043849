import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton, Tooltip } from "@mui/material";

import { SearchResultLocators } from "@constants";
import { OpportunityType } from "features/opportunities/models";
import { useExportOpportunityList } from "features/search/hooks";

interface ExportOpportunityListButtonProps {
  opportunityList: OpportunityType[];
}

export function ExportOpportunityListButton({ opportunityList }: ExportOpportunityListButtonProps) {
  const { runExportOpportunities, exportOpportunityListData } = useExportOpportunityList(opportunityList);

  return (
    <Tooltip
      title="Export list of opportunities"
      id={SearchResultLocators.ExportButtonTooltip}
      data-testid={SearchResultLocators.ExportButtonTooltip}
    >
      <IconButton
        size="small"
        id={SearchResultLocators.ExportButton}
        data-testid={SearchResultLocators.ExportButton}
        onClick={() => runExportOpportunities(exportOpportunityListData)}
      >
        <LogoutIcon />
      </IconButton>
    </Tooltip>
  );
}
