import { ChangeEvent, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";

import { NoErrors, useOpportunityContext } from "features/opportunities/context";

import { ReferenceDocumentType, validateReferenceDocument } from "../models";
import { ActionTypes } from "../reducers";
import { formatZodErrors } from "./useValidation";

export type ErrorType = {
  [Property in keyof ReferenceDocumentType]: string;
};

export function useReferenceDocument(initialRefDoc: ReferenceDocumentType | null) {
  const [selectedReferenceDocument, setSelectedReferenceDocument] = useState<ReferenceDocumentType | null>(null);
  const [refDocFormErrors, setRefDocFFormErrors] = useState<ErrorType>({} as ErrorType);
  const { opportunity, dispatch } = useOpportunityContext();

  useEffect(() => {
    setSelectedReferenceDocument(initialRefDoc);
  }, [initialRefDoc]);

  const isReferenceDocumentValid = (refDoc: ReferenceDocumentType) => {
    const result = validateReferenceDocument(refDoc);
    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setRefDocFFormErrors(formattedErrors);
    }
    return result.success;
  };

  function updateReferenceDocuments(newRefDocList: ReferenceDocumentType[]) {
    dispatch({
      type: ActionTypes.REPLACE_REFERENCE_DOCUMENTS,
      payload: newRefDocList,
    });
  }

  const handleSubmit = () => {
    if (!selectedReferenceDocument) {
      return;
    }

    let newRefDocList: ReferenceDocumentType[] = opportunity.referenceDocuments ?? [];

    if (selectedReferenceDocument.id === 0) {
      const ids = newRefDocList.map(function (o) {
        return o.id;
      });
      const addEditId = ids !== undefined && ids.filter((x) => x < 0).length > 0 ? Math.min(...ids) - 1 : -1;
      selectedReferenceDocument.id = addEditId;
      newRefDocList.push(selectedReferenceDocument);
      updateReferenceDocuments(newRefDocList);
      return;
    }

    selectedReferenceDocument.isUpdated = selectedReferenceDocument.id > 0;
    let index: number = newRefDocList.findIndex((x) => x.id === selectedReferenceDocument.id);
    newRefDocList.splice(index, 1, selectedReferenceDocument);
    updateReferenceDocuments(newRefDocList);
    return;
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSelectedReferenceDocument((prevState) => ({
      ...prevState!,
      [name]: value.trimStart(),
    }));
    resetErrors();
  };

  const handleTypeChange = (event: SelectChangeEvent<number>) => {
    setSelectedReferenceDocument({
      ...selectedReferenceDocument!,
      referenceDocumentTypeId: parseInt(event.target.value as string),
    });
    resetErrors();
  };

  const resetErrors = () => {
    setRefDocFFormErrors(NoErrors);
  };

  return {
    refDocFormErrors,
    selectedReferenceDocument,
    isReferenceDocumentValid,
    handleInputChange,
    handleSubmit,
    handleTypeChange,
    resetErrors,
    setSelectedReferenceDocument,
  };
}
