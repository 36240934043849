import { apiParameters } from "@constants";
import { ExportOpportunityList, OpportunityModel, UserFavoriteModel } from "types";
import { apiClient as client } from "services";
import {
  AlertType,
  CommentType,
  NamedEntity,
  ParentOpportunityType,
  ResponsibleGroupType,
  ResponsiblePersonType,
} from "features/opportunities/models";
import { ApiAdvancedSearchModelType } from "features/search/models";

export const getRecentOpportunities = (take = apiParameters.getRecentOpportunitiesTake) =>
  client.get<OpportunityModel[]>(`/opportunity?take=${take}`);
export const getOpportunityById = (id: string) => client.get<OpportunityModel>(`/opportunity/${id}`);
export const createOpportunity = (data: Partial<OpportunityModel>) =>
  client.post<OpportunityModel>("/opportunity", data);
export const editOpportunity = (id: string, data: Partial<OpportunityModel>) =>
  client.put<OpportunityModel>(`/opportunity/${id}`, data);
export const deleteOpportunity = (id: string) => client.delete(`/opportunity/${id}`);
export const searchOpportunity = (query: string) => client.get<OpportunityModel[]>(`/opportunity/search/${query}`);
export const advancedSearchOpportunity = (searchCriteria: ApiAdvancedSearchModelType) =>
  client.post<OpportunityModel[]>(`/opportunity/advancedSearch`, searchCriteria);
export const searchForParentOpportunities = (searchQuery: string) =>
  client.get<ParentOpportunityType[]>(`/opportunity/searchParent/${searchQuery}`);
export const searchForNamedEntities = (searchQuery: string) =>
  client.get<NamedEntity[]>(`/namedentity/search/${searchQuery}`);
export const searchForResponsiblePersons = (searchQuery: string) =>
  client.get<ResponsiblePersonType[]>(`/ResponsibleEntity/searchPerson/${searchQuery}`);
export const searchForResponsibleGroups = () => client.get<ResponsibleGroupType[]>(`/ResponsibleEntity/getGroups`);
export const opportunityIsParent = (id: string) => client.get<boolean>(`/opportunity/isParent/${id}`);
export const addUserFavorite = (opportunityId: UserFavoriteModel) => client.post(`/UserFavorite`, opportunityId);
export const removeUserFavorite = (opportunityId: string) => client.delete(`/UserFavorite/${opportunityId}`);
export const getUserFavorites = () => client.get<OpportunityModel[]>("/UserFavorite");
export const addAlert = (data: AlertType) => client.post<AlertType>("/Alert", data);
export const updateAlert = (id: number, data: Partial<AlertType>) => client.put<AlertType>(`/Alert/${id}`, data);
export const deleteAlert = (id: number) => client.delete(`/Alert/${id}`);
export const getAlertsByOpportunityId = (opportunityId: string) => client.get<AlertType[]>(`/Alert/${opportunityId}`);
export const getUserAlerts = () => client.get<AlertType[]>("/Alert");
export const getCommentByOpportunityId = (opportunityId: string) =>
  client.get<CommentType[]>(`/OpportunityComment/${opportunityId}`);
export const addComment = (data: Partial<CommentType>) => client.post<CommentType>("/OpportunityComment", data);
export const updateComment = (id: number, data: Partial<CommentType>) => client.put(`/OpportunityComment/${id}`, data);
export const deleteComment = (id: number) => client.delete(`/OpportunityComment/${id}`);
export const validateLandfolioId = (landfolioId: string) =>
  client.get<boolean>(`/Landfolio?landfolioId=${landfolioId}`);
export const exportOpportunityList = (data: ExportOpportunityList) => client.post(`/ExportOpportunity`, data);
