import { PropsWithChildren, createContext, useContext, useMemo, useReducer, useState } from "react";

import { SpatialSearchBound } from "features/search/models/AdvancedSearchCriteria";

import { advancedSearchReducer, initialState } from "../hooks";
import { ActionTypes, CriteriaOperatorPayload, CriteriaValuePayload } from "../hooks/useAdvancedSearchReducer";
import { AdvancedSearchModelType } from "../models";
import { UserSavedSearchType } from "../models/UserSavedSearch";

type AdvancedSearchContextType = {
  searchCriteria: AdvancedSearchModelType;
  selectedSavedSearch: UserSavedSearchType | null;
  setBounds: (bounds?: SpatialSearchBound) => void;
  clearSearchCriteria: () => void;
  replaceSearchCriteria: (searchCriteria: AdvancedSearchModelType) => void;
  setFieldValue: (value: CriteriaValuePayload<keyof AdvancedSearchModelType>) => void;
  setOperatorValue: (value: CriteriaOperatorPayload<keyof AdvancedSearchModelType>) => void;
  setSelectedSavedSearch: (value: UserSavedSearchType | null) => void;
};

const advancedSearchContextInitialValue: AdvancedSearchContextType = {
  searchCriteria: initialState,
  selectedSavedSearch: null,
  setBounds: () => {},
  clearSearchCriteria: () => {},
  replaceSearchCriteria: () => {},
  setFieldValue: () => {},
  setOperatorValue: () => {},
  setSelectedSavedSearch: () => {},
};

export const AdvancedSearchContext = createContext<AdvancedSearchContextType>(advancedSearchContextInitialValue);

export const useAdvancedSearchContext = (): AdvancedSearchContextType => {
  return useContext(AdvancedSearchContext);
};

export function AdvancedSearchContextProvider({ children }: PropsWithChildren) {
  const [state, dispatch] = useReducer(advancedSearchReducer, initialState);
  const [selectedSavedSearch, setSelectedSavedSearch] = useState<UserSavedSearchType | null>(null);

  const setBounds = (bounds?: SpatialSearchBound) => dispatch({ type: ActionTypes.SetBounds, payload: bounds });
  const clearSearchCriteria = () => {
    setSelectedSavedSearch(null);
    dispatch({ type: ActionTypes.Clear });
  };

  const replaceSearchCriteria = (searchCriteria: AdvancedSearchModelType) =>
    dispatch({ type: ActionTypes.ReplaceModel, payload: searchCriteria });

  const setFieldValue = (value: CriteriaValuePayload<keyof AdvancedSearchModelType>) => {
    dispatch({ type: ActionTypes.SetFieldValue, payload: value });
  };

  const setOperatorValue = (value: CriteriaOperatorPayload<keyof AdvancedSearchModelType>) =>
    dispatch({ type: ActionTypes.SetOperatorValue, payload: value });

  const value = useMemo(
    () => ({
      searchCriteria: state,
      selectedSavedSearch,
      setBounds,
      clearSearchCriteria,
      replaceSearchCriteria,
      setFieldValue,
      setOperatorValue,
      setSelectedSavedSearch,
    }),
    [selectedSavedSearch, state]
  );

  return <AdvancedSearchContext.Provider value={value}>{children}</AdvancedSearchContext.Provider>;
}
