import { Box, ListItemButton, ListItemText, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { SearchResultLocators } from "@constants";
import { getAllCommoditiesToString, getFullLocation } from "features/opportunities/constants";
import { OpportunityType, ReferenceCommodityGroups } from "features/opportunities/models";

interface SearchResultListItemProps {
  opportunity: OpportunityType;
  referenceData: ReferenceCommodityGroups[] | undefined;
  onClick: () => void;
}

export function SearchResultListItem({ opportunity, referenceData, onClick }: SearchResultListItemProps) {
  const textStyle = { component: "span", variant: "body2", color: "text.primary" };
  const { opportunityId } = useParams();

  const isItemSelected = (opp: OpportunityType) => {
    if (opportunityId) {
      return opportunityId === opp.id;
    }
  };

  return (
    <ListItemButton
      selected={isItemSelected(opportunity)}
      data-testid={`search-list-item-${opportunity.id}`}
      key={opportunity.id}
      onClick={onClick}
    >
      <ListItemText
        primary={
          <Box display="flex">
            <Typography
              data-testid={SearchResultLocators.ItemName}
              style={textStyle}
              sx={{
                display: "inline",
                textDecoration: "underline",
                fontWeight: 400,
                fontSize: ".875rem",
                lineHeight: "1rem",
              }}
            >
              {opportunity.opportunityName}
            </Typography>
            <Typography
              data-testid={SearchResultLocators.ItemAlternateNames}
              style={textStyle}
              sx={{
                marginLeft: "1rem",
                fontWeight: 400,
                fontSize: ".75rem",
                lineHeight: ".875rem",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {opportunity.opportunityAlternateNames}
            </Typography>
          </Box>
        }
        secondary={
          <>
            <Typography
              data-testid={SearchResultLocators.ItemCommodities}
              component="span"
              style={textStyle}
              sx={{
                fontWeight: 400,
                fontSize: ".75rem",
                lineHeight: "123.5%",
                display: "block",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                marginTop: ".5rem",
              }}
            >
              {getAllCommoditiesToString(opportunity, referenceData)}
            </Typography>
            <Typography
              data-testid={SearchResultLocators.ItemLocation}
              component="span"
              style={textStyle}
              sx={{
                display: "block",
                fontWeight: 400,
                fontSize: ".75rem",
                lineHeight: "123.5%",
              }}
            >
              {getFullLocation(opportunity.country, opportunity.stateProvince)}
            </Typography>
          </>
        }
      />
    </ListItemButton>
  );
}
