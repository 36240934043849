import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, List, ListItem, Typography } from "@mui/material";

import { AlertLocators } from "@constants";
import { formatAsShortDate } from "services";
import { useAuthorization } from "context";
import { ExtendedPanel } from "components";
import { useOpportunityContext } from "features/opportunities/context";
import { useAlert } from "features/opportunities/hooks/useAlert";
import { AlertType } from "features/opportunities/models";

import { AddEditAlert } from "./AddEditAlert";

interface AlertsPaneProps {
  toggleAlertsPane: Dispatch<SetStateAction<boolean>>;
}

const newAlert: AlertType = {
  id: 0,
  opportunityId: "",
  title: "",
  description: "",
  action: "",
  alertDate: null,
};

export function AlertsPane({ toggleAlertsPane }: AlertsPaneProps) {
  const { opportunity } = useOpportunityContext();
  const { isReadOnly } = useAuthorization();
  const [openDialog, setOpenDialog] = useState(false);
  const { selectedAlert, setSelectedAlert, handleDelete, displayRecipients, handleSortAlerts, sortButtonText } =
    useAlert(newAlert);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openEditDeleteMenu = Boolean(anchorEl);

  let descriptionRefs = useRef<HTMLSpanElement[]>(Array(opportunity.alerts?.length));
  let actionRefs = useRef<HTMLSpanElement[]>(Array(opportunity.alerts?.length));
  const [expandDescription, setExpandDescription] = useState<boolean[]>(
    Array(opportunity.alerts?.length).fill(false) ?? []
  );
  const [expandAction, setExpandAction] = useState<boolean[]>(Array(opportunity.alerts?.length).fill(false) ?? []);

  const isTextOverflowing = (section: string, index: number) => {
    const maxHeightBeforeExpand = 96;

    if (section.toLowerCase() === "description") {
      return descriptionRefs.current[index]?.scrollHeight! > maxHeightBeforeExpand;
    } else if (section.toLowerCase() === "action") {
      return actionRefs.current[index]?.scrollHeight! > maxHeightBeforeExpand;
    }
  };

  const toggleEditDeleteMenu = (event: React.MouseEvent<HTMLButtonElement>, alert: AlertType) => {
    setAnchorEl(event.currentTarget);
    setSelectedAlert(alert);
  };

  const handleOpenDialog = () => {
    setSelectedAlert(newAlert);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedAlert(null);
    setOpenDialog(false);
  };

  const handleEditMenu = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  return useMemo(
    () => {
      return (
        <>
          <ExtendedPanel
            panel={AlertLocators.PanelHeader}
            panelHeaderText="Alerts"
            setCloseExtendedPanel={toggleAlertsPane}
            menuAnchorEl={anchorEl}
            setMenuAnchorEl={setAnchorEl}
            handleOpenDialog={handleOpenDialog}
            handleEditMenu={handleEditMenu}
            handleDelete={handleDelete}
            sortButtonText={sortButtonText}
            handleSort={handleSortAlerts}
            showAddButton={!isReadOnly}
          >
            {opportunity.alerts && (
              <List id={AlertLocators.AlertList} data-testid={AlertLocators.AlertList} sx={{ p: 0, m: 0 }}>
                {opportunity.alerts.map((alert, index) => (
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                      p: 0,
                    }}
                    key={AlertLocators.Alert + index}
                  >
                    <Box
                      id={AlertLocators.Alert + index}
                      data-testid={AlertLocators.Alert + index}
                      sx={{ display: "flex", alignSelf: "stretch" }}
                    >
                      <Box sx={{ display: "flex", flex: "1 0 0", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontStyle: "normal",
                              fontSize: "1.125rem",
                              fontWeight: "500",
                              lineHeight: "1.8rem",
                              letterSpacing: "0.15px",
                              color: "rgba(0, 0, 0, 0.60)",
                              paddingRight: 2,
                            }}
                            data-testid={AlertLocators.AlertDate + index}
                          >
                            {formatAsShortDate(alert.alertDate)}
                          </Typography>
                          <Typography
                            sx={{
                              fontStyle: "normal",
                              lineHeight: "1.313rem",
                              letterSpacing: "0.15px",
                              color: "rgba(0, 0, 0, 0.60)",
                              overflowWrap: "anywhere",
                            }}
                          >
                            {alert.creator?.firstName} {alert.creator?.lastName}
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            alignSelf: "stretch",
                            fontSize: "1.25rem",
                            fontWeight: "500",
                            fontStyle: "normal",
                            lineHeight: "2rem",
                            letterSpacing: "0.15px",
                            color: "rgba(0, 0, 0, 0.87)",
                            pb: 1,
                            overflowWrap: "anywhere",
                          }}
                          data-testid={AlertLocators.Title + index}
                        >
                          {alert.title}
                        </Typography>
                        <Typography
                          ref={(element) => (descriptionRefs.current[index] = element!)}
                          sx={{
                            alignSelf: "stretch",
                            fontSize: "1rem",
                            fontWeight: "400",
                            fontStyle: "normal",
                            lineHeight: "1.5rem",
                            letterSpacing: "0.15px",
                            color: "rgba(0, 0, 0, 0.60)",
                            mb: 2,
                            overflowWrap: "anywhere",
                            whiteSpace: "pre-line",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: expandDescription[index] ? undefined : "-webkit-box",
                            WebkitLineClamp: "4",
                            WebkitBoxOrient: "vertical",
                            cursor: isTextOverflowing("description", index) ? "pointer" : "default",
                            pointerEvents: isTextOverflowing("description", index) ? undefined : "none",
                          }}
                          data-testid={AlertLocators.Description + index}
                          onClick={() => {
                            let updatedExpandDescription = [...expandDescription];
                            updatedExpandDescription[index] = !updatedExpandDescription[index];
                            setExpandDescription(updatedExpandDescription);
                          }}
                        >
                          {alert.description}
                        </Typography>
                        {alert.action ? (
                          <Typography
                            ref={(element) => (actionRefs.current[index] = element!)}
                            sx={{
                              alignSelf: "stretch",
                              fontSize: "1rem",
                              fontWeight: "400",
                              fontStyle: "normal",
                              lineHeight: "1.5rem",
                              letterSpacing: "0.15px",
                              color: "rgba(0, 0, 0, 0.60)",
                              mb: 2,
                              overflowWrap: "anywhere",
                              whiteSpace: "pre-line",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: expandAction[index] ? undefined : "-webkit-box",
                              WebkitLineClamp: "4",
                              WebkitBoxOrient: "vertical",
                              cursor: isTextOverflowing("action", index) ? "pointer" : "default",
                              pointerEvents: isTextOverflowing("action", index) ? undefined : "none",
                            }}
                            data-testid={AlertLocators.Action + index}
                            onClick={() => {
                              let updatedExpandAction = [...expandAction];
                              updatedExpandAction[index] = !updatedExpandAction[index];
                              setExpandAction(updatedExpandAction);
                            }}
                          >
                            {alert.action}
                          </Typography>
                        ) : null}
                        {alert.recipients ? (
                          <Typography
                            sx={{
                              alignSelf: "stretch",
                              fontSize: "1rem",
                              fontWeight: "400",
                              fontStyle: "normal",
                              lineHeight: "1.5rem",
                              letterSpacing: "0.15px",
                              color: "rgba(0, 0, 0, 0.60)",
                              pb: 2,
                            }}
                            data-testid={AlertLocators.NotifyList + index}
                          >
                            {displayRecipients(
                              alert.recipients.map((x) => {
                                if (!x.firstName && !x.lastName) {
                                  return x.email;
                                }

                                return `${x.firstName} ${x.lastName}`;
                              })
                            )}
                          </Typography>
                        ) : null}
                      </Box>
                      <Box sx={{ flexGrow: 0, width: "1.5rem" }}>
                        {alert.isEditable && !isReadOnly && (
                          <IconButton
                            id={AlertLocators.EditDeleteButton + index}
                            data-testid={AlertLocators.EditDeleteButton + index}
                            onClick={(event) => toggleEditDeleteMenu(event, alert)}
                            aria-controls={AlertLocators.EditDeleteButton + index}
                            aria-haspopup="true"
                            aria-expanded={openEditDeleteMenu ? "true" : undefined}
                            sx={{ p: 0 }}
                          >
                            <MoreVertIcon sx={{ fill: "rgba(0, 0, 0, 0.87)" }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    <Divider sx={{ mb: 4 }} flexItem />
                  </ListItem>
                ))}
              </List>
            )}
          </ExtendedPanel>
          <AddEditAlert
            alertsOrder={sortButtonText}
            initialAlert={selectedAlert}
            shouldOpen={openDialog}
            onClose={handleCloseDialog}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleAlertsPane, anchorEl, opportunity.alerts, openDialog, openEditDeleteMenu, handleCloseDialog]
  );
}
