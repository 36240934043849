import { Box, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import { SimpleDialogContextProvider, ConfirmationModalProvider } from "context";
import { Map, MenuAppBar, NavigationMenu } from "components";
import { OpportunityProvider } from "features/opportunities/context";
import { AdvancedSearchContextProvider } from "features/search/context/AdvancedSearchContext";
import { SearchResultContextProvider } from "features/search/context/SearchResultContext";

export function MapLayout() {
  const theme = useTheme();

  return (
    <Box sx={{ overflowX: "auto", overflowY: "hidden", display: "flex", flexDirection: "column", height: "100%" }}>
      <Box
        sx={{
          minWidth: 1366,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <MenuAppBar />
        <NavigationMenu />
        <ConfirmationModalProvider>
          <SimpleDialogContextProvider>
            <Box sx={{ display: "flex", flexGrow: 1, padding: ".625rem", gap: ".5rem", marginLeft: "4rem" }}>
              <OpportunityProvider>
                <AdvancedSearchContextProvider>
                  <SearchResultContextProvider>
                    <Outlet />
                    <Box sx={{ flexGrow: 1, width: 0 }}>
                      <Map />
                    </Box>
                  </SearchResultContextProvider>
                </AdvancedSearchContextProvider>
              </OpportunityProvider>
            </Box>
          </SimpleDialogContextProvider>
        </ConfirmationModalProvider>
      </Box>
    </Box>
  );
}
