import { useEffect, useReducer } from "react";
import { AxiosError, AxiosResponse } from "axios";

import { SharedMessages, extractErrorMessage } from "@constants";
import { ErrorResponse, ValidationErrors } from "models";
import { useSnackBarContext } from "context";

type LoadingAction = {
  type: "LOADING";
};

type SuccessAction<T> = {
  type: "SUCCESS";
  response: AxiosResponse<T>;
};

type ErrorAction<T> = {
  type: "ERROR";
  error: AxiosError<T>;
  validationErrors: ValidationErrors;
};

type AsyncAction<D> = LoadingAction | SuccessAction<D> | ErrorAction<ErrorResponse>;

export type AsyncState<D> = {
  status: "idle" | "loading" | "success" | "error";
  response: AxiosResponse<D> | undefined;
  error: AxiosError<ErrorResponse> | undefined;
  validationErrors?: ValidationErrors;
};

type PromiseFn<T> = (...args: any) => Promise<AxiosResponse<T>>;
type InferAxiosResponseType<T> = T extends PromiseFn<infer R> ? R : never;

export const useAsync = <F extends PromiseFn<any>>(promiseFn: F) => {
  const context = useSnackBarContext();

  const asyncReducer = (
    _state: AsyncState<InferAxiosResponseType<F>>,
    action: AsyncAction<InferAxiosResponseType<F>>
  ): AsyncState<InferAxiosResponseType<F>> => {
    switch (action.type) {
      case "LOADING":
        return {
          status: "loading",
          response: undefined,
          error: undefined,
        };
      case "SUCCESS":
        return {
          status: "success",
          response: action.response,
          error: undefined,
        };
      case "ERROR":
        return {
          status: "error",
          response: undefined,
          error: action.error,
          validationErrors: action.validationErrors,
        };
    }
  };

  const [state, dispatch] = useReducer(asyncReducer, {
    status: "idle",
    response: undefined,
    error: undefined,
    validationErrors: undefined,
  });

  const run = (...params: Parameters<F>) => {
    dispatch({ type: "LOADING" });

    promiseFn(...params)
      .then((response) => {
        dispatch({
          type: "SUCCESS",
          response: response,
        });
      })
      .catch((e: AxiosError<ErrorResponse, any>) => {
        context.setOpen(true);

        const message = getGenericErrorMessage(e.response?.status, e.response?.data.detail);

        context.setSnackBarContent({
          message,
          severity: e.response?.status !== 400 ? "error" : "warning",
        });

        dispatch({
          type: "ERROR",
          error: e,
          validationErrors: getValidationErrors(e.response?.data),
        });
      });
  };

  return [{ ...state, data: state.response?.data }, run] as const;
};

export function useAsyncEffect<D, F extends PromiseFn<D>>(promiseFn: F, params: Parameters<F>, deps: any[]) {
  const [state, run] = useAsync(promiseFn);
  useEffect(
    () => {
      run(...params);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps
  );

  return [{ ...state, data: state.response?.data }, run] as const;
}

function getValidationErrors(axiosError: ErrorResponse | undefined) {
  const initialValue: ValidationErrors = {};

  if (!axiosError || !isValidationError(axiosError)) {
    return initialValue;
  }

  const validationErrors = Object.keys(axiosError.errors).reduce<ValidationErrors>((prev, cur) => {
    if (!cur) {
      return prev;
    }

    const camelCaseKey = cur
      .split(".")
      .map((item) => item[0].toLowerCase() + item.slice(1))
      .join(".");

    const errorsArray = axiosError.errors[cur];

    if (!errorsArray || !Array.isArray(errorsArray)) {
      return prev;
    }

    prev = { ...prev, [camelCaseKey]: errorsArray[0] };
    return prev;
  }, initialValue);

  return validationErrors;
}

function isValidationError(error: unknown): error is ErrorResponse {
  const castedError = error as ErrorResponse;
  return (
    castedError &&
    typeof castedError.status === "number" &&
    typeof castedError.errors === "object" &&
    castedError.errors !== null
  );
}

function getGenericErrorMessage(status?: number, message?: string) {
  switch (status) {
    case 400:
      return "Please check the validation errors.";
    case 401:
    case 403:
      return "You don't have enough permissions to perform this action.";
    case 404:
      const default404Message = "The requested resource cannot be found.";
      return message ? extractErrorMessage(message) || default404Message : default404Message;
    default:
      return SharedMessages.SomethingWentWrongContactAdmin;
  }
}
