export interface GeocodeResult {
  address: Address;
  location: Location;
}

interface Address {
  Match_addr: string;
  LongLabel: string;
  ShortLabel: string;
  Addr_type: string;
  Type: string;
  PlaceName: string;
  AddNum: string;
  Address: string;
  Block: string;
  Sector: string;
  Neighborhood: string;
  District: string;
  City: string;
  MetroArea: string;
  Subregion: string;
  Region: string;
  RegionAbbr: string;
  Territory: string;
  Postal: string;
  PostalExt: string;
  CntryName: string;
  CountryCode: string;
}

interface Location {
  x: number;
  y: number;
  spatialReference: {
    wkid: number;
    latestWkid: number;
  };
}

export interface GeocodeError {
  error: GeocodeErrorError;
}

export interface GeocodeErrorError {
  code: number;
  details: string[];
  extendedCode: number;
  message: string;
}

export function isSuccessGeocodeResult(result: GeocodeResult | GeocodeError): result is GeocodeResult {
  return (result as GeocodeResult)?.address !== undefined;
}

export function isErrorGeocodeResult(result?: GeocodeResult | GeocodeError): result is GeocodeError {
  return (result as GeocodeError)?.error !== undefined;
}
