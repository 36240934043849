import { ChangeEvent, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { AlertColor } from "@mui/material";
import dayjs from "dayjs";

import { DateFieldType } from "types";
import { useSnackBarContext } from "context";
import { useAsync } from "hooks";
import { addAlert, deleteAlert, searchForResponsiblePersons, updateAlert } from "features/opportunities/services";

import { NoErrors, useOpportunityContext } from "../context";
import { AlertRecipientType, AlertType, ResponsiblePersonType, validateAlert } from "../models";
import { ActionTypes } from "../reducers";
import { useFetch } from "./useFetch";
import { formatZodErrors } from "./useValidation";

export type ErrorType = {
  [Property in keyof AlertType]: string;
};

export function useAlert(initialAlert?: AlertType | null, alertsOrder?: string) {
  const [addResponse, runAddAlert] = useAsync(addAlert);
  const [updateResponse, runUpdateAlert] = useAsync(updateAlert);
  const [deleteResponse, runDeleteAlert] = useAsync(deleteAlert);
  const [getUsersResponse, runGetUsers] = useAsync(searchForResponsiblePersons);
  const [selectedAlert, setSelectedAlert] = useState<AlertType | null>(null);
  const [alertFormErrors, setAlertFormErrors] = useState<ErrorType>({} as ErrorType);
  const [alertRecipients, setAlertRecipients] = useState<(string | AlertRecipientType)[] | null>(null);
  const [sortButtonText, setSortButtonText] = useState<string>(alertsOrder ?? "Earliest First");
  const [userOptions, setUserOptions] = useState<ResponsiblePersonType[]>([]);
  const [notifiedUsersInputValue, setNotifiedUsersInputValue] = useState("");
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { opportunity, dispatch } = useOpportunityContext();
  const { accounts } = useMsal();
  const fetch = useFetch(runGetUsers);

  const setSnackBarContentAndOpen = (msg: string, msgSeverity: AlertColor) => {
    setSnackBarContent({
      message: msg,
      severity: msgSeverity,
    });
    setOpen(true);
  };

  const isAlertValid = (alert: AlertType) => {
    const result = validateAlert(alert);
    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setAlertFormErrors(formattedErrors);
    }
    return result.success;
  };

  function updateAlerts(newAlertList: AlertType[]) {
    dispatch({
      type: ActionTypes.REPLACE_ALERTS,
      payload: newAlertList,
    });
  }

  const handleSubmit = () => {
    if (!selectedAlert) {
      return;
    }

    if (Number(selectedAlert.id) === 0) {
      const alertToAdd: AlertType = {
        ...selectedAlert,
        opportunityId: opportunity.id,
        notifyList: selectedAlert.notifyList
          ? accounts?.[0]?.username.concat(",", selectedAlert.notifyList)
          : accounts?.[0]?.username,
      };
      runAddAlert(alertToAdd);
      return;
    }

    const alertCreator: AlertRecipientType = selectedAlert?.recipients?.find(
      (x) => x.firstName === selectedAlert?.creator?.firstName && x.lastName === selectedAlert?.creator?.lastName
    )!;
    const alertToUpdate: Omit<AlertType, "id" | "opportunityId" | "isEditable" | "recipients"> = {
      action: selectedAlert.action,
      alertDate: selectedAlert.alertDate,
      description: selectedAlert.description,
      notifyList: selectedAlert.notifyList
        ? alertCreator.email.concat(",", selectedAlert.notifyList)
        : alertCreator.email,
      title: selectedAlert.title,
    };
    runUpdateAlert(selectedAlert.id, alertToUpdate);
    return;
  };

  const handleDelete = () => {
    if (selectedAlert) {
      runDeleteAlert(selectedAlert.id);
    }
  };

  const toggleAlertsPath = (openAlertsPane: boolean, currentPath: string) => {
    return openAlertsPane ? currentPath.replace("/alerts", "") : `${currentPath}/alerts`;
  };

  const resetErrors = () => {
    setAlertFormErrors(NoErrors);
  };

  const handleSortAlerts = () => {
    setSortButtonText(sortButtonText === "Earliest First" ? "Latest First" : "Earliest First");
    updateAlerts(opportunity.alerts?.reverse()!);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSelectedAlert((prevState) => ({
      ...prevState!,
      [name]: value.trimStart(),
    }));
    resetErrors();
  };

  const handleDateChange = (name: string, value: DateFieldType | undefined) => {
    const currentDate = new Date();
    const newDate = value
      ? value.hour(currentDate.getHours()).minute(currentDate.getMinutes()).second(currentDate.getSeconds())
      : null;

    setSelectedAlert((prevState) => ({
      ...prevState!,
      [name]: newDate,
    }));
    resetErrors();
  };

  const recipientExists = (notifyList: string[], recipientEmail: string) => {
    return (
      notifyList.some((existingRecipient) => existingRecipient.toLowerCase() === recipientEmail.toLowerCase()) ||
      recipientEmail.toLowerCase() === accounts?.[0]?.username.toLowerCase()
    );
  };

  const handleNotifyListChange = (value: (string | AlertRecipientType)[] | null) => {
    let notifyList: string[] =
      value?.map((x) => {
        if (typeof x === "string") {
          return x;
        } else {
          return x.email;
        }
      }) ?? [];
    let filteredNotifyList: string[] = [];
    notifyList.forEach((recipient) => {
      if (!recipientExists(filteredNotifyList, recipient)) {
        filteredNotifyList.push(recipient);
      }
    });
    setSelectedAlert((prevState) => ({
      ...prevState!,
      notifyList: filteredNotifyList.join(","),
    }));
    resetErrors();
  };

  const recipientsOptions = userOptions
    .filter((option) => option.email !== accounts?.[0]?.username)
    .map((x) => {
      const recipient: AlertRecipientType = {
        firstName: x.firstName,
        lastName: x.lastName!,
        email: x.email!,
      };

      return recipient;
    });

  const validateNotifyListInput = () => {
    const checkEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(notifiedUsersInputValue);
    const filteredUserOptions = recipientsOptions.filter(
      (option) =>
        !alertRecipients?.find((recipient) => typeof recipient !== "string" && recipient.email === option.email)
    );

    return !checkEmail && filteredUserOptions.length === 0;
  };

  const displayRecipients = (recipients: string[]) => {
    let recipientString = "";
    if (recipients.length > 1) {
      recipientString = recipients
        .slice(0, 2)
        .join(", ")
        .concat(recipients.length - 2 !== 0 ? ` (${recipients.length - 2} more)` : "");
    } else {
      recipientString = recipients[0];
    }
    return recipientString;
  };

  const areRecipientsEqual = (option: AlertRecipientType, value: AlertRecipientType) => {
    return value.firstName && value.lastName
      ? value.firstName === option.firstName && value.lastName === option.lastName
      : value.email === option.email;
  };

  const notifyListCheckOptionEqualToValue = (
    option: string | AlertRecipientType,
    value: string | AlertRecipientType
  ) => {
    if (typeof value !== "string") {
      return typeof option !== "string" ? areRecipientsEqual(value, option) : value.email === option;
    }

    if (typeof option !== "string") {
      return value === option.email;
    }

    return value === option;
  };

  const notifyListGetOptionLabel = (option: string | AlertRecipientType) => {
    if (typeof option === "string") {
      return option;
    }

    return option.firstName && option.lastName ? `${option.firstName} ${option.lastName}` : option.email;
  };

  const insertAddEditAlert = (alerts: AlertType[], alertToInsert: AlertType) => {
    let alertList = alerts;
    alertList.push(alertToInsert);

    return alertsOrder === "Earliest First"
      ? alertList.sort((a, b) => (dayjs(a.alertDate).isAfter(dayjs(b.alertDate)) ? 1 : -1))
      : alertList.sort((a, b) => (dayjs(a.alertDate).isAfter(dayjs(b.alertDate)) ? -1 : 1));
  };

  const notifyListHelperText =
    alertFormErrors.notifyList ?? "People who are notified in addition to the owner of the alert";

  useEffect(() => {
    if (initialAlert) {
      setSelectedAlert(initialAlert);
      setAlertRecipients(
        initialAlert?.recipients?.filter(
          (x) => x.firstName !== initialAlert?.creator?.firstName && x.lastName !== initialAlert?.creator?.lastName
        )!
      );
    }
  }, [initialAlert]);

  useEffect(() => {
    handleNotifyListChange(alertRecipients);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertRecipients]);

  useEffect(() => {
    if (addResponse.status !== "error") {
      return;
    }
    if (addResponse.validationErrors) {
      setAlertFormErrors((prev) => ({ ...prev, ...addResponse.validationErrors }));
      return;
    }
    setSnackBarContentAndOpen("Something went wrong. See console for details.", "error");
    console.error(addResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.error, addResponse.status]);

  useEffect(() => {
    if (addResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Alert has been added to opportunity.", "success");

    let alertList: AlertType[] = opportunity.alerts ?? [];
    alertList = insertAddEditAlert(alertList, addResponse.data!);
    updateAlerts(alertList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.data, addResponse.status]);

  useEffect(() => {
    if (updateResponse.status !== "error") {
      return;
    }
    if (updateResponse.validationErrors) {
      setAlertFormErrors((prev) => ({ ...prev, ...updateResponse.validationErrors }));
      return;
    }
    setSnackBarContentAndOpen("Something went wrong. See console for details.", "error");
    console.error(updateResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse.error, updateResponse.status]);

  useEffect(() => {
    if (updateResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Alert has been updated.", "success");

    let alertList: AlertType[] = opportunity.alerts ?? [];
    const alertToEditIndex = alertList.findIndex((alert) => alert.id === updateResponse.data?.id);
    alertList.splice(alertToEditIndex, 1);
    alertList = insertAddEditAlert(alertList, updateResponse.data!);
    updateAlerts(alertList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateResponse.data, updateResponse.status]);

  useEffect(() => {
    if (deleteResponse.status !== "error") {
      return;
    }
    setSnackBarContentAndOpen("Something went wrong. See console for details.", "error");
    console.error(deleteResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResponse.error, deleteResponse.status]);

  useEffect(() => {
    if (deleteResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Alert has been deleted from opportunity.", "success");

    const alertList: AlertType[] = opportunity.alerts?.filter((x) => x.id !== selectedAlert?.id) ?? [];
    updateAlerts(alertList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteResponse.data, deleteResponse.status]);

  useEffect(() => {
    if (getUsersResponse.data) {
      setUserOptions(getUsersResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUsersResponse.data]);

  useEffect(() => {
    if (notifiedUsersInputValue !== "") {
      fetch(notifiedUsersInputValue);
    }
  }, [notifiedUsersInputValue, fetch]);

  return {
    selectedAlert,
    setSelectedAlert,
    isAlertValid,
    alertFormErrors,
    resetErrors,
    handleInputChange,
    handleDateChange,
    handleSubmit,
    handleDelete,
    alertRecipients,
    setAlertRecipients,
    recipientsOptions,
    notifiedUsersInputValue,
    setNotifiedUsersInputValue,
    validateNotifyListInput,
    displayRecipients,
    notifyListCheckOptionEqualToValue,
    notifyListGetOptionLabel,
    handleSortAlerts,
    sortButtonText,
    toggleAlertsPath,
    notifyListHelperText,
  };
}
