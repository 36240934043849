import { Dayjs } from "dayjs";
import { z } from "zod";

import { DateFieldType } from "types";
import {
  DateMaxValue,
  DateMinValue,
  delimiterSeparatedStringToArray,
  emptyStringToUndefined,
  zeroToUndefined,
  zodInvalidDateRefinement,
} from "services";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

import { AlertSchema } from "./alert";
import { CommentSchema } from "./comment";
import { NamedEntitySchema } from "./namedEntity";
import { OwnershipSchema, OwnershipType } from "./ownership";
import { ParentOpportunitySchema } from "./parentOpportunity";
import { ReferenceDocumentSchema } from "./referenceDocument";
import { responsibleGroupSchema } from "./responsibleGroup";
import { responsiblePersonSchema } from "./responsiblePerson";

const delimiterSeparatedStringValidator = (
  arg: string,
  minEntries: number,
  maxLengthOfSeparatedElements: number,
  delimiter: string
) => {
  const arr = delimiterSeparatedStringToArray(delimiter, arg);
  return arr.length <= minEntries && arr.every((item) => item.length <= maxLengthOfSeparatedElements);
};

interface OwnershipRange {
  fromDate: Dayjs;
  toDate: Dayjs;
  shareRateSum: number;
}

export const validateOwnershipList = (ownershipList: OwnershipType[]) => {
  const isValidData = ownershipsListSchema.safeParse(ownershipList);
  return isValidData;
};

function overlap(startDate1: Dayjs, endDate1: Dayjs, startDate2: Dayjs, endDate2: Dayjs): boolean {
  return startDate1 < endDate2 && startDate2 < endDate1;
}

function areOwnerShareRatesValid(ownerships: OwnershipType[]): boolean {
  let ranges = [] as OwnershipRange[];
  ownerships
    .sort((a, b) => ((a.ownershipToDate ?? DateMaxValue) > (b.ownershipToDate ?? DateMaxValue) ? 1 : -1))
    .forEach((ownership) => {
      if (ownership.isDeleted) {
        return;
      }
      const from = ownership.ownershipFromDate ?? DateMinValue;
      const to = ownership.ownershipToDate ?? DateMaxValue;
      ranges
        .filter((x) => overlap(from, to, x.fromDate, x.toDate))
        .forEach((x) => (x.shareRateSum += ownership.ownerShareRate));
      ranges.push({ fromDate: from, toDate: to, shareRateSum: ownership.ownerShareRate });
    });

  return ranges.find((x) => x.shareRateSum > 100) === undefined;
}

const validatePrecisionScale = (arg: number, precision: number, scale: number) => {
  const stringValue = String(Math.abs(arg));
  const dotIndex = stringValue.indexOf(".");

  if (dotIndex === -1) {
    return stringValue.length <= precision;
  }

  const decimals = stringValue.length - dotIndex - 1;
  return decimals <= scale && dotIndex <= precision - scale;
};

export const idSchema = z.object({
  id: z.string().min(1, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK),
});

export const generalDetailsSchema = z.object({
  opportunityCode: z
    .string()
    .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
    .max(10, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(10))
    .regex(/^[a-zA-Z0-9]+$/, VALIDATION_MESSAGES.ALPHANUMERIC)
    .transform((val) => val.toUpperCase()),
  opportunityName: z
    .string()
    .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
    .max(100, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(100)),
  opportunityAlternateNames: z
    .string()
    .refine(
      (val) => delimiterSeparatedStringValidator(val, 10, 100, ";"),
      "Enter maximum 10 items and no more than 100 characters each entry."
    )
    .optional()
    .transform(emptyStringToUndefined),
  opportunityDescription: z.string().optional().transform(emptyStringToUndefined),
  opportunityTypeId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  opportunitySubTypeId: z.number().optional().transform(zeroToUndefined),
  responsiblePersonId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  responsiblePerson: responsiblePersonSchema.optional(),
  responsibleGroupId: z.number().optional().transform(zeroToUndefined),
  responsibleGroup: responsibleGroupSchema.optional(),
  group: z
    .string()
    .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
    .max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80))
    .or(z.literal(""))
    .optional()
    .transform(emptyStringToUndefined),
  restrictedFlag: z.boolean(),
  userFavoriteFlag: z.boolean(),
});

export const statusSchema = z.object({
  opportunityStatusId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  startDate: z.custom<DateFieldType>().superRefine(zodInvalidDateRefinement),
  endDate: z.custom<DateFieldType>().superRefine(zodInvalidDateRefinement),
  explorationStageId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  explorationTypeId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  depositStageId: z.number().optional().transform(zeroToUndefined),
  testDate: z.custom<DateFieldType>().superRefine(zodInvalidDateRefinement),
  testMethodId: z.number().optional().transform(zeroToUndefined),
});

export const geologicalSchema = z.object({
  primaryCommodityId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  secondaryCommodityId: z.number().optional().transform(zeroToUndefined),
  otherCommodities: z
    .string()
    .max(250, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(250))
    .optional()
    .transform(emptyStringToUndefined),
  depositTypeGroupId: z.number().optional().transform(zeroToUndefined),
  depositTypeClassId: z.number().optional().transform(zeroToUndefined),
  depositTypeSubClassId: z.number().optional().transform(zeroToUndefined),
});

export const locationSchema = z.object({
  longitude: z
    .union([z.string().transform(emptyStringToUndefined).pipe(z.coerce.number().optional()), z.number().optional()])
    .pipe(
      z.coerce
        .number()
        .gte(-180, VALIDATION_MESSAGES.OUT_OF_RANGE)
        .lte(180, VALIDATION_MESSAGES.OUT_OF_RANGE)
        .refine((arg) => validatePrecisionScale(arg, 9, 6), VALIDATION_MESSAGES.SCALE_ERROR)
        .optional()
    ),
  latitude: z
    .union([z.string().transform(emptyStringToUndefined).pipe(z.coerce.number().optional()), z.number().optional()])
    .pipe(
      z.coerce
        .number()
        .gte(-90, VALIDATION_MESSAGES.OUT_OF_RANGE)
        .lte(90, VALIDATION_MESSAGES.OUT_OF_RANGE)
        .refine((arg) => validatePrecisionScale(arg, 8, 6), VALIDATION_MESSAGES.SCALE_ERROR)
        .optional()
    ),
  spatialExtents: z.string().optional().transform(emptyStringToUndefined),
  locationAccuracyId: z.number().optional().transform(zeroToUndefined),
  countryId: z.number().optional().transform(zeroToUndefined),
  stateProvince: z
    .string()
    .max(100, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(100))
    .optional()
    .transform(emptyStringToUndefined),
  rtxRegion: z
    .string()
    .max(30, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(30))
    .optional()
    .transform(emptyStringToUndefined),
  rtxSubRegion: z
    .string()
    .max(30, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(30))
    .optional()
    .transform(emptyStringToUndefined),
});

export const locationCountrySchema = z.object({
  country: z
    .string()
    .max(50, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(50))
    .optional()
    .transform(emptyStringToUndefined),
});

export const lineageSchema = z.object({
  originId: z.number().gt(0, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  originNameId: z.number().optional().transform(zeroToUndefined),
  originName: NamedEntitySchema.optional(),
  parentOpportunityId: z.string().optional(),
  parentOpportunity: ParentOpportunitySchema.optional(),
});

export const ownershipsListSchema = z
  .array(OwnershipSchema)
  .optional()
  .superRefine((args, ctx) => {
    if (args) {
      if (!areOwnerShareRatesValid(args)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["ownerShareRate"],
          message: VALIDATION_MESSAGES.OWNERSHIP_SHARE_RATE,
        });
      }
    }
  });

export const commercialSchema = z.object({
  fundingTypeId: z.number().optional().transform(zeroToUndefined),
  companyId: z.number().gt(-1, VALIDATION_MESSAGES.SELECTED_OPTION_OUT_OF_RANGE),
  company: NamedEntitySchema.optional(),
  commercialStatusId: z.number().optional().transform(zeroToUndefined),
  thirdPartyNameId: z.number().optional().transform(zeroToUndefined),
  thirdPartyName: NamedEntitySchema.optional(),
  thirdPartyNotifiedDate: z.custom<DateFieldType>().superRefine(zodInvalidDateRefinement),
  ownerships: ownershipsListSchema.optional(),
  confidentialityAgreement: z.boolean().optional(),
});

export const additionalDetailsSchema = z.object({
  siteConditions: z.string().optional().transform(emptyStringToUndefined),
  previousWorkSummary: z.string().optional().transform(emptyStringToUndefined),
  watchlistPriority: z.coerce.number().optional(),
  missingData: z.string().optional().transform(emptyStringToUndefined),
  includeForMetrics: z.boolean().optional(),
  notes: z.string().optional().transform(emptyStringToUndefined),
});

export const referencesSchema = z.object({
  referenceDocuments: z.array(ReferenceDocumentSchema).optional(),
});

export const opportunityFeaturesSchema = z.object({
  alerts: z.array(AlertSchema).optional(),
  comments: z.array(CommentSchema).optional(),
});

export const integrationsSchema = z.object({
  landfolioId: z
    .string()
    .max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80))
    .optional()
    .transform(emptyStringToUndefined),
});

export const changeLogSchema = z.object({
  createdDate: z.custom<DateFieldType>(),
  createdBy: z.string().optional().transform(emptyStringToUndefined),
  updatedDate: z.custom<DateFieldType>(),
  updatedBy: z.string().optional().transform(emptyStringToUndefined),
});

export const addCustomRefinements = (schema: z.ZodObject<any>) => {
  return schema.superRefine((opportunity, context) => {
    validateCoordinates(opportunity, context);
    validateStartEndDate(opportunity, context);
  });
};

const validateCoordinates = (opportunity: Record<string, any>, context: z.RefinementCtx) => {
  if (!!opportunity.longitude && !opportunity.latitude) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["latitude"],
      message: VALIDATION_MESSAGES.MISSING_LATITUDE,
    });
  }

  if (!!opportunity.latitude && !opportunity.longitude) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["longitude"],
      message: VALIDATION_MESSAGES.MISSING_LONGITUDE,
    });
  }
};

const validateStartEndDate = (opportunity: Record<string, any>, context: z.RefinementCtx) => {
  var startDate = opportunity.startDate;
  var endDate = opportunity.endDate;

  if (!startDate || !endDate) {
    return z.NEVER;
  }

  if (startDate > endDate) {
    context.addIssue({
      code: z.ZodIssueCode.custom,
      path: ["endDate"],
      message: VALIDATION_MESSAGES.START_END_DATE,
    });
  }
};
