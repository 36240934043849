import { useTheme } from "@mui/material/styles";

export const AutoCompleteSx = () => {
  const theme = useTheme();

  return {
    color: "inherit",
    flexGrow: 1,
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up("sm")]: {
        width: "100%",
        "&:focus": {
          width: "100%",
        },
      },
    },
    "& ::placeholder": {
      fontSize: ".875rem",
    },
    "& fieldset": { border: "none" },
  } as const;
};

export const RenderInputTextBoxSx = () => {
  const theme = useTheme();

  return {
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
  } as const;
};
