import { useEffect, useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";
import { useParams } from "react-router-dom";

import { Mode, CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { useReferenceDataContext } from "context";
import { referenceDataMenuItems } from "features/opportunities/components";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";
import { getSelectedOpportunityId } from "features/opportunities/services";

export function OpportunityTypeSelect() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, mode, formErrors, updateField } = useOpportunityContext();
  const { opportunityType } = useParams();

  const preselectedOpportunityTypeId = useMemo(
    () => getSelectedOpportunityId(referenceData?.opportunityTypes, opportunityType),
    [opportunityType, referenceData?.opportunityTypes]
  );

  useEffect(() => {
    if (opportunity.opportunityTypeId === 0 && preselectedOpportunityTypeId > 0) {
      updateField("opportunityTypeId", preselectedOpportunityTypeId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => {
    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
      updateField("opportunityStatusId", 0);
    };

    return (
      <FormControl required disabled={mode === Mode.Edit}>
        <InputLabel id={CardBodyLocators.OpportunityType} data-testid={CardBodyLocators.OpportunityType}>
          Type
        </InputLabel>
        <Select
          labelId={CardBodyLocators.OpportunityType}
          id={CardBodyLocators.OpportunityTypeSelect}
          label="Type"
          name={opportunityTypeOfFactory("opportunityTypeId")}
          onChange={handleSelectionChange}
          value={opportunity.opportunityTypeId || 0}
          SelectDisplayProps={{ "data-testid": CardBodyLocators.OpportunityTypeSelect } as PropsWithTestId}
          error={Boolean(opportunity.opportunityTypeId && formErrors.opportunityTypeId)}
        >
          {referenceDataMenuItems(referenceData?.opportunityTypes, "opp-types-")}
        </Select>
        {opportunity.opportunityTypeId > 0 && formErrors.opportunityTypeId && (
          <FormHelperText>{formErrors.opportunityTypeId}</FormHelperText>
        )}
      </FormControl>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.opportunityTypeId,
    referenceData?.opportunityTypes,
    formErrors.opportunityTypeId,
    mode,
    preselectedOpportunityTypeId,
  ]);
}
