import { Dispatch, SetStateAction, createContext, useContext, ReactNode, useState } from "react";

export type SimpleDialogType = {
  openSimpleDialog: boolean;
  setOpenSimpleDialog: Dispatch<SetStateAction<boolean>>;
  dialogBody: string;
  setDialogBody: Dispatch<SetStateAction<string>>;
};

export const SimpleDialogContext = createContext<SimpleDialogType>({
  openSimpleDialog: false,
  setOpenSimpleDialog: () => {},
  dialogBody: "",
  setDialogBody: () => {},
});

export const useSimpleDialogContext = () => {
  return useContext(SimpleDialogContext);
};

interface Props {
  children: ReactNode;
}

export function SimpleDialogContextProvider({ children }: Props) {
  const [openSimpleDialog, setOpenSimpleDialog] = useState(false);
  const [dialogBody, setDialogBody] = useState("");

  return (
    <SimpleDialogContext.Provider
      value={{
        openSimpleDialog,
        setOpenSimpleDialog,
        dialogBody,
        setDialogBody,
      }}
    >
      {children}
    </SimpleDialogContext.Provider>
  );
}
