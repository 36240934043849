import { PropsWithChildren } from "react";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";

interface ConditionalGridProps extends Grid2Props {
  displayGrid?: boolean;
}

export function ConditionalGrid(props: PropsWithChildren<ConditionalGridProps>) {
  const { displayGrid, children, ...otherProps } = props;
  if (displayGrid === false) {
    return null;
  }

  return <Grid {...otherProps}>{children}</Grid>;
}
