import dayjs from "dayjs";
import { rest } from "msw";

import { OpportunityModel, UserSavedSearch } from "types";
import { GeocodeResult } from "features/arcgis/models";
import {
  AlertRecipientType,
  AlertType,
  CommentType,
  NamedEntity,
  Reference,
  ResponsibleGroupType,
  ResponsiblePersonType,
} from "features/opportunities/models";

import {
  mockComments,
  mockGetSavedSearches,
  mockAlerts,
  mockNamedEntities,
  mockRecentOpportunities,
  mockReferenceData,
  mockResponsibleGroup,
  mockResponsiblePerson,
  mockReverseGeocodeResponse,
  mockSavedSearches,
} from "./responseMocks";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

type ExceptionModel = {
  title: string;
  status: number;
  detail?: string;
  errors?: Map<string, string[]>;
};

export const handlers = [
  rest.get(`${backendUrl}/opportunity`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<OpportunityModel[]>(mockRecentOpportunities));
  }),
  rest.get(`${backendUrl}/opportunity/:id`, (req, res, ctx) => {
    const opportunity = mockRecentOpportunities.find((item) => item.id === req.params.id);
    return opportunity
      ? res(ctx.status(200), ctx.json<OpportunityModel>(opportunity))
      : res(ctx.status(404), ctx.json<ExceptionModel>({ title: "Opportunity was not found", status: 404 }));
  }),
  rest.post(`${backendUrl}/opportunity`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.put(`${backendUrl}/opportunity/:id`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.delete(`${backendUrl}/opportunity/:id`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${backendUrl}/opportunity/search/:searchQuery`, (req, res, ctx) => {
    const searchQuery = req.params.searchQuery as string;

    const searchResult = mockRecentOpportunities.filter(
      (item) =>
        item.opportunityName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.country?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.opportunityAlternateNames?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return res(ctx.status(200), ctx.json<OpportunityModel[]>(searchResult));
  }),

  rest.post(`${backendUrl}/opportunity/advancedSearch`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<OpportunityModel[]>(mockRecentOpportunities));
  }),

  rest.get(`${backendUrl}/opportunity/searchParent/:searchQuery`, (req, res, ctx) => {
    const searchQuery = req.params.searchQuery as string;

    const searchResult = mockRecentOpportunities.filter(
      (item) =>
        item.id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.opportunityName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return res(ctx.status(200), ctx.json<OpportunityModel[]>(searchResult));
  }),
  rest.get(`${backendUrl}/reference`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<Reference>(mockReferenceData));
  }),

  rest.get(`${backendUrl}/namedentity/search/:searchQuery`, (req, res, ctx) => {
    const searchQuery = req.params.searchQuery as string;

    const searchResult = mockNamedEntities.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return res(ctx.status(200), ctx.json<NamedEntity[]>(searchResult));
  }),

  rest.get(`${backendUrl}/ResponsibleEntity/searchPerson/:searchQuery`, (req, res, ctx) => {
    const searchQuery = req.params.searchQuery as string;

    const searchResult = mockResponsiblePerson.filter((item) =>
      item.firstName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return res(ctx.status(200), ctx.json<ResponsiblePersonType[]>(searchResult));
  }),
  rest.get(`${backendUrl}/ResponsibleEntity/getGroups`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<ResponsibleGroupType[]>(mockResponsibleGroup));
  }),
  rest.get(`${process.env.REACT_APP_ARCGIS_GEOCODE_URL}`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<GeocodeResult>(mockReverseGeocodeResponse));
  }),

  rest.post(`${backendUrl}/UserFavorite`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.delete(`${backendUrl}/UserFavorite/:opportunityId`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${backendUrl}/UserFavorite`, (_req, res, ctx) => {
    const userFavorites = mockRecentOpportunities.filter((item) => item.userFavoriteFlag);

    return res(ctx.status(200), ctx.json<OpportunityModel[]>(userFavorites));
  }),

  rest.get(`${backendUrl}/UserSavedSearch`, (_req, res, ctx) => {
    return res(ctx.status(200), ctx.json<UserSavedSearch[]>(mockGetSavedSearches));
  }),
  rest.get(`${backendUrl}/UserSavedSearch/:id`, (req, res, ctx) => {
    const savedSearch = mockSavedSearches.find((item) => item.id === Number(req.params.id));
    return res(ctx.status(200), ctx.json<UserSavedSearch>(savedSearch!));
  }),
  rest.post(`${backendUrl}/UserSavedSearch`, async (req, res, ctx) => {
    const newSavedSearch = await req.json();
    newSavedSearch.id = mockSavedSearches.length + 1;
    return res(ctx.status(200), ctx.json<UserSavedSearch>(newSavedSearch));
  }),
  rest.put(`${backendUrl}/UserSavedSearch/:id`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.delete(`${backendUrl}/UserSavedSearch/:id`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.get(`${backendUrl}/Alert/:opportunityId`, (req, res, ctx) => {
    const opportunityAlerts = mockAlerts.filter((item) => item.opportunityId === req.params.opportunityId) ?? [];
    return res(ctx.status(200), ctx.json<AlertType[]>(opportunityAlerts));
  }),
  rest.post(`${backendUrl}/Alert`, async (req, res, ctx) => {
    let newAlert = await req.json();
    newAlert.recipients = newAlert.notifyList.split(",").map((x: string) => {
      const responsiblePerson = mockResponsiblePerson.find((item) => item.email?.toLowerCase() === x);

      const recipient: AlertRecipientType = {
        firstName: responsiblePerson?.firstName ?? "",
        lastName: responsiblePerson?.lastName ?? "",
        email: x,
      };

      return recipient;
    });
    return res(ctx.status(200), ctx.json<AlertType>(newAlert));
  }),
  rest.put(`${backendUrl}/Alert/:id`, async (req, res, ctx) => {
    const updatedAlert = await req.json();
    return res(ctx.status(200), ctx.json<AlertType>(updatedAlert));
  }),
  rest.delete(`${backendUrl}/Alert/:id`, (_req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.get(`${backendUrl}/Alert`, (_req, res, ctx) => {
    const userAlerts = mockAlerts.filter((alert) =>
      alert.recipients?.some((recipient) => recipient.email === "test.user@riotinto.com")
    );
    return res(ctx.status(200), ctx.json<AlertType[]>(userAlerts!));
  }),

  rest.get(`${backendUrl}/OpportunityComment/:opportunityId`, (req, res, ctx) => {
    const opportunityComments = mockComments.filter((item) => item.opportunityId === req.params.opportunityId);

    return res(ctx.status(200), ctx.json<CommentType[]>(opportunityComments));
  }),
  rest.post(`${backendUrl}/OpportunityComment`, async (req, res, ctx) => {
    const newComment: CommentType = await req.json();
    newComment.responsiblePerson = {
      firstName: "Test",
      lastName: "Creator",
    };
    newComment.createdDate = dayjs(new Date());
    return res(ctx.status(200), ctx.json<CommentType>(newComment));
  }),

  rest.post(`${backendUrl}/ExportOpportunity`, async (req, res, ctx) => {
    const request = await req.json();
    const dataToExport = window.btoa(request);

    return request.opportunityList.length > 0 ? res(ctx.status(200), ctx.json(dataToExport)) : res(ctx.status(400));
  }),
];
