import { Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate, generatePath } from "react-router-dom";

import { Mode, PATH } from "@constants";
import { useReferenceDataContext } from "context";
import { useDocumentTitle } from "hooks";
import {
  CardHeader,
  CardHeaderSkeleton,
  CardBodySkeleton,
  CardBodyCreateEdit,
  CardBodyCreateEditSummary,
} from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useSetScreenMode } from "features/opportunities/hooks";

export function CreateOpportunity() {
  useDocumentTitle("Create Opportunity");
  const { showAllFields, setShowAllFields } = useOpportunityContext();
  useSetScreenMode(Mode.Create);
  const { referenceData } = useReferenceDataContext();
  const navigate = useNavigate();

  const isDataLoading = (...objects: any[]) => objects.some((obj) => Object.keys(obj).length === 0);

  const handleToggle = () => {
    setShowAllFields((prevShowToggle) => !prevShowToggle);
  };
  return (
    <Paper
      square
      sx={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: grey.A200, width: 512 }}
    >
      {isDataLoading(referenceData) ? (
        <>
          <CardHeaderSkeleton />
          <CardBodySkeleton />
        </>
      ) : (
        <>
          <CardHeader
            mode={Mode.Create}
            showAllFields={showAllFields}
            setShowAllFields={handleToggle}
            onClose={() => navigate(PATH.HOME)}
            onSubmitted={(id?: string) =>
              id ? navigate(generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId: id })) : navigate(PATH.HOME)
            }
          />
          {showAllFields ? <CardBodyCreateEdit /> : <CardBodyCreateEditSummary />}
        </>
      )}
    </Paper>
  );
}
