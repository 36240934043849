import dayjs from "dayjs";
import { z } from "zod";

import { DateFieldType } from "types";
import { emptyStringToUndefined, zodEmptyDateRefinement, zodInvalidDateRefinement } from "services";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

import { responsiblePersonSchema } from "./responsiblePerson";

const AlertRecipientSchema = z.object({
  firstName: z.string().max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80)),
  lastName: z.string().max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80)),
  email: z.string().email(),
});

export const AlertSchema = z.object({
  id: z.number(),
  creator: responsiblePersonSchema.pick({ firstName: true, lastName: true }).optional(),
  opportunityId: z.string(),
  opportunity: z
    .object({
      opportunityName: z
        .string()
        .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
        .max(100, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(100)),
      country: z
        .string()
        .max(50, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(50))
        .optional()
        .transform(emptyStringToUndefined),
    })
    .optional(),
  title: z
    .string()
    .min(1, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK)
    .max(150, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(150))
    .transform((val) => val.trim()),
  description: z
    .string()
    .min(1, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK)
    .transform((val) => val.trim()),
  action: z.string().optional(),
  alertDate: z.custom<DateFieldType>().superRefine((date, context) => {
    date = dayjs(date);
    zodEmptyDateRefinement(date, context);
    zodInvalidDateRefinement(date, context);

    if (date && date < dayjs(new Date().setHours(0, 0, 0, 0))) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        path: context.path,
        message: VALIDATION_MESSAGES.PAST_ALERT_DATE,
      });
    }
  }),
  notifyList: z
    .string()
    .optional()
    .transform(emptyStringToUndefined)
    .refine((val) => validateNotifyList(val!), VALIDATION_MESSAGES.INVALID_ALERT_RECIPIENT_EMAIL),
  recipients: z.array(AlertRecipientSchema).optional(),
  isEditable: z.boolean().optional(),
});

export interface AlertRecipientType extends z.infer<typeof AlertRecipientSchema> {}
export interface AlertType extends z.infer<typeof AlertSchema> {}

export const validateAlert = (alert: AlertType) => {
  const isValidData = AlertSchema.safeParse(alert);
  return isValidData;
};

const validateNotifyList = (notifyList: string) => {
  const notifyListArray = notifyList ? notifyList.split(",") : [];

  return notifyListArray.length > 0 && process.env.REACT_APP_ALERT_RECIPIENT_ALLOW_NON_RIO_EMAILS === "false"
    ? notifyListArray.every((email) => /^[A-Z0-9._%+-]+@riotinto\.com$/i.test(email))
    : true;
};
