import { Dispatch, PropsWithChildren, SetStateAction, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@mui/material";

import { ExtendedPanelLocators } from "@constants";
import { ReactComponent as SortClockAscending } from "assets/SortClockAscending.svg";
import { ConfirmationModal } from "components/ConfirmationModal";
import theme from "theme";

export function ExtendedPanel(props: PropsWithChildren<ExtendedPanelProps>) {
  const {
    children,
    panel,
    panelHeaderText,
    sortButtonText,
    setCloseExtendedPanel,
    menuAnchorEl,
    setMenuAnchorEl,
    handleOpenDialog,
    handleEditMenu,
    handleDelete,
    handleSort,
    showAddButton = true,
  } = props;

  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [confirmButtonText, setConfirmButtonText] = useState<string>("");
  const [cancelButtonText, setCancelButtonText] = useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const openEditDeleteMenu = Boolean(menuAnchorEl);

  const onCloseDeleteDialog = () => {
    setShowDeleteModal(false);
  };

  const onConfirmDeleteDialog = () => {
    setShowDeleteModal(false);
    handleDelete();
  };

  const closeButton = (
    <IconButton
      size="small"
      id={`${panel}${ExtendedPanelLocators.CloseButton}`}
      data-testid={`${panel}${ExtendedPanelLocators.CloseButton}`}
      sx={{ marginLeft: "1rem" }}
      onClick={() => setCloseExtendedPanel(false)}
    >
      <CloseIcon sx={{ fill: theme.palette.primary.main }} />
    </IconButton>
  );

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleDeleteMenu = (_event: React.MouseEvent<HTMLLIElement>) => {
    setMenuAnchorEl(null);
    setDialogTitle(`Delete ${panel}?`);
    setConfirmButtonText("Delete");
    setCancelButtonText("Cancel");
    setShowDeleteModal(true);
  };

  return (
    <>
      <Card
        id={`${panel}${ExtendedPanelLocators.Panel}`}
        data-testid={`${panel}${ExtendedPanelLocators.Panel}`}
        square
        sx={{ position: "relative", display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Paper
          square
          sx={{
            width: 480,
            height: "100px",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
          }}
        >
          <CardHeader
            id={panel}
            data-testid={panel}
            title={
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: "1rem",
                  fontWeight: "400",
                  lineHeight: "1.235rem",
                  letterSpacing: "0.25px",
                  color: "#3D3D3D",
                }}
              >
                {panelHeaderText}
              </Typography>
            }
            action={closeButton}
            sx={{ width: "100%", p: "12px" }}
          />
          <CardContent
            sx={{
              p: "12px",
              height: "100px",
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", pb: "16px" }}>
              <Button
                id={`${panel}${ExtendedPanelLocators.SortButton}`}
                data-testid={`${panel}${ExtendedPanelLocators.SortButton}`}
                startIcon={<SortClockAscending />}
                sx={{ color: "rgba(0, 0, 0, 0.87) !important" }}
                onClick={handleSort}
              >
                {sortButtonText}
              </Button>
              {showAddButton && (
                <Button
                  id={`${panel}${ExtendedPanelLocators.AddButton}`}
                  data-testid={`${panel}${ExtendedPanelLocators.AddButton}`}
                  startIcon={<AddIcon />}
                  sx={{ color: "rgba(0, 0, 0, 0.87)" }}
                  onClick={handleOpenDialog}
                >
                  New {panel}
                </Button>
              )}
            </Box>
            <Box sx={{ overflowX: "auto", overflowY: "overlay" }}>{children}</Box>
          </CardContent>
        </Paper>
      </Card>
      <Menu
        id={`${panel}${ExtendedPanelLocators.EditDeleteMenu}`}
        data-testid={`${panel}${ExtendedPanelLocators.EditDeleteMenu}`}
        anchorEl={menuAnchorEl}
        open={openEditDeleteMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem
          id={`${panel}${ExtendedPanelLocators.EditMenuItem}`}
          data-testid={`${panel}${ExtendedPanelLocators.EditMenuItem}`}
          onClick={handleEditMenu}
        >
          Edit
        </MenuItem>
        <MenuItem
          id={`${panel}${ExtendedPanelLocators.DeleteMenuItem}`}
          data-testid={`${panel}${ExtendedPanelLocators.DeleteMenuItem}`}
          onClick={handleDeleteMenu}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationModal
        dialogTitle={dialogTitle}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
        dialogBody=""
        show={showDeleteModal}
        onClose={onCloseDeleteDialog}
        onConfirm={onConfirmDeleteDialog}
      />
    </>
  );
}

interface ExtendedPanelProps {
  panel: string;
  panelHeaderText: string;
  setCloseExtendedPanel: Dispatch<SetStateAction<boolean>>;
  menuAnchorEl: HTMLElement | null;
  setMenuAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  handleOpenDialog: () => void;
  handleEditMenu: () => void;
  handleDelete: () => void;
  sortButtonText: string;
  handleSort: () => void;
  showAddButton?: boolean;
}
