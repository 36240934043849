import { Navigate } from "react-router-dom";

import { PATH } from "@constants";
import { useAuthorization } from "context";
import { UploadFile } from "features/admin/components";

export function BulkUpload() {
  const { isAdmin } = useAuthorization();

  if (!isAdmin) {
    return <Navigate to={PATH.HOME} />;
  }

  return <UploadFile />;
}
