import { z } from "zod";

import { DateFieldType } from "types";
import { VALIDATION_MESSAGES } from "features/opportunities/constants";

import { responsiblePersonSchema } from "./responsiblePerson";

export const CommentSchema = z.object({
  id: z.number(),
  responsiblePerson: responsiblePersonSchema.pick({ firstName: true, lastName: true, email: true }).optional(),
  opportunityId: z.string(),
  comment: z
    .string()
    .min(1, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK)
    .transform((val) => val.trim()),
  createdDate: z.custom<DateFieldType>().optional(),
  updatedDate: z.custom<DateFieldType>().optional(),
});

export interface CommentType extends z.infer<typeof CommentSchema> {}

export const validateComment = (comment: CommentType) => {
  const isValidData = CommentSchema.safeParse(comment);
  return isValidData;
};
