import { useEffect } from "react";
import { AlertColor } from "@mui/material";

import { SharedMessages } from "@constants";
import { useSnackBarContext } from "context";
import { useAsync } from "hooks";
import { addUserFavorite, removeUserFavorite } from "features/opportunities/services";

export function useAddRemoveFavoriteDispatcher() {
  const [addResponse, runAddFavorite] = useAsync(addUserFavorite);
  const [removeResponse, runRemoveFavorite] = useAsync(removeUserFavorite);
  const { setOpen, setSnackBarContent } = useSnackBarContext();

  const setSnackBarContentAndOpen = (msg: string, msgSeverity: AlertColor) => {
    setSnackBarContent({
      message: msg,
      severity: msgSeverity,
    });
    setOpen(true);
  };

  useEffect(() => {
    if (addResponse.status !== "error") {
      return;
    }
    setSnackBarContentAndOpen(SharedMessages.SomethingWentWrong, "error");
    console.error(addResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.error, addResponse.status]);

  useEffect(() => {
    if (addResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Opportunity has been marked as favorite.", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.data, addResponse.status]);

  useEffect(() => {
    if (removeResponse.status !== "error") {
      return;
    }
    setSnackBarContentAndOpen(SharedMessages.SomethingWentWrong, "error");
    console.error(removeResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeResponse.error, removeResponse.status]);

  useEffect(() => {
    if (removeResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Opportunity has been removed from favorites.", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeResponse.data, removeResponse.status]);

  return { addStatus: addResponse.status, runAddFavorite, removeStatus: removeResponse.status, runRemoveFavorite };
}
