import { useEffect, useMemo, useState } from "react";
import { Autocomplete, TextField, Tooltip, Typography } from "@mui/material";
import { createSearchParams, useNavigate } from "react-router-dom";

import { BasicSearchLocators, PATH, removeLeadingSlash } from "@constants";
import { responsesToFormModels } from "services";
import { useAsync } from "hooks";
import { useFetch } from "features/opportunities/hooks";
import { OpportunityType } from "features/opportunities/models";
import { searchOpportunity } from "features/opportunities/services";

import { AutoCompleteSx, RenderInputTextBoxSx } from "./BasicSearchStyle";

export function BasicSearch() {
  const [searchInput, setSearchInput] = useState("");
  const [options, setOptions] = useState<OpportunityType[]>([]);
  const [queryResponse, runSearchQuery] = useAsync(searchOpportunity);
  const [openSearch, setOpenSearch] = useState(options.length > 0);
  const [value] = useState<OpportunityType | null>(null);
  const navigate = useNavigate();
  const fetch = useFetch(runSearchQuery);
  const [isLoading, setIsLoading] = useState(false);

  function openOpportunity(opportunity: OpportunityType) {
    navigate(`${PATH.OPPORTUNITY}/${opportunity.id}`);
  }

  function openSearchView() {
    navigate({
      pathname: PATH.SEARCH,
      search: createSearchParams({ q: searchInput }).toString(),
    });
  }

  useEffect(() => {
    if (queryResponse.data) {
      setOptions(responsesToFormModels(queryResponse.data));
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryResponse.data]);

  useEffect(() => {
    if (searchInput) {
      setIsLoading(true);
      setOpenSearch(true);
      fetch(searchInput);
    } else {
      setOpenSearch(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, searchInput, fetch]);

  return useMemo(() => {
    return (
      <Tooltip
        placement="left"
        title="Search by Opportunity Name, Alternate Name, Responsible Person, Primary Commodity, Country"
      >
        <Autocomplete
          sx={AutoCompleteSx}
          open={openSearch}
          id={BasicSearchLocators.SearchOpportunity}
          data-testid={BasicSearchLocators.SearchOpportunity}
          getOptionLabel={(option) => (typeof option === "string" ? option : option.opportunityName)}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={(x) => x}
          options={options}
          autoComplete
          value={value}
          loading={isLoading}
          loadingText={
            <Typography id={BasicSearchLocators.LoadingResults} data-testid={BasicSearchLocators.LoadingResults}>
              Searching...
            </Typography>
          }
          noOptionsText={
            <Typography id={BasicSearchLocators.NoResults} data-testid={BasicSearchLocators.NoResults}>
              No opportunities found
            </Typography>
          }
          onInputChange={(_, newInputValue) => {
            setSearchInput(removeLeadingSlash(newInputValue));
          }}
          onKeyUp={(event) => {
            if (event.key === "Enter" && searchInput) {
              openSearchView();
            }
          }}
          renderInput={(params) => (
            <TextField
              sx={RenderInputTextBoxSx}
              {...params}
              inputProps={{
                ...params.inputProps,
                "aria-label": "search",
                "data-testid": `${BasicSearchLocators.KeywordSearchInput}`,
              }}
              fullWidth
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id} onClick={() => openOpportunity(option)}>
              {option.opportunityName}
            </li>
          )}
        />
      </Tooltip>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, options, openSearch, searchInput]);
}
