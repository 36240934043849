import { ChangeEvent, Fragment, useMemo } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import { CardBodyLocators } from "@constants";
import { NoErrors, useOpportunityContext } from "features/opportunities/context";
import { useSearchResponsibleGroups } from "features/opportunities/hooks";
import { ResponsibleGroupType } from "features/opportunities/models";

export function ResponsibleGroup() {
  const { opportunity, formErrors, setFormErrors } = useOpportunityContext();
  const {
    responsibleGroupOptions,
    responsibleGroupValue,
    dialogValue,
    open,
    onValueChange,
    handleClose,
    handleSubmit,
    setDialogValue,
    toggleOpen,
    filterOptions,
  } = useSearchResponsibleGroups();

  return useMemo(() => {
    const handleAutocompleteChange = (_event: ChangeEvent<{}>, newValue: ResponsibleGroupType | null) => {
      if (!newValue?.inputValue) {
        onValueChange(newValue);
        return;
      }

      toggleOpen(true);
      setDialogValue({
        name: newValue.inputValue,
      });
    };

    const renderResponsibleGroupInput = (params: AutocompleteRenderInputParams) => (
      <Tooltip title={responsibleGroupValue?.name} placement="top">
        <TextField
          {...params}
          label="Responsible Group"
          inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.ResponsibleGroup }}
          error={Boolean(opportunity.responsibleGroupId && formErrors.responsibleGroupId)}
          helperText={formErrors.responsibleGroupId ? formErrors.responsibleGroupId : undefined}
        />
      </Tooltip>
    );

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { id, value } = event.target;
      if (id === CardBodyLocators.ResponsibleGroupAdd) {
        setDialogValue({ ...dialogValue, name: value });
      }
      setFormErrors(NoErrors);
    };

    return (
      <Fragment>
        <Autocomplete
          value={responsibleGroupValue}
          filterOptions={filterOptions}
          autoHighlight
          id={CardBodyLocators.ResponsibleGroup}
          data-testid={CardBodyLocators.ResponsibleGroupSelect}
          options={responsibleGroupOptions}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
          selectOnFocus
          onChange={handleAutocompleteChange}
          renderOption={(props, option) => {
            return (
              <Box component="li" {...props} key={option.id}>
                <Typography variant="body2" color="text.primary" display="block">
                  {option.name}
                </Typography>
              </Box>
            );
          }}
          renderInput={renderResponsibleGroupInput}
        />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add a new group</DialogTitle>
          <DialogContent>
            <DialogContentText>Did you miss any Responsible Group in our list? Please, add it!</DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id={CardBodyLocators.ResponsibleGroupAdd}
              data-testid={CardBodyLocators.ResponsibleGroupAdd}
              value={dialogValue.name}
              onChange={handleInputChange}
              error={Boolean(formErrors.name)}
              helperText={formErrors.name}
              label="Name"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              id={CardBodyLocators.ResponsibleGroupButtonCancel}
              data-testid={CardBodyLocators.ResponsibleGroupButtonCancel}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              id={CardBodyLocators.ResponsibleGroupButtonAdd}
              data-testid={CardBodyLocators.ResponsibleGroupButtonAdd}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    responsibleGroupValue,
    responsibleGroupOptions,
    open,
    dialogValue,
    formErrors.name,
    opportunity.responsibleGroupId,
  ]);
}
