import { useEffect, useState } from "react";
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom";

import { PATH } from "@constants";
import { OptionalString } from "types";
import { useAsync } from "hooks";
import { searchOpportunity } from "features/opportunities/services";

import { useSearchResultContext } from "../context/SearchResultContext";

export function useSearchQueryDispatcher() {
  const [searchParams] = useSearchParams();
  const { selectedOpportunity, setSelectedOpportunity, setSearchOpportunityResult } = useSearchResultContext();
  const [openOpportunityView, setOpenOpportunityView] = useState(selectedOpportunity != null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [response, run] = useAsync(searchOpportunity);
  const navigate = useNavigate();
  const [, setPreviousId] = useState<OptionalString>(undefined);
  const { searchQuery, opportunityId } = useParams();
  const getSearchQuery = () => searchParams.get("q") ?? searchQuery;
  const [isLoading, setIsLoading] = useState(false);

  var finalSearchQuery = getSearchQuery();

  useEffect(() => {
    setSearchOpportunityResult([]);
    setSelectedOpportunity(null);
    setIsInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPreviousId((previousId) => {
      if (previousId && opportunityId === undefined && finalSearchQuery) {
        setIsLoading(true);
        run(finalSearchQuery);
      }
      return opportunityId;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId]);

  useEffect(() => {
    if (finalSearchQuery) {
      setIsLoading(true);
      run(finalSearchQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalSearchQuery]);

  useEffect(() => {
    setOpenOpportunityView(!!opportunityId);

    if (!opportunityId) {
      setSelectedOpportunity(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityId]);

  useEffect(() => {
    if (response.data) {
      setSearchOpportunityResult(response.data);
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  const handleSubmittedOpportunity = (id?: string) => {
    if (!id || !finalSearchQuery) {
      navigate(PATH.HOME);
      return;
    }

    navigate(generatePath(PATH.VIEW_SEARCH_OPPORTUNITY_WITH_ID, { searchQuery: finalSearchQuery, opportunityId: id }));
    setIsLoading(true);
    run(finalSearchQuery);
  };

  const handleCloseOpportunity = () => {
    navigate(generatePath(PATH.VIEW_SEARCH_OPPORTUNITY, { searchQuery: finalSearchQuery }));
  };

  const handleDeleteOpportunity = () => {
    handleCloseOpportunity();

    if (!finalSearchQuery) {
      setSearchOpportunityResult([]);
      return;
    }

    setIsLoading(true);
    run(finalSearchQuery);
  };

  useEffect(() => {
    if (isInitialLoad) {
      return;
    }

    if (selectedOpportunity?.id && selectedOpportunity?.id !== opportunityId) {
      navigate(
        generatePath(PATH.VIEW_SEARCH_OPPORTUNITY_WITH_ID, {
          searchQuery: finalSearchQuery,
          opportunityId: selectedOpportunity.id,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialLoad, selectedOpportunity?.id]);

  const handleSearchResultCloseButton = () => {
    navigate(PATH.HOME);
  };

  const handleEditOpportunity = (id: string) => {
    if (finalSearchQuery) {
      navigate(generatePath(PATH.UPDATE_SEARCH_OPPORTUNITY, { searchQuery: finalSearchQuery, opportunityId: id }), {
        state: { navigated: true },
      });
      return;
    }
  };

  return {
    isLoading,
    openOpportunityView,
    setOpenOpportunityView,
    handleSearchResultCloseButton,
    handleEditOpportunity,
    handleDeleteOpportunity,
    handleCloseOpportunity,
    handleSubmittedOpportunity,
  };
}
