import { ReactNode } from "react";
import { Checkbox, Chip, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { SxProps, Theme } from "@mui/system";
import { generatePath, Link as RouterLink } from "react-router-dom";

import { PATH, ViewOpportunityValuesLocators } from "@constants";
import { PropsWithTestId } from "types";
import {
  delimiterSeparatedStringToArray,
  getDepositTypeClassLabel,
  getDepositTypeGroupLabel,
  getDepositTypeSubclassLabel,
  mapIdsToName,
  mapIdToValue,
  formatAsShortDate,
  formatAsDateTime,
} from "services";
import { SimpleDialogType } from "context/SimpleDialogContext";
import { getAllCommodities } from "features/opportunities/components";
import { OpportunityType, IdName, ReferenceOpportunityType, Reference } from "features/opportunities/models";

import { truncate } from "./stringUtils";

const sxFunc = (isActiveLink: boolean): SxProps<Theme> =>
  isActiveLink
    ? { cursor: "pointer", verticalAlign: "top", overflowWrap: "break-word" }
    : { cursor: "default", pointerEvents: "none", verticalAlign: "top", overflowWrap: "break-word" };

export const getGeneralDetailsSection = (
  viewOpportunity: OpportunityType,
  simpleDialogContext: SimpleDialogType,
  referenceDataOpportunityTypes: ReferenceOpportunityType[] | undefined
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-code-label",
    name: "Opportunity Code",
    value: viewOpportunity.opportunityCode && (
      <Typography data-testid={ViewOpportunityValuesLocators.OpportunityCode} variant="body2">
        {viewOpportunity.opportunityCode}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-name-label",
    name: "Name",
    value: viewOpportunity.opportunityName && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.OpportunityName}
        variant="body2"
        sx={{ overflowWrap: "break-word" }}
      >
        {viewOpportunity.opportunityName}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-alternate-names-label",
    name: "Alternate Names",
    value: viewOpportunity.opportunityAlternateNames && (
      <Grid container spacing={0.5}>
        {delimiterSeparatedStringToArray(";", viewOpportunity.opportunityAlternateNames).map((alternateName, index) => (
          <Grid xs={12} key={index}>
            <Chip
              data-testid={`${ViewOpportunityValuesLocators.OpportunityAlternateNames}-${index}`}
              label={alternateName}
              sx={{ width: "100%" }}
            />
          </Grid>
        ))}
      </Grid>
    ),
  },
  {
    labelDataTestId: "view-opportunity-description-label",
    name: "Description",
    value: viewOpportunity.opportunityDescription && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.OpportunityDescription}
        variant="body2"
        sx={{ ...sxFunc(viewOpportunity.opportunityDescription.length > 345) }}
        onClick={() => {
          simpleDialogContext.setDialogBody(viewOpportunity.opportunityDescription ?? "");
          simpleDialogContext.setOpenSimpleDialog(true);
        }}
      >
        {truncate(viewOpportunity.opportunityDescription, 345)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-responsible-person-label",
    name: "Responsible Person",
    value: viewOpportunity.responsiblePerson && (
      <Typography data-testid={ViewOpportunityValuesLocators.ResponsiblePerson} variant="body2">
        {`${viewOpportunity.responsiblePerson.firstName} ${viewOpportunity.responsiblePerson.lastName}`}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-responsible-group-label",
    name: "Responsible Group",
    value: viewOpportunity.responsibleGroup && (
      <Typography data-testid={ViewOpportunityValuesLocators.ResponsibleGroup} variant="body2">
        {viewOpportunity.responsibleGroup?.name}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-type-label",
    name: "Type",
    value: viewOpportunity.opportunityTypeId && referenceDataOpportunityTypes && (
      <Typography data-testid={ViewOpportunityValuesLocators.OpportunityType} variant="body2">
        {mapIdsToName(referenceDataOpportunityTypes, viewOpportunity.opportunityTypeId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-sub-type-label",
    name: "Sub-type",
    value: viewOpportunity.opportunitySubTypeId && referenceDataOpportunityTypes && (
      <Typography data-testid={ViewOpportunityValuesLocators.OpportunitySubType} variant="body2">
        {mapIdsToName(
          referenceDataOpportunityTypes.find((item) => item.id === viewOpportunity.opportunityTypeId)
            ?.opportunitySubTypes,
          viewOpportunity.opportunitySubTypeId
        )}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-group-label",
    name: "Group",
    value: viewOpportunity.group && (
      <Typography data-testid={ViewOpportunityValuesLocators.Group} variant="body2" sx={{ overflowWrap: "break-word" }}>
        {viewOpportunity.group}
      </Typography>
    ),
  },
];

export const getStatusSection = (
  viewOpportunity: OpportunityType,
  referenceData: Reference
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-status-label",
    name: "Status",
    value: viewOpportunity.opportunityStatusId && referenceData.opportunityStatuses && (
      <Typography data-testid={ViewOpportunityValuesLocators.OpportunityStatus} variant="body2">
        {mapIdsToName(referenceData.opportunityStatuses, viewOpportunity.opportunityStatusId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-start-date-label",
    name: "Start Date",
    value: viewOpportunity.startDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.StartDate} variant="body2">
        {formatAsShortDate(viewOpportunity.startDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-end-date-label",
    name: "End Date",
    value: viewOpportunity.endDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.EndDate} variant="body2">
        {formatAsShortDate(viewOpportunity.endDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-exploration-type-label",
    name: "Exploration Type",
    value: viewOpportunity.explorationTypeId && referenceData.explorationTypes && (
      <Typography data-testid={ViewOpportunityValuesLocators.ExplorationType} variant="body2">
        {mapIdsToName(referenceData.explorationTypes, viewOpportunity.explorationTypeId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-exploration-stage-label",
    name: "Exploration Stage",
    value: viewOpportunity.explorationStageId && referenceData.explorationStages && (
      <Typography data-testid={ViewOpportunityValuesLocators.ExplorationStage} variant="body2">
        {mapIdsToName(referenceData.explorationStages, viewOpportunity.explorationStageId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-test-method-label",
    name: "Test Method",
    value: viewOpportunity.testMethodId && referenceData.testMethods && (
      <Typography data-testid={ViewOpportunityValuesLocators.TestMethod} variant="body2">
        {mapIdsToName(referenceData.testMethods, viewOpportunity.testMethodId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-test-date-label",
    name: "Test Date",
    value: viewOpportunity.testDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.TestDate} variant="body2">
        {formatAsShortDate(viewOpportunity.testDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-deposit-stage-label",
    name: "Deposit Stage",
    value: viewOpportunity.depositStageId && referenceData.depositStages && (
      <Typography data-testid={ViewOpportunityValuesLocators.DepositStage} variant="body2">
        {mapIdsToName(referenceData.depositStages, viewOpportunity.depositStageId)}
      </Typography>
    ),
  },
];

export const getGeologicalSection = (
  viewOpportunity: OpportunityType,
  referenceData: Reference
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => {
  var result = [
    {
      labelDataTestId: "view-opportunity-primary-commodity-label",
      name: "Primary Commodity",
      value: viewOpportunity.primaryCommodityId && referenceData.commodityGroups && (
        <Typography data-testid={ViewOpportunityValuesLocators.PrimaryCommodity} variant="body2">
          {mapIdsToName(
            referenceData.commodityGroups.flatMap((commodityClass) =>
              commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
            ),
            viewOpportunity.primaryCommodityId
          )}
        </Typography>
      ),
    },
    {
      labelDataTestId: "view-opportunity-secondary-commodity-label",
      name: "Secondary Commodity",
      value: viewOpportunity.secondaryCommodityId && referenceData.commodityGroups && (
        <Typography data-testid={ViewOpportunityValuesLocators.SecondaryCommodity} variant="body2">
          {mapIdsToName(
            referenceData.commodityGroups.flatMap((commodityClass) =>
              commodityClass.commodityClasses.flatMap((commodities) => commodities.commodities)
            ),
            viewOpportunity.secondaryCommodityId
          )}
        </Typography>
      ),
    },
    {
      labelDataTestId: "view-opportunity-other-commodities-label",
      name: "Other Commodities",
      value: viewOpportunity.otherCommodities && (
        <Grid container spacing={0.5}>
          {delimiterSeparatedStringToArray(",", viewOpportunity.otherCommodities).map((otherCommodityId, index) => (
            <Grid xs={12} key={index}>
              <Chip
                data-testid={`${ViewOpportunityValuesLocators.OtherCommodities}-${index}`}
                label={mapIdToValue(getAllCommodities(referenceData.commodityGroups), Number(otherCommodityId))}
                sx={{ width: "100%" }}
              />
            </Grid>
          ))}
        </Grid>
      ),
    },
  ];

  const { depositTypeGroupId, depositTypeClassId, depositTypeSubClassId } = viewOpportunity;
  if (depositTypeGroupId || depositTypeClassId || depositTypeSubClassId) {
    const depositTypeLabels = [];

    if (depositTypeGroupId) {
      depositTypeLabels.push({
        labelDataTestId: "view-opportunity-deposit-type-group-label",
        name: "Deposit Type Group",
        value: (
          <Typography data-testid={ViewOpportunityValuesLocators.DepositTypeGroup} variant="body2">
            {getDepositTypeGroupLabel(viewOpportunity, referenceData.depositTypeGroups)}
          </Typography>
        ),
      });
    }

    if (depositTypeClassId && depositTypeGroupId) {
      depositTypeLabels.push({
        labelDataTestId: "view-opportunity-deposit-type-class-label",
        name: "Deposit Type Class",
        value: (
          <Typography data-testid={ViewOpportunityValuesLocators.DepositTypeClass} variant="body2">
            {getDepositTypeClassLabel(viewOpportunity, referenceData.depositTypeGroups)}
          </Typography>
        ),
      });
    }

    if (depositTypeSubClassId && depositTypeClassId && depositTypeGroupId) {
      depositTypeLabels.push({
        labelDataTestId: "view-opportunity-deposit-type-subclass-label",
        name: "Deposit Type Sub Class",
        value: (
          <Typography data-testid={ViewOpportunityValuesLocators.DepositTypeSubclass} variant="body2">
            {getDepositTypeSubclassLabel(viewOpportunity, referenceData.depositTypeGroups)}
          </Typography>
        ),
      });
    }

    result.push(...depositTypeLabels);
  }

  return result;
};

export const getLocationSection = (
  viewOpportunity: OpportunityType,
  referenceDataLocationAccuracies: IdName[] | undefined
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-longitude-label",
    name: "Longitude",
    value: viewOpportunity.longitude && (
      <Typography data-testid={ViewOpportunityValuesLocators.Longitude} variant="body2">
        {viewOpportunity.longitude}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-latitude-label",
    name: "Latitude",
    value: viewOpportunity.latitude && (
      <Typography data-testid={ViewOpportunityValuesLocators.Latitude} variant="body2">
        {viewOpportunity.latitude}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-accuracy-label",
    name: "Accuracy",
    value: viewOpportunity.locationAccuracyId && referenceDataLocationAccuracies && (
      <Typography data-testid={ViewOpportunityValuesLocators.LocationAccuracy} variant="body2">
        {mapIdsToName(referenceDataLocationAccuracies, viewOpportunity.locationAccuracyId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-country-label",
    name: "Country",
    value: viewOpportunity.country && (
      <Typography data-testid={ViewOpportunityValuesLocators.Country} variant="body2">
        {viewOpportunity.country}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-state-province-label",
    name: "State / Province",
    value: viewOpportunity.stateProvince && (
      <Typography data-testid={ViewOpportunityValuesLocators.StateProvince} variant="body2">
        {viewOpportunity.stateProvince}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-rtx-region-label",
    name: "RTX Region",
    value: viewOpportunity.rtxRegion && (
      <Typography data-testid={ViewOpportunityValuesLocators.RtxRegion} variant="body2">
        {viewOpportunity.rtxRegion}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-rtx-sub-region-label",
    name: "RTX Subregion",
    value: viewOpportunity.rtxSubRegion && (
      <Typography data-testid={ViewOpportunityValuesLocators.RtxSubRegion} variant="body2">
        {viewOpportunity.rtxSubRegion}
      </Typography>
    ),
  },
];

export const getLineageSection = (
  viewOpportunity: OpportunityType,
  referenceDataOpportunityOriginTypes: IdName[] | undefined,
  hasRestrictedAccess: boolean
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-origin-label",
    name: "Origin",
    value: viewOpportunity.originId && referenceDataOpportunityOriginTypes && (
      <Typography data-testid={ViewOpportunityValuesLocators.Origin} variant="body2">
        {mapIdsToName(referenceDataOpportunityOriginTypes, viewOpportunity.originId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-origin-name-label",
    name: "Origin Name",
    value: viewOpportunity.originNameId && viewOpportunity.originName && (
      <Typography data-testid={ViewOpportunityValuesLocators.OriginName} variant="body2">
        {viewOpportunity.originName.name}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-parent-opportunity-label",
    name: "Parent Opportunity",
    value:
      viewOpportunity.parentOpportunityId &&
      viewOpportunity.parentOpportunity &&
      (!hasRestrictedAccess && viewOpportunity.parentOpportunity.restrictedFlag ? (
        <Typography data-testid={ViewOpportunityValuesLocators.ParentOpportunity} variant="body2">
          === Restricted ===
        </Typography>
      ) : (
        <Link
          data-testid={ViewOpportunityValuesLocators.ParentOpportunity}
          variant="body2"
          component={RouterLink}
          to={generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId: viewOpportunity.parentOpportunityId })}
          sx={{ verticalAlign: "top", color: "inherit" }}
          target="_self"
          rel="noopener"
        >
          {viewOpportunity.parentOpportunity.opportunityName}
        </Link>
      )),
  },
];

export const getCommercialSection = (
  viewOpportunity: OpportunityType,
  referenceData: Reference
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-funding-type-label",
    name: "Funding Type",
    value: viewOpportunity.fundingTypeId && referenceData.fundingTypes && (
      <Typography data-testid={ViewOpportunityValuesLocators.FundingType} variant="body2">
        {mapIdsToName(referenceData.fundingTypes, viewOpportunity.fundingTypeId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-company-label",
    name: "Company",
    value: viewOpportunity.companyId && viewOpportunity.company && (
      <Typography data-testid={ViewOpportunityValuesLocators.Company} variant="body2">
        {viewOpportunity.company.name}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-commercial-status-label",
    name: "Commercial Status",
    value: viewOpportunity.commercialStatusId && referenceData.commercialStatuses && (
      <Typography data-testid={ViewOpportunityValuesLocators.CommercialStatus} variant="body2">
        {mapIdsToName(referenceData.commercialStatuses, viewOpportunity.commercialStatusId)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-third-party-name-label",
    name: "Third Party Name",
    value: viewOpportunity.thirdPartyNameId && viewOpportunity.thirdPartyName && (
      <Typography data-testid={ViewOpportunityValuesLocators.ThirdPartyName} variant="body2">
        {viewOpportunity.thirdPartyName.name}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-date-notified-label",
    name: "Date Notified",
    value: viewOpportunity.thirdPartyNotifiedDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.ThirdPartyNotifiedDate} variant="body2">
        {formatAsShortDate(viewOpportunity.thirdPartyNotifiedDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-confidentiality-agreement-label",
    name: "Confidentiality agreement",
    value: viewOpportunity.confidentialityAgreement && (
      <Checkbox
        id={ViewOpportunityValuesLocators.ConfidentialityAgreement}
        disabled
        sx={{ padding: 0 }}
        checked={viewOpportunity.confidentialityAgreement}
        inputProps={{ "data-testid": ViewOpportunityValuesLocators.ConfidentialityAgreement } as PropsWithTestId}
      />
    ),
  },
];

export const getAdditionalDetailsSection = (
  viewOpportunity: OpportunityType,
  simpleDialogContext: SimpleDialogType
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-watch-list-priority-label",
    name: "Watch List Priority",
    value: viewOpportunity.watchlistPriority && (
      <Typography data-testid={ViewOpportunityValuesLocators.WatchlistPriority} variant="body2">
        {viewOpportunity.watchlistPriority}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-include-for-metrics-label",
    name: "Include for Metrics",
    value: viewOpportunity.includeForMetrics && (
      <Checkbox
        id={ViewOpportunityValuesLocators.IncludeForMetrics}
        disabled
        sx={{ padding: 0 }}
        checked={viewOpportunity.includeForMetrics}
        inputProps={{ "data-testid": ViewOpportunityValuesLocators.IncludeForMetrics } as PropsWithTestId}
      />
    ),
  },
  {
    labelDataTestId: "view-opportunity-site-conditions-label",
    name: "Site Conditions",
    value: viewOpportunity.siteConditions && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.SiteConditions}
        variant="body2"
        sx={{ ...sxFunc(viewOpportunity.siteConditions.length > 345) }}
        onClick={() => {
          simpleDialogContext.setDialogBody(viewOpportunity.siteConditions ?? "");
          simpleDialogContext.setOpenSimpleDialog(true);
        }}
      >
        {truncate(viewOpportunity.siteConditions, 345)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-previous-work-summary-label",
    name: "Previous Work Summary",
    value: viewOpportunity.previousWorkSummary && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.PreviousWorkSummary}
        variant="body2"
        sx={{ ...sxFunc(viewOpportunity.previousWorkSummary.length > 345) }}
        onClick={() => {
          simpleDialogContext.setDialogBody(viewOpportunity.previousWorkSummary ?? "");
          simpleDialogContext.setOpenSimpleDialog(true);
        }}
      >
        {truncate(viewOpportunity.previousWorkSummary, 345)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-missing-data-label",
    name: "Missing Data",
    value: viewOpportunity.missingData && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.MissingData}
        variant="body2"
        sx={{ ...sxFunc(viewOpportunity.missingData.length > 345) }}
        onClick={() => {
          simpleDialogContext.setDialogBody(viewOpportunity.missingData ?? "");
          simpleDialogContext.setOpenSimpleDialog(true);
        }}
      >
        {truncate(viewOpportunity.missingData, 345)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-notes-label",
    name: "Notes",
    value: viewOpportunity.notes && (
      <Typography
        data-testid={ViewOpportunityValuesLocators.Notes}
        variant="body2"
        sx={{ ...sxFunc(viewOpportunity.notes.length > 345) }}
        onClick={() => {
          simpleDialogContext.setDialogBody(viewOpportunity.notes ?? "");
          simpleDialogContext.setOpenSimpleDialog(true);
        }}
      >
        {truncate(viewOpportunity.notes, 345)}
      </Typography>
    ),
  },
];

export const getIntegrationSection = (
  viewOpportunity: OpportunityType
): Array<{ labelDataTestId: string; name: string; value: ReactNode }> => [
  {
    labelDataTestId: "view-opportunity-landfolio-id-label",
    name: "Landfolio ID",
    value: viewOpportunity.landfolioId && (
      <Typography data-testid={ViewOpportunityValuesLocators.LandfolioId} variant="body2">
        {viewOpportunity.landfolioId}
      </Typography>
    ),
  },
];

export const getChangeLogSection = (
  viewOpportunity: OpportunityType
): Array<{ labelDataTestId: string; name: string; value: ReactNode; nameStyle?: SxProps<Theme> }> => [
  {
    labelDataTestId: "view-opportunity-created-date-label",
    name: "Create Date",
    value: viewOpportunity.createdDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.CreatedDate} variant="body2">
        {formatAsDateTime(viewOpportunity.createdDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-created-by-label",
    name: "Created By",
    value: viewOpportunity.createdBy && (
      <Typography data-testid={ViewOpportunityValuesLocators.CreatedBy} variant="body2">
        {viewOpportunity.createdBy}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-updated-date-label",
    name: "Last Update",
    value: viewOpportunity.updatedDate && (
      <Typography data-testid={ViewOpportunityValuesLocators.UpdatedDate} variant="body2">
        {formatAsDateTime(viewOpportunity.updatedDate)}
      </Typography>
    ),
  },
  {
    labelDataTestId: "view-opportunity-updated-by-label",
    name: "Updated By",
    value: viewOpportunity.updatedBy && (
      <Typography data-testid={ViewOpportunityValuesLocators.UpdatedBy} variant="body2">
        {viewOpportunity.updatedBy}
      </Typography>
    ),
  },
];
