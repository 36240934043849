import { Fragment, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Unstable_Grid2";
import { SxProps, Theme } from "@mui/system";

import { CardBodyTooltip, CardTooltipType } from ".";

interface CollectionType {
  labelDataTestId: string;
  name: string;
  value: ReactNode;
  componentId?: string;
  nameStyle?: SxProps<Theme>;
}

interface SectionProps {
  sectionId: string;
  sectionNameId: string;
  sectionName: string;
  collection: CollectionType[];
  tooltipFields?: CardTooltipType;
}

export function CardBodyViewSectionBase({
  sectionId,
  sectionNameId,
  sectionName,
  collection,
  tooltipFields,
}: SectionProps) {
  const sectionContent =
    collection
      .filter((item) => Boolean(item.value))
      .map<JSX.Element>((item, index) => (
        <Fragment key={item.labelDataTestId + index}>
          <Grid xs={5} textAlign="right">
            <Typography data-testid={item.labelDataTestId} variant="body2" color={grey[600]} sx={item.nameStyle}>
              {item.name}
            </Typography>
          </Grid>
          <Grid xs={7}>{item.value}</Grid>
        </Fragment>
      )) ?? [];

  return (
    <Box data-testid={sectionId}>
      <Box display="flex" alignItems="center">
        <Typography data-testid={sectionNameId} variant="subtitle2" sx={{ flexGrow: 1 }}>
          {sectionName}
        </Typography>
        <CardBodyTooltip id={sectionId} fields={tooltipFields} />
      </Box>
      <Grid container spacing={1} alignItems={"flex-start"}>
        {sectionContent}
      </Grid>
    </Box>
  );
}
