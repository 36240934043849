import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { SharedMessages } from "@constants";
import { useSnackBarContext } from "context";
import { useAsync } from "hooks";
import { deleteOpportunity } from "features/opportunities/services";

export function useDeleteOpportunityDispatcher(onDeleted: (id: string) => void) {
  const [response, runDelete] = useAsync(deleteOpportunity);
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { opportunityId } = useParams();

  useEffect(() => {
    if (response.status !== "error") {
      return;
    }

    setSnackBarContent({
      message: SharedMessages.SomethingWentWrong,
      severity: "error",
    });

    setOpen(true);
    console.error(response.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.error, response.status]);

  useEffect(() => {
    if (response.status !== "success") {
      return;
    }

    setSnackBarContent({
      message: "Opportunity has been deleted",
      severity: "success",
    });

    setOpen(true);
    onDeleted(opportunityId || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data, response.status]);

  return { status: response.status, runDelete };
}
