import { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { IdName } from "features/opportunities/models";
import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";
import { AdvancedSearchModelType } from "features/search/models";

type Props = {
  id: string;
  label: string;
  propKey: keyof AdvancedSearchModelType;
  errorText?: string;
} & (
  | { type: "string"; options: IdName<string>[]; selectedValues: IdName<string>[] }
  | { type: "number"; options: IdName<number>[]; selectedValues: IdName<number>[] }
);

export function AdvancedSearchCriteriaAutocomplete({
  id,
  label,
  propKey,
  type,
  options,
  selectedValues,
  errorText,
}: Props) {
  const { setFieldValue } = useAdvancedSearchContext();
  const autocompleteId = `advanced-search-${id}`;
  const selectId = `advanced-search-${id}-select`;

  const updateAutocomplete = (name: keyof AdvancedSearchModelType, value: IdName<number>[]) => {
    setFieldValue({ name, value });
  };

  const updateAutocompleteString = (name: keyof AdvancedSearchModelType, value: IdName<string>[]) => {
    setFieldValue({ name, value });
  };

  return useMemo(() => {
    return type === "string" ? (
      <Autocomplete
        id={autocompleteId}
        data-testid={selectId}
        multiple
        autoHighlight
        limitTags={1}
        options={options}
        getOptionLabel={(option) => getOptionLabel(option, "name")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedValues || []}
        onChange={(_event, value) => updateAutocompleteString(propKey, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{ ...params.inputProps, "data-testid": id }}
            error={!!errorText}
            helperText={errorText}
          />
        )}
      />
    ) : (
      <Autocomplete
        id={autocompleteId}
        data-testid={selectId}
        multiple
        autoHighlight
        limitTags={1}
        options={options}
        getOptionLabel={(option) => getOptionLabel(option, "name")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={selectedValues || []}
        onChange={(_event, value) => updateAutocomplete(propKey, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            inputProps={{ ...params.inputProps, "data-testid": id }}
            error={!!errorText}
            helperText={errorText}
          />
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, selectedValues, errorText]);
}

function getOptionLabel<TId>(option: IdName<TId>, optionListSelector?: keyof IdName<TId>): string {
  if (typeof option === "string") {
    return option;
  }

  return optionListSelector ? String(option[optionListSelector]) : option.name;
}
