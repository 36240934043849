import { SyntheticEvent, forwardRef } from "react";
import { Snackbar, SnackbarCloseReason } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import { useSnackBarContext } from "context/SnackBarContext";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function SnackBar() {
  const { open, setOpen, snackBarContent } = useSnackBarContext();
  const handleClose = (event: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (!!snackBarContent.onClose) {
      snackBarContent.onClose(event, reason);
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={snackBarContent.autoHideDuration || 3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={snackBarContent.severity} sx={{ width: "100%" }}>
        {snackBarContent.message}
      </Alert>
    </Snackbar>
  );
}
