import { useState, useRef, useEffect, ChangeEvent, Fragment, MouseEvent as ReactMouseEvent } from "react";
import { ArrowDropDown, ArrowDropUp, Close } from "@mui/icons-material";
import { Card, IconButton, Popper, TextField, Typography } from "@mui/material";

import { CardBodyLocators } from "@constants";

import { NestedList, LabelTypeResultTextData, ObjectWithLevel, flattenJson } from "./NestedList";

export interface Criteria {
  identifier: string;
  labelProperty: string;
  sortProperty: string;
  children: string[];
  childrenLabels: string[];
}
export interface DataObject<T> {
  dataList: T[];
  criteria: Criteria;
  selectedIds: number[];
  handleNestedListData: (labelTypeIds: number[]) => void;
}
export interface IdWithName {
  id: number;
  name: string;
}

export function NestedListAutoComplete<T>(data: DataObject<T>) {
  var { dataList, criteria, selectedIds, handleNestedListData } = data;
  const [inputValue, setInputValue] = useState("");
  const [isAscending, setIsAscending] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchResultData, updateSearchResult] = useState<LabelTypeResultTextData | null>(null);
  const [searchFreeText, updateSearchFreeText] = useState<string>("");
  const [lableTypeIdNames, updatelableTypeIdNames] = useState<IdWithName[]>([]);
  const popperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLDivElement>(null);
  const flattenedDepositTypes: ObjectWithLevel[] = flattenJson(dataList, data.criteria, [], []);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(
    () => {
      var lableTypeIdNames: IdWithName[] = [];
      setLabelTypeText();
      selectedIds.forEach((e) => {
        var idName = flattenedDepositTypes.find((x) => x.id === e)?.name;
        if (idName) {
          lableTypeIdNames.push({ id: e, name: idName } as IdWithName);
        }
      });
      updatelableTypeIdNames(lableTypeIdNames);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedIds]
  );

  const getlabelTypeText = ({ labelTypeText, idsToCheck }: LabelTypeResultTextData) => {
    if (labelTypeText.length <= 0) {
      idsToCheck = idsToCheck.filter((e) => e !== undefined && e !== 0);
      if (idsToCheck.length > 0) {
        const LabelTypeTextArr = idsToCheck.map((e) => flattenedDepositTypes.find((x) => x.id === e)?.name);
        labelTypeText = LabelTypeTextArr as string[];
      }
    }
    return labelTypeText;
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (
      !popperRef.current?.contains(e.target as HTMLDivElement) &&
      !inputRef.current?.contains(e.target as HTMLDivElement)
    ) {
      setOpen(false);
    }
  };

  const setLabelTypeText = () => {
    if (inputValue.length <= 0) {
      const LabelTypeText: string[] = getlabelTypeText({
        labelTypeText: [],
        idsToCheck: selectedIds,
      } as LabelTypeResultTextData);
      updateSearchResult({
        labelTypeText: LabelTypeText ? LabelTypeText : [],
        idsToCheck: selectedIds,
      } as LabelTypeResultTextData);
    } else {
      if (!searchResultData?.idsToCheck.some((e) => e !== undefined && e !== 0)) {
        setInputValue("");
        updateSearchFreeText("");
      }
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 0) {
      setInputValue(event.target.value);
      updateSearchFreeText(event.target.value);
      if (open === false) {
        setIsAscending(true);
        setOpen(true);
      }
    } else {
      clearInput();
      updateSearchFreeText("");
    }
  };

  const handleInputClick = (e: ReactMouseEvent<HTMLDivElement>) => {
    if (e !== undefined) {
      setIsAscending(!isAscending);
      setOpen((previousOpen) => !previousOpen);
      setAnchorEl(e.currentTarget);
    } else {
      setOpen((previousOpen) => !previousOpen);
    }
  };

  const handleSearchResult = (data: LabelTypeResultTextData) => {
    updateSearchResult(data);
    handleNestedListData(data.idsToCheck);
    setIsAscending(!isAscending);
    setOpen((previousOpen) => !previousOpen);
  };

  const clearInput = () => {
    setInputValue("");
    updateSearchResult(null);
    handleNestedListData([]);
    updateSearchFreeText("");
  };

  const getLabelGroupText = (lableTypeIdNames: IdWithName[], displayLabels: string[]) => {
    if (displayLabels.length > 0 && lableTypeIdNames.length > 0) {
      var labelGroupText = lableTypeIdNames.map((x) => {
        return (
          <Typography
            variant="body2"
            gutterBottom
            key={lableTypeIdNames.indexOf(x)}
            data-testid={"list-selected-values-" + lableTypeIdNames.indexOf(x)}
          >
            <b>{displayLabels[lableTypeIdNames.indexOf(x)]}: </b>
            {lableTypeIdNames[lableTypeIdNames.indexOf(x)].name}
          </Typography>
        );
      });
      if (labelGroupText.length > 0) {
        return (
          <>
            <Card sx={{ padding: 1, marginTop: 1.5 }} data-testid="list-selected-values-container">
              {labelGroupText}
            </Card>
          </>
        );
      }
      return null;
    }
    return null;
  };

  return (
    <Fragment>
      <TextField
        id={CardBodyLocators.DepositType}
        data-testid={CardBodyLocators.DepositType}
        aria-describedby={CardBodyLocators.DepositType}
        label="Deposit Type"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onClick={handleInputClick}
        ref={inputRef}
        autoComplete="off"
        sx={{
          "& .MuiInputBase-input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        InputProps={{
          endAdornment: (
            <>
              <IconButton onClick={clearInput} data-testid="nested-list-autocomplete-clear-button">
                {searchResultData && searchResultData.idsToCheck.length > 0 ? <Close /> : null}
              </IconButton>
              <IconButton data-testid="nested-list-autocomplete-arrowupdown-button">
                {isAscending ? <ArrowDropUp /> : <ArrowDropDown />}
              </IconButton>
            </>
          ),
        }}
      />
      {getLabelGroupText(lableTypeIdNames, criteria.childrenLabels)}
      <Popper
        id={`${CardBodyLocators.DepositType}-popper`}
        open={open}
        anchorEl={anchorEl}
        ref={popperRef}
        sx={{ width: "100%", maxWidth: "431px", bgcolor: "background.paper", zIndex: 99 }}
      >
        <NestedList
          {...{
            data: data,
            jsonData: dataList,
            flattenJsonData: flattenedDepositTypes,
            searchResult: handleSearchResult,
            searchResultData: searchResultData,
            searchFreeText: searchFreeText,
          }}
        ></NestedList>
      </Popper>
    </Fragment>
  );
}
