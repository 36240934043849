import { useState } from "react";
import { Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { Mode, PATH } from "@constants";
import { useReferenceDataContext } from "context";
import { useDocumentTitle } from "hooks";
import {
  AlertsPane,
  CardBodySkeleton,
  CardBodyView,
  CardHeader,
  CardHeaderSkeleton,
  CommentsPane,
} from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useGetOpportunityAndReplaceForm, useSetScreenMode, useComment } from "features/opportunities/hooks";
import { useAlert } from "features/opportunities/hooks/useAlert";

interface ViewOpportunityProps {
  initialOpenAlertsPane?: boolean;
  initialOpenCommentsPane?: boolean;
}

export function ViewOpportunity({ initialOpenAlertsPane, initialOpenCommentsPane }: ViewOpportunityProps) {
  useDocumentTitle("View Opportunity");
  const [showAllFields, setShowAllFields] = useState(false);
  const [openAlertsPane, setOpenAlertsPane] = useState(initialOpenAlertsPane || false);
  const [openCommentsPane, setOpenCommentsPane] = useState(initialOpenCommentsPane || false);
  useSetScreenMode(Mode.View);
  useGetOpportunityAndReplaceForm();
  const navigate = useNavigate();
  const { opportunityId } = useParams();
  const { opportunity } = useOpportunityContext();
  const { referenceData } = useReferenceDataContext();
  const isDataLoading = (...objects: any[]) => objects.some((obj) => Object.keys(obj).length === 0);
  const { toggleCommentsPath } = useComment();
  const { toggleAlertsPath } = useAlert();

  return (
    <>
      <Paper
        square
        sx={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: grey.A200, width: 512 }}
      >
        {isDataLoading(referenceData) || !opportunity.id ? (
          <>
            <CardHeaderSkeleton />
            <CardBodySkeleton />
          </>
        ) : (
          <>
            <CardHeader
              mode={Mode.View}
              showAllFields={showAllFields}
              setShowAllFields={setShowAllFields}
              setOpenAlertsPane={setOpenAlertsPane}
              setOpenCommentsPane={setOpenCommentsPane}
              onEdit={() => navigate(generatePath(PATH.EDIT_OPPORTUNITY, { opportunityId }))}
              onClose={() => navigate(PATH.HOME)}
              onDeleted={() => navigate(PATH.HOME)}
            />
            <CardBodyView />
          </>
        )}
      </Paper>
      {openAlertsPane && (
        <AlertsPane
          toggleAlertsPane={() => {
            setOpenAlertsPane((prevOpenAlertsPane) => !prevOpenAlertsPane);
            navigate(generatePath(toggleAlertsPath(openAlertsPane, window.location.pathname), { opportunityId }));
          }}
        />
      )}
      {openCommentsPane && (
        <CommentsPane
          toggleCommentsPane={() => {
            setOpenCommentsPane((prevOpenCommentsPane) => !prevOpenCommentsPane);
            navigate(generatePath(toggleCommentsPath(openCommentsPane, window.location.pathname), { opportunityId }));
          }}
        />
      )}
    </>
  );
}
