import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { useReferenceDataContext } from "context";
import { referenceDataOpportunityStatusMenuItems } from "features/opportunities/components";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

export function OpportunityStatus() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, updateField } = useOpportunityContext();

  return useMemo(() => {
    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    const opportunityStatuses = referenceData?.opportunityStatuses?.filter(
      (item) => item.opportunityTypeId === opportunity.opportunityTypeId
    );
    const opportunityStatus = opportunityStatuses?.find((item) => item.id === opportunity.opportunityStatusId);
    const opportunityStatusId = (opportunityStatus && opportunity.opportunityStatusId) || 0;

    return (
      <FormControl required>
        <InputLabel id={CardBodyLocators.OpportunityStatus} data-testid={CardBodyLocators.OpportunityStatus}>
          Status
        </InputLabel>
        <Select
          labelId={CardBodyLocators.OpportunityStatus}
          id={CardBodyLocators.OpportunityStatusSelect}
          label="Status"
          name={opportunityTypeOfFactory("opportunityStatusId")}
          onChange={handleSelectionChange}
          value={opportunityStatusId}
          SelectDisplayProps={{ "data-testid": CardBodyLocators.OpportunityStatusSelect } as PropsWithTestId}
          error={Boolean(opportunity.opportunityStatusId && formErrors.opportunityStatusId)}
        >
          {referenceDataOpportunityStatusMenuItems(
            opportunity.opportunityTypeId,
            referenceData?.opportunityStatuses,
            "opp-status-"
          )}
        </Select>
        {opportunity.opportunityStatusId > 0 && formErrors.opportunityStatusId && (
          <FormHelperText>{formErrors.opportunityStatusId}</FormHelperText>
        )}
      </FormControl>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.opportunityStatusId,
    opportunity.opportunityTypeId,
    referenceData?.opportunityStatuses,
    formErrors.opportunityStatusId,
  ]);
}
