import { useMemo } from "react";

import { CardBodyLocators } from "@constants";
import { CommodityAutocomplete } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";

export function PrimaryCommodity() {
  const { opportunity, formErrors } = useOpportunityContext();

  return useMemo(() => {
    return (
      <CommodityAutocomplete
        id={CardBodyLocators.PrimaryCommodity}
        idSelect={CardBodyLocators.PrimaryCommoditySelect}
        label="Primary Commodity"
        propKey="primaryCommodityId"
        required
        error={Boolean(opportunity.primaryCommodityId && formErrors.primaryCommodityId)}
        helperText={
          opportunity.primaryCommodityId > 0 && formErrors.primaryCommodityId
            ? formErrors.primaryCommodityId
            : undefined
        }
      />
    );
  }, [opportunity.primaryCommodityId, formErrors.primaryCommodityId]);
}
