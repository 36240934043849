import axios from "axios";

import { undefinedResponseInterceptor } from "./interceptors";

const arcGISClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

arcGISClient.interceptors.response.use(undefinedResponseInterceptor);

export { arcGISClient };
