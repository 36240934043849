import { useMemo } from "react";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { formatAsDateTime } from "services";
import { useOpportunityContext } from "features/opportunities/context";

export function ChangelogSection() {
  const { opportunity } = useOpportunityContext();

  return useMemo(() => {
    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid xs={6}>
          <TextField
            id="created-date"
            label="Create Date"
            value={formatAsDateTime(opportunity.createdDate) || ""}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "created-date" }}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            id="created-by"
            label="Created By"
            value={opportunity.createdBy || ""}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "created-by" }}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            id="modified-date"
            label="Last Update"
            value={formatAsDateTime(opportunity.updatedDate) || ""}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "modified-date" }}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            id="modified-by"
            label="Updated By"
            value={opportunity.updatedBy || ""}
            variant="standard"
            InputLabelProps={{ shrink: true }}
            inputProps={{ "data-testid": "modified-by" }}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
          />
        </Grid>
      </Grid>
    );
  }, [opportunity.createdBy, opportunity.createdDate, opportunity.updatedBy, opportunity.updatedDate]);
}
