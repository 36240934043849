import { useEffect, useRef } from "react";
import * as L from "leaflet";
import { useMap, FeatureGroup } from "react-leaflet";

import { trimToScale } from "services";
import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";

import { EditControl } from "./EditControl";

L.Icon.Default.imagePath = "/leaflet_images/";
L.drawLocal.draw.toolbar.buttons.rectangle = "Spatial search in the selected region";

export function MapEditorSpatialSearch() {
  const { searchCriteria, setBounds } = useAdvancedSearchContext();
  const featureGroupRef = useRef<L.FeatureGroup>(new L.FeatureGroup());
  const rectangleRef = useRef<L.Rectangle | null>(null);
  const map = useMap();

  useEffect(() => {
    if (!searchCriteria.bounds) {
      rectangleRef.current?.removeFrom(map);
      rectangleRef.current = null;
      return;
    }

    if (!rectangleRef.current) {
      rectangleRef.current = new L.Rectangle(
        L.latLngBounds(
          [searchCriteria.bounds.southWest.latitude, searchCriteria.bounds.southWest.longitude],
          [searchCriteria.bounds.northEast.latitude, searchCriteria.bounds.northEast.longitude]
        ),
        {
          dashArray: [4, 8],
          color: "yellow",
        }
      );
      rectangleRef.current.addTo(featureGroupRef.current!);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCriteria.bounds]);

  const cleanRectangleRef = () => {
    if (rectangleRef.current) {
      rectangleRef.current.removeFrom(map);
      rectangleRef.current = null;
    }
  };

  const handleCreate = (event: L.DrawEvents.Created) => {
    if (event.layerType !== "rectangle") {
      return;
    }

    cleanRectangleRef();
    rectangleRef.current = event.layer as L.Rectangle;
    const latLng = rectangleRef.current.getBounds();

    if (!latLng) {
      setBounds(undefined);

      return;
    }

    const southWest = latLng.getSouthWest().wrap();
    const northEast = latLng.getNorthEast().wrap();

    setBounds({
      southWest: { latitude: trimToScale(southWest.lat, 6) || 0, longitude: trimToScale(southWest.lng, 6) || 0 },
      northEast: { latitude: trimToScale(northEast.lat, 6) || 0, longitude: trimToScale(northEast.lng, 6) || 0 },
    });
  };

  return (
    <FeatureGroup ref={featureGroupRef}>
      <EditControl
        position="bottomright"
        onCreated={handleCreate}
        draw={{
          marker: false,
          polygon: false,
          polyline: false,
          rectangle: {
            shapeOptions: {
              dashArray: [4, 8],
              color: "yellow",
            },
          },
          circle: false,
          circlemarker: false,
        }}
        edit={{ edit: false, remove: false, featureGroup: featureGroupRef.current }}
      />
    </FeatureGroup>
  );
}
