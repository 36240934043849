import { ChangeEvent, useMemo } from "react";
import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { ConditionalGrid } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType } from "features/opportunities/models";
import { nameOfFactory } from "services/utils";

export function AdditionalDetailsSection() {
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();

  return useMemo(() => {
    const nameof = nameOfFactory<OpportunityType>();

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.checked);
    };

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["watchlistPriority"] ?? true}>
            <TextField
              id={CardBodyLocators.WatchlistPriority}
              inputProps={{ "data-testid": CardBodyLocators.WatchlistPriority }}
              label="Watchlist Priority"
              name={nameof("watchlistPriority")}
              onChange={handleInputChange}
              value={opportunity.watchlistPriority || ""}
              error={Boolean(opportunity.watchlistPriority && formErrors.watchlistPriority)}
              helperText={
                opportunity.watchlistPriority && formErrors.watchlistPriority ? formErrors.watchlistPriority : undefined
              }
            />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["includeForMetrics"] ?? true}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id={CardBodyLocators.IncludeForMetrics}
                  name={nameof("includeForMetrics")}
                  onChange={handleCheckboxChange}
                  checked={opportunity.includeForMetrics ?? false}
                  inputProps={{ "data-testid": CardBodyLocators.IncludeForMetrics } as PropsWithTestId}
                />
              }
              label="Include for Metrics"
            />
          </FormGroup>
        </ConditionalGrid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["siteConditions"] ?? true}>
          <TextField
            id={CardBodyLocators.SiteConditions}
            inputProps={{ "data-testid": CardBodyLocators.SiteConditions }}
            label="Site Conditions"
            multiline
            rows={4}
            name={nameof("siteConditions")}
            onChange={handleInputChange}
            value={opportunity.siteConditions || ""}
            error={Boolean(opportunity.siteConditions && formErrors.siteConditions)}
            helperText={opportunity.siteConditions && formErrors.siteConditions ? formErrors.siteConditions : undefined}
          />
        </ConditionalGrid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["previousWorkSummary"] ?? true}>
          <TextField
            id={CardBodyLocators.PreviousWorkSummary}
            inputProps={{ "data-testid": CardBodyLocators.PreviousWorkSummary }}
            label="Previous Work Summary"
            multiline
            rows={4}
            name={nameof("previousWorkSummary")}
            onChange={handleInputChange}
            value={opportunity.previousWorkSummary || ""}
            error={Boolean(opportunity.previousWorkSummary && formErrors.previousWorkSummary)}
            helperText={
              opportunity.previousWorkSummary && formErrors.previousWorkSummary
                ? formErrors.previousWorkSummary
                : undefined
            }
          />
        </ConditionalGrid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["missingData"] ?? true}>
          <TextField
            id={CardBodyLocators.MissingData}
            inputProps={{ "data-testid": CardBodyLocators.MissingData }}
            label="Missing Data"
            multiline
            rows={4}
            name={nameof("missingData")}
            onChange={handleInputChange}
            value={opportunity.missingData || ""}
            error={Boolean(opportunity.missingData && formErrors.missingData)}
            helperText={opportunity.missingData && formErrors.missingData ? formErrors.missingData : undefined}
          />
        </ConditionalGrid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["notes"] ?? true}>
          <TextField
            id={CardBodyLocators.Notes}
            inputProps={{ "data-testid": CardBodyLocators.Notes }}
            label="Notes"
            multiline
            rows={4}
            name={nameof("notes")}
            onChange={handleInputChange}
            value={opportunity.notes || ""}
            error={Boolean(opportunity.notes && formErrors.notes)}
            helperText={opportunity.notes && formErrors.notes ? formErrors.notes : undefined}
          />
        </ConditionalGrid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.includeForMetrics,
    opportunity.missingData,
    opportunity.notes,
    opportunity.previousWorkSummary,
    opportunity.siteConditions,
    opportunity.watchlistPriority,
    formErrors.watchlistPriority,
    formErrors.includeForMetrics,
    formErrors.siteConditions,
    formErrors.previousWorkSummary,
    formErrors.missingData,
    formErrors.notes,
    opportunityAttributes,
  ]);
}
