import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { useMsal } from "@azure/msal-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Divider, IconButton, List, ListItem, Typography } from "@mui/material";
import dayjs from "dayjs";

import { CommentsLocators } from "@constants";
import { formatAsShortDate } from "services";
import { useAuthorization } from "context";
import { ExtendedPanel } from "components";
import { useOpportunityContext } from "features/opportunities/context";
import { useComment } from "features/opportunities/hooks";
import { CommentType } from "features/opportunities/models/comment";

import { AddEditComment } from "./AddEditComment";

interface CommentsPaneProps {
  toggleCommentsPane: Dispatch<SetStateAction<boolean>>;
}

const newComment: CommentType = {
  id: 0,
  opportunityId: "",
  comment: "",
};

export function CommentsPane({ toggleCommentsPane }: CommentsPaneProps) {
  const { opportunity } = useOpportunityContext();
  const [openDialog, setOpenDialog] = useState(false);
  const { selectedComment, setSelectedComment, runDeleteComment, sortButtonText, handleSortComments } =
    useComment(newComment);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openEditDeleteMenu = Boolean(anchorEl);
  const { isAdmin } = useAuthorization();
  const { accounts } = useMsal();

  let commentRefs = useRef<HTMLSpanElement[]>(Array(opportunity.comments?.length));
  const [expandComment, setExpandComment] = useState<boolean[]>(Array(opportunity.comments?.length).fill(false) ?? []);

  const handleOpenDialog = () => {
    setSelectedComment(newComment);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEditMenu = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleDelete = () => {
    if (selectedComment) {
      runDeleteComment(selectedComment.id);
    }
  };

  const onSaveAddEditDialog = () => {
    setSelectedComment(null);
    handleCloseDialog();
  };

  const toggleEditDeleteMenu = (event: React.MouseEvent<HTMLButtonElement>, comment: CommentType) => {
    setAnchorEl(event.currentTarget);
    setSelectedComment(comment);
  };

  const isTextOverflowing = (index: number) => {
    const maxHeightBeforeExpand = 96;
    return commentRefs.current[index]?.scrollHeight! > maxHeightBeforeExpand;
  };

  return useMemo(
    () => {
      return (
        <>
          <ExtendedPanel
            panel={CommentsLocators.PanelHeader}
            panelHeaderText="Comments"
            sortButtonText={sortButtonText}
            setCloseExtendedPanel={toggleCommentsPane}
            menuAnchorEl={anchorEl}
            setMenuAnchorEl={setAnchorEl}
            handleOpenDialog={handleOpenDialog}
            handleEditMenu={handleEditMenu}
            handleDelete={handleDelete}
            handleSort={handleSortComments}
          >
            {opportunity.comments && (
              <List id={CommentsLocators.CommentList} data-testid={CommentsLocators.CommentList} sx={{ p: 0, m: 0 }}>
                {opportunity.comments.map((comment, index) => (
                  <ListItem
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      alignSelf: "stretch",
                      p: 0,
                    }}
                    key={CommentsLocators.Comment + index}
                  >
                    <Box
                      id={CommentsLocators.Comment + index}
                      data-testid={CommentsLocators.Comment + index}
                      sx={{ display: "flex", alignItems: "center", alignSelf: "stretch" }}
                    >
                      <Typography
                        sx={{
                          flex: "1 0 0",
                          fontStyle: "normal",
                          fontSize: "0.875rem",
                          fontWeight: "400",
                          lineHeight: "1.313rem",
                          letterSpacing: "0.15px",
                          color: "rgba(0, 0, 0, 0.87)",
                        }}
                        data-testid={CommentsLocators.CommentHeader + index}
                      >
                        {comment.responsiblePerson?.firstName} {comment.responsiblePerson?.lastName} &bull;{" "}
                        {formatAsShortDate(comment.createdDate)}
                      </Typography>
                      {(isAdmin || comment.responsiblePerson?.email === accounts?.[0].username) && (
                        <Box sx={{ flexGrow: 0 }}>
                          <IconButton
                            id={CommentsLocators.EditDeleteButton + index}
                            data-testid={CommentsLocators.EditDeleteButton + index}
                            onClick={(event) => toggleEditDeleteMenu(event, comment)}
                            aria-controls={CommentsLocators.EditDeleteButton + index}
                            aria-haspopup="true"
                            aria-expanded={openEditDeleteMenu ? "true" : undefined}
                            sx={{ p: 0 }}
                          >
                            <MoreVertIcon sx={{ fill: "rgba(0, 0, 0, 0.87)" }} />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    <Typography
                      ref={(element) => (commentRefs.current[index] = element!)}
                      sx={{
                        alignSelf: "stretch",
                        fontSize: "0.875rem",
                        fontWeight: "400",
                        fontStyle: "normal",
                        lineHeight: "1.5rem",
                        letterSpacing: "0.15px",
                        color: "rgba(0, 0, 0, 0.87)",
                        mb: 2,
                        overflowWrap: "anywhere",
                        whiteSpace: "pre-line",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: expandComment[index] ? undefined : "-webkit-box",
                        WebkitLineClamp: "4",
                        WebkitBoxOrient: "vertical",
                        cursor: isTextOverflowing(index) ? "pointer" : "default",
                        pointerEvents: isTextOverflowing(index) ? undefined : "none",
                        paddingRight: "16px",
                      }}
                      data-testid={CommentsLocators.CommentContent + index}
                      onClick={() => {
                        let updatedExpandComment = [...expandComment];
                        updatedExpandComment[index] = !updatedExpandComment[index];
                        setExpandComment(updatedExpandComment);
                      }}
                    >
                      {comment.comment}
                    </Typography>
                    {!dayjs(comment.updatedDate).isSame(dayjs(comment.createdDate), "second") && (
                      <Typography
                        sx={{
                          flex: "1 0 0",
                          fontStyle: "normal",
                          fontSize: "0.75rem",
                          fontWeight: "400",
                          lineHeight: "1.125rem",
                          letterSpacing: "0.15px",
                          color: "rgba(0, 0, 0, 0.87)",
                          pb: 2,
                        }}
                        data-testid={CommentsLocators.CommentUpdatedDate + index}
                      >
                        Modified on {formatAsShortDate(comment.updatedDate)}
                      </Typography>
                    )}
                    {index !== opportunity.comments?.length! - 1 ? <Divider sx={{ mb: 4 }} flexItem /> : null}
                  </ListItem>
                ))}
              </List>
            )}
          </ExtendedPanel>
          <AddEditComment
            commentsOrder={sortButtonText}
            initialComment={selectedComment}
            shouldOpen={openDialog}
            onClose={handleCloseDialog}
            onSave={onSaveAddEditDialog}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toggleCommentsPane, openDialog, anchorEl, openEditDeleteMenu, onSaveAddEditDialog, opportunity.comments]
  );
}
