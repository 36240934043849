import { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { AlertLocators } from "@constants";
import { nameOfFactory } from "services";
import { useAlert } from "features/opportunities/hooks/useAlert";
import { AlertRecipientType, AlertType } from "features/opportunities/models";

interface AlertsDialogProps {
  alertsOrder: string;
  initialAlert: AlertType | null;
  shouldOpen: boolean;
  onClose: () => void;
}

export type ErrorType = {
  [Property in keyof AlertType]: string;
};

export function AddEditAlert({ initialAlert, shouldOpen, onClose, alertsOrder }: AlertsDialogProps) {
  const [open, toggleOpen] = useState(false);
  const {
    selectedAlert,
    isAlertValid,
    alertFormErrors,
    resetErrors,
    handleInputChange,
    handleDateChange,
    handleSubmit,
    alertRecipients,
    setAlertRecipients,
    recipientsOptions,
    notifiedUsersInputValue,
    setNotifiedUsersInputValue,
    validateNotifyListInput,
    notifyListCheckOptionEqualToValue,
    notifyListGetOptionLabel,
    notifyListHelperText,
  } = useAlert(initialAlert, alertsOrder);

  const handleCloseAddEditAlert = () => {
    onClose();
    resetErrors();
  };

  const handleSaveAlert = () => {
    if (selectedAlert && !isAlertValid(selectedAlert)) {
      return;
    }

    handleSubmit();
    onClose();
  };

  useEffect(() => {
    toggleOpen(shouldOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpen]);

  return useMemo(
    () => {
      const nameof = nameOfFactory<AlertType>();

      return (
        <Dialog
          open={open}
          onClose={handleCloseAddEditAlert}
          id={AlertLocators.NewAlertDialog}
          data-testid={AlertLocators.NewAlertDialog}
        >
          <DialogTitle id={AlertLocators.AlertDialogHeader} data-testid={AlertLocators.AlertDialogHeader}>
            {Number(selectedAlert?.id) === 0 ? "New Alert" : "Edit Alert"}
          </DialogTitle>
          <DialogContent sx={{ width: "600px" }}>
            <Box
              id={AlertLocators.NewAlert}
              data-testid={AlertLocators.NewAlert}
              sx={{ flexGrow: 1, display: "flex", gap: "1rem", flexDirection: "column", paddingTop: ".5rem" }}
            >
              <TextField
                id={AlertLocators.NewTitle}
                inputProps={{ "data-testid": AlertLocators.NewTitle }}
                required
                variant="outlined"
                fullWidth
                label="Title"
                value={selectedAlert?.title ?? ""}
                name={nameof("title")}
                onChange={handleInputChange}
                error={Boolean(selectedAlert?.title !== undefined && alertFormErrors.title)}
                helperText={
                  selectedAlert?.title !== undefined && alertFormErrors.title ? alertFormErrors.title : undefined
                }
                size="medium"
              />
              <TextField
                id={AlertLocators.NewDescription}
                inputProps={{ "data-testid": AlertLocators.NewDescription }}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                label="Description"
                name={nameof("description")}
                onChange={handleInputChange}
                value={selectedAlert?.description ?? ""}
                size="medium"
                required
                error={Boolean(selectedAlert?.description !== undefined && alertFormErrors.description)}
                helperText={
                  selectedAlert?.description !== undefined && alertFormErrors.description
                    ? alertFormErrors.description
                    : undefined
                }
              />
              <TextField
                id={AlertLocators.NewAction}
                inputProps={{ "data-testid": AlertLocators.NewAction }}
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                label="Action"
                name={nameof("action")}
                onChange={handleInputChange}
                value={selectedAlert?.action ?? ""}
                size="medium"
              />
              <DatePicker
                label="Alert Date"
                disablePast
                inputFormat="YYYY/MM/DD"
                value={selectedAlert?.alertDate}
                onChange={(newValue) => handleDateChange(nameof("alertDate"), newValue)}
                renderInput={(params) => (
                  <TextField
                    id={AlertLocators.NewAlertDate}
                    {...params}
                    inputProps={{ ...params.inputProps, "data-testid": AlertLocators.NewAlertDate }}
                    sx={{ width: "220px" }}
                    error={Boolean(alertFormErrors.alertDate)}
                    helperText={alertFormErrors.alertDate}
                    size="medium"
                    required
                  />
                )}
              />
              <Autocomplete
                id={AlertLocators.NewNotifyList}
                autoHighlight
                multiple
                filterSelectedOptions
                options={recipientsOptions}
                isOptionEqualToValue={notifyListCheckOptionEqualToValue}
                value={alertRecipients ?? []}
                getOptionLabel={notifyListGetOptionLabel}
                onChange={(_event: any, newValue: (string | AlertRecipientType)[] | null) => {
                  setAlertRecipients(newValue);
                }}
                onInputChange={(_event, newInputValue) => {
                  setNotifiedUsersInputValue(newInputValue);
                }}
                PaperComponent={(props) => {
                  return (
                    <Paper
                      id={AlertLocators.NewNotifyListSelect}
                      data-testid={AlertLocators.NewNotifyListSelect}
                      {...props}
                    />
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Notifications"
                    inputProps={{
                      ...params.inputProps,
                      "data-testid": AlertLocators.NewNotifyList,
                      onKeyDown: (e) => {
                        if (e.key === "Enter" && validateNotifyListInput()) {
                          e.stopPropagation();
                        }
                      },
                    }}
                    error={Boolean(alertFormErrors.notifyList)}
                    helperText={notifyListHelperText}
                    size="medium"
                  />
                )}
                freeSolo
                forcePopupIcon
                limitTags={2}
                getLimitTagsText={(moreOptionsSelected) => `(${moreOptionsSelected} more)`}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseAddEditAlert}
              variant="outlined"
              id={AlertLocators.CancelButton}
              data-testid={AlertLocators.CancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveAlert}
              id={AlertLocators.SaveButton}
              data-testid={AlertLocators.SaveButton}
              variant="contained"
              disabled={notifiedUsersInputValue !== ""}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialAlert, selectedAlert, open, alertFormErrors, recipientsOptions]
  );
}
