import { FormEvent, PropsWithChildren, ReactNode, useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  Collapse,
  IconButton,
  IconButtonProps,
  Fade,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import theme from "theme";

export function CollapsableCard(props: PropsWithChildren<CollapsableCardProps>) {
  const {
    children,
    cardId,
    cardHeaderId,
    cardHeaderText,
    collapsedHeaderId,
    collapseButtonId,
    closeButtonId,
    submitButtonId,
    submitButtonText,
    cancelButtonId,
    cancelButtonText,
    closeButtonHandler,
    submitButtonHandler,
    submitFormHandler,
    cancelButtonHandler,
    additionalControls,
    ...otherProps
  } = props;

  const [collapsed, setCollapsed] = useState(false);

  const handleExpandClick = () => {
    setCollapsed(!collapsed);
  };

  const closeButton = !!closeButtonHandler ? (
    <IconButton
      size="small"
      id={closeButtonId}
      data-testid={closeButtonId}
      sx={{ marginLeft: "1rem" }}
      onClick={closeButtonHandler}
    >
      <CloseIcon sx={{ fill: theme.palette.primary.main }} />
    </IconButton>
  ) : null;

  const actionButtons = (
    <>
      {additionalControls ?? null}
      {closeButton}
    </>
  );

  const cancelButton = !!cancelButtonHandler ? (
    <Fade in={!collapsed}>
      <Button
        id={cancelButtonId}
        data-testid={cancelButtonId}
        fullWidth
        variant="outlined"
        onClick={cancelButtonHandler}
        sx={{ width: "7.5em", marginLeft: "auto" }}
      >
        {cancelButtonText || "Cancel"}
      </Button>
    </Fade>
  ) : null;

  const submitButton =
    !!submitFormHandler || !!submitButtonHandler ? (
      <Fade in={!collapsed}>
        <Button
          id={submitButtonId}
          data-testid={submitButtonId}
          fullWidth
          type="submit"
          variant="contained"
          onClick={submitButtonHandler}
          sx={{ width: "7.5em" }}
        >
          {submitButtonText || "Submit"}
        </Button>
      </Fade>
    ) : null;

  const collapsableText = (
    <Fade in={collapsed}>
      <Typography
        id={collapsedHeaderId}
        data-testid={collapsedHeaderId}
        variant="h5"
        sx={{
          position: "absolute",
          color: grey.A700,
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%) scale(-1)",
          writingMode: "vertical-lr",
        }}
      >
        {cardHeaderText}
      </Typography>
    </Fade>
  );

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    !!submitFormHandler && submitFormHandler(e);
  };

  return (
    <Card
      id={cardId}
      data-testid={cardId}
      square
      sx={{ position: "relative" }}
      aria-expanded={!collapsed}
      {...otherProps}
    >
      <Collapse orientation="horizontal" in={!collapsed} collapsedSize="3.5rem" sx={{ height: "100%" }}>
        <Box
          component="form"
          onSubmit={onSubmit}
          sx={{ display: "flex", flexDirection: "column", height: "100%", width: 420, maxHeight: "100%" }}
        >
          {collapsableText}
          {cardHeaderText ? (
            <Fade in={!collapsed}>
              <CardHeader id={cardHeaderId} data-testid={cardHeaderId} title={cardHeaderText} action={actionButtons} />
            </Fade>
          ) : null}
          <Fade in={!collapsed}>
            <CardContent sx={{ flexGrow: 1, paddingTop: "1rem", overflow: "hidden overlay", height: 0 }}>
              {children}
            </CardContent>
          </Fade>
          <CardActions disableSpacing sx={{ gap: ".5rem" }}>
            <CollapseButton
              id={collapseButtonId}
              data-testid={collapseButtonId}
              aria-controls={cardId}
              collapsed={collapsed}
              onClick={handleExpandClick}
            >
              <ChevronRightIcon />
            </CollapseButton>
            {cancelButton}
            {submitButton}
          </CardActions>
        </Box>
      </Collapse>
    </Card>
  );
}

interface Props {
  cardId: string;
  cardHeaderId: string;
  cardHeaderText: ReactNode;
  closeButtonId: string;
  collapsedHeaderId: string;
  collapseButtonId: string;
  submitButtonId: string;
  submitButtonText: ReactNode;
  cancelButtonId: string;
  cancelButtonText: ReactNode;
  closeButtonHandler: () => void;
  submitButtonHandler: () => void;
  cancelButtonHandler: () => void;
  submitFormHandler: (e: FormEvent) => void;
  additionalControls?: JSX.Element;
}

interface CollapsableCardProps extends Partial<Props>, CardProps {}

interface CollapseButtonProps extends IconButtonProps {
  collapsed: boolean;
}

const CollapseButton = styled((props: CollapseButtonProps) => {
  const { collapsed, ...other } = props;
  return (
    <Tooltip title={collapsed ? "Expand" : "Collapse"} placement="right">
      <IconButton {...other} />
    </Tooltip>
  );
})(({ theme, collapsed }) => ({
  transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
