import { Suspense } from "react";
import { RouteObject } from "react-router-dom";

import { PATH } from "@constants";
import { Loading } from "components";
import { AuthorizedComponent } from "components/Security/AuthorizedRoute";
import { MapLayout } from "layouts";
import { CreateOpportunity, EditOpportunity, ViewOpportunity } from "pages";

export const OpportunityRoutes: RouteObject = {
  path: PATH.OPPORTUNITY,
  element: <MapLayout />,
  children: [
    {
      index: true,
      path: PATH.OPPORTUNITY,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={CreateOpportunity} />
        </Suspense>
      ),
    },
    {
      path: PATH.OPPORTUNITY_WITH_TYPE,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={CreateOpportunity} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_OPPORTUNITY,
      element: (
        <Suspense fallback={<Loading />}>
          <ViewOpportunity />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_OPPORTUNITY_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <ViewOpportunity initialOpenAlertsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.VIEW_OPPORTUNITY_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <ViewOpportunity initialOpenCommentsPane={true} />
        </Suspense>
      ),
    },
    {
      path: PATH.EDIT_OPPORTUNITY,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={EditOpportunity} />
        </Suspense>
      ),
    },
    {
      path: PATH.EDIT_OPPORTUNITY_ALERTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <EditOpportunity initialOpenAlertsPane={true} />} />
        </Suspense>
      ),
    },
    {
      path: PATH.EDIT_OPPORTUNITY_COMMENTS,
      element: (
        <Suspense fallback={<Loading />}>
          <AuthorizedComponent component={() => <EditOpportunity initialOpenCommentsPane={true} />} />
        </Suspense>
      ),
    },
  ],
};
