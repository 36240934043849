import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

import { CardHeaderLocators } from "@constants";

interface Props {
  onEdit: (id: string) => void;
}

export function EditOpportunityButton({ onEdit }: Props) {
  const { opportunityId } = useParams();

  const handleEdit = () => {
    if (opportunityId) {
      onEdit(opportunityId);
    }
  };

  return (
    <Button
      id={CardHeaderLocators.EditOpportunityButton}
      data-testid={CardHeaderLocators.EditOpportunityButton}
      variant="contained"
      onClick={handleEdit}
    >
      Edit
    </Button>
  );
}
