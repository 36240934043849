import { ChangeEvent, useEffect, useState } from "react";
import { AlertColor, FilterOptionsState, createFilterOptions } from "@mui/material";
import { AxiosError } from "axios";

import { SharedMessages } from "@constants";
import { UserSavedSearchWithCriteria } from "types";
import { ErrorResponse } from "models";
import { useReferenceDataContext, useSnackBarContext } from "context";
import { useAsync } from "hooks";

import { useAdvancedSearchContext } from "../context/AdvancedSearchContext";
import { convertAdvancedSearchModel, convertToAdvancedSearchModelType } from "../models";
import { UserSavedSearchType } from "../models/UserSavedSearch";
import {
  addUserSavedSearch,
  getUserSavedSearchById,
  getUserSavedSearches,
  removeUserSavedSearch,
  updateUserSavedSearch,
} from "../services";

export function useSavedSearch() {
  const [saveSearchName, setSaveSearchName] = useState("");
  const [showEmptyErrorMessage, setShowEmptyErrorMessage] = useState(false);
  const [addUserSavedSearchResponse, runAddUserSavedSearch] = useAsync(addUserSavedSearch);
  const [updateUserSavedSearchResponse, runUpdateUserSavedSearch] = useAsync(updateUserSavedSearch);
  const [removeUserSavedSearchResponse, runRemoveUserSavedSearch] = useAsync(removeUserSavedSearch);
  const [getByIdResponse, runGetUserSavedSearchById] = useAsync(getUserSavedSearchById);
  const { searchCriteria, replaceSearchCriteria, selectedSavedSearch, setSelectedSavedSearch } =
    useAdvancedSearchContext();
  const [userSavedSearchOptions, setUserSavedSearchOptions] = useState<UserSavedSearchType[]>([]);
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { referenceData } = useReferenceDataContext();

  const setSnackBarContentAndOpen = (msg: string, msgSeverity: AlertColor) => {
    setSnackBarContent({
      message: msg,
      severity: msgSeverity,
    });
    setOpen(true);
  };

  const setSnackbarError = (error: AxiosError<ErrorResponse, any> | undefined) => {
    if (!error) {
      return;
    }
    setSnackBarContentAndOpen(SharedMessages.SomethingWentWrong, "error");
    console.error(error);
  };

  const setSnackbarSuccess = (msg: string, status: "idle" | "loading" | "success" | "error") => {
    if (status !== "success") {
      return;
    }
    setSnackBarContentAndOpen(msg, "success");
  };

  const sortList = (list: UserSavedSearchType[]) => {
    return list.sort((a, b) => {
      const nameA = a.savedSearchName.toUpperCase();
      const nameB = b.savedSearchName.toUpperCase();
      return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
    });
  };

  useEffect(() => {
    getUserSavedSearches().then((response) => {
      if (response.data) {
        setUserSavedSearchOptions(response.data);
        setShowEmptyErrorMessage(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSnackbarError(addUserSavedSearchResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserSavedSearchResponse.error]);

  useEffect(() => {
    setSnackbarError(removeUserSavedSearchResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeUserSavedSearchResponse.error]);

  useEffect(() => {
    setSnackbarError(updateUserSavedSearchResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserSavedSearchResponse.error]);

  const filter = createFilterOptions<UserSavedSearchType>();

  const filteredOptions = (options: UserSavedSearchType[], params: FilterOptionsState<UserSavedSearchType>) => {
    const filtered = filter(options, params);
    if (params.inputValue) {
      filtered.push({
        id: 0,
        inputValue: `Add "${params.inputValue}"`,
        savedSearchName: params.inputValue,
      });
    }
    return filtered;
  };

  const submitUserSavedSearch = () => {
    if (!selectedSavedSearch) {
      setShowEmptyErrorMessage(true);
      return;
    }
    const searchToSave: UserSavedSearchWithCriteria = {
      id: selectedSavedSearch.id,
      savedSearchName: selectedSavedSearch.savedSearchName,
      searchQuery: convertAdvancedSearchModel(searchCriteria),
    };
    if (searchToSave.id === 0) {
      runAddUserSavedSearch(searchToSave);
    } else {
      runUpdateUserSavedSearch(searchToSave.id, searchToSave);
    }
  };

  const handleDeleteUserSavedSearch = () => {
    if (selectedSavedSearch) {
      runRemoveUserSavedSearch(selectedSavedSearch.id);
    }
  };

  useEffect(() => {
    setSnackbarSuccess("Search has been deleted.", removeUserSavedSearchResponse.status);
    const newOptions = userSavedSearchOptions.filter((x) => x.id !== selectedSavedSearch?.id);
    setUserSavedSearchOptions(newOptions);
    setSelectedSavedSearch(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeUserSavedSearchResponse.status]);

  useEffect(() => {
    setSnackbarSuccess("Search has been saved.", addUserSavedSearchResponse.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserSavedSearchResponse.status]);

  useEffect(() => {
    if (addUserSavedSearchResponse.data) {
      const newList = userSavedSearchOptions.filter((x) => x.id !== addUserSavedSearchResponse.data?.id);
      setSelectedSavedSearch(addUserSavedSearchResponse.data);
      newList.push(addUserSavedSearchResponse.data);
      setUserSavedSearchOptions(sortList(newList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserSavedSearchResponse.data]);

  useEffect(() => {
    setSnackbarSuccess("Search has been updated.", updateUserSavedSearchResponse.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserSavedSearchResponse.status]);

  useEffect(() => {
    if (addUserSavedSearchResponse.data) {
      const newList = userSavedSearchOptions.filter((x) => x.id !== addUserSavedSearchResponse.data?.id);
      newList.push(addUserSavedSearchResponse.data);
      setUserSavedSearchOptions(sortList(newList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUserSavedSearchResponse.data]);

  const handleSavedSearchChange = (_event: ChangeEvent<{}>, newValue: UserSavedSearchType | null) => {
    if (newValue && newValue.id !== 0) {
      runGetUserSavedSearchById(newValue.id);
    }
    setSelectedSavedSearch(newValue);
    setShowEmptyErrorMessage(false);
  };

  useEffect(() => {
    setSnackbarError(getByIdResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdResponse.error]);

  useEffect(() => {
    if (getByIdResponse.data) {
      const selectedSavedSearchCriteria = convertToAdvancedSearchModelType(
        getByIdResponse.data.searchQuery,
        referenceData
      );
      replaceSearchCriteria(selectedSavedSearchCriteria);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getByIdResponse.data]);

  return {
    userSavedSearchOptions,
    selectedSavedSearch,
    saveSearchName,
    showEmptyErrorMessage,
    submitUserSavedSearch,
    setSaveSearchName,
    filteredOptions,
    handleSavedSearchChange,
    setSelectedSavedSearch,
    handleDeleteUserSavedSearch,
  };
}
