import { ChangeEvent, useMemo } from "react";
import { TextField } from "@mui/material";

import { CardBodyLocators } from "@constants";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

export function OpportunityName() {
  const { opportunity, formErrors, updateField } = useOpportunityContext();

  return useMemo(() => {
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.value);
    };

    return (
      <TextField
        required
        id={CardBodyLocators.OpportunityName}
        label="Name"
        name={opportunityTypeOfFactory("opportunityName")}
        onChange={handleInputChange}
        value={opportunity.opportunityName}
        inputProps={{ "data-testid": CardBodyLocators.OpportunityName }}
        error={Boolean(opportunity.opportunityName && formErrors.opportunityName)}
        helperText={opportunity.opportunityName && formErrors.opportunityName ? formErrors.opportunityName : undefined}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.opportunityName, formErrors.opportunityName]);
}
