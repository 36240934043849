import { ChangeEvent, useEffect, useState } from "react";
import { SharedMessages } from "@constants/messages";
import { AlertColor } from "@mui/material";
import dayjs from "dayjs";

import { useSnackBarContext } from "context";
import { useAsync } from "hooks";

import { NoErrors, useOpportunityContext } from "../context";
import { CommentType, validateComment } from "../models";
import { ActionTypes } from "../reducers";
import { addComment, deleteComment, updateComment } from "../services";
import { formatZodErrors } from "./useValidation";

export type ErrorType = {
  [Property in keyof CommentType]: string;
};

export function useComment(initialComment?: CommentType | null, commentsOrder?: string) {
  const [addResponse, runAddComment] = useAsync(addComment);
  const [selectedComment, setSelectedComment] = useState<CommentType | null>(null);
  const [deleteCommentResponse, runDeleteComment] = useAsync(deleteComment);
  const [updateCommentResponse, runUpdateComment] = useAsync(updateComment);
  const [commentFormErrors, setCommentFormErrors] = useState<ErrorType>({} as ErrorType);
  const [sortButtonText, setSortButtonText] = useState<string>(commentsOrder ?? "Recent First");
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { opportunity, dispatch } = useOpportunityContext();

  const setSnackBarContentAndOpen = (msg: string, msgSeverity: AlertColor) => {
    setSnackBarContent({
      message: msg,
      severity: msgSeverity,
    });
    setOpen(true);
  };

  const isCommentValid = (comment: CommentType) => {
    const result = validateComment(comment);
    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setCommentFormErrors(formattedErrors);
    }
    return result.success;
  };

  function updateComments(newCommentList: CommentType[]) {
    dispatch({
      type: ActionTypes.REPLACE_COMMENTS,
      payload: newCommentList,
    });
  }

  const handleSubmit = () => {
    if (!selectedComment) {
      return;
    }

    if (selectedComment.id === 0) {
      const commentToAdd: CommentType = {
        ...selectedComment,
        opportunityId: opportunity.id,
      };
      runAddComment(commentToAdd);
      return;
    }
    handleUpdate();
  };

  const handleUpdate = () => {
    if (!selectedComment) {
      return;
    }
    runUpdateComment(selectedComment.id, selectedComment);
    let commentsList = opportunity.comments || [];
    const selectedCommentIndex = commentsList.findIndex((comment) => comment.id === selectedComment.id);
    commentsList[selectedCommentIndex].comment = selectedComment.comment;
    commentsList[selectedCommentIndex].updatedDate = dayjs(new Date());
    updateComments(commentsList);
  };

  const toggleCommentsPath = (openCommentsPane: any, currentPath: string) => {
    return openCommentsPane ? currentPath.replace("/comments", "") : `${currentPath}/comments`;
  };

  const resetErrors = () => {
    setCommentFormErrors(NoErrors);
  };

  const handleSortComments = () => {
    setSortButtonText(sortButtonText === "Recent First" ? "Oldest First" : "Recent First");
    updateComments(opportunity.comments?.reverse()!);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setSelectedComment((prevState) => ({
      ...prevState!,
      [name]: value.trimStart(),
    }));
    resetErrors();
  };

  useEffect(() => {
    if (initialComment) {
      setSelectedComment(initialComment);
    }
  }, [initialComment]);

  useEffect(() => {
    if (addResponse.status !== "error") {
      return;
    }
    if (addResponse.validationErrors) {
      setCommentFormErrors((prev) => ({ ...prev, ...addResponse.validationErrors }));
      return;
    }
    setSnackBarContentAndOpen("Something went wrong. See console for details.", "error");
    console.error(addResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.error, addResponse.status]);

  useEffect(() => {
    if (addResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Comment has been added to opportunity.", "success");

    let commentsList: CommentType[] = opportunity.comments ?? [];
    if (commentsOrder === "Recent First") {
      commentsList.unshift(addResponse.data!);
    } else {
      commentsList.push(addResponse.data!);
    }

    updateComments(commentsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addResponse.data, addResponse.status]);

  useEffect(() => {
    if (deleteCommentResponse.status !== "error") {
      return;
    }
    setSnackBarContentAndOpen(SharedMessages.SomethingWentWrong, "error");
    console.error(deleteCommentResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommentResponse.error, deleteCommentResponse.status]);

  useEffect(() => {
    if (deleteCommentResponse.status !== "success") {
      let commentsList: CommentType[] = opportunity.comments ?? [];
      let updatedCommentsList = commentsList.filter((comment) => comment !== selectedComment);
      updateComments(updatedCommentsList);
      return;
    }
    setSnackBarContentAndOpen("Opportunity Comment has been removed.", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCommentResponse.data, deleteCommentResponse.status]);

  useEffect(() => {
    if (updateCommentResponse.status !== "error") {
      return;
    }
    setSnackBarContentAndOpen(SharedMessages.SomethingWentWrong, "error");
    console.error(updateCommentResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommentResponse.error, updateCommentResponse.status]);

  useEffect(() => {
    if (updateCommentResponse.status !== "success") {
      return;
    }
    setSnackBarContentAndOpen("Opportunity Comment has been updated.", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCommentResponse.data, updateCommentResponse.status]);

  return {
    selectedComment,
    setSelectedComment,
    addResponse,
    runAddComment,
    deleteCommentResponse,
    runDeleteComment,
    updateComment,
    runUpdateComment,
    isCommentValid,
    commentFormErrors,
    resetErrors,
    sortButtonText,
    handleSortComments,
    handleInputChange,
    handleSubmit,
    toggleCommentsPath,
  };
}
