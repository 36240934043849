import { ChangeEvent, useMemo, useState } from "react";
import {
  Autocomplete,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { delimiterSeparatedStringToArray, nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import { ConditionalGrid, referenceDataOpportunitySubTypeMenuItems } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { useCustomFields } from "features/opportunities/hooks";
import { OpportunityType } from "features/opportunities/models";

import { OpportunityCode } from "./GeneralDetails/OpportunityCode";
import { OpportunityName } from "./GeneralDetails/OpportunityName";
import { OpportunityTypeSelect } from "./GeneralDetails/OpportunityTypeSelect";
import { ResponsibleGroup } from "./GeneralDetails/ResponsibleGroup";
import { ResponsiblePerson } from "./GeneralDetails/ResponsiblePerson";

export function GeneralDetailsSection() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();
  const { customAlternateNames } = useCustomFields();
  const nameof = nameOfFactory<OpportunityType>();
  const [alternateNameInputValue, setAlternateNameInputValue] = useState("");

  return useMemo(() => {
    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleAutocompleteInputChange = (name: string, value: string[], delimiter: string) => {
      const flattenArray = value.map((item) => delimiterSeparatedStringToArray(delimiter, item)).flat(2);
      const uniqueValues = Array.from(new Set(flattenArray));
      updateField(name, uniqueValues.join(delimiter));
    };

    const opportunityType = referenceData?.opportunityTypes?.find((item) => item.id === opportunity.opportunityTypeId);
    const subType = opportunityType?.opportunitySubTypes?.find((item) => item.id === opportunity.opportunitySubTypeId);

    const opportunitySubTypeId = (subType && opportunity.opportunitySubTypeId) || 0;

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityCode"] ?? true}>
            <OpportunityCode />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityName"] ?? true}>
            <OpportunityName />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["opportunityAlternateNames"] ?? true}>
          <Autocomplete
            id={CardBodyLocators.AlternateNames}
            data-testid={CardBodyLocators.AlternateNamesSelect}
            multiple
            freeSolo
            limitTags={3}
            getLimitTagsText={(number) => <Chip label={`+${number} more`} variant="outlined" />}
            options={[]}
            value={customAlternateNames}
            onChange={(_event: any, newValue: string[]) =>
              handleAutocompleteInputChange(nameof("opportunityAlternateNames"), newValue, ";")
            }
            inputValue={alternateNameInputValue}
            onInputChange={(_event, newInputValue) => {
              if (newInputValue.indexOf(";") > 0) {
                handleAutocompleteInputChange(
                  nameof("opportunityAlternateNames"),
                  [...customAlternateNames, newInputValue.replace(";", "")],
                  ";"
                );
                setAlternateNameInputValue("");
              } else {
                setAlternateNameInputValue(newInputValue);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Alternate Names"
                inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.AlternateNames }}
                error={Boolean(opportunity.opportunityAlternateNames && formErrors.opportunityAlternateNames)}
                helperText={
                  opportunity.opportunityAlternateNames && formErrors.opportunityAlternateNames
                    ? formErrors.opportunityAlternateNames
                    : undefined
                }
              />
            )}
          />
        </ConditionalGrid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["opportunityDescription"] ?? true}>
          <TextField
            id={CardBodyLocators.Description}
            label="Description"
            multiline
            rows={4}
            name={nameof("opportunityDescription")}
            onChange={handleInputChange}
            value={opportunity.opportunityDescription || ""}
            inputProps={{ "data-testid": CardBodyLocators.Description }}
            error={Boolean(opportunity.opportunityDescription && formErrors.opportunityDescription)}
            helperText={
              opportunity.opportunityDescription && formErrors.opportunityDescription
                ? formErrors.opportunityDescription
                : undefined
            }
          />
        </ConditionalGrid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityTypeId"] ?? true}>
            <OpportunityTypeSelect />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunitySubTypeId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.OpportunitySubType} data-testid={CardBodyLocators.OpportunitySubType}>
                Sub-type
              </InputLabel>
              <Select
                labelId={CardBodyLocators.OpportunitySubType}
                id={CardBodyLocators.OpportunitySubTypeSelect}
                label="Sub-type"
                name={nameof("opportunitySubTypeId")}
                onChange={handleSelectionChange}
                value={opportunitySubTypeId}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.OpportunitySubTypeSelect } as PropsWithTestId}
                error={Boolean(opportunity.opportunitySubTypeId && formErrors.opportunitySubTypeId)}
              >
                {referenceDataOpportunitySubTypeMenuItems(
                  opportunity.opportunityTypeId,
                  referenceData?.opportunityTypes,
                  "opp-sub-types-"
                )}
              </Select>
              {Number(opportunity.opportunitySubTypeId) > 0 && formErrors.opportunitySubTypeId && (
                <FormHelperText>{formErrors.opportunitySubTypeId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["responsiblePersonId"] ?? true}>
            <ResponsiblePerson />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["responsibleGroupId"] ?? true}>
            <ResponsibleGroup />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["group"] ?? true}>
          <TextField
            id={CardBodyLocators.Group}
            label="Group"
            name={nameof("group")}
            onChange={handleInputChange}
            value={opportunity.group || ""}
            inputProps={{ "data-testid": CardBodyLocators.Group }}
            error={Boolean(opportunity.group && formErrors.group)}
            helperText={opportunity.group && formErrors.group ? formErrors.group : undefined}
          />
        </ConditionalGrid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.opportunityAlternateNames,
    opportunity.opportunityDescription,
    opportunity.group,
    opportunity.responsibleGroupId,
    opportunity.opportunityTypeId,
    opportunity.opportunitySubTypeId,
    referenceData?.opportunityTypes,
    customAlternateNames,
    formErrors.opportunityAlternateNames,
    formErrors.opportunityDescription,
    formErrors.opportunitySubTypeId,
    formErrors.responsibleGroupId,
    formErrors.group,
    alternateNameInputValue,
    opportunityAttributes,
  ]);
}
