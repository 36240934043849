import { ReactNode, StrictMode } from "react";
import { AuthenticationResult, AuthError, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { RouterProvider } from "react-router-dom";

import { ReferenceDataProvider, SnackBarContextProvider, TooltipContextProvider } from "context";
import { SnackBar, Tooltip, UnauthenticatedComponent } from "components";
import { AuthorizationProvider } from "context/AuthorizationContext";
import { router } from "routes/routes";
import theme from "theme";

import "./index.css";

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID || "",
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

msalInstance.addEventCallback((message: EventMessage) => {
  if (message.eventType === EventType.LOGIN_FAILURE) {
    if (message.error instanceof AuthError) {
      //TODO add implementation when the error handling is done
      console.error(message);
    }
  }
});

export function getRootContainer(r: typeof router): ReactNode {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StrictMode>
        <MsalProvider instance={msalInstance}>
          <AuthenticatedTemplate>
            <AuthorizationProvider>
              <SnackBarContextProvider>
                <TooltipContextProvider>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ReferenceDataProvider>
                      <RouterProvider router={r} />
                    </ReferenceDataProvider>
                  </LocalizationProvider>
                  <Tooltip />
                </TooltipContextProvider>
                <SnackBar />
              </SnackBarContextProvider>
            </AuthorizationProvider>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <UnauthenticatedComponent />
          </UnauthenticatedTemplate>
        </MsalProvider>
      </StrictMode>
    </ThemeProvider>
  );
}
