import { useMemo } from "react";
import { Autocomplete, Paper, TextField } from "@mui/material";

import { CardBodyLocators } from "@constants";
import { useOpportunityContext } from "features/opportunities/context";
import { useSearchResponsiblePersons } from "features/opportunities/hooks";
import { ResponsiblePersonType } from "features/opportunities/models";

export function ResponsiblePerson() {
  const { opportunity, formErrors } = useOpportunityContext();
  const { options, setInputValue, responsiblePersonValue, onValueChange, inputValue } = useSearchResponsiblePersons();

  return useMemo(() => {
    return (
      <Autocomplete
        id={CardBodyLocators.ResponsiblePerson}
        autoHighlight
        autoComplete
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        options={options}
        noOptionsText={inputValue ? "No Options" : "Start typing to search"}
        getOptionLabel={(option) => (typeof option === "string" ? option : option.firstName + " " + option.lastName)}
        value={responsiblePersonValue}
        onChange={(_event: any, newValue: ResponsiblePersonType | null) => {
          onValueChange(newValue);
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        PaperComponent={(props) => {
          return (
            <Paper
              id={CardBodyLocators.ResponsiblePersonSelect}
              data-testid={CardBodyLocators.ResponsiblePersonSelect}
              {...props}
            />
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Responsible Person"
            inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.ResponsiblePerson }}
            error={Boolean(opportunity.responsiblePersonId && formErrors.responsiblePersonId)}
            helperText={
              opportunity.responsiblePersonId > 0 && formErrors.responsiblePersonId
                ? formErrors.responsiblePersonId
                : undefined
            }
          />
        )}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.responsiblePersonId, formErrors.responsiblePersonId, options, responsiblePersonValue]);
}
