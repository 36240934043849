import { Box } from "@mui/material";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";

import { ConfirmationModalProvider } from "context";
import { MenuAppBar, NavigationMenu } from "components";

export function MainLayout() {
  return (
    <Box sx={{ overflowX: "auto", overflowY: "hidden", display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ minWidth: 1366, display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <MenuAppBar />
        <NavigationMenu />
        <Container maxWidth="lg" sx={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
          <ConfirmationModalProvider>
            <Outlet />
          </ConfirmationModalProvider>
        </Container>
      </Box>
    </Box>
  );
}
