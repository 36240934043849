import { memo, useEffect, useRef, useState } from "react";
import { Popper, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { OwnershipLocators, Mode } from "@constants";
import { formatAsShortDate } from "services";

interface GridCellExpandProps {
  value: string;
  width: number;
  datatestid: string;
}

function isOverflown(element: Element): boolean {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

const GridCellExpand = memo(function GridCellExpand(props: GridCellExpandProps) {
  const { width, value, datatestid } = props;
  const wrapper = useRef<HTMLDivElement | null>(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current!);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent: KeyboardEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        data-testid={datatestid}
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper open={showFullCell && anchorEl !== null} anchorEl={anchorEl} style={{ width }}>
          <Paper elevation={1} style={{ minHeight: wrapper.current!.offsetHeight - 3 }}>
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function renderCellExpand(params: GridRenderCellParams<string>, datatestid: string) {
  return <GridCellExpand value={params.value || ""} width={params.colDef.computedWidth} datatestid={datatestid} />;
}

export const OwnershipColumns = (mode: Mode): GridColDef[] => {
  let ownerNameWidth = 120;
  if (mode === Mode.View) {
    ownerNameWidth = 160;
  }
  return [
    {
      field: "ownerName",
      headerName: "Owner Name",
      width: ownerNameWidth,
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string>) => renderCellExpand(params, OwnershipLocators.OwnerNameColumn),
    },
    {
      field: "ownerShareRate",
      headerName: "%",
      width: 55,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams<string>) => renderCellExpand(params, OwnershipLocators.ShareRateColumn),
    },
    {
      field: "ownershipFromDate",
      headerName: "From",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ value }) => formatAsShortDate(value) || "",
      renderCell: (params: GridRenderCellParams<string>) => renderCellExpand(params, OwnershipLocators.FromDateColumn),
    },
    {
      field: "ownershipToDate",
      headerName: "To",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      valueGetter: ({ value }) => formatAsShortDate(value) || "",
      renderCell: (params: GridRenderCellParams<string>) => renderCellExpand(params, OwnershipLocators.ToDateColumn),
    },
  ];
};
