import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

export function UnauthenticatedComponent() {
  const { instance } = useMsal();

  useEffect(() => {
    const scopes = process.env.REACT_APP_AUTH_SCOPES?.split(",") || [];
    instance.loginRedirect({ scopes });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
