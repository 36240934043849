import { Button } from "@mui/material";

import { CardHeaderLocators } from "@constants";
import { useValidation, useSubmitOpportunityDispatcher } from "features/opportunities/hooks";

interface Props {
  onSubmitted?: (id?: string) => void;
}

export function SubmitOpportunityButton({ onSubmitted }: Props) {
  useValidation();
  const { submitClickHandler, disabled } = useSubmitOpportunityDispatcher(onSubmitted);

  return (
    <Button
      id={CardHeaderLocators.UpsertOpportunityButton}
      data-testid={CardHeaderLocators.UpsertOpportunityButton}
      variant="contained"
      onClick={submitClickHandler}
      disabled={disabled}
    >
      Submit
    </Button>
  );
}
