import { useEffect, ReactNode, useState } from "react";
import { GridSelectionModel } from "@mui/x-data-grid";

import { Mode } from "@constants";
import { useConfirmationModalContext } from "context";
import { AddEditOwnership } from "features/opportunities/components";
import { NoErrors, useOpportunityContext } from "features/opportunities/context";
import { OwnershipType, validateOwnership, validateOwnershipList } from "features/opportunities/models";
import { ActionTypes } from "features/opportunities/reducers";

import { initialOwnershipState, useOwnershipFields } from "./useOwnershipFields";
import { formatZodErrors } from "./useValidation";

export function useOwnershipActions() {
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [dialogBody, setDialogBody] = useState<ReactNode>(null);
  const [confirmButtonText, setConfirmButtonText] = useState<string>("");
  const [cancelButtonText, setCancelButtonText] = useState<string>("");

  const [ownershipMode, setOwnershipMode] = useState<string>(Mode.Add);
  const [disableDelete, setDisableDelete] = useState(true);
  const [disableAddEdit, setDisableAddEdit] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [ownershipFields, setOwnershipFields] = useOwnershipFields(0, "", 0, "", 0, "", "");
  const { opportunity, dispatch, setFormErrors } = useOpportunityContext();

  const confirmationModalContext = useConfirmationModalContext();

  useEffect(() => {
    if (ownershipMode !== Mode.Edit) {
      return;
    }
    setDialogBody(
      <AddEditOwnership ownershipMode={ownershipMode} fields={ownershipFields} setFields={setOwnershipFields} />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ownershipFields]);

  const onCloseDialog = () => {
    setFormErrors(NoErrors);
    setDialogVisible(false);
  };

  function getAddEditOwnership(): OwnershipType {
    let addEditId: number | undefined;
    let isNewOwnership: boolean;

    if (ownershipFields.id !== undefined) {
      addEditId = ownershipFields.id;
      isNewOwnership = ownershipFields.id <= 0;
    } else {
      const ids = opportunity.ownerships?.map(function (o) {
        return o.id ?? 0;
      });
      addEditId = ids !== undefined && ids.length > 0 ? Math.min(...ids) - 1 : 0;
      isNewOwnership = true;
    }

    const addEditOwnership: OwnershipType = {
      id: addEditId,
      ownerId: ownershipFields.ownerId ? ownershipFields.ownerId : 0,
      ownerName: ownershipFields.ownerName ? ownershipFields.ownerName : "",
      ownerShareRate: ownershipFields.ownerShareRate ? ownershipFields.ownerShareRate : 0,
      ownershipFromDate: ownershipFields.ownershipFromDate,
      ownershipToDate: ownershipFields.ownershipToDate,
      isDeleted: false,
      isNew: isNewOwnership,
    };

    return addEditOwnership;
  }

  const isOwnershipValid = (ownership: OwnershipType) => {
    const result = validateOwnership(ownership);
    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setFormErrors(formattedErrors);
    }
    return result.success;
  };

  const isOwnershipListValid = (ownershipList: OwnershipType[]) => {
    const isValidList = validateOwnershipList(ownershipList);
    if (!isValidList.success) {
      const formattedErrors = formatZodErrors(isValidList.error.issues);
      setFormErrors(formattedErrors);
    }
    return isValidList.success;
  };

  const onConfirmDialog = () => {
    const ownership = getAddEditOwnership();
    if (!isOwnershipValid(ownership)) {
      return;
    }

    let newOwnershipList = [] as OwnershipType[];
    if (opportunity.ownerships) {
      newOwnershipList = opportunity.ownerships.filter((x) => x.id !== ownership.id);
    }
    newOwnershipList.push(ownership);
    if (!isOwnershipListValid(newOwnershipList)) {
      return;
    }

    dispatch({
      type: ActionTypes.REPLACE_OWNERSHIPS,
      payload: newOwnershipList,
    });
    setDialogVisible(false);
    confirmationModalContext.setCanShow(true);
  };

  const deleteRows = () => {
    const selectedIDs = new Set(selectionModel);
    if (!opportunity.ownerships) {
      return;
    }
    let newOwnershipList = [] as OwnershipType[];
    opportunity.ownerships.forEach((ownership) => {
      if (!selectedIDs.has(ownership.id)) {
        newOwnershipList.push(ownership);
        return;
      }
      if (ownership.isNew) {
        return;
      }
      ownership.isDeleted = true;
      newOwnershipList.push(ownership);
    });
    dispatch({ type: ActionTypes.REPLACE_OWNERSHIPS, payload: newOwnershipList });
    confirmationModalContext.setCanShow(true);
  };

  const addRow = () => {
    setDialogBody(
      <AddEditOwnership ownershipMode={ownershipMode} fields={ownershipFields} setFields={setOwnershipFields} />
    );
    setOwnershipFields(initialOwnershipState);
    setDialogTitle("Add new ownership");
    setConfirmButtonText("Add");
    setCancelButtonText("Cancel");
    setDialogVisible(true);
  };

  const editRow = () => {
    setDialogBody(
      <AddEditOwnership ownershipMode={ownershipMode} fields={ownershipFields} setFields={setOwnershipFields} />
    );
    setCancelButtonText("Cancel");
    setDialogTitle("Edit ownership");
    setConfirmButtonText("Save");
    const selectedIDs = new Set(selectionModel);
    const selectedRow = opportunity.ownerships?.filter((row) => row.id !== undefined && selectedIDs.has(row.id));
    if (selectedRow !== undefined && selectedRow.length > 0) {
      setOwnershipFields((prevState) => ({
        ...prevState,
        id: selectedRow[0].id,
        ownerId: selectedRow[0].ownerId,
        ownerName: selectedRow[0].ownerName,
        ownerType: selectedRow[0].ownerType,
        ownerShareRate: selectedRow[0].ownerShareRate,
        ownershipFromDate: selectedRow[0].ownershipFromDate,
        ownershipToDate: selectedRow[0].ownershipToDate,
      }));
    }
    setDialogVisible(true);
  };

  const selectionChanged = (newSelectionModel: GridSelectionModel) => {
    setSelectionModel(newSelectionModel);
    if (newSelectionModel.length === 0) {
      setOwnershipMode(Mode.Add);
      setDisableDelete(true);
      setDisableAddEdit(false);
      return;
    }
    if (newSelectionModel.length === 1) {
      setOwnershipMode(Mode.Edit);
      setDisableDelete(false);
      setDisableAddEdit(false);
      return;
    }
    setDisableDelete(false);
    setDisableAddEdit(true);
  };

  return {
    showModal: dialogVisible,
    dialogTitle,
    dialogBody,
    confirmButtonText,
    cancelButtonText,
    selectionModel,
    ownershipMode,
    ownershipFields,
    disableDelete,
    disableAddEdit,
    onCloseDialog,
    onConfirmDialog,
    deleteRows,
    addRow,
    editRow,
    selectionChanged,
  };
}
