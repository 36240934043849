import { useMemo, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionSummary, Box, Typography } from "@mui/material";

import { BulkUploadLocators, SharedMessages } from "@constants";
import { BulkUploadTotalResult } from "types";
import theme from "theme";

import { UploadResult } from "./UploadResult";

interface Props {
  uploadResult?: BulkUploadTotalResult;
  apiMessage?: string;
}

export function UploadResultList({ uploadResult, apiMessage }: Props) {
  const [expanded, setExpanded] = useState(true);
  const toggleExpanded = () => {
    setExpanded((prevExpandedValue) => !prevExpandedValue);
  };

  const errorMessageLabel = (
    <Accordion
      onChange={toggleExpanded}
      sx={{ display: "block", backgroundColor: theme.palette.error.light, padding: "0rem, 1rem" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        id={BulkUploadLocators.ErrorLabel}
        data-testid={BulkUploadLocators.ErrorLabel}
        key={BulkUploadLocators.ErrorLabel}
        sx={{ color: "white" }}
      >
        {apiMessage ?? SharedMessages.SomethingWentWrongSeeErrors}
      </AccordionSummary>
    </Accordion>
  );

  return useMemo(() => {
    return (
      <Box
        sx={{
          overflowX: "auto",
          height: "100px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "1rem 0",
          gap: "1rem",
          overflowY: "overlay",
        }}
      >
        {uploadResult && uploadResult.isSuccessful && (
          <Box display="flex" gap="1rem" padding="1rem">
            <Box sx={{ backgroundColor: theme.palette.secondary.light, padding: "1rem" }}>
              <Typography
                variant="h6"
                id={BulkUploadLocators.SuccessLabel}
                data-testid={BulkUploadLocators.SuccessLabel}
                color="white"
              >
                {SharedMessages.FileProcessedSuccessfully}
              </Typography>
            </Box>
          </Box>
        )}
        <Box gap="1rem" padding="1rem" id={BulkUploadLocators.ErrorResultWrapper}>
          {((uploadResult && !uploadResult.isSuccessful) || apiMessage) && errorMessageLabel}
          {uploadResult &&
            !uploadResult.isSuccessful &&
            uploadResult.results.map((item, index) => (
              <Box
                sx={{ display: "block", padding: ".5rem 0" }}
                key={`${BulkUploadLocators.ErrorResultBlock}-${item.entityId}`}
              >
                <UploadResult result={item} key={item.entityId} initialExpanded={expanded} resultIndex={index + 1} />
              </Box>
            ))}
        </Box>
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadResult, apiMessage, expanded]);
}
