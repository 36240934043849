import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";

import { CardBodyLocators, OwnershipLocators, Mode, UTILS } from "@constants";
import { useConfirmationModalContext } from "context";
import { ConfirmationModal } from "components";
import { ConditionalGrid } from "features/opportunities/components";
import { OwnershipColumns } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";
import { useOwnershipActions } from "features/opportunities/hooks";
import { OwnershipType } from "features/opportunities/models";

export function OwnershipSection() {
  const confirmationModalContext = useConfirmationModalContext();
  const { opportunity, mode, opportunityAttributes } = useOpportunityContext();
  const {
    showModal,
    dialogTitle,
    dialogBody,
    confirmButtonText,
    cancelButtonText,
    selectionModel,
    ownershipMode,
    ownershipFields,
    disableDelete,
    disableAddEdit,
    onCloseDialog,
    onConfirmDialog,
    deleteRows,
    addRow,
    editRow,
    selectionChanged,
  } = useOwnershipActions();

  const [rows, setRows] = useState<OwnershipType[]>([] as OwnershipType[]);

  useEffect(() => {
    let newRows = [] as OwnershipType[];
    if (opportunity.ownerships) {
      opportunity.ownerships.forEach((item) => {
        if (!item.isDeleted) {
          const newRow: OwnershipType = {
            id: item.id,
            ownerId: item.ownerId,
            ownerName: item.ownerName,
            ownerShareRate: item.ownerShareRate,
            ownershipFromDate: item.ownershipFromDate,
            ownershipToDate: item.ownershipToDate,
            isDeleted: item.isDeleted,
            isNew: item.isNew,
          };
          newRows.push(newRow);
        }
      });
    }
    setRows(newRows);
  }, [opportunity.ownerships]);

  return useMemo(() => {
    return (
      <>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["ownerships"] ?? true}>
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="subtitle1"
              sx={{ flexGrow: "1" }}
              id={OwnershipLocators.OwnershipSubtitle}
              data-testid={OwnershipLocators.OwnershipSubtitle}
            >
              Ownership
            </Typography>
            <Box sx={{ display: "flex", gap: "1rem" }}>
              <Button
                id={OwnershipLocators.DeleteOwnerships}
                data-testid={OwnershipLocators.DeleteOwnerships}
                variant="outlined"
                startIcon={<DeleteIcon />}
                disabled={disableDelete}
                onClick={deleteRows}
              >
                Delete
              </Button>
              <Button
                id={OwnershipLocators.AddEditOwnership}
                data-testid={OwnershipLocators.AddEditOwnership}
                variant="contained"
                startIcon={ownershipMode === Mode.Add ? <AddIcon /> : <EditIcon />}
                disabled={disableAddEdit}
                onClick={ownershipMode === Mode.Add ? addRow : editRow}
              >
                {ownershipMode}
              </Button>
            </Box>
          </Box>
        </ConditionalGrid>
        <ConditionalGrid
          xs={12}
          displayGrid={opportunityAttributes["ownerships"] ?? true}
          id={CardBodyLocators.OwnershipTable}
          data-testid={CardBodyLocators.OwnershipTable}
        >
          <DataGrid
            autoHeight
            rows={rows}
            columns={OwnershipColumns(mode)}
            pageSize={UTILS.PAGESIZE}
            rowsPerPageOptions={[UTILS.PAGESIZE]}
            headerHeight={32}
            rowHeight={32}
            checkboxSelection={true}
            disableColumnFilter
            getRowClassName={(_) => OwnershipLocators.OwnershipRow}
            onSelectionModelChange={(newSelectionModel) => selectionChanged(newSelectionModel)}
            initialState={{
              sorting: {
                sortModel: [{ field: "ownershipFromDate", sort: "asc" }],
              },
            }}
          />
        </ConditionalGrid>

        <ConfirmationModal
          dialogTitle={dialogTitle}
          confirmButtonText={confirmButtonText}
          cancelButtonText={cancelButtonText}
          dialogBody={dialogBody}
          show={showModal}
          onClose={onCloseDialog}
          onConfirm={onConfirmDialog}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirmationModalContext.canShow,
    showModal,
    dialogBody,
    ownershipFields,
    selectionModel,
    ownershipMode,
    disableDelete,
    disableAddEdit,
    opportunity.ownerships,
    rows,
    opportunityAttributes,
  ]);
}
