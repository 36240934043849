import { useMemo } from "react";
import { Box } from "@mui/material";

import { Mode } from "@constants";
import { useReferenceDataContext } from "context";
import {
  GeneralDetailsSection,
  StatusSection,
  LocationSection,
  LineageSection,
  CommercialSection,
  AdditionalDetailsSection,
  ReferencesSection,
  IntegrationSection,
  ChangelogSection,
  GeologicalSection,
  CardBodySectionBase,
} from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";

import { FieldsGroup, getAllTooltipFields } from "../services/utils";

export function CardBodyCreateEdit() {
  const { referenceData } = useReferenceDataContext();
  const { mode, opportunityAttributes } = useOpportunityContext();
  const tooltipFields = useMemo(
    () => getAllTooltipFields(referenceData?.opportunityAttributes),
    [referenceData?.opportunityAttributes]
  );

  return useMemo(() => {
    return (
      <Box
        sx={{
          overflowX: "auto",
          height: "100px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: "1rem",
          gap: "1rem",
          overflowY: "overlay",
        }}
      >
        <CardBodySectionBase
          id="general-details-header"
          ariaControls="general-details-content"
          title="General Details"
          tooltipFields={tooltipFields[FieldsGroup.GeneralDetails]}
        >
          <GeneralDetailsSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="status-header"
          ariaControls="status-content"
          title="Status"
          tooltipFields={tooltipFields[FieldsGroup.Status]}
        >
          <StatusSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="geological-header"
          ariaControls="geological-content"
          title="Geological"
          tooltipFields={tooltipFields[FieldsGroup.GeologicalDetails]}
        >
          <GeologicalSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="location-header"
          ariaControls="location-content"
          title="Location"
          tooltipFields={tooltipFields[FieldsGroup.Location]}
        >
          <LocationSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="lineage-header"
          ariaControls="lineage-content"
          title="Lineage"
          tooltipFields={tooltipFields[FieldsGroup.Lineage]}
        >
          <LineageSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="commercial-header"
          ariaControls="commercial-content"
          title="Commercial"
          tooltipFields={tooltipFields[FieldsGroup.Commercial]}
        >
          <CommercialSection />
        </CardBodySectionBase>
        <CardBodySectionBase
          id="additional-details-header"
          ariaControls="additional-details-content"
          title="Additional Details"
          tooltipFields={tooltipFields[FieldsGroup.AdditionalDetails]}
        >
          <AdditionalDetailsSection />
        </CardBodySectionBase>
        {opportunityAttributes["referenceDocuments"] ?? true ? (
          <CardBodySectionBase
            id="references-header"
            ariaControls="references-content"
            title="References"
            tooltipFields={tooltipFields[FieldsGroup.References]}
          >
            <ReferencesSection />
          </CardBodySectionBase>
        ) : null}
        {opportunityAttributes["landfolioId"] ?? true ? (
          <CardBodySectionBase
            id="integration-header"
            ariaControls="integration-content"
            title="Integration"
            tooltipFields={tooltipFields[FieldsGroup.Integration]}
          >
            <IntegrationSection />
          </CardBodySectionBase>
        ) : null}
        {mode === Mode.Edit ? (
          <CardBodySectionBase
            id="changelog-header"
            ariaControls="changelog-content"
            title="Change Log"
            tooltipFields={tooltipFields[FieldsGroup.Changelog]}
          >
            <ChangelogSection />
          </CardBodySectionBase>
        ) : null}
      </Box>
    );
  }, [tooltipFields, opportunityAttributes, mode]);
}
