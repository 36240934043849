import dayjs from "dayjs";

import { BulkUploadTotalResult, OpportunityModel, UserSavedSearch, UserSavedSearchWithCriteria } from "types";
import { GeocodeResult } from "features/arcgis/models";
import {
  AlertType,
  CommentType,
  NamedEntity,
  Reference,
  ResponsibleGroupType,
  ResponsiblePersonType,
} from "features/opportunities/models";

const defaultedOpportunityResponse: OpportunityModel = {
  commercialStatusId: null,
  company: {
    id: 0,
    namedEntityTypeId: 0,
    namedEntityType: null,
    name: "",
    contactInfo: null,
    address: null,
    description: null,
  },
  companyId: 0,
  confidentialityAgreement: null,
  country: null,
  countryId: null,
  createdBy: "",
  createdDate: "",
  deleteFlag: false,
  depositStageId: null,
  depositTypeClassId: null,
  depositTypeGroupId: null,
  depositTypeSubClassId: null,
  endDate: null,
  explorationStageId: 0,
  fundingTypeId: null,
  group: null,
  id: "",
  includeForMetrics: null,
  landfolioId: null,
  latitude: null,
  locationAccuracyId: null,
  longitude: null,
  missingData: null,
  notes: null,
  opportunityAlternateNames: null,
  opportunityCode: null,
  opportunityDescription: null,
  opportunityName: "",
  opportunityStatusId: 0,
  opportunitySubTypeId: null,
  opportunityTypeId: 0,
  originId: 0,
  originName: null,
  originNameId: null,
  otherCommodities: null,
  ownerships: [],
  parentOpportunity: null,
  parentOpportunityId: null,
  previousWorkSummary: null,
  primaryCommodityId: 0,
  referenceDocuments: [],
  responsibleGroup: null,
  responsibleGroupId: null,
  responsiblePerson: null,
  responsiblePersonId: 0,
  rtxRegion: null,
  rtxSubRegion: null,
  secondaryCommodityId: null,
  siteConditions: null,
  spatialExtents: null,
  explorationTypeId: 0,
  startDate: null,
  stateProvince: null,
  testDate: null,
  testMethodId: null,
  thirdPartyName: null,
  thirdPartyNameId: null,
  thirdPartyNotifiedDate: null,
  updatedBy: null,
  updatedDate: null,
  watchlistPriority: null,
  restrictedFlag: false,
  userFavoriteFlag: false,
};

export const mockRecentOpportunities: OpportunityModel[] = [
  {
    id: "RTXFP1000001",
    opportunityName: "Name1",
    opportunityCode: "N1",
    opportunityAlternateNames:
      "old_name;short name;a very long name to trim with a lot of spaces;theSameVeryLongNameOnlyWithoutSpaces",
    opportunityDescription: "No description",
    opportunityTypeId: 2,
    opportunitySubTypeId: 6,
    responsiblePersonId: 1,
    responsiblePerson: {
      id: 1,
      firstName: "Andrew",
      lastName: "Ransom",
      email: "andrew.ransom@riotinto.com",
    },
    group: "Test Group",
    responsibleGroupId: 1,
    responsibleGroup: {
      id: 1,
      name: "AAR Gen",
      description: null,
    },
    opportunityStatusId: 2,
    startDate: dayjs(new Date("01/23/23")).toISOString(),
    endDate: dayjs(new Date("01/24/23")).toISOString(),
    explorationStageId: 1,
    explorationTypeId: 1,
    depositStageId: 2,
    testDate: dayjs(new Date("01/24/22")).toISOString(),
    primaryCommodityId: 1,
    secondaryCommodityId: 2,
    otherCommodities: "5,6",
    latitude: 88.56,
    longitude: 34.45,
    locationAccuracyId: 2,
    countryId: 1,
    country: "Australia",
    stateProvince: "Queensland",
    rtxRegion: "Test Region",
    rtxSubRegion: "Test SubRegion",
    originId: 1,
    originNameId: 1,
    originName: {
      id: 1,
      namedEntityTypeId: 1,
      namedEntityType: "Company",
      name: "Company 1",
      contactInfo: "contactInfo",
      address: "address",
      description: "origin description",
    },
    parentOpportunityId: "RTXFP1000002",
    parentOpportunity: { id: "RTXFP1000002", opportunityName: "Name2", restrictedFlag: false },
    fundingTypeId: 1,
    companyId: 1,
    company: {
      id: 1,
      namedEntityTypeId: 1,
      namedEntityType: "Company",
      name: "RioTinto",
      contactInfo: "contact info",
      address: "address",
      description: "company description",
    },
    commercialStatusId: 2,
    thirdPartyNameId: 1,
    thirdPartyName: {
      id: 1,
      namedEntityTypeId: 1,
      namedEntityType: "Company",
      name: "Company 1",
      contactInfo: "contactInfo",
      address: "address",
      description: "third party description",
    },
    thirdPartyNotifiedDate: dayjs(new Date("1/24/22")).toISOString(),
    ownerships: [
      {
        id: 1,
        ownerId: 1,
        ownerName: "TestCompany",
        ownerType: "Company",
        ownerShareRate: 11,
        ownershipFromDate: dayjs(new Date("1/1/2020")).toISOString(),
        ownershipToDate: dayjs(new Date("1/1/2021")).toISOString(),
        isDeleted: false,
        isNew: false,
      },
    ],
    watchlistPriority: 1,
    includeForMetrics: true,
    siteConditions: "Test Site Conditions",
    previousWorkSummary: "Test Previous Work Summary",
    missingData: "No missing data",
    notes: "No notes",
    depositTypeGroupId: 1,
    depositTypeClassId: 1,
    depositTypeSubClassId: 1,
    testMethodId: 1,
    confidentialityAgreement: true,
    landfolioId: "Landfolio1",
    createdDate: "2022-12-01T00:00:00.0000000Z",
    createdBy: "RTX User 1",
    updatedDate: "2023-12-01T00:00:00.0000000Z",
    updatedBy: "RTX User 2",
    deleteFlag: false,
    spatialExtents: null,
    referenceDocuments: [
      {
        id: 1,
        referenceDocumentTypeId: 1,
        title: "TestDocument",
        url: "",
        notes: "Test reference document",
        isDeleted: false,
        isNew: true,
        isUpdated: false,
      },
    ],
    restrictedFlag: false,
    userFavoriteFlag: false,
  },
  {
    ...defaultedOpportunityResponse,
    id: "RTXFP1000002",
    opportunityName: "Name2",
    opportunityCode: "N2",
    opportunityAlternateNames: "a very long name to trim with a lot of spaces",
    opportunityDescription: "No description",
    opportunityTypeId: 1,
    opportunitySubTypeId: 1,
    responsiblePersonId: 1,
    responsiblePerson: {
      id: 1,
      firstName: "Andrew",
      lastName: "Ransom",
      email: "andrew.ransom@riotinto.com",
    },
    opportunityStatusId: 8,
    explorationStageId: 2,
    explorationTypeId: 1,
    primaryCommodityId: 1,
    secondaryCommodityId: 2,
    otherCommodities: "4",
    country: "Australia",
    originId: 1,
    companyId: 2,
    company: {
      id: 2,
      namedEntityTypeId: 1,
      namedEntityType: "Company",
      name: "Test Company",
      contactInfo: "contact info",
      address: "address",
      description: "company description",
    },
    parentOpportunityId: "RTXFP1000003",
    parentOpportunity: { id: "RTXFP1000003", opportunityName: "Name3", restrictedFlag: true },
    createdDate: "2023-02-13T05:57:52.8907249Z",
    updatedDate: "2023-02-14T06:32:13.3364101Z",
    createdBy: "RTXUser",
    updatedBy: "RTXUser",
    userFavoriteFlag: true,
  },
  {
    ...defaultedOpportunityResponse,
    id: "RTXFP1000003",
    opportunityName: "Name3",
    opportunityCode: "N3",
    opportunityAlternateNames: "short name",
    opportunityDescription: "No description",
    opportunityTypeId: 1,
    opportunitySubTypeId: 1,
    responsiblePersonId: 1,
    responsiblePerson: {
      id: 1,
      firstName: "Andrew",
      lastName: "Ransom",
      email: "andrew.ransom@riotinto.com",
    },
    opportunityStatusId: 8,
    explorationStageId: 2,
    explorationTypeId: 1,
    primaryCommodityId: 1,
    secondaryCommodityId: 2,
    otherCommodities: "4",
    country: "Australia",
    originId: 1,
    companyId: 2,
    company: {
      id: 2,
      namedEntityTypeId: 1,
      namedEntityType: "Company",
      name: "Test Company",
      contactInfo: "contact info",
      address: "address",
      description: "company description",
    },
    restrictedFlag: true,
    createdDate: "2023-02-13T05:57:52.8907249Z",
    updatedDate: "2023-02-14T06:32:13.3364101Z",
    createdBy: "RTXUser",
    updatedBy: "RTXUser",
    userFavoriteFlag: false,
  },
];

export const mockReferenceData: Reference = {
  opportunityTypes: [
    {
      opportunitySubTypes: [
        {
          id: 1,
          name: "3rd Party Review",
        },
        {
          id: 2,
          name: "3rd Party Submittal",
        },
        {
          id: 3,
          name: "Project Generation",
        },
        {
          id: 4,
          name: "Project Support",
        },
        {
          id: 5,
          name: "Government Auction",
        },
      ],
      id: 1,
      name: "Generative",
    },
    {
      opportunitySubTypes: [
        {
          id: 6,
          name: "RTX Internal",
        },
        {
          id: 7,
          name: "JV- RTX Operated",
        },
        {
          id: 8,
          name: "JV- RTX Non Operated",
        },
      ],
      id: 2,
      name: "Project",
    },
    {
      opportunitySubTypes: [
        {
          id: 9,
          name: "Geochemical",
        },
        {
          id: 10,
          name: "Geophysical",
        },
        {
          id: 11,
          name: "Geology",
        },
        {
          id: 12,
          name: "Unknown",
        },
      ],
      id: 3,
      name: "Target",
    },
    {
      opportunitySubTypes: [
        {
          id: 13,
          name: "NA",
        },
      ],
      id: 4,
      name: "Programme",
    },
  ],
  opportunityStatuses: [
    {
      opportunityTypeId: 1,
      displaySequence: 1,
      id: 1,
      name: "Active",
    },
    {
      opportunityTypeId: 1,
      displaySequence: 2,
      id: 8,
      name: "Inactive",
    },
    {
      opportunityTypeId: 1,
      displaySequence: 3,
      id: 9,
      name: "On-Hold",
    },
    {
      opportunityTypeId: 2,
      displaySequence: 1,
      id: 2,
      name: "Active",
    },
    {
      opportunityTypeId: 2,
      displaySequence: 2,
      id: 3,
      name: "Inactive",
    },
    {
      opportunityTypeId: 2,
      displaySequence: 3,
      id: 4,
      name: "On-Hold",
    },
    {
      opportunityTypeId: 3,
      displaySequence: 1,
      id: 5,
      name: "Tested",
    },
    {
      opportunityTypeId: 3,
      displaySequence: 2,
      id: 6,
      name: "Untested",
    },
    {
      opportunityTypeId: 4,
      displaySequence: 1,
      id: 7,
      name: "Active",
    },
    {
      opportunityTypeId: 4,
      displaySequence: 2,
      id: 10,
      name: "Inactive",
    },
    {
      opportunityTypeId: 4,
      displaySequence: 3,
      id: 11,
      name: "On-Hold",
    },
  ],
  explorationStages: [
    {
      id: 1,
      name: "Generative (Target Generative)",
    },
    {
      id: 2,
      name: "Target Testing",
    },
    {
      id: 3,
      name: "Project of Merit",
    },
    {
      id: 4,
      name: "Conceptual Study",
    },
    {
      id: 5,
      name: "Discovery",
    },
  ],
  explorationTypes: [
    {
      id: 1,
      name: "Greenfields",
    },
    {
      id: 2,
      name: "Brownfields",
    },
  ],
  depositStages: [
    {
      id: 1,
      name: "Closed",
    },
    {
      id: 2,
      name: "Closed - Deposit Depleted",
    },
    {
      id: 3,
      name: "Closed - Due to Economic Factors",
    },
    {
      id: 4,
      name: "Development",
    },
    {
      id: 5,
      name: "Evaluation",
    },
    {
      id: 6,
      name: "Exploration",
    },
    {
      id: 7,
      name: "Feasibility Study",
    },
    {
      id: 8,
      name: "Feasibility Study Completed",
    },
    {
      id: 9,
      name: "Mining",
    },
    {
      id: 10,
      name: "Mining Suspended",
    },
    {
      id: 11,
      name: "Pre-Feasibility",
    },
    {
      id: 12,
      name: "Pre-Feasibility Study Complete",
    },
  ],
  depositTypeGroups: [
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 6,
          depositTypeSubClasses: [
            {
              displaySequence: 15,
              id: 1506,
              name: "SedimentHostedNiArsenide",
            },
            {
              displaySequence: 15,
              id: 1507,
              name: "VolcanoSedimentaryHostedNiSulphide",
            },
            {
              displaySequence: 15,
              id: 1508,
              name: "VolcanoSedimentaryHostedNiArsenide",
            },
            {
              displaySequence: 15,
              id: 1509,
              name: "UltramaficHostedNiSulphide",
            },
            {
              displaySequence: 15,
              id: 1510,
              name: "UltramaficHostedNiArsenide",
            },
            {
              displaySequence: 15,
              id: 1511,
              name: "UltramaficHostedNiAlloy",
            },
            {
              displaySequence: 28,
              id: 45782,
              name: "SedimentHostedNiSulphide",
            },
          ],
          id: 1503,
          name: "MetasomaticHydrothermalNi",
        },
        {
          displaySequence: 6,
          depositTypeSubClasses: [],
          id: 1534,
          name: "MetasomaticU",
        },
        {
          displaySequence: 6,
          depositTypeSubClasses: [
            {
              displaySequence: 15,
              id: 45793,
              name: "SkarnCu",
            },
            {
              displaySequence: 15,
              id: 45800,
              name: "SkarnAu",
            },
            {
              displaySequence: 15,
              id: 45820,
              name: "SkarnMo",
            },
            {
              displaySequence: 15,
              id: 45842,
              name: "SkarnSn-W",
            },
            {
              displaySequence: 15,
              id: 45854,
              name: "SkarnPb-Zn",
            },
            {
              displaySequence: 15,
              id: 45882,
              name: "SkarnU",
            },
            {
              displaySequence: 15,
              id: 45884,
              name: "SkarnFe",
            },
            {
              displaySequence: 15,
              id: 45895,
              name: "SkarnPorphyryRelated",
            },
            {
              displaySequence: 15,
              id: 45904,
              name: "SkarnREE",
            },
          ],
          id: 45673,
          name: "Skarn",
        },
      ],
      id: 1502,
      name: "MetasomaticOrHydrothermal",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 2,
          depositTypeSubClasses: [
            {
              displaySequence: 6,
              id: 45817,
              name: "Lamprophyre",
            },
          ],
          id: 45656,
          name: "Lamprophyre",
        },
        {
          displaySequence: 2,
          depositTypeSubClasses: [
            {
              displaySequence: 5,
              id: 45866,
              name: "Kimberlite",
            },
          ],
          id: 45657,
          name: "Kimberlite",
        },
        {
          displaySequence: 2,
          depositTypeSubClasses: [
            {
              displaySequence: 6,
              id: 45784,
              name: "Lamproite",
            },
          ],
          id: 45664,
          name: "Lamproite",
        },
      ],
      id: 45602,
      name: "MagmaticDiamonds",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 14,
          depositTypeSubClasses: [
            {
              displaySequence: 46,
              id: 45855,
              name: "HotSpringBorate",
            },
          ],
          id: 45661,
          name: "HotSprings",
        },
        {
          displaySequence: 14,
          depositTypeSubClasses: [
            {
              displaySequence: 45,
              id: 45860,
              name: "CalcreteU",
            },
            {
              displaySequence: 45,
              id: 45906,
              name: "Calcrete",
            },
          ],
          id: 45668,
          name: "Calcrete",
        },
        {
          displaySequence: 14,
          depositTypeSubClasses: [
            {
              displaySequence: 47,
              id: 45790,
              name: "GuanoPhosphate",
            },
          ],
          id: 45672,
          name: "Phosphate",
        },
      ],
      id: 45603,
      name: "Surficial",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 6,
          depositTypeSubClasses: [
            {
              displaySequence: 17,
              id: 45780,
              name: "MetamorphicGraphite",
            },
            {
              displaySequence: 17,
              id: 45808,
              name: "LodeOrogenicAu",
            },
            {
              displaySequence: 17,
              id: 45821,
              name: "MetamorphicB",
            },
            {
              displaySequence: 17,
              id: 45825,
              name: "MetamorphicU",
            },
            {
              displaySequence: 17,
              id: 45831,
              name: "MetapeliteK",
            },
            {
              displaySequence: 17,
              id: 45876,
              name: "MetamorphicVein",
            },
            {
              displaySequence: 17,
              id: 45843,
              name: "MetamorphicDiamonds",
            },
          ],
          id: 45648,
          name: "Metamorphic",
        },
      ],
      id: 45604,
      name: "Metamorphic",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 5,
          depositTypeSubClasses: [
            {
              displaySequence: 13,
              id: 45839,
              name: "CarbonatiteGraphite",
            },
            {
              displaySequence: 13,
              id: 45859,
              name: "CarbonatiteREE",
            },
            {
              displaySequence: 13,
              id: 45880,
              name: "CarbonatiteCu",
            },
            {
              displaySequence: 13,
              id: 45890,
              name: "CarbonatiteNd",
            },
            {
              displaySequence: 13,
              id: 45813,
              name: "CarbonatiteP",
            },
            {
              displaySequence: 13,
              id: 45787,
              name: "CarbonatiteU",
            },
          ],
          id: 45639,
          name: "Carbonatite",
        },
        {
          displaySequence: 5,
          depositTypeSubClasses: [
            {
              displaySequence: 14,
              id: 45818,
              name: "Feldspar",
            },
            {
              displaySequence: 14,
              id: 45871,
              name: "Felspathoid",
            },
          ],
          id: 45669,
          name: "AlkalicIgneous",
        },
        {
          displaySequence: 5,
          depositTypeSubClasses: [
            {
              displaySequence: 12,
              id: 45846,
              name: "PeralkalineTa-Nb",
            },
            {
              displaySequence: 12,
              id: 45783,
              name: "PeralkalineREE",
            },
          ],
          id: 45670,
          name: "Peralkaline",
        },
      ],
      id: 45605,
      name: "AlkaliMagmaAssociated",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 3,
          depositTypeSubClasses: [
            {
              displaySequence: 9,
              id: 45827,
              name: "PolymetallicVein",
            },
          ],
          id: 45640,
          name: "PolymetallicVein",
        },
        {
          displaySequence: 3,
          depositTypeSubClasses: [
            {
              displaySequence: 7,
              id: 45822,
              name: "PorphyryCu-Mo",
            },
            {
              displaySequence: 7,
              id: 45833,
              name: "PorphyrySn",
            },
            {
              displaySequence: 7,
              id: 45806,
              name: "PorphyryAlkalic",
            },
            {
              displaySequence: 7,
              id: 45872,
              name: "PorphyryMo_Undefined",
            },
            {
              displaySequence: 7,
              id: 45874,
              name: "PorphyryMo_ArcType",
            },
            {
              displaySequence: 7,
              id: 45867,
              name: "PorphyryAu",
            },
            {
              displaySequence: 7,
              id: 45863,
              name: "PorphyryCu-Au",
            },
            {
              displaySequence: 7,
              id: 9237488,
              name: "PorphyryCu-Mo-Au",
            },
            {
              displaySequence: 7,
              id: 45902,
              name: "PorphyryMo_ClimaxType",
            },
          ],
          id: 45654,
          name: "Porphyry",
        },
        {
          displaySequence: 3,
          depositTypeSubClasses: [
            {
              displaySequence: 8,
              id: 9237492,
              name: "IntrusionRelatedCuAu",
            },
            {
              displaySequence: 8,
              id: 45905,
              name: "GraniteU",
            },
            {
              displaySequence: 8,
              id: 45907,
              name: "Pegmatite (LCT)",
            },
            {
              displaySequence: 8,
              id: 45908,
              name: "Pegmatite (NYF)",
            },
            {
              displaySequence: 8,
              id: 45909,
              name: "Pegmatite (REE)",
            },
            {
              displaySequence: 8,
              id: 45864,
              name: "IntrusionRelatedAu",
            },
            {
              displaySequence: 8,
              id: 45847,
              name: "NaMetasomatic-AlbiteU",
            },
            {
              displaySequence: 8,
              id: 45812,
              name: "VeinU",
            },
            {
              displaySequence: 8,
              id: 45809,
              name: "Sn-W",
            },
            {
              displaySequence: 8,
              id: 45804,
              name: "Pegmatite",
            },
            {
              displaySequence: 8,
              id: 45879,
              name: "GraniteVein",
            },
          ],
          id: 45667,
          name: "GraniteRelated",
        },
        {
          displaySequence: 3,
          depositTypeSubClasses: [
            {
              displaySequence: 10,
              id: 45881,
              name: "VolcanicU",
            },
          ],
          id: 45677,
          name: "FelsicVolcanicHosted",
        },
      ],
      id: 45606,
      name: "FelsicMagmaAssociated",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 4,
          depositTypeSubClasses: [
            {
              displaySequence: 11,
              id: 45850,
              name: "VolcanicHostedCu-Ag",
            },
            {
              displaySequence: 11,
              id: 45796,
              name: "BasaltHostedCu",
            },
          ],
          id: 45650,
          name: "IntermediateMaficVolcanicHosted",
        },
      ],
      id: 45607,
      name: "IntermediateMaficVolcanicHosted",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 11,
          depositTypeSubClasses: [
            {
              displaySequence: 35,
              id: 45851,
              name: "BasementHostedU",
            },
            {
              displaySequence: 35,
              id: 45886,
              name: "UnconformityU",
            },
          ],
          id: 45680,
          name: "UnconformityRelatedUranium",
        },
      ],
      id: 45608,
      name: "UnconformityRelated",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 1,
          depositTypeSubClasses: [
            {
              displaySequence: 1,
              id: 45840,
              name: "ConduitChromite",
            },
            {
              displaySequence: 1,
              id: 45794,
              name: "LayeredChromite",
            },
            {
              displaySequence: 1,
              id: 45826,
              name: "PodiformChromite",
            },
          ],
          id: 45643,
          name: "Chromite",
        },
        {
          displaySequence: 1,
          depositTypeSubClasses: [
            {
              displaySequence: 3,
              id: 45802,
              name: "MagmaticPGE_SulphideReef",
            },
            {
              displaySequence: 3,
              id: 1500,
              name: "MagmaticPGE_ConduitHosted",
            },
            {
              displaySequence: 3,
              id: 1501,
              name: "MagmaticPGE_ContactType",
            },
            {
              displaySequence: 3,
              id: 45861,
              name: "MagmaticPGE_MagnetiteReef",
            },
            {
              displaySequence: 3,
              id: 45897,
              name: "MagmaticPGE_ChromiteReef",
            },
          ],
          id: 45653,
          name: "MagmaticPGE",
        },
        {
          displaySequence: 1,
          depositTypeSubClasses: [
            {
              displaySequence: 2,
              id: 45885,
              name: "MagmaticRutile-Ilmenite",
            },
            {
              displaySequence: 2,
              id: 45795,
              name: "MagmaticTi-V-Magnetite",
            },
          ],
          id: 45655,
          name: "MagmaticFe-Ti-V",
        },
        {
          displaySequence: 1,
          depositTypeSubClasses: [
            {
              displaySequence: 4,
              id: 45779,
              name: "Ni-CuSulphide_KomatiiteHosted",
            },
            {
              displaySequence: 4,
              id: 45837,
              name: "Ni-CuSulphide_ConduitHosted",
            },
            {
              displaySequence: 4,
              id: 45887,
              name: "Ni-CuSulphide_ContactType",
            },
            {
              displaySequence: 4,
              id: 45892,
              name: "Ni-CuSulphide_Impact",
            },
          ],
          id: 45665,
          name: "MagmaticNi-Cu-PGESulphide",
        },
      ],
      id: 45609,
      name: "MaficUltramaficMagmaAssociated",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 8,
          depositTypeSubClasses: [
            {
              displaySequence: 21,
              id: 45883,
              name: "LowSulphidation",
            },
            {
              displaySequence: 21,
              id: 45852,
              name: "HighSulphidation",
            },
            {
              displaySequence: 21,
              id: 45848,
              name: "IntermediateSulphidation",
            },
          ],
          id: 45635,
          name: "EpithermalAg-Au",
        },
        {
          displaySequence: 8,
          depositTypeSubClasses: [
            {
              displaySequence: 22,
              id: 45789,
              name: "HydrothermalAlunite",
            },
            {
              displaySequence: 22,
              id: 45798,
              name: "Lithocap",
            },
          ],
          id: 45663,
          name: "AcidSulphateAlteration",
        },
      ],
      id: 45610,
      name: "Epithermal",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 32,
              id: 45801,
              name: "ManganeseNodules",
            },
            {
              displaySequence: 32,
              id: 45838,
              name: "Evaporites",
            },
            {
              displaySequence: 32,
              id: 45862,
              name: "GlauconiticSands",
            },
          ],
          id: 45632,
          name: "ChemicalSediments",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 31,
              id: 45803,
              name: "SedimentaryPhosphate",
            },
            {
              displaySequence: 31,
              id: 45781,
              name: "PhosphateHostedU",
            },
          ],
          id: 45634,
          name: "SedimentaryPhosphate",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 34,
              id: 45819,
              name: "ConglomerateU-Au",
            },
            {
              displaySequence: 34,
              id: 45857,
              name: "ConglomerateDiamonds",
            },
          ],
          id: 45636,
          name: "Conglomerate",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 33,
              id: 45873,
              name: "BrownCoal",
            },
            {
              displaySequence: 33,
              id: 45841,
              name: "Lignite",
            },
            {
              displaySequence: 33,
              id: 45891,
              name: "BituminousCoking",
            },
            {
              displaySequence: 33,
              id: 45792,
              name: "SubBituminous",
            },
            {
              displaySequence: 33,
              id: 45834,
              name: "BituminousThermal",
            },
            {
              displaySequence: 33,
              id: 45823,
              name: "Anthracite",
            },
            {
              displaySequence: 33,
              id: 45788,
              name: "Peat",
            },
          ],
          id: 45638,
          name: "Coal",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 26,
              id: 45835,
              name: "FerruginousQuartzite",
            },
            {
              displaySequence: 26,
              id: 45810,
              name: "ChannelIronDeposits",
            },
            {
              displaySequence: 26,
              id: 45893,
              name: "IronSands",
            },
            {
              displaySequence: 26,
              id: 45877,
              name: "DetritalIron",
            },
            {
              displaySequence: 26,
              id: 45853,
              name: "BIF",
            },
            {
              displaySequence: 26,
              id: 45830,
              name: "OoliticIronstones",
            },
          ],
          id: 45642,
          name: "SedimentHostedFe",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 27,
              id: 45785,
              name: "Manganese",
            },
          ],
          id: 45659,
          name: "SedimentHostedMn",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 25,
              id: 45799,
              name: "MVT_IrishPb-Zn",
            },
            {
              displaySequence: 25,
              id: 1517,
              name: "DiageneticSyngeneticShaleHostedNi",
            },
            {
              displaySequence: 25,
              id: 1535,
              name: "CRD_CarbonateReplacementDeposit",
            },
            {
              displaySequence: 25,
              id: 45832,
              name: "SedimentHostedCu",
            },
            {
              displaySequence: 25,
              id: 45844,
              name: "SEDEX_SedimentaryExhalative",
            },
            {
              displaySequence: 25,
              id: 45845,
              name: "SedimentHostedAu",
            },
            {
              displaySequence: 25,
              id: 45888,
              name: "MVT_MississippiValley",
            },
          ],
          id: 45660,
          name: "SedimentHostedSulphides",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 30,
              id: 45896,
              name: "ShaleHostedU",
            },
            {
              displaySequence: 30,
              id: 45814,
              name: "BrecciaHostedU",
            },
          ],
          id: 45676,
          name: "NonSandstoneHostedUranium",
        },
        {
          displaySequence: 10,
          depositTypeSubClasses: [
            {
              displaySequence: 29,
              id: 45878,
              name: "ChannelHostedU",
            },
            {
              displaySequence: 29,
              id: 45849,
              name: "StrataboundU",
            },
            {
              displaySequence: 29,
              id: 45858,
              name: "RollFrontU",
            },
          ],
          id: 45678,
          name: "SandstoneHostedUranium",
        },
      ],
      id: 45611,
      name: "SedimentHosted",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 7,
          depositTypeSubClasses: [
            {
              displaySequence: 18,
              id: 45836,
              name: "IOCG_OlympicDamType",
            },
          ],
          id: 45646,
          name: "IOCG_OlympicDamType",
        },
        {
          displaySequence: 7,
          depositTypeSubClasses: [
            {
              displaySequence: 19,
              id: 45875,
              name: "IOCG_IronOxideWithCopperGold",
            },
          ],
          id: 45647,
          name: "IOCG_CalcAlkalineType",
        },
        {
          displaySequence: 7,
          depositTypeSubClasses: [
            {
              displaySequence: 20,
              id: 45805,
              name: "IOA_IronOxideApatite",
            },
          ],
          id: 45671,
          name: "IOCG_MagnetiteApatiteType",
        },
      ],
      id: 45612,
      name: "IOCG",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 9,
          depositTypeSubClasses: [
            {
              displaySequence: 23,
              id: 45807,
              name: "PeliticMafic",
            },
            {
              displaySequence: 23,
              id: 45868,
              name: "Mafic",
            },
            {
              displaySequence: 23,
              id: 45865,
              name: "BimodalMafic",
            },
          ],
          id: 45658,
          name: "MaficVMS",
        },
        {
          displaySequence: 9,
          depositTypeSubClasses: [
            {
              displaySequence: 24,
              id: 45815,
              name: "SiliclasticFelsic",
            },
            {
              displaySequence: 24,
              id: 45797,
              name: "BimodalFelsic",
            },
          ],
          id: 45662,
          name: "FelsicVMS",
        },
      ],
      id: 45613,
      name: "VMS",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 12,
          depositTypeSubClasses: [
            {
              displaySequence: 36,
              id: 45894,
              name: "BauxiteKarst",
            },
          ],
          id: 45633,
          name: "Karst",
        },
        {
          displaySequence: 12,
          depositTypeSubClasses: [
            {
              displaySequence: 37,
              id: 45889,
              name: "LateriteAu",
            },
            {
              displaySequence: 37,
              id: 45903,
              name: "LateriteFe",
            },
            {
              displaySequence: 37,
              id: 45900,
              name: "Tikhdin",
            },
            {
              displaySequence: 37,
              id: 45791,
              name: "LateriteBauxite",
            },
            {
              displaySequence: 37,
              id: 1513,
              name: "LimoniteNiCo",
            },
            {
              displaySequence: 37,
              id: 1514,
              name: "SaprolitesNiCo",
            },
            {
              displaySequence: 37,
              id: 1515,
              name: "AsbolaneNiCo",
            },
            {
              displaySequence: 37,
              id: 45811,
              name: "LateriteNiCoUnclassified",
            },
          ],
          id: 45641,
          name: "Laterite",
        },
        {
          displaySequence: 12,
          depositTypeSubClasses: [
            {
              displaySequence: 39,
              id: 45901,
              name: "ExoticUnclassified",
            },
            {
              displaySequence: 39,
              id: 45898,
              name: "ExoticCu",
            },
          ],
          id: 45645,
          name: "Exotic",
        },
        {
          displaySequence: 12,
          depositTypeSubClasses: [
            {
              displaySequence: 38,
              id: 45828,
              name: "BauxiteSedimentary",
            },
          ],
          id: 45649,
          name: "Sedimentary",
        },
        {
          displaySequence: 12,
          depositTypeSubClasses: [
            {
              displaySequence: 40,
              id: 45899,
              name: "Clays",
            },
          ],
          id: 45675,
          name: "Clays",
        },
      ],
      id: 45614,
      name: "WeatheringOrSecondary",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 13,
          depositTypeSubClasses: [
            {
              displaySequence: 41,
              id: 45869,
              name: "PlacerPGE",
            },
            {
              displaySequence: 41,
              id: 45870,
              name: "PlacerU",
            },
            {
              displaySequence: 41,
              id: 45829,
              name: "PlacerAu",
            },
          ],
          id: 45644,
          name: "PlacerMetals",
        },
        {
          displaySequence: 13,
          depositTypeSubClasses: [
            {
              displaySequence: 43,
              id: 45856,
              name: "HeavyMineralSands",
            },
          ],
          id: 45652,
          name: "HeavyMineralSands",
        },
        {
          displaySequence: 13,
          depositTypeSubClasses: [
            {
              displaySequence: 44,
              id: 45824,
              name: "DetritalDiamonds",
            },
          ],
          id: 45666,
          name: "DetritalDiamonds",
        },
      ],
      id: 45615,
      name: "Placer",
    },
    {
      displaySequence: 1,
      depositTypeClasses: [
        {
          displaySequence: 15,
          depositTypeSubClasses: [
            {
              displaySequence: 48,
              id: 45786,
              name: "Brines",
            },
          ],
          id: 45651,
          name: "Brines",
        },
      ],
      id: 45616,
      name: "MetalliferousBrines",
    },
    {
      displaySequence: 100,
      depositTypeClasses: [],
      id: 4717932,
      name: "VeinUndefined",
    },
  ],
  commodityGroups: [
    {
      commodityClasses: [
        {
          commodities: [
            {
              displaySequence: 1,
              id: 1,
              name: "Antimony",
            },
            {
              displaySequence: 2,
              id: 2,
              name: "Arsenic",
            },
            {
              displaySequence: 3,
              id: 3,
              name: "Bismuth",
            },
            {
              displaySequence: 4,
              id: 4,
              name: "Cadmium",
            },
            {
              displaySequence: 5,
              id: 5,
              name: "Chromium",
            },
            {
              displaySequence: 6,
              id: 6,
              name: "Cobalt",
            },
            {
              displaySequence: 7,
              id: 7,
              name: "Copper",
            },
            {
              displaySequence: 8,
              id: 8,
              name: "Gallium",
            },
            {
              displaySequence: 9,
              id: 9,
              name: "Indium",
            },
            {
              displaySequence: 10,
              id: 10,
              name: "Lead",
            },
            {
              displaySequence: 11,
              id: 11,
              name: "Manganese",
            },
            {
              displaySequence: 12,
              id: 12,
              name: "Molybdenum",
            },
            {
              displaySequence: 13,
              id: 13,
              name: "Nickel",
            },
            {
              displaySequence: 14,
              id: 14,
              name: "Rhenium",
            },
            {
              displaySequence: 15,
              id: 15,
              name: "Tellurium",
            },
            {
              displaySequence: 16,
              id: 16,
              name: "Thallium",
            },
            {
              displaySequence: 17,
              id: 17,
              name: "Tin",
            },
            {
              displaySequence: 18,
              id: 18,
              name: "Tungsten",
            },
            {
              displaySequence: 19,
              id: 19,
              name: "Vanadium",
            },
            {
              displaySequence: 20,
              id: 20,
              name: "Zinc",
            },
          ],
          displaySequence: 1,
          id: 1,
          name: "Base Metals",
        },
        {
          commodities: [
            {
              displaySequence: 1,
              id: 21,
              name: "Iridium",
            },
            {
              displaySequence: 2,
              id: 22,
              name: "Osmium",
            },
            {
              displaySequence: 3,
              id: 23,
              name: "Palladium",
            },
            {
              displaySequence: 4,
              id: 24,
              name: "PGEUnclassified",
            },
            {
              displaySequence: 5,
              id: 25,
              name: "Platinum",
            },
            {
              displaySequence: 6,
              id: 26,
              name: "Rhodium",
            },
            {
              displaySequence: 7,
              id: 27,
              name: "Ruthenium",
            },
          ],
          displaySequence: 2,
          id: 2,
          name: "Platinum Group Elements",
        },
        {
          commodities: [
            {
              displaySequence: 1,
              id: 28,
              name: "Gold",
            },
            {
              displaySequence: 2,
              id: 29,
              name: "Silver",
            },
          ],
          displaySequence: 3,
          id: 3,
          name: "Precious Metals",
        },
      ],
      id: 1,
      name: "Base and Precious Minerals",
    },
  ],
  locationAccuracies: [
    {
      id: 1,
      name: "Greater than 50 km",
    },
    {
      id: 2,
      name: "Within 50 km",
    },
    {
      id: 3,
      name: "Within 5 km",
    },
    {
      id: 4,
      name: "Within 0.5 km",
    },
    {
      id: 5,
      name: "Less than 100 m",
    },
    {
      id: 6,
      name: "Unknown",
    },
  ],
  opportunityOriginTypes: [
    {
      id: 1,
      name: "Internal",
    },
    {
      id: 2,
      name: "External",
    },
  ],
  fundingTypes: [
    {
      id: 1,
      name: "RTX Funded",
    },
    {
      id: 2,
      name: "Joint Venture Funded",
    },
    {
      id: 3,
      name: "Business Unit Funded",
    },
    {
      id: 4,
      name: "Unknown",
    },
  ],
  commodityColors: [
    {
      commodityId: 1,
      commodityHexColor: "#854C8F",
    },
    {
      commodityId: 2,
      commodityHexColor: "#38B6A7",
    },
    {
      commodityId: 3,
      commodityHexColor: "#FFB347",
    },
    {
      commodityId: 4,
      commodityHexColor: "#7E9AFF",
    },
    {
      commodityId: 5,
      commodityHexColor: "#F08080",
    },
  ],
  namedEntityTypes: [
    {
      id: 1,
      name: "Company1",
      displaySequence: 1,
    },
    {
      id: 2,
      name: "NGO",
      displaySequence: 2,
    },
    {
      id: 3,
      name: "Company2",
      displaySequence: 3,
    },
  ],
  commercialStatuses: [
    {
      id: 1,
      name: "Failed - Could Not Secured",
    },
    {
      id: 2,
      name: "In Progress",
    },
    {
      id: 3,
      name: "Not Applicable",
    },
    {
      id: 4,
      name: "Not Started",
    },
    {
      id: 5,
      name: "On Hold",
    },
    {
      id: 6,
      name: "Secured",
    },
    {
      id: 7,
      name: "Unknown",
    },
  ],
  testMethods: [
    {
      id: 1,
      name: "Method 1",
    },
    {
      id: 2,
      name: "Method 2",
    },
  ],
  countries: [
    { id: 1, name: "Australia", countryCode: "AUS", continent: "Oceania", rtxRegion: "AAR", rtxSubRegion: "AAR" },
    { id: 2, name: "Canada", countryCode: "CAN", continent: "North America", rtxRegion: "AMR", rtxSubRegion: "AMR S" },
    { id: 3, name: "Moldova", countryCode: "MDA", continent: "Europe", rtxRegion: "AER", rtxSubRegion: "AER E" },
  ],
  opportunityAttributes: {
    opportunityName: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 2,
      label: "Name",
      description: "Name of the opportunity",
    },
    opportunityCode: {
      generative: false,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 1,
      label: "Opportunity Code",
      description: "A 10 char all caps code for the opportunity, for use in other systems",
    },
    opportunityAlternateNames: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 3,
      label: "Alternate Names",
      description: "Past or alternate names for the opportunity.",
    },
    opportunityDescription: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 4,
      label: "Description",
      description: "A brief description of the opportunity",
    },
    opportunityTypeId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 5,
      label: "Type",
      description: "Classification of the idea as a concept, target, program, project.",
    },
    opportunitySubTypeId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 6,
      label: "Sub Type",
      description: "A further way to break down an opportunity classification. E.g. Target Type",
    },
    responsiblePersonId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 7,
      label: "Responsible Person",
      description: "The name of the person currently responsible for the opportunity",
    },
    responsibleGroupId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 1,
      fieldSequence: 8,
      label: "Responsible Group",
      description: "The name of the group that manages the opportunity",
    },
    group: {
      generative: true,
      project: true,
      target: true,
      programme: false,
      fieldGroupSequence: 1,
      fieldSequence: 9,
      label: "Group",
      description: "An arbitrary name for a project, target or opportunity group.",
    },
    opportunityStatusId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 2,
      fieldSequence: 1,
      label: "Opportunity Status",
      description: "Status of the opportunity. E.g. on hold, active, etc",
    },
    startDate: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 2,
      fieldSequence: 2,
      label: "Start Date",
      description: "The date on which work on the opportunity started",
    },
    endDate: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 2,
      fieldSequence: 3,
      label: "EndDate",
      description: "The date on which work on the opportunity ended",
    },
    explorationStageId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 2,
      fieldSequence: 4,
      label: "Exploration Stage",
      description: "The exploration stage (i.e. generation, target testing, etc) for the opportunity",
    },
    explorationTypeId: {
      generative: false,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 2,
      fieldSequence: 5,
      label: "Exploration Type",
      description: "Greenfields or Brownfields",
    },
    depositStageId: {
      generative: false,
      project: true,
      target: false,
      programme: false,
      fieldGroupSequence: 2,
      fieldSequence: 6,
      label: "Deposit Stage",
      description: "The stage of the deposit after an opportunity has been handed over for discovery",
    },
    testDate: {
      generative: false,
      project: false,
      target: true,
      programme: false,
      fieldGroupSequence: 2,
      fieldSequence: 7,
      label: "Test Date",
      description: "The date when the Target was drill tested",
    },
    testMethodId: {
      generative: false,
      project: false,
      target: true,
      programme: false,
      fieldGroupSequence: 2,
      fieldSequence: 8,
      label: "Test Method",
      description: "The method(s) used to test this target",
    },
    primaryCommodityId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 1,
      label: "Primary Commodity",
      description: "The main potential commodity related to this opportunity",
    },
    secondaryCommodityId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 2,
      label: "Secondary Commodity",
      description: "Secondary potential commodity related to the opportunity",
    },
    otherCommodities: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 3,
      label: "Other Commodities",
      description: "Other commodities associated with the opportunity",
    },
    depositTypeGroupId: {
      generative: true,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 4,
      label: "Deposit Type Group",
      description: "The type of deposit with which the opportunity is associated",
    },
    depositTypeClassId: {
      generative: true,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 5,
      label: "Deposit Type Class",
      description: "The type of deposit with which the opportunity is associated",
    },
    depositTypeSubClassId: {
      generative: true,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 3,
      fieldSequence: 6,
      label: "Deposit Type Subclass",
      description: "The type of deposit with which the opportunity is associated",
    },
    longitude: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 1,
      label: "Longitude",
      description: "The x coordinate, in WGS84 decimal degrees",
    },
    latitude: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 2,
      label: "Latitude",
      description: "The y coordinate, in WGS84 decimal degrees",
    },
    locationAccuracyId: {
      generative: true,
      project: true,
      target: true,
      programme: false,
      fieldGroupSequence: 4,
      fieldSequence: 3,
      label: "Location Accuracy",
      description: "An indication of how accurately the opportunity is located",
    },
    countryId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 4,
      label: "Country",
      description: "The country in which the opportunity is located",
    },
    stateProvince: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 5,
      label: "State / Province",
      description: "The state, province, or other political region in which the opportunity is located",
    },
    rtxRegion: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 6,
      label: "RTX Region",
      description: "The RTX Region in which the opportunity is located",
    },
    rtxSubRegion: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 4,
      fieldSequence: 7,
      label: "RTX Subregion",
      description: "The RTX Sub-region in which the opportunity is location",
    },
    originId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 5,
      fieldSequence: 1,
      label: "Origin",
      description: "Where the idea originated. E.g. 3rd party or internal team",
    },
    originNameId: {
      generative: true,
      project: true,
      target: true,
      programme: false,
      fieldGroupSequence: 5,
      fieldSequence: 2,
      label: "Origin Name",
      description: "The name of the company or other entity from which the opportunity was originated",
    },
    parentOpportunityId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 5,
      fieldSequence: 4,
      label: "Parent Opportunity",
      description: "The project or program of which the opportunity is a part",
    },
    fundingTypeId: {
      generative: false,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 6,
      fieldSequence: 1,
      label: "Funding Type",
      description: "RTX, Business Unit, or JV",
    },
    companyId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 6,
      fieldSequence: 2,
      label: "Company",
      description: "The Rio Tinto entity that 'owns' or is responsible for the opportunity.",
    },
    commercialStatusId: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 6,
      fieldSequence: 3,
      label: "Commercial Status",
      description: "Whether the opportunity is commercially secured or not.",
    },
    thirdPartyNameId: {
      generative: true,
      project: false,
      target: false,
      programme: false,
      fieldGroupSequence: 6,
      fieldSequence: 5,
      label: "3rd Party Name",
      description: "The name of a 3rd party involved in the opportunity, if any.",
    },
    thirdPartyNotifiedDate: {
      generative: true,
      project: false,
      target: false,
      programme: false,
      fieldGroupSequence: 6,
      fieldSequence: 6,
      label: "3rd Party Notified",
      description: "Whether a 3rd party is notified about or invovled with the opportunity",
    },
    ownerships: {
      generative: true,
      project: true,
      target: false,
      programme: false,
      fieldGroupSequence: 6,
      fieldSequence: 8,
      label: "Ownership",
      description: "A record of which entity owns the opportunity or the ground where the opportunity is located",
    },
    confidentialityAgreement: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 6,
      fieldSequence: 7,
      label: "Confidentiality Agreement",
      description: "A flag to indicate of there is a confidentiality agreement associated with the opportunity",
    },
    siteConditions: {
      generative: true,
      project: true,
      target: true,
      programme: false,
      fieldGroupSequence: 7,
      fieldSequence: 3,
      label: "Site Conditions",
      description: "What the physical conditions are like on the site of the entry",
    },
    previousWorkSummary: {
      generative: true,
      project: true,
      target: true,
      programme: false,
      fieldGroupSequence: 7,
      fieldSequence: 4,
      label: "Previous Work Summary",
      description: "A text description of what work has already been done on the entry",
    },
    watchlistPriority: {
      generative: true,
      project: false,
      target: false,
      programme: false,
      fieldGroupSequence: 7,
      fieldSequence: 1,
      label: "Watchlist Priority",
      description: "A numeric ranking for where this sits in the priority list",
    },
    missingData: {
      generative: true,
      project: false,
      target: false,
      programme: false,
      fieldGroupSequence: 7,
      fieldSequence: 5,
      label: "Missing Data",
      description: "A description of what data about the opportunity is missing/needs finding",
    },
    includeForMetrics: {
      generative: true,
      project: true,
      target: false,
      programme: false,
      fieldGroupSequence: 7,
      fieldSequence: 2,
      label: "Include for Metrics",
      description: "A flag to indicate if the opportunity is included in KPI metrics. E.g. target tested",
    },
    notes: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 7,
      fieldSequence: 6,
      label: "Notes",
      description: "Other comments/notes about the opportunity.",
    },
    referenceDocuments: {
      generative: true,
      project: true,
      target: true,
      programme: true,
      fieldGroupSequence: 8,
      fieldSequence: 1,
      label: "Reference Documents",
      description: "A one-to-many list of reference document links",
    },
    landfolioId: {
      generative: false,
      project: true,
      target: false,
      programme: true,
      fieldGroupSequence: 9,
      fieldSequence: 2,
      label: "Landfolio ID",
      description: "The Landfolio Identifier that links the opportunity to the same project in the Landfolio system",
    },
  },
  referenceDocumentTypes: [
    {
      id: 1,
      name: "Sharepoint Online",
    },
    {
      id: 2,
      name: "External URL",
    },
    {
      id: 3,
      name: "Physical",
    },
  ],
  rtXfootprintsConfigs: [{ id: 1, settingName: "AppURL", settingValue: "https://localhost" }],
  bulkUploadList: ["Opportunity", "OpportunityOwnership", "ReferenceDocument", "NamedEntity", "RTXGroup"],
};

export const mockNamedEntities: NamedEntity[] = [
  {
    id: 1,
    name: "Company1",
    namedEntityTypeId: 1,
  },
  {
    id: 2,
    name: "NGO",
    namedEntityTypeId: 2,
  },
  {
    id: 3,
    name: "Company2",
    namedEntityTypeId: 3,
  },
];

export const mockResponsiblePerson: ResponsiblePersonType[] = [
  {
    id: 1,
    firstName: "Andrew",
    lastName: "Ransom",
    email: "andrew.ransom@riotinto.com",
  },
  {
    id: 2,
    firstName: "Dinah",
    lastName: "Bezuidenhout",
    email: "dinah.bezuidenhout@riotinto.com",
  },
];

export const mockResponsibleGroup: ResponsibleGroupType[] = [
  {
    id: 1,
    name: "AAR Gen",
  },
  {
    id: 2,
    name: "RTX Aus East",
  },
  {
    id: 3,
    name: "RTX Aus West",
  },
  {
    id: 4,
    name: "RTX APAC",
  },
  {
    id: 5,
    name: "RTX MinMet",
  },
];

export const mockReverseGeocodeResponse: GeocodeResult = {
  address: {
    Match_addr: "",
    LongLabel: "",
    ShortLabel: "",
    Addr_type: "Locality",
    Type: "City",
    PlaceName: "",
    AddNum: "",
    Address: "",
    Block: "",
    Sector: "",
    Neighborhood: "",
    District: "",
    City: "",
    MetroArea: "",
    Subregion: "",
    Region: "New South Wales",
    RegionAbbr: "NSW",
    Territory: "",
    Postal: "",
    PostalExt: "",
    CntryName: "Australia",
    CountryCode: "AUS",
  },
  location: {
    x: 1.2744139999999999,
    y: 28.729130000000001,
    spatialReference: {
      wkid: 4326,
      latestWkid: 4326,
    },
  },
};

export const mockGetSavedSearches: UserSavedSearch[] = [
  {
    id: 1,
    savedSearchName: "Saved Search 1",
  },
  {
    id: 2,
    savedSearchName: "Saved Search 2",
  },
  {
    id: 3,
    savedSearchName: "Saved Search 3",
  },
];

export const mockSavedSearches: UserSavedSearchWithCriteria[] = [
  {
    id: 1,
    savedSearchName: "Saved Search 1",
    searchQuery: {
      opportunityName: {
        operator: 3,
        value: "Name",
      },
      opportunityCode: undefined,
      opportunityType: undefined,
      opportunityStatus: undefined,
      explorationType: undefined,
      explorationStage: undefined,
      country: undefined,
      rtxRegion: undefined,
      rtxSubRegion: undefined,
      primaryCommodity: undefined,
      secondaryCommodity: undefined,
      otherCommodities: {
        operator: 5,
        value: [4],
      },
      bounds: undefined,
    },
  },
  {
    id: 2,
    savedSearchName: "Saved Search 2",
    searchQuery: {
      opportunityName: undefined,
      opportunityCode: undefined,
      opportunityType: undefined,
      opportunityStatus: undefined,
      explorationType: undefined,
      explorationStage: undefined,
      country: undefined,
      rtxRegion: undefined,
      rtxSubRegion: undefined,
      primaryCommodity: undefined,
      secondaryCommodity: undefined,
      otherCommodities: {
        operator: 5,
        value: [6],
      },
      bounds: undefined,
    },
  },
  {
    id: 3,
    savedSearchName: "Saved Search 3",
    searchQuery: {
      opportunityName: undefined,
      opportunityCode: undefined,
      opportunityType: undefined,
      opportunityStatus: undefined,
      explorationType: undefined,
      explorationStage: undefined,
      country: {
        operator: 5,
        value: [1],
      },
      rtxRegion: undefined,
      rtxSubRegion: undefined,
      primaryCommodity: undefined,
      secondaryCommodity: undefined,
      otherCommodities: undefined,
      bounds: undefined,
    },
  },
];

export const mockAlerts: AlertType[] = [
  {
    id: 1,
    opportunityId: "RTXFP1000001",
    opportunity: {
      opportunityName: "Name1",
      country: "Australia",
    },
    title: "TestAlert",
    description: "TestAlertDescription",
    action: "TestAlertAction",
    alertDate: dayjs(new Date()).add(1, "day"),
    creator: {
      firstName: "Andrew",
      lastName: "Ransom",
    },
    recipients: [
      {
        firstName: "Andrew",
        lastName: "Ransom",
        email: "andrew.ransom@riotinto.com",
      },
      {
        firstName: "",
        lastName: "",
        email: "test.user@riotinto.com",
      },
    ],
    notifyList: "",
    isEditable: true,
  },
  {
    id: 2,
    opportunityId: "RTXFP1000002",
    opportunity: {
      opportunityName: "Name2",
      country: "Australia",
    },
    title: "TestAlert2",
    description: "TestAlertDescription2",
    alertDate: dayjs(new Date()).add(10, "day"),
    creator: {
      firstName: "Andrew",
      lastName: "Ransom",
    },
    recipients: [
      {
        firstName: "Andrew",
        lastName: "Ransom",
        email: "andrew.ransom@riotinto.com",
      },
    ],
    notifyList: "",
    isEditable: true,
  },
  {
    id: 3,
    opportunityId: "RTXFP1000003",
    opportunity: {
      opportunityName: "Name3",
      country: "Australia",
    },
    title: "TestAlert3",
    description: "TestAlertDescription3",
    action: "TestAlertAction3",
    alertDate: dayjs(new Date()).add(20, "day"),
    creator: {
      firstName: "Test",
      lastName: "User",
    },
    recipients: [
      {
        firstName: "",
        lastName: "",
        email: "test.user@riotinto.com",
      },
      {
        firstName: "Andrew",
        lastName: "Ransom",
        email: "andrew.ransom@riotinto.com",
      },
    ],
    notifyList: "",
    isEditable: true,
  },
];

export const mockComments: CommentType[] = [
  {
    id: 1,
    opportunityId: "RTXFP1000001",
    comment: "Test Comment 1",
    responsiblePerson: {
      firstName: "Test Creator 1",
      email: "test.creator1@email.com",
    },
    createdDate: dayjs(new Date()).subtract(2, "day"),
    updatedDate: dayjs(new Date()),
  },
  {
    id: 2,
    responsiblePerson: {
      firstName: "Test",
      lastName: "User",
      email: "test.user@email.com",
    },
    opportunityId: "RTXFP1000002",
    comment: "This is a test comment.",
    createdDate: dayjs(new Date()).subtract(5, "day"),
  },
  {
    id: 3,
    responsiblePerson: {
      firstName: "Test",
      lastName: "User",
      email: "test.user@email.com",
    },
    opportunityId: "RTXFP1000001",
    comment: "This is a test comment.",
    createdDate: dayjs(new Date()).subtract(10, "day"),
  },
];

export const mockBulkUploadTotalResultSuccess: BulkUploadTotalResult = {
  results: [],
  isSuccessful: true,
};

export const mockBulkUploadTotalResultError: BulkUploadTotalResult = {
  results: [
    {
      entityId: "Opportunity1",
      isSuccessful: false,
      errors: ["Opportunity1 doesn't exist in the database."],
      message: "Missing id.",
    },
    {
      entityId: "Opportunity2",
      isSuccessful: false,
      errors: ["Opportunity2 doesn't exist in the database."],
      message: "Missing id.",
    },
  ],
  isSuccessful: false,
};

export const mockBulkUploadMissingHeaderError: BulkUploadTotalResult = {
  results: [
    {
      entityId: "",
      isSuccessful: false,
      errors: ["Header with name 'Id'[0] was not found"],
      message: "Header with name 'Id'[0] was not found",
    },
  ],
  isSuccessful: false,
};
