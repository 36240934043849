import { mapIdsToName } from "services";
import { getAllCommodities } from "features/opportunities/components";
import { IdName, Reference } from "features/opportunities/models";

export enum SearchOperatorType {
  Equal = 1,
  NotEqual,
  Contains,
  NotContain,
  In,
  NotIn,
  IsNull,
  IsNotNull,
}

export type AdvancedSearchModelType = {
  opportunityCode: AdvancedSearchModelCriteriaType<string>;
  opportunityName: AdvancedSearchModelCriteriaType<string>;
  opportunityType: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  opportunityStatus: AdvancedSearchModelCriteriaType<IdName<string>[]>;
  explorationType: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  explorationStage: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  country: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  rtxRegion: AdvancedSearchModelCriteriaType<IdName<string>[]>;
  rtxSubRegion: AdvancedSearchModelCriteriaType<IdName<string>[]>;
  primaryCommodity: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  secondaryCommodity: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  otherCommodities: AdvancedSearchModelCriteriaType<IdName<number>[]>;
  bounds?: SpatialSearchBound;
};

export type AdvancedSearchModelCriteriaType<TValue> = {
  operator: SearchOperatorType;
  value: TValue;
};

export type LatLng = { latitude: number; longitude: number };
export type SpatialSearchBound = { southWest: LatLng; northEast: LatLng };

export const convertAdvancedSearchModel = (model: AdvancedSearchModelType) => {
  return {
    opportunityCode: extractTextCriteria(model.opportunityCode),
    opportunityName: extractTextCriteria(model.opportunityName),
    opportunityType: extractListCriteria(model.opportunityType),
    opportunityStatus: extractListCriteria(model.opportunityStatus),
    explorationType: extractListCriteria(model.explorationType),
    explorationStage: extractListCriteria(model.explorationStage),
    country: extractListCriteria(model.country),
    rtxRegion: extractListCriteria(model.rtxRegion),
    rtxSubRegion: extractListCriteria(model.rtxSubRegion),
    primaryCommodity: extractListCriteria(model.primaryCommodity),
    secondaryCommodity: extractListCriteria(model.secondaryCommodity),
    otherCommodities: extractListCriteria(model.otherCommodities),
    bounds: model.bounds,
  };
};

const extractTextCriteria = (model: AdvancedSearchModelCriteriaType<string>) => {
  return shouldTextfieldSearchCriteriaBeUndefined(model.operator) && !model.value ? undefined : model;
};

const extractListCriteria = <T>(model: AdvancedSearchModelCriteriaType<IdName<T>[]>) => {
  return shouldAutocompleteSearchCriteriaBeUndefined(model.operator) && model.value.length === 0
    ? undefined
    : {
        operator: model.operator,
        value: extractIds(model.value),
      };
};

export type ApiAdvancedSearchModelType = ReturnType<typeof convertAdvancedSearchModel>;

const extractIds = <T>(list: IdName<T>[]) => list.map((item) => item.id);

const shouldAutocompleteSearchCriteriaBeUndefined = (selectedOperator: SearchOperatorType) =>
  selectedOperator === SearchOperatorType.In || selectedOperator === SearchOperatorType.NotIn;

const shouldTextfieldSearchCriteriaBeUndefined = (selectedOperator: SearchOperatorType) =>
  selectedOperator === SearchOperatorType.Equal ||
  selectedOperator === SearchOperatorType.NotEqual ||
  selectedOperator === SearchOperatorType.Contains ||
  selectedOperator === SearchOperatorType.NotContain;

const getSearchCriteriaWithMapping = (
  criteria: { operator: SearchOperatorType; value: number[] } | undefined,
  referenceData: IdName[]
) => {
  if (!criteria) {
    return { operator: SearchOperatorType.In, value: [] };
  }
  return {
    operator: criteria.operator,
    value: criteria.value.map((x) => {
      return {
        id: x,
        name: mapIdsToName(referenceData, x),
      };
    }),
  };
};

const getSearchCriteria = (criteria: { operator: SearchOperatorType; value: string[] } | undefined) => {
  if (!criteria) {
    return { operator: SearchOperatorType.In, value: [] };
  }
  return {
    operator: criteria.operator,
    value: criteria.value.map((x) => {
      return {
        id: x,
        name: x,
      };
    }),
  };
};

export const convertToAdvancedSearchModelType = (
  criteriaList: ApiAdvancedSearchModelType,
  referenceData: Reference
) => {
  const allCommodities = getAllCommodities(referenceData.commodityGroups);
  const selectedSavedSearchCriteria: AdvancedSearchModelType = {
    opportunityCode: criteriaList.opportunityCode ?? { operator: SearchOperatorType.Contains, value: "" },
    opportunityName: criteriaList.opportunityName ?? { operator: SearchOperatorType.Contains, value: "" },
    opportunityType: getSearchCriteriaWithMapping(criteriaList.opportunityType, referenceData.opportunityTypes),
    opportunityStatus: getSearchCriteria(criteriaList.opportunityStatus),
    explorationType: getSearchCriteriaWithMapping(criteriaList.explorationType, referenceData.explorationTypes),
    explorationStage: getSearchCriteriaWithMapping(criteriaList.explorationStage, referenceData.explorationStages),
    country: getSearchCriteriaWithMapping(criteriaList.country, referenceData.countries),
    rtxRegion: getSearchCriteria(criteriaList.rtxRegion),
    rtxSubRegion: getSearchCriteria(criteriaList.rtxSubRegion),
    primaryCommodity: getSearchCriteriaWithMapping(criteriaList.primaryCommodity, allCommodities),
    secondaryCommodity: getSearchCriteriaWithMapping(criteriaList.secondaryCommodity, allCommodities),
    otherCommodities: getSearchCriteriaWithMapping(criteriaList.otherCommodities, allCommodities),
    bounds: criteriaList.bounds,
  };
  return selectedSavedSearchCriteria;
};
