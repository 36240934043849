import { z } from "zod";

import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const responsiblePersonSchema = z.object({
  id: z.number(),
  firstName: z
    .string()
    .min(2, VALIDATION_MESSAGES.AT_LEAST_N_CHARACTERS(2))
    .max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80)),
  lastName: z.string().max(80, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(80)).optional(),
  email: z.string().email().optional(),
});

export interface ResponsiblePersonType extends z.infer<typeof responsiblePersonSchema> {
  inputValue?: string;
}
