import { CommodityWithColor } from "components/models";
import { getAllCommodities } from "features/opportunities/components";
import { CommodityColor, IdNameWithSequence, OpportunityType, Reference } from "features/opportunities/models";

export const mergeCommodityWithColor = (
  commodities: IdNameWithSequence[],
  commodityColors: CommodityColor[]
): CommodityWithColor[] => {
  var commoditiesWithColor = commodities
    .map((idName) => {
      const color = commodityColors[idName.id]?.commodityHexColor;
      return {
        id: idName.id,
        name: idName.name,
        color: color ? color : undefined,
      };
    })
    .filter(Boolean);

  return commoditiesWithColor;
};

export const getFilteredCommoditiesWithColor = (referenceData: Reference, searchResult: OpportunityType[]) => {
  const searchResultByCommodities = searchResult.map((e) => e.primaryCommodityId);
  const flattenedCommodities = getAllCommodities(referenceData.commodityGroups);
  const filteredCommoditites = flattenedCommodities.filter((e: IdNameWithSequence) =>
    searchResultByCommodities.includes(e.id)
  );
  filteredCommoditites.sort((a, b) => (a.name < b.name ? -1 : 1));
  return mergeCommodityWithColor(filteredCommoditites, referenceData.commodityColors);
};
