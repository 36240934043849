import { ReactNode, MouseEvent } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

interface ConfirmationModalProps {
  dialogTitle: string;
  dialogBody?: string | ReactNode;
  cancelButtonText: string;
  confirmButtonText: string;
  show: boolean;
  onClose?: (event: MouseEvent<HTMLButtonElement>) => void;
  onConfirm?: (event: MouseEvent<HTMLButtonElement>) => void;
}

export function ConfirmationModal({
  dialogTitle,
  dialogBody,
  cancelButtonText,
  confirmButtonText,
  show,
  onClose,
  onConfirm,
}: ConfirmationModalProps) {
  const handleClose = (event: MouseEvent<HTMLButtonElement>) => {
    if (!!onClose) {
      onClose(event);
    }
  };

  const handleConfirm = (event: MouseEvent<HTMLButtonElement>) => {
    if (!!onConfirm) {
      onConfirm(event);
    }
  };

  return (
    <Dialog
      id="unsaved-work-alert"
      data-testid="unsaved-work-alert"
      open={show}
      onClose={handleClose}
      fullWidth={false}
    >
      <DialogTitle id="alert-dialog-title" data-testid="alert-dialog-title" sx={{ minWidth: "345px" }}>
        {dialogTitle}
      </DialogTitle>
      {dialogBody && (
        <DialogContent id="alert-dialog-body-content" data-testid="alert-dialog-body-content">
          {typeof dialogBody === "string" ? (
            <DialogContentText id="alert-dialog-body" data-testid="alert-dialog-body">
              {dialogBody}
            </DialogContentText>
          ) : (
            dialogBody
          )}
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="outlined"
          id="unsaved-work-alert-cancel"
          data-testid="unsaved-work-alert-cancel"
          onClick={handleClose}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant="contained"
          id="unsaved-work-alert-confirm"
          data-testid="unsaved-work-alert-confirm"
          onClick={handleConfirm}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
