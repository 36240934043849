import { useEffect, useMemo, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TablePagination,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { SxProps, Theme } from "@mui/system";

import { Mode, ReferenceDocumentLocators, UTILS } from "@constants";
import { useConfirmationModalContext } from "context";
import { ConfirmationModal } from "components";
import { AddEditReferenceDocument } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { ReferenceDocumentType } from "features/opportunities/models";
import { ActionTypes } from "features/opportunities/reducers";

const newRefDoc: ReferenceDocumentType = {
  id: 0,
  referenceDocumentTypeId: 0,
  title: "",
  notes: "",
  url: "",
  isNew: true,
  isDeleted: false,
  isUpdated: false,
};

export function ReferencesSection() {
  const { opportunity, mode, dispatch } = useOpportunityContext();
  const { setCanShow } = useConfirmationModalContext();
  const [page, setPage] = useState(0);
  const [noOfItems, setNoOfItems] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const pageSize = UTILS.PAGESIZE;
  const openEditDeleteMenu = Boolean(anchorEl);
  const [selectedReferenceDocument, setSelectedReferenceDocument] = useState<ReferenceDocumentType | null>(newRefDoc);
  const [addEditConfirmButtonText, setAddEditConfirmButtonText] = useState("");

  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [confirmButtonText, setConfirmButtonText] = useState<string>("");
  const [cancelButtonText, setCancelButtonText] = useState<string>("");
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onCloseDeleteDialog = () => {
    setSelectedReferenceDocument(null);
    setShowDeleteModal(false);
  };

  const onConfirmDeleteDialog = () => {
    if (selectedReferenceDocument) {
      let newRefDocList: ReferenceDocumentType[] = opportunity.referenceDocuments ?? [];

      if (selectedReferenceDocument.isNew) {
        newRefDocList = newRefDocList?.filter((x) => x.id !== selectedReferenceDocument.id) ?? [];
      } else {
        selectedReferenceDocument.isDeleted = true;
        let index: number = newRefDocList.findIndex((x) => x.id === selectedReferenceDocument.id);
        newRefDocList.splice(index, 1, selectedReferenceDocument);
      }

      dispatch({
        type: ActionTypes.REPLACE_REFERENCE_DOCUMENTS,
        payload: newRefDocList,
      });
    }
    setCanShow(true);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setNoOfItems(opportunity.referenceDocuments?.filter((x) => !x.isDeleted).length || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.referenceDocuments?.filter((x) => !x.isDeleted).length]);

  const toggleEditDeleteMenu = (event: React.MouseEvent<HTMLButtonElement>, selectedRefDoc: ReferenceDocumentType) => {
    setAnchorEl(event.currentTarget);
    setSelectedReferenceDocument(selectedRefDoc);
  };

  const handleCloseMenu = () => {
    setSelectedReferenceDocument(newRefDoc);
    setAnchorEl(null);
  };

  const handleEditMenu = (_event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(null);
    setAddEditConfirmButtonText("Save");
    setOpenDialog(true);
  };

  const handleDeleteMenu = (_event: React.MouseEvent<HTMLLIElement>) => {
    setAnchorEl(null);
    setDialogTitle("Delete reference?");
    setConfirmButtonText("Delete");
    setCancelButtonText("Cancel");
    setShowDeleteModal(true);
  };

  const addNewReferenceDocuments = () => {
    setSelectedReferenceDocument(newRefDoc);
    setAddEditConfirmButtonText("Add");
    setOpenDialog(true);
  };

  const onSaveAddEditDialog = () => {
    setCanShow(true);
    closeAddEditDialog();
  };

  const closeAddEditDialog = () => {
    setSelectedReferenceDocument(null);
    setOpenDialog(false);
  };

  const sxFunc = (isActiveLink: boolean): SxProps<Theme> =>
    isActiveLink
      ? { cursor: "pointer", verticalAlign: "top", overflowWrap: "break-word" }
      : {
          cursor: "default",
          pointerEvents: "none",
          verticalAlign: "top",
          overflowWrap: "break-word",
          textDecorationLine: "none",
        };

  return useMemo(
    () => {
      return (
        <>
          {mode !== Mode.View && (
            <Box sx={{ display: "flex", gap: "1rem", justifyContent: "flex-end" }}>
              <Button
                id={ReferenceDocumentLocators.AddButton}
                data-testid={ReferenceDocumentLocators.AddButton}
                variant="contained"
                size="small"
                startIcon={<AddIcon />}
                onClick={addNewReferenceDocuments}
              >
                Add
              </Button>
              <AddEditReferenceDocument
                shouldOpen={openDialog}
                initialRefDoc={selectedReferenceDocument}
                confirmButtonText={addEditConfirmButtonText}
                onSave={onSaveAddEditDialog}
                onClose={closeAddEditDialog}
              />
            </Box>
          )}
          {opportunity.referenceDocuments && (
            <>
              <List
                id={ReferenceDocumentLocators.DocumentList}
                data-testid={ReferenceDocumentLocators.DocumentList}
                sx={{ display: "flex", flexDirection: "column", paddingTop: "0" }}
              >
                {opportunity.referenceDocuments
                  .filter((x) => !x.isDeleted)
                  .slice(page * pageSize, (page + 1) * pageSize)
                  .map((referenceDocument, index) => (
                    <ListItem sx={{ paddingLeft: "2rem" }} key={ReferenceDocumentLocators.Document + index}>
                      <ListItemText data-testid={ReferenceDocumentLocators.Document + index} sx={{ display: "flex" }}>
                        <Link
                          data-testid={ReferenceDocumentLocators.Title + index}
                          variant="body1"
                          href={referenceDocument.url ?? ""}
                          sx={{
                            ...sxFunc(
                              referenceDocument.url !== undefined &&
                                referenceDocument.url !== null &&
                                referenceDocument.url !== ""
                            ),
                            verticalAlign: "top",
                            color: "inherit",
                            display: "block",
                            overflowWrap: "anywhere",
                          }}
                          underline="always"
                          target="_blank"
                          rel="noopener"
                        >
                          {referenceDocument.title}
                        </Link>
                        <Typography
                          sx={{ whiteSpace: "pre-wrap", paddingTop: ".5rem", overflowWrap: "anywhere" }}
                          data-testid={ReferenceDocumentLocators.Notes + index}
                          variant="body2"
                          color={grey[600]}
                        >
                          {referenceDocument.notes}
                        </Typography>
                      </ListItemText>
                      {mode !== Mode.View && (
                        <Box sx={{ flexGrow: 0 }}>
                          <IconButton
                            id={ReferenceDocumentLocators.EditDeleteButton + index}
                            data-testid={ReferenceDocumentLocators.EditDeleteButton + index}
                            onClick={(event) => toggleEditDeleteMenu(event, referenceDocument)}
                            aria-controls={ReferenceDocumentLocators.EditDeleteMenu}
                            aria-haspopup="true"
                            aria-expanded={openEditDeleteMenu ? "true" : undefined}
                            sx={{ p: 0 }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </Box>
                      )}
                    </ListItem>
                  ))}
              </List>
              <Box component="span">
                <TablePagination
                  component="div"
                  count={noOfItems}
                  page={page}
                  onPageChange={(_event: any, newPage: number) => setPage(newPage)}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[]}
                />
              </Box>
            </>
          )}
          <Menu
            id={ReferenceDocumentLocators.EditDeleteMenu}
            data-testid={ReferenceDocumentLocators.EditDeleteMenu}
            anchorEl={anchorEl}
            open={openEditDeleteMenu}
            onClose={handleCloseMenu}
          >
            <MenuItem
              id={ReferenceDocumentLocators.EditMenuItem}
              data-testid={ReferenceDocumentLocators.EditMenuItem}
              onClick={handleEditMenu}
            >
              Edit
            </MenuItem>
            <MenuItem
              id={ReferenceDocumentLocators.DeleteMenuItem}
              data-testid={ReferenceDocumentLocators.DeleteMenuItem}
              onClick={handleDeleteMenu}
            >
              Delete
            </MenuItem>
          </Menu>
          <ConfirmationModal
            dialogTitle={dialogTitle}
            confirmButtonText={confirmButtonText}
            cancelButtonText={cancelButtonText}
            dialogBody=""
            show={showDeleteModal}
            onClose={onCloseDeleteDialog}
            onConfirm={onConfirmDeleteDialog}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mode,
      opportunity.referenceDocuments,
      page,
      noOfItems,
      openDialog,
      openEditDeleteMenu,
      anchorEl,
      selectedReferenceDocument,
      showDeleteModal,
    ]
  );
}
