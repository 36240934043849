import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent } from "@mui/material";

import { CardBodyLocators } from "@constants";
import { PropsWithTestId } from "types";
import { useReferenceDataContext } from "context";
import { referenceDataMenuItems } from "features/opportunities/components";
import { opportunityTypeOfFactory } from "features/opportunities/constants";
import { useOpportunityContext } from "features/opportunities/context";

export function ExplorationStage() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, updateField } = useOpportunityContext();

  return useMemo(() => {
    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    return (
      <FormControl required>
        <InputLabel id={CardBodyLocators.ExplorationStage} data-testid={CardBodyLocators.ExplorationStage}>
          Exploration Stage
        </InputLabel>
        <Select
          labelId={CardBodyLocators.ExplorationStage}
          id={CardBodyLocators.ExplorationStageSelect}
          label="Exploration Stage"
          name={opportunityTypeOfFactory("explorationStageId")}
          onChange={handleSelectionChange}
          value={opportunity.explorationStageId}
          SelectDisplayProps={{ "data-testid": CardBodyLocators.ExplorationStageSelect } as PropsWithTestId}
          error={Boolean(opportunity.explorationStageId && formErrors.explorationStageId)}
        >
          {referenceDataMenuItems(referenceData?.explorationStages, "opp-exploration-stage-")}
        </Select>
        {opportunity.explorationStageId > 0 && formErrors.explorationStageId && (
          <FormHelperText>{formErrors.explorationStageId}</FormHelperText>
        )}
      </FormControl>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.explorationStageId, referenceData?.explorationStages, formErrors.explorationStageId]);
}
