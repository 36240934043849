import { useEffect, useState } from "react";

import { delimiterSeparatedStringToArray } from "services";
import { useOpportunityContext } from "features/opportunities/context";

type CustomFieldsType = {
  alternateNames: string[];
};

export const useCustomFields = () => {
  const { opportunity } = useOpportunityContext();
  const [customFields, setCustomFields] = useState<CustomFieldsType>({
    alternateNames: [],
  });

  useEffect(() => {
    setCustomFields((prevState) => ({
      ...prevState,
      alternateNames: delimiterSeparatedStringToArray(";", opportunity.opportunityAlternateNames),
    }));
  }, [opportunity.opportunityAlternateNames]);

  return {
    customAlternateNames: customFields.alternateNames,
  };
};
