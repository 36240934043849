import { z } from "zod";

import {
  additionalDetailsSchema,
  changeLogSchema,
  commercialSchema,
  generalDetailsSchema,
  geologicalSchema,
  idSchema,
  integrationsSchema,
  lineageSchema,
  locationCountrySchema,
  locationSchema,
  opportunityFeaturesSchema,
  referencesSchema,
  statusSchema,
} from "./opportunityProperties";

export const CreateOpportunitySchema = generalDetailsSchema
  .merge(statusSchema)
  .merge(geologicalSchema)
  .merge(locationSchema)
  .merge(lineageSchema)
  .merge(commercialSchema)
  .merge(additionalDetailsSchema)
  .merge(referencesSchema)
  .merge(integrationsSchema);

export const EditOpportunitySchema = CreateOpportunitySchema.merge(idSchema);
export const OpportunitySchema = EditOpportunitySchema.merge(changeLogSchema)
  .merge(locationCountrySchema)
  .merge(opportunityFeaturesSchema);

// @ts-ignore
export const MinimalOpportunitySchema = OpportunitySchema.pick({
  id: true,
  opportunityCode: true,
  opportunityName: true,
  opportunityTypeId: true,
  opportunityStatusId: true,
  responsiblePersonId: true,
  explorationStageId: true,
  explorationTypeId: true,
  primaryCommodityId: true,
  originId: true,
  companyId: true,
  company: true,
  longitude: true,
  latitude: true,
  countryId: true,
  stateProvince: true,
  rtxRegion: true,
  rtxSubRegion: true,
  spatialExtents: true,
  restrictedFlag: true,
});

// @ts-ignore
export interface OpportunityType extends z.infer<typeof OpportunitySchema> {}
export interface MinimalFormData extends z.infer<typeof MinimalOpportunitySchema> {}

export const opportunityInitialState: OpportunityType = {
  id: "",
  opportunityCode: "",
  opportunityName: "",
  opportunityTypeId: 0,
  opportunityStatusId: 0,
  responsiblePersonId: 0,
  explorationStageId: 0,
  explorationTypeId: 0,
  primaryCommodityId: 0,
  originId: 0,
  companyId: -1,
  startDate: null,
  endDate: null,
  testDate: null,
  thirdPartyNotifiedDate: null,
  createdDate: null,
  updatedDate: null,
  restrictedFlag: false,
  userFavoriteFlag: false,
};
