import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import HelpIcon from "@mui/icons-material/Help";
import HomeIcon from "@mui/icons-material/Home";
import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { ClickAwayListener, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { AppBarLocators, PATH, UTILS } from "@constants";
import { useAuthorization, useReferenceDataContext } from "context";
import { ReactComponent as ArcGisIcon } from "assets/ArcgisIcon.svg";
import { ReactComponent as PowerBiIcon } from "assets/PowerBiLogo.svg";

const openedMenu = (theme: Theme): CSSObject => ({
  width: "16rem",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  top: "3.5rem",
  zIndex: "2",
});

const closedMenu = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  top: "3.5rem",
  zIndex: "2",
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "fixed",
  width: "16rem",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMenu(theme),
    "& .MuiDrawer-paper": openedMenu(theme),
  }),
  ...(!open && {
    ...closedMenu(theme),
    "& .MuiDrawer-paper": closedMenu(theme),
  }),
  "& .MuiListItemIcon-root": { margin: ".25rem 0" },
}));

interface NavigationMenuSettings {
  powerBiLink: string;
  powerBiTarget: string;
  arcGisLink: string;
  arcGisTarget: string;
  supportLink: string;
  supportTarget: string;
}

const initialSettings: NavigationMenuSettings = {
  powerBiLink: "",
  powerBiTarget: "",
  arcGisLink: "",
  arcGisTarget: "",
  supportLink: "",
  supportTarget: "",
};

export function NavigationMenu() {
  const { isAdmin } = useAuthorization();
  const [open, setOpen] = React.useState(false);
  const { referenceData, rtxfootprintsConfigSettings } = useReferenceDataContext();
  const [navigationMenuSettings, setNavigationMenuSettings] = useState<NavigationMenuSettings>(initialSettings);

  useEffect(() => {
    if (referenceData && referenceData.rtXfootprintsConfigs) {
      const settings: NavigationMenuSettings = {
        powerBiLink: rtxfootprintsConfigSettings[UTILS.PowerBiUrlName] ?? "",
        powerBiTarget: rtxfootprintsConfigSettings[UTILS.PowerBiUrlTarget] ?? "",
        arcGisLink: rtxfootprintsConfigSettings[UTILS.ArcGisUrlName] ?? "",
        arcGisTarget: rtxfootprintsConfigSettings[UTILS.ArcGisUrlTarget] ?? "",
        supportLink: rtxfootprintsConfigSettings[UTILS.SupportUrlName] ?? "",
        supportTarget: rtxfootprintsConfigSettings[UTILS.SupportUrlTarget] ?? "",
      };
      setNavigationMenuSettings(settings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceData]);

  const toggleExpandMenu = () => {
    setOpen((prevOpenValue) => !prevOpenValue);
  };

  const handleNavigationMenyClickAway = () => {
    setOpen(false);
  };

  return useMemo(() => {
    return (
      <ClickAwayListener onClickAway={handleNavigationMenyClickAway}>
        <Box sx={{ display: "flex" }}>
          <Drawer variant="permanent" open={open}>
            <List>
              <ListItem
                key={AppBarLocators.NavigationMenuButton}
                disablePadding
                sx={{ display: "block", paddingBottom: ".5rem" }}
              >
                <ListItemButton
                  id={AppBarLocators.NavigationMenuButton}
                  data-testid={AppBarLocators.NavigationMenuButton}
                  onClick={toggleExpandMenu}
                >
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
              <Divider />
              <ListItem key={AppBarLocators.HomeMenuItemButton} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  id={AppBarLocators.HomeMenuItemButton}
                  data-testid={AppBarLocators.HomeMenuItemButton}
                  component={Link}
                  to={PATH.HOME}
                >
                  <Tooltip title={open ? "" : "Home"}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      id={AppBarLocators.HomeMenuItemLabel}
                      data-testid={AppBarLocators.HomeMenuItemLabel}
                      primary="Home"
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem key={AppBarLocators.MapMenuItemButton} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  id={AppBarLocators.MapMenuItemButton}
                  data-testid={AppBarLocators.MapMenuItemButton}
                  component={Link}
                  to={PATH.ADVANCED_SEARCH}
                >
                  <Tooltip title={open ? "" : "Map"}>
                    <ListItemIcon>
                      <LanguageIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      id={AppBarLocators.MapMenuItemLabel}
                      data-testid={AppBarLocators.MapMenuItemLabel}
                      primary="Map"
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem key={AppBarLocators.PowerBiMenuItemButton} disablePadding sx={{ display: "block" }}>
                <Divider />
                <ListItemButton
                  id={AppBarLocators.PowerBiMenuItemButton}
                  data-testid={AppBarLocators.PowerBiMenuItemButton}
                  component={Link}
                  to={navigationMenuSettings.powerBiLink}
                  target={navigationMenuSettings.powerBiTarget}
                >
                  <Tooltip title={open ? "" : "Power BI"}>
                    <ListItemIcon>
                      <PowerBiIcon width="1.5rem" height="1.5rem" />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      id={AppBarLocators.PowerBiMenuItemLabel}
                      data-testid={AppBarLocators.PowerBiMenuItemLabel}
                      primary="Power BI"
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem key={AppBarLocators.ArcGisMenuItemButton} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  id={AppBarLocators.ArcGisMenuItemButton}
                  data-testid={AppBarLocators.ArcGisMenuItemButton}
                  component={Link}
                  to={navigationMenuSettings.arcGisLink}
                  target={navigationMenuSettings.arcGisTarget}
                >
                  <Tooltip title={open ? "" : "GIS Portal layer"}>
                    <ListItemIcon>
                      <ArcGisIcon width="1.5rem" height="1.5rem" />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      id={AppBarLocators.ArcGisMenuItemLabel}
                      data-testid={AppBarLocators.ArcGisMenuItemLabel}
                      primary="GIS Portal layer"
                    />
                  )}
                </ListItemButton>
              </ListItem>
              <ListItem key={AppBarLocators.SupportMenuItemButton} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  id={AppBarLocators.SupportMenuItemButton}
                  data-testid={AppBarLocators.SupportMenuItemButton}
                  component={Link}
                  to={navigationMenuSettings.supportLink}
                  target={navigationMenuSettings.supportTarget}
                >
                  <Tooltip title={open ? "" : "Support"}>
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                  </Tooltip>
                  {open && (
                    <ListItemText
                      id={AppBarLocators.SupportMenuItemLabel}
                      data-testid={AppBarLocators.SupportMenuItemLabel}
                      primary="Support"
                    />
                  )}
                </ListItemButton>
              </ListItem>
              {isAdmin && (
                <>
                  <Divider />
                  <ListItem key={AppBarLocators.BulkUploadMenuItemButton} disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      id={AppBarLocators.BulkUploadMenuItemButton}
                      data-testid={AppBarLocators.BulkUploadMenuItemButton}
                      component={Link}
                      to={PATH.BULK_UPLOAD}
                    >
                      <Tooltip title={open ? "" : "Bulk Upload"}>
                        <ListItemIcon>
                          <UploadFileIcon />
                        </ListItemIcon>
                      </Tooltip>
                      {open && (
                        <ListItemText
                          id={AppBarLocators.BulkUploadMenuItemLabel}
                          data-testid={AppBarLocators.BulkUploadMenuItemLabel}
                          primary="Bulk Upload"
                        />
                      )}
                    </ListItemButton>
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>
        </Box>
      </ClickAwayListener>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationMenuSettings, open]);
}
