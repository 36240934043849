import { useEffect } from "react";
import { AlertColor } from "@mui/material";
import dayjs from "dayjs";
import { useLocation, useSearchParams } from "react-router-dom";

import { PATH } from "@constants";
import { ExportOpportunityList, ExportSearchCriteria } from "types";
import { useSnackBarContext } from "context";
import { useAsync } from "hooks";
import { OpportunityType } from "features/opportunities/models";
import { exportOpportunityList } from "features/opportunities/services";
import { convertAdvancedSearchModel } from "features/search/models";

import { useAdvancedSearchContext } from "../context/AdvancedSearchContext";
import { useSearchResultContext } from "../context/SearchResultContext";

export function useExportOpportunityList(opportunityList: OpportunityType[]) {
  const [exportResponse, runExportOpportunities] = useAsync(exportOpportunityList);
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { searchCriteria, selectedSavedSearch } = useAdvancedSearchContext();
  const { filterValue } = useSearchResultContext();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const opportunityIdList = opportunityList.map((opportunity) => opportunity.id);
  const isAdvancedSearch = location.pathname.startsWith(PATH.ADVANCED_SEARCH);
  const isSavedSearch = selectedSavedSearch ? selectedSavedSearch.savedSearchName.replace(/\s/g, "") : "RTXfootprints";

  const setSnackBarContentAndOpen = (msg: string, msgSeverity: AlertColor) => {
    setSnackBarContent({
      message: msg,
      severity: msgSeverity,
    });
    setOpen(true);
  };

  const generateFileExportElement = (fileUrl: string, fileName: string) => {
    const downloadElement = document.createElement("a");
    downloadElement.href = fileUrl;
    downloadElement.setAttribute("download", fileName);
    downloadElement.click();
  };

  const exportSearchCriteria: ExportSearchCriteria = {
    basicSearch: isAdvancedSearch ? null : searchParams.get("q"),
    advancedSearch: isAdvancedSearch ? convertAdvancedSearchModel(searchCriteria) : null,
    filterSearch: !!filterValue ? filterValue : null,
  };

  const exportOpportunityListData: ExportOpportunityList = {
    searchCriteria: exportSearchCriteria,
    opportunityList: opportunityIdList,
  };

  const decodeDataToExport = (data: string) => {
    const exportData = window.atob(data);
    const exportDataPercentEncoding = exportData
      .split("")
      .map((x) => "%" + ("00" + x.charCodeAt(0).toString(16)).slice(-2))
      .join("");

    return decodeURIComponent(exportDataPercentEncoding);
  };

  useEffect(() => {
    if (exportResponse.status !== "success") {
      return;
    }

    const exportedData = decodeDataToExport(exportResponse.data);
    const blob = new Blob([exportedData], {
      type: "text/csv;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);
    const exportedFileName = `${dayjs(new Date()).format("YYYYMMDD")}_${isSavedSearch}_Export.csv`;

    generateFileExportElement(url, exportedFileName);

    setSnackBarContentAndOpen("Opportunity list has been exported.", "success");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportResponse.data, exportResponse.status]);

  useEffect(() => {
    if (exportResponse.status !== "error") {
      return;
    }

    const errorMessage =
      opportunityIdList.length === 0
        ? "There are no opportunities to be exported."
        : "Something went wrong. See console for details.";

    setSnackBarContentAndOpen(errorMessage, "error");
    console.error(exportResponse.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportResponse.error, exportResponse.status]);

  return { runExportOpportunities, exportOpportunityListData };
}
