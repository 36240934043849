import { LatLng, LatLngTuple } from "leaflet";

export const UTILS = {
  DEBOUNCETIME: 500,
  PAGESIZE: 5,
  PowerBiUrlName: "PowerBIURL",
  PowerBiUrlTarget: "PowerBITargetTab",
  ArcGisUrlName: "ArcGISURL",
  ArcGisUrlTarget: "ArcGISTargetTab",
  SupportUrlName: "SupportURL",
  SupportUrlTarget: "SupportTargetTab",
};

export const getMedianLatLng = (latlngs: LatLngTuple[]): LatLngTuple => {
  const lats = latlngs.map((latLng) => latLng[0]);
  const lngs = latlngs.map((latLng) => latLng[1]);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const middleLat = (minLat + maxLat) / 2;

  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);
  const middleLng = (minLng + maxLng) / 2;

  return [middleLat, middleLng];
};

export const removeLeadingSlash = (searchText: string): string => {
  let sanitizedText = searchText.replace(/\//g, "");
  return sanitizedText;
};

export const extractErrorMessage = (errorMessage: string): string | null => {
  let matched = errorMessage.match(/Next error\(s\) occured:\* ([\s\S]*?)\r\n/);
  return matched && matched[1];
};

export const identifyArrayDimensions = (coordinates: any): string => {
  if (
    Array.isArray(coordinates) &&
    Array.isArray(coordinates[0]) &&
    Array.isArray(coordinates[0][0]) &&
    Array.isArray(coordinates[0][0][0])
  ) {
    return "3D";
  }
  if (Array.isArray(coordinates)) {
    return "2D";
  }
  return "";
};

export const geoCoordinatesLatLngs = (geometries: number[][][] | number[][], arrayDim: string) => {
  var latLngs: LatLng[] = [];
  if (arrayDim === "3D") {
    geometries.forEach((coordinates: any) => {
      coordinates.forEach((coordinatePair: number[]) => {
        latLngs.push(new LatLng(coordinatePair[1], coordinatePair[0]));
      });
    });
    return latLngs;
  }
  geometries.forEach((coordinates: any) => {
    coordinates.forEach((coordinatePair: number[]) => {
      latLngs.push(new LatLng(coordinatePair[1], coordinatePair[0]));
    });
  });
  return latLngs;
};
