import { useState } from "react";
import { Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useReferenceDataContext } from "context";
import { Mode } from "constants/modes";
import { PATH } from "constants/paths";
import { AlertsPane, CommentsPane } from "features/opportunities/components";
import { CardBodyCreateEdit } from "features/opportunities/components/CardBodyCreateEdit";
import { CardBodySkeleton } from "features/opportunities/components/CardBodySkeleton";
import { CardHeader } from "features/opportunities/components/CardHeader";
import { CardHeaderSkeleton } from "features/opportunities/components/CardHeaderSkeleton";
import { useOpportunityContext } from "features/opportunities/context";
import { useGetOpportunityAndReplaceForm, useSetScreenMode, useComment } from "features/opportunities/hooks";
import { useAlert } from "features/opportunities/hooks/useAlert";
import { useDocumentTitle } from "hooks/useDocumentTitle";

interface EditOpportunityProps {
  initialOpenAlertsPane?: boolean;
  initialOpenCommentsPane?: boolean;
}

export function EditOpportunity({ initialOpenAlertsPane, initialOpenCommentsPane }: EditOpportunityProps) {
  useDocumentTitle("Edit Opportunity");
  const [showAllFields, setShowAllFields] = useState(false);
  const [openAlertsPane, setOpenAlertsPane] = useState(initialOpenAlertsPane || false);
  const [openCommentsPane, setOpenCommentsPane] = useState(initialOpenCommentsPane || false);
  const navigate = useNavigate();
  useSetScreenMode(Mode.Edit);
  useGetOpportunityAndReplaceForm();
  const { opportunity } = useOpportunityContext();
  const { referenceData } = useReferenceDataContext();
  const { opportunityId } = useParams();
  const isDataLoading = (...objects: any[]) => objects.some((obj) => Object.keys(obj).length === 0);
  const { toggleCommentsPath } = useComment();
  const { toggleAlertsPath } = useAlert();

  const onSubmitted = (id?: string) => {
    if (!id) {
      navigate(PATH.HOME);
      return;
    }

    navigate(generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId: id }));
  };

  return (
    <>
      <Paper
        square
        sx={{ display: "flex", flexDirection: "column", height: "100%", backgroundColor: grey.A200, width: 512 }}
      >
        {isDataLoading(referenceData) || !opportunity.id ? (
          <>
            <CardHeaderSkeleton />
            <CardBodySkeleton />
          </>
        ) : (
          <>
            <CardHeader
              mode={Mode.Edit}
              showAllFields={showAllFields}
              setShowAllFields={setShowAllFields}
              setOpenAlertsPane={setOpenAlertsPane}
              setOpenCommentsPane={setOpenCommentsPane}
              onSubmitted={onSubmitted}
              onClose={() => navigate(generatePath(PATH.VIEW_OPPORTUNITY, { opportunityId }))}
              onDeleted={() => navigate(PATH.HOME)}
            />
            <CardBodyCreateEdit />
          </>
        )}
      </Paper>
      {openAlertsPane && (
        <AlertsPane
          toggleAlertsPane={() => {
            setOpenAlertsPane((prevOpenAlertsPane) => !prevOpenAlertsPane);
            navigate(generatePath(toggleAlertsPath(openAlertsPane, window.location.pathname), { opportunityId }));
          }}
        />
      )}
      {openCommentsPane && (
        <CommentsPane
          toggleCommentsPane={() => {
            setOpenCommentsPane((prevOpenCommentsPane) => !prevOpenCommentsPane);
            navigate(generatePath(toggleCommentsPath(openCommentsPane, window.location.pathname), { opportunityId }));
          }}
        />
      )}
    </>
  );
}
