import { useMsal } from "@azure/msal-react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { Box, Link, Paper, Typography, useTheme } from "@mui/material";

import crown from "assets/crown.png";
import { useAuthorization } from "context/AuthorizationContext";

export function UserProfile() {
  const { accounts, instance } = useMsal();
  const { role } = useAuthorization();
  const theme = useTheme();
  const user = accounts?.[0]?.name ?? "Test User";
  const isAndrew = user === "Ransom, Andrew (RTX)";
  const email = accounts?.[0]?.username ?? "test@riotinto.com";

  return (
    <Paper
      square
      sx={{
        display: "flex",
        position: "absolute",
        flexDirection: "column",
        padding: "1rem",
        minWidth: "20rem",
        maxWidth: "26rem",
        right: "0",
        top: "100%",
        zIndex: "2",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography>Rio Tinto</Typography>
        <Link
          sx={{ cursor: "pointer" }}
          underline="none"
          onClick={() => {
            instance.logoutRedirect();
          }}
        >
          Sign out
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          marginTop: "1rem",
        }}
      >
        <Box sx={{ position: "relative" }}>
          {isAndrew ? (
            <>
              <Box
                component="img"
                sx={{
                  width: "2.25rem",
                  height: "2.5rem",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  transform: "translate(60%, 25%)",
                }}
                src={crown}
                alt="Crown for the king!"
              />
              <WorkspacePremiumIcon sx={{ fontSize: "5rem", fill: theme.palette.primary.main }}></WorkspacePremiumIcon>
            </>
          ) : (
            <AccountCircleIcon sx={{ fontSize: "5rem", fill: theme.palette.grey[500] }}></AccountCircleIcon>
          )}
        </Box>
        <Box sx={{ flexGrow: "1", flexDirection: "column", marginLeft: "1rem" }}>
          <Typography sx={{ textOverflow: "ellipsis" }} variant="h6">
            {user}
          </Typography>
          <Typography>{email}</Typography>
          <Typography>{role}</Typography>
        </Box>
      </Box>
    </Paper>
  );
}
