import { useEffect, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";

import { CommentsLocators } from "@constants";
import { nameOfFactory } from "services";
import { useComment } from "features/opportunities/hooks";
import { CommentType } from "features/opportunities/models/comment";

interface CommentsDialogProps {
  commentsOrder: string;
  initialComment: CommentType | null;
  shouldOpen: boolean;
  onClose: () => void;
  onSave: () => void;
}

export function AddEditComment({ initialComment, shouldOpen, onClose, onSave, commentsOrder }: CommentsDialogProps) {
  const [open, toggleOpen] = useState(false);
  const { selectedComment, handleSubmit, isCommentValid, commentFormErrors, handleInputChange, resetErrors } =
    useComment(initialComment, commentsOrder);

  const handleCloseAddEditComment = () => {
    onClose();
    resetErrors();
  };

  const handleSaveComment = () => {
    if (selectedComment && !isCommentValid(selectedComment)) {
      return;
    }

    handleSubmit();
    onSave();
  };

  useEffect(() => {
    toggleOpen(shouldOpen);
  }, [shouldOpen]);

  return useMemo(
    () => {
      const nameof = nameOfFactory<CommentType>();

      return (
        <Dialog
          open={open}
          onClose={handleCloseAddEditComment}
          id={CommentsLocators.AddEditCommentDialog}
          data-testid={CommentsLocators.AddEditCommentDialog}
        >
          <DialogTitle>{selectedComment?.id === 0 ? "New Comment" : "Edit Comment"}</DialogTitle>
          <DialogContent
            sx={{
              width: "600px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              alignSelf: "stretch",
              paddingTop: "0.5rem !important",
              paddingBottom: "1.5rem",
            }}
          >
            <TextField
              id={CommentsLocators.AddEditCommentField}
              inputProps={{ "data-testid": CommentsLocators.AddEditCommentField }}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              label="Comment"
              name={nameof("comment")}
              onChange={handleInputChange}
              value={selectedComment?.comment ?? ""}
              size="medium"
              required
              error={Boolean(selectedComment?.comment !== undefined && commentFormErrors.comment)}
              helperText={
                selectedComment?.comment !== undefined && commentFormErrors.comment
                  ? commentFormErrors.comment
                  : undefined
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseAddEditComment}
              variant="outlined"
              id={CommentsLocators.CancelButton}
              data-testid={CommentsLocators.CancelButton}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSaveComment}
              id={CommentsLocators.SaveButton}
              data-testid={CommentsLocators.SaveButton}
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [open, initialComment, selectedComment, commentFormErrors]
  );
}
