import InfoIcon from "@mui/icons-material/Info";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { OpportunityAttribute } from "../models";

export type CardTooltipType = Required<Pick<OpportunityAttribute, "label" | "description">>[];

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

interface Props {
  id: string;
  fields?: CardTooltipType;
}

export function CardBodyTooltip({ id, fields }: Props) {
  const groupedFields =
    fields?.map((field, index) => (
      <TableRow
        key={index}
        sx={{
          "& td, & th": { border: 0 },
          "& td:first-of-type": { whiteSpace: "nowrap", fontWeight: "bold" },
          "& td": { padding: ".375rem .5rem" },
        }}
      >
        <TableCell align="right" sx={{ fontSize: ".75rem", color: "white", verticalAlign: "top" }}>
          {field.label}
        </TableCell>
        <TableCell sx={{ fontSize: ".75rem", color: "white" }}> {field.description}</TableCell>
      </TableRow>
    )) || [];

  const table =
    groupedFields.length > 0 ? (
      <TableContainer>
        <Table size="small">
          <TableBody>{groupedFields}</TableBody>
        </Table>
      </TableContainer>
    ) : null;

  return !!table ? (
    <CustomWidthTooltip title={table} placement="right-start">
      <IconButton id={`${id}-tooltip`} data-testid={`${id}-tooltip`} sx={{ cursor: "default" }}>
        <InfoIcon />
      </IconButton>
    </CustomWidthTooltip>
  ) : null;
}
