import { UserSavedSearch, UserSavedSearchWithCriteria } from "types";
import { apiClient as client } from "services";

export const getUserSavedSearches = () => client.get<UserSavedSearch[]>(`/UserSavedSearch`);
export const getUserSavedSearchById = (id: number) => client.get<UserSavedSearchWithCriteria>(`/UserSavedSearch/${id}`);
export const addUserSavedSearch = (searchToSave: UserSavedSearchWithCriteria) =>
  client.post<UserSavedSearch>(`/UserSavedSearch`, searchToSave);
export const updateUserSavedSearch = (id: number, searchToSave: UserSavedSearchWithCriteria) =>
  client.put<UserSavedSearch>(`/UserSavedSearch/${id}`, searchToSave);
export const removeUserSavedSearch = (id: number) => client.delete(`/UserSavedSearch/${id}`);
