import { z } from "zod";

import { VALIDATION_MESSAGES } from "features/opportunities/constants";

export const ReferenceDocumentSchema = z.object({
  id: z.number(),
  referenceDocumentTypeId: z.number().refine((val) => {
    return val !== undefined && val > 0;
  }, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK),
  title: z
    .string()
    .min(1, VALIDATION_MESSAGES.NOT_EMPTY_OR_BLANK)
    .max(250, VALIDATION_MESSAGES.NO_MORE_THAN_N_CHARACTERS(250))
    .transform((val) => val.trim()),
  url: z
    .union([z.literal(""), z.string().trim().url(VALIDATION_MESSAGES.INVALID_URL)])
    .optional()
    .transform((val) => (val === "" ? null : val))
    .refine((val) => !urlCheckForWhitespace(val!), VALIDATION_MESSAGES.INVALID_URL),
  notes: z.string().optional(),
  isDeleted: z.boolean().optional(),
  isNew: z.boolean().optional(),
  isUpdated: z.boolean().optional(),
});

export interface ReferenceDocumentType extends z.infer<typeof ReferenceDocumentSchema> {}

export const validateReferenceDocument = (referenceDocument: ReferenceDocumentType) => {
  const isValidData = ReferenceDocumentSchema.safeParse(referenceDocument);
  return isValidData;
};

const urlCheckForWhitespace = (url: string) => {
  if (url !== undefined) {
    const hasWhitespace = /\s/g.test(url);
    return hasWhitespace;
  }
  return false;
};
