import { HTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { Autocomplete, Chip, TextField, styled } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

import { delimiterSeparatedStringToArray } from "services";
import { useReferenceDataContext } from "context";
import { getAllCommodities } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType, IdNameWithSequence } from "features/opportunities/models";

type CommodityItemType = IdNameWithSequence[] | (IdNameWithSequence | null);

interface Props {
  id: string;
  idSelect: string;
  label: string;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  propKey: keyof OpportunityType;
  multiple?: boolean;
  limitTags?: number;
}

export function CommodityAutocomplete(props: Props) {
  const { commodityItem, commodityList, selectedCommoditiesIds, handleAutocompleteChange } =
    useCommodityAutocomplete(props);

  return useMemo(() => {
    return (
      <Autocomplete
        id={props.id}
        data-testid={props.idSelect}
        autoHighlight
        multiple={props.multiple}
        limitTags={props.limitTags}
        getLimitTagsText={
          props.multiple ? (number) => <Chip label={`+${number} more`} variant="outlined" /> : undefined
        }
        options={commodityList}
        getOptionLabel={(commodity) => (typeof commodity === "string" ? commodity : commodity.name)}
        getOptionDisabled={(commodity) => getOptionDisabled(selectedCommoditiesIds, commodity, commodityItem)}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        value={commodityItem}
        onChange={(_event, newValue) => handleAutocompleteChange(props.propKey, newValue)}
        renderOption={(props, commodity) => renderOption(selectedCommoditiesIds, props, commodity, commodityItem)}
        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required}
            label={props.label}
            inputProps={{ ...params.inputProps, "data-testid": props.id }}
            error={props.error}
            helperText={props.helperText}
          />
        )}
      />
    );
  }, [
    props.id,
    props.idSelect,
    props.multiple,
    props.limitTags,
    props.propKey,
    props.required,
    props.label,
    props.error,
    props.helperText,
    commodityList,
    commodityItem,
    selectedCommoditiesIds,
    handleAutocompleteChange,
  ]);
}

function useCommodityAutocomplete(props: Props) {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, updateField } = useOpportunityContext();
  const [commodityItem, setCommodityItem] = useState<CommodityItemType>(props.multiple ? [] : null);

  const commodityList = useMemo(
    () => getAllCommodities(referenceData?.commodityGroups),
    [referenceData?.commodityGroups]
  );

  const selectedCommoditiesIds = useMemo<number[]>(() => {
    const otherCommoditiesIds = delimiterSeparatedStringToArray(",", opportunity.otherCommodities)
      .map((otherCommodity) => +otherCommodity)
      .filter((otherCommodity) => !isNaN(otherCommodity));

    return [opportunity.primaryCommodityId, opportunity.secondaryCommodityId, ...otherCommoditiesIds]
      .filter((selectedCommodity) => !!selectedCommodity)
      .map((selectedCommodity) => selectedCommodity!);
  }, [opportunity.otherCommodities, opportunity.primaryCommodityId, opportunity.secondaryCommodityId]);

  const handleAutocompleteChange = useCallback(
    (name: string, value: CommodityItemType) =>
      Array.isArray(value)
        ? updateField(name, value.map((commodity) => commodity.id).join(","))
        : updateField(name, value?.id ?? undefined),
    [updateField]
  );

  useEffect(() => {
    if (!props.multiple) {
      const commodity = commodityList.find((item) => item.id === (opportunity[props.propKey] as number));
      setCommodityItem(commodity ?? null);
      return;
    }

    const ids = delimiterSeparatedStringToArray(",", opportunity[props.propKey] as string)
      .map((item) => parseInt(item, 10))
      .filter((item) => !isNaN(item));

    const selectedCommodities = commodityList.filter((item) => ids.includes(item.id));
    setCommodityItem(selectedCommodities);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commodityList, opportunity[props.propKey]]);

  return { commodityItem, commodityList, selectedCommoditiesIds, handleAutocompleteChange };
}

const NoWrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const getOptionDisabled = (
  selectedCommoditiesIds: number[],
  option: IdNameWithSequence,
  currentValue: CommodityItemType
) => {
  if (!Array.isArray(currentValue)) {
    return option.id !== currentValue?.id && selectedCommoditiesIds.includes(option.id);
  }

  const currentCommoditiesIds = currentValue
    .filter((currentCommodity) => !!currentCommodity)
    .map((currentCommodity) => currentCommodity!.id);

  return !currentCommoditiesIds.includes(option.id) && selectedCommoditiesIds.includes(option.id);
};

const renderOption = (
  selectedCommoditiesIds: number[],
  props: HTMLAttributes<HTMLLIElement>,
  option: IdNameWithSequence,
  currentCommodity: CommodityItemType
) => {
  const optionListItem = (
    <li {...props} key={option.id}>
      {option.name}
    </li>
  );

  return getOptionDisabled(selectedCommoditiesIds, option, currentCommodity) ? (
    <NoWrapTooltip
      key={`tooltip-${option.id}`}
      placement="right"
      title="This option is selected in one of the other commodity fields"
    >
      {optionListItem}
    </NoWrapTooltip>
  ) : (
    optionListItem
  );
};
