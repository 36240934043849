import "leaflet/dist/leaflet.css";
import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import L from "leaflet";
import { MapContainer, LayersControl, TileLayer, ZoomControl, useMap } from "react-leaflet";
import { useLocation } from "react-router-dom";

import { Mode, PATH, map } from "@constants";
import config from "config.json";
import { useOpportunityContext } from "features/opportunities/context";

import LegendControl from "./LegendControl";
import { MapEditor } from "./MapEditor";
import { MapEditorSearch } from "./MapEditorSearch";
import { MapEditorSpatialSearch } from "./MapEditorSpatialSearch";

export function Map() {
  const storedMapLayer = localStorage.getItem(map.mapLayerStorageKey);
  const [activeMapLayer, setMapLayer] = useState(storedMapLayer ?? map.defaultMapLayer);

  const handleTileLayerEvent = (_event: L.LeafletMouseEvent, mapLayerName: string) => {
    setMapLayer(mapLayerName);
    localStorage.setItem(map.mapLayerStorageKey, mapLayerName);
  };
  const TileLayers = config.map.layers.map((mapLayer, index) => (
    <LayersControl.BaseLayer key={index} checked={activeMapLayer === mapLayer.name} name={mapLayer.name}>
      <TileLayer
        attribution={mapLayer.attribution}
        url={mapLayer.url}
        noWrap
        eventHandlers={{
          add: (e) => handleTileLayerEvent(e.target, mapLayer.name),
        }}
      />
    </LayersControl.BaseLayer>
  ));

  useEffect(() => {
    const value = localStorage.getItem(map.mapLayerStorageKey);
    if (value) {
      setMapLayer(value);
    }
  }, []);

  return useMemo(() => {
    return (
      <MapContainer
        style={{ width: "100%", height: "100%", zIndex: 1 }}
        center={[config.map.defaultLocation.latitude, config.map.defaultLocation.longitude]}
        zoom={config.map.defaultLocation.zoom}
        zoomControl={false}
        scrollWheelZoom={true}
        maxBoundsViscosity={1}
        maxBounds={[
          [-90, -180],
          [90, 180],
        ]}
        minZoom={3}
      >
        <LayersControl>{TileLayers}</LayersControl>
        <ZoomControl position="bottomright" />
        <Coordinate position="topleft" />
        <MapLayoutInner />
      </MapContainer>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function MapLayoutInner() {
  const { mode } = useOpportunityContext();
  const location = useLocation();
  const isSearchViewScreen = useMemo(
    () => location.pathname.startsWith(PATH.SEARCH) && mode === Mode.View,
    [location.pathname, mode]
  );
  const isAdvancedSearchViewScreen = useMemo(
    () => location.pathname.startsWith(PATH.ADVANCED_SEARCH) && mode === Mode.View,
    [location.pathname, mode]
  );

  return useMemo(
    () =>
      isSearchViewScreen ? (
        <>
          <MapEditorSearch />
          {isAdvancedSearchViewScreen && <MapEditorSpatialSearch />}
          <LegendControl />
        </>
      ) : (
        <MapEditor />
      ),
    [isSearchViewScreen, isAdvancedSearchViewScreen]
  );
}

const POSITION_CLASSES = {
  bottomleft: "leaflet-bottom leaflet-left",
  bottomright: "leaflet-bottom leaflet-right",
  topleft: "leaflet-top leaflet-left",
  topright: "leaflet-top leaflet-right",
};

type CoordinateProps = {
  position: keyof typeof POSITION_CLASSES;
};

function Coordinate({ position }: CoordinateProps) {
  const map = useMap();

  const [coordinates, setCoordinates] = useState<{ latitude: string; longitude: string }>({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    map.on("mousemove", mouseMoveEvent);

    return () => {
      map.off("mousemove", mouseMoveEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mouseMoveEvent = (e: L.LeafletMouseEvent) => {
    setCoordinates({ latitude: e.latlng.wrap().lat.toFixed(6), longitude: e.latlng.wrap().lng.toFixed(6) });
  };

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  const coordinatesComponent = useMemo(() => {
    return (
      <>
        <Typography variant="h6" color="white" fontSize=".8rem">
          Longitude: {coordinates.longitude}
        </Typography>
        <Typography variant="h6" color="white" fontSize=".8rem">
          Latitude: {coordinates.latitude}
        </Typography>
      </>
    );
  }, [coordinates.latitude, coordinates.longitude]);

  return (
    <Box className={positionClass}>
      <Box className="leaflet-control leaflet-bar" sx={{ backgroundColor: grey.A700, width: "14rem" }}>
        <Box padding=".5rem">{coordinatesComponent}</Box>
      </Box>
    </Box>
  );
}
