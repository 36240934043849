import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";

import { BulkUploadLocators, SharedMessages } from "@constants";
import { BulkUploadResult } from "types";
import theme from "theme";

interface Props {
  result: BulkUploadResult;
  initialExpanded: boolean;
  resultIndex: number;
}

export function UploadResult({ result, initialExpanded, resultIndex }: Props) {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    setExpanded(initialExpanded);
  }, [initialExpanded]);

  const toggleExpanded = () => {
    setExpanded((prevExpandedValue) => !prevExpandedValue);
  };

  return (
    <Accordion expanded={expanded} onChange={toggleExpanded} key={resultIndex}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls={`${BulkUploadLocators.ErrorSummary}-${resultIndex}`}
        id={`${BulkUploadLocators.ErrorSummary}-${resultIndex}`}
        data-testid={`${BulkUploadLocators.ErrorSummary}-${resultIndex}`}
        key={resultIndex}
        sx={{ backgroundColor: theme.palette.error.light, color: "white" }}
      >
        <Typography
          variant="body1"
          id={`${BulkUploadLocators.ErrorLabel}-${resultIndex}`}
          data-testid={`${BulkUploadLocators.ErrorLabel}-${resultIndex}`}
          key={`${BulkUploadLocators.ErrorLabel}-${resultIndex}`}
          sx={{ flexGrow: 1 }}
          color="white"
        >
          {result.entityId !== "" ? result.entityId : SharedMessages.ValidationError}
        </Typography>
      </AccordionSummary>
      <AccordionDetails key={result.entityId}>
        {result.errors.length === 0 && (
          <Typography
            variant="body1"
            color={"primary.main"}
            id={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-0`}
            data-testid={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-0`}
            key={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-0`}
            sx={{ whiteSpace: "pre-line" }}
          >
            {result.message}
          </Typography>
        )}
        {result.errors.map((error, index) => (
          <Typography
            variant="body1"
            color={"primary.main"}
            id={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-${index}`}
            data-testid={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-${index}`}
            key={`${BulkUploadLocators.ErrorLabel}-${resultIndex}-${index}`}
            sx={{ whiteSpace: "pre-line" }}
          >
            {error}
          </Typography>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
