import { Dispatch, ReactNode, SetStateAction, SyntheticEvent, createContext, useContext, useState } from "react";
import { AlertColor, SnackbarCloseReason } from "@mui/material";

type SnackBarContent = {
  message: string;
  severity?: AlertColor;
  autoHideDuration?: number;
  onClose?: (event: Event | SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => void;
};

type ModalType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  snackBarContent: SnackBarContent;
  setSnackBarContent: Dispatch<SetStateAction<SnackBarContent>>;
};

export const SnackBarContext = createContext<ModalType>({
  open: false,
  setOpen: () => {},
  snackBarContent: {
    message: "",
  },
  setSnackBarContent: () => {},
});

export const useSnackBarContext = (): ModalType => {
  return useContext(SnackBarContext);
};

interface Props {
  children: ReactNode;
}

export function SnackBarContextProvider({ children }: Props) {
  const [open, setOpen] = useState(false);
  const [snackBarContent, setSnackBarContent] = useState<SnackBarContent>({
    message: "",
  });

  return (
    <SnackBarContext.Provider value={{ open, setOpen, snackBarContent, setSnackBarContent }}>
      {children}
    </SnackBarContext.Provider>
  );
}
