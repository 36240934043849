import { useEffect, useMemo, useRef } from "react";
import { debounce } from "@mui/material/utils";
import * as L from "leaflet";
import { useMap, FeatureGroup } from "react-leaflet";
import { useLocation, useParams } from "react-router-dom";

import { getMedianLatLng, UTILS } from "@constants";
import { areCoordinatesValid, getCustomMarker } from "services";
import { useReferenceDataContext } from "context";
import { parseAndPopulatePolygons } from "components/utils";
import { MousePosition, useTooltipContext } from "context/TooltipContext";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType } from "features/opportunities/models";
import { useSearchResultContext } from "features/search/context/SearchResultContext";
import { isSearchPath } from "features/search/services";

L.Icon.Default.imagePath = "/leaflet_images/";

export function MapEditorSearch() {
  const { setOpenTooltip, setMousePosition, setOpportunity, setReferenceData } = useTooltipContext();
  const { searchOpportunityResult, setSelectedOpportunity } = useSearchResultContext();
  const { opportunity } = useOpportunityContext();
  const { referenceData, commoditiesWithColor } = useReferenceDataContext();
  const featureGroupRef = useRef<L.FeatureGroup>(new L.FeatureGroup());
  const { opportunityId } = useParams();
  const map = useMap();
  const location = useLocation();

  const handleMouseOver = (opp: OpportunityType, mousePosition: MousePosition) => {
    setOpportunity(opp);
    setMousePosition(mousePosition);
    setReferenceData(referenceData);
    setOpenTooltip(true);
  };

  useEffect(() => {
    if (areCoordinatesValid(opportunity)) {
      panTo(map, opportunity.latitude!, opportunity.longitude!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity.latitude, opportunity.longitude]);

  useEffect(() => {
    return () => {
      panTo.clear();
      setOpenTooltip(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOnClickHandler = (opportunity: OpportunityType) => {
    setSelectedOpportunity(opportunity);
    panTo(map, opportunity.latitude!, opportunity.longitude!);
    map.invalidateSize();
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (map) {
        map.invalidateSize();
      }
    });
    if (map) {
      resizeObserver.observe(map.getContainer());
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [map]);

  const panTo = useMemo(
    () =>
      debounce(
        (map: L.Map, latitude: number, longitude: number) => map.panTo([latitude, longitude]),
        UTILS.DEBOUNCETIME
      ),
    []
  );

  const addMarkers = (isSelectedViewMode: Boolean) => {
    featureGroupRef.current.clearLayers();

    searchOpportunityResult
      ?.filter((opp) => areCoordinatesValid(opp))
      .forEach((opp) => {
        const marker = getMarker(opp, isSelectedViewMode);
        marker.addTo(featureGroupRef.current);

        marker.on("click", () => {
          setOpenTooltip(false);
          addOnClickHandler(opp);
        });

        marker.on("mouseover", function (e: L.LeafletMouseEvent) {
          if (isSelectedViewMode && opportunityId === opp.id) {
            return;
          }
          handleMouseOver(opp, { x: e.originalEvent.x, y: e.originalEvent.y });
        });

        marker.on("mouseout", () => {
          setOpenTooltip(false);
        });
      });
  };

  const addPolygons = () => {
    searchOpportunityResult
      ?.filter((opp) => opportunityId === opp.id)
      .forEach((opp) => {
        if (!opp.spatialExtents) {
          return;
        }
        parseAndPopulatePolygons(opp.spatialExtents, featureGroupRef);
      });
  };

  const getColor = (opportunity: OpportunityType) => {
    if (!referenceData) {
      return "#44444";
    }
    const commodity = commoditiesWithColor?.find((item) => item.id === opportunity.primaryCommodityId);
    return commodity?.color ?? "#44444";
  };

  const getMarker = (opportunity: OpportunityType, isSelectedViewMode: Boolean) => {
    if (isSelectedViewMode && opportunityId === opportunity.id) {
      return new L.Marker([opportunity.latitude!, opportunity.longitude!]);
    }
    return new L.Marker([opportunity.latitude!, opportunity.longitude!], {
      icon: getCustomMarker(opportunity, getColor(opportunity)),
    });
  };

  useEffect(() => {
    if (isSearchPath(location) && !opportunityId) {
      addMarkers(false);
    }
    if (isSearchPath(location) && opportunityId) {
      addMarkers(true);
      addPolygons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, opportunityId, searchOpportunityResult, referenceData]);

  useEffect(() => {
    if (!opportunityId) {
      map.invalidateSize();

      const latLongs = searchOpportunityResult
        .filter((opp) => areCoordinatesValid(opp))
        .map(({ latitude, longitude }) => [latitude, longitude]) as [number, number][];

      const medianLatLng = getMedianLatLng(latLongs);

      if (medianLatLng && !isNaN(medianLatLng[0]) && !isNaN(medianLatLng[1])) {
        panTo(map, medianLatLng[0], medianLatLng[1]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpportunityResult]);

  return <FeatureGroup ref={featureGroupRef}></FeatureGroup>;
}
