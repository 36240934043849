import { OptionalString } from "types";
import {
  IdName,
  FilteredOpportunityAttribute,
  OpportunityAttribute,
  OpportunityAttributeEnum,
  OpportunityAttributes,
  OpportunityTypeEnum,
} from "features/opportunities/models";

import { CardTooltipType } from "../components";

export const shouldDisplayField = (
  attribute: OpportunityAttribute | undefined,
  opportunityType: keyof OpportunityAttribute
) => {
  if (!attribute) {
    return true;
  }

  return attribute[opportunityType] === true;
};

export const getFieldsToDisplay = (
  attributes: OpportunityAttributes | undefined,
  opportunityType: keyof OpportunityAttribute
) => {
  if (!attributes || Object.keys(attributes).length === 0) {
    return {};
  }

  const filteredAttributes = (
    Object.keys(attributes) as Array<keyof OpportunityAttributes>
  ).reduce<FilteredOpportunityAttribute>((acc, attribute) => {
    acc[attribute] = shouldDisplayField(attributes[attribute], opportunityType);
    return acc;
  }, {});

  return filteredAttributes;
};

export const getSelectedOpportunityAttribute = (opportunityType: number): keyof OpportunityAttribute => {
  switch (opportunityType) {
    case OpportunityTypeEnum.generative:
    default:
      return OpportunityAttributeEnum.Generative;
    case OpportunityTypeEnum.project:
      return OpportunityAttributeEnum.Project;
    case OpportunityTypeEnum.target:
      return OpportunityAttributeEnum.Target;
    case OpportunityTypeEnum.programme:
      return OpportunityAttributeEnum.Programme;
  }
};

export const getSelectedOpportunityId = (
  opportunityTypes: IdName[] | undefined,
  opportunityType: OptionalString
): number => {
  if (!opportunityType || !opportunityTypes || opportunityTypes.length === 0) {
    return 0;
  }

  return opportunityTypes.find((item) => item.name.toLowerCase() === opportunityType.toLowerCase())?.id || 0;
};

const tooltipKeys: Array<keyof OpportunityAttributes> = [
  "opportunityName",
  "opportunityCode",
  "opportunityTypeId",
  "opportunityStatusId",
  "responsiblePersonId",
  "explorationStageId",
  "explorationTypeId",
  "primaryCommodityId",
  "originId",
  "companyId",
];

export enum FieldsGroup {
  GeneralDetails = 1,
  Status,
  GeologicalDetails,
  Location,
  Lineage,
  Commercial,
  AdditionalDetails,
  References,
  Integration,
  Changelog,
}

export const getAllTooltipFields = (opportunityAttributes?: OpportunityAttributes) =>
  getFilteredAndSortedFields(opportunityAttributes, false)?.reduce<Record<number, CardTooltipType>>((prev, cur) => {
    const opportunityAttribute = opportunityAttributes
      ? opportunityAttributes[cur as keyof OpportunityAttributes]
      : null;

    if (!opportunityAttribute || !opportunityAttribute.label) {
      return prev;
    }

    const fieldGroupSequence = prev[opportunityAttribute.fieldGroupSequence] || [];
    fieldGroupSequence.push(createTooltipField(opportunityAttribute));
    return { ...prev, [opportunityAttribute.fieldGroupSequence]: fieldGroupSequence };
  }, {}) || [];

export const getSummaryTooltipFields = (opportunityAttributes?: OpportunityAttributes) =>
  getFilteredAndSortedFields(opportunityAttributes, true)?.reduce<CardTooltipType>((prev, cur) => {
    const opportunityAttribute = opportunityAttributes
      ? opportunityAttributes[cur as keyof OpportunityAttributes]
      : null;

    if (!opportunityAttribute || !opportunityAttribute.label) {
      return prev;
    }

    return [...prev, createTooltipField(opportunityAttribute)];
  }, []) || [];

const getFilteredAndSortedFields = (opportunityAttributes?: OpportunityAttributes, onlySummaryFields?: boolean) => {
  return !opportunityAttributes
    ? null
    : Object.keys(opportunityAttributes)
        .filter(
          (opportunityAttribute) =>
            !onlySummaryFields || tooltipKeys.includes(opportunityAttribute as keyof OpportunityAttributes)
        )
        .sort((a, b) => {
          const opportunityAttributeA = opportunityAttributes[a as keyof OpportunityAttributes];
          const opportunityAttributeB = opportunityAttributes[b as keyof OpportunityAttributes];

          if (!opportunityAttributeA || !opportunityAttributeB) {
            return 0;
          }

          return (
            opportunityAttributeA.fieldGroupSequence - opportunityAttributeB.fieldGroupSequence ||
            opportunityAttributeA.fieldSequence - opportunityAttributeB.fieldSequence
          );
        });
};

const createTooltipField = (opportunityAttribute: OpportunityAttribute) => ({
  label: opportunityAttribute.label || "",
  description: opportunityAttribute.description || "No description",
});
