import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiAccordion: {
      defaultProps: { square: true, disableGutters: true, defaultExpanded: true },
      styleOverrides: { root: { borderRadius: "4px" } },
    },
    MuiTextField: {
      defaultProps: { size: "small", fullWidth: true, variant: "outlined" },
    },
    MuiFormControl: { defaultProps: { size: "small", fullWidth: true, variant: "outlined" } },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: red[500],
        },
      },
    },
    MuiChip: {
      defaultProps: { size: "small" },
      styleOverrides: { root: { backgroundColor: "#b1b4b7" } },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& input[required] ~ fieldset": {
            borderWidth: "3px",
          },
          "& textarea[required] ~ fieldset": {
            borderWidth: "3px",
          },
        },
      },
    },
    MuiSelect: { defaultProps: { MenuProps: { PaperProps: { sx: { maxHeight: 300 } } } } },
    MuiAutocomplete: {
      defaultProps: { size: "small", fullWidth: true },
      styleOverrides: {
        listbox: {
          '& li[aria-disabled="true"]': {
            pointerEvents: "inherit !important",
          },
          '& li[aria-disabled="true"]:hover, & li[aria-disabled="true"]:focus': {
            background: "white !important",
          },
          '& li[aria-disabled="true"]:active': {
            background: "white !important",
            pointerEvents: "none !important",
          },
          '& li[aria-selected="true"]': {
            background: "rgba(102, 194, 170, 1) !important",
          },
          "& :hover": {
            background: "rgba(102, 194, 170, 0.15) !important",
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 3 },
      styleOverrides: {
        root: {
          backgroundColor: "#f2f4f6",
        },
      },
    },

    MuiListItemButton: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "rgba(102, 194, 170, 0.15)",
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(102, 194, 170, 1)",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          ":hover": {
            backgroundColor: "rgba(102, 194, 170, 0.15)",
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(102, 194, 170, 1)",
          },
        },
      },
    },
    MuiAccordionSummary: { styleOverrides: { content: { alignItems: "center" } } },
  },
  palette: {
    primary: {
      main: "#007461",
    },
    secondary: {
      main: "#009A72",
    },
    background: {
      default: "#f2f4f6",
      paper: "#FBFDFF",
    },
    action: {
      selected: "rgba(102, 194, 170, 1)",
    },
    grey: {
      "500": "#b1b4b7",
    },
    warning: {
      light: "rgba(237, 108, 2, 0.2)",
      main: "rgba(237, 108, 2, 0.5)",
      dark: "rgba(237, 108, 2, 1)",
    },
  },
});

export default theme;
