import { Dispatch, SetStateAction, createContext, useContext, ReactNode, useState } from "react";

type ModalType = {
  canShow: boolean;
  setCanShow: Dispatch<SetStateAction<boolean>>;
};

export const ConfirmationModalContext = createContext<ModalType>({
  canShow: false,
  setCanShow: () => {},
});

export const useConfirmationModalContext = () => {
  return useContext(ConfirmationModalContext);
};

interface Props {
  children: ReactNode;
}

export function ConfirmationModalProvider({ children }: Props) {
  const [canShow, setCanShow] = useState(false);

  return (
    <ConfirmationModalContext.Provider value={{ canShow, setCanShow }}>{children}</ConfirmationModalContext.Provider>
  );
}
