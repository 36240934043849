import {
  NamedEntity,
  OpportunityType,
  opportunityInitialState,
  OwnershipType,
  ParentOpportunityType,
  ResponsibleGroupType,
  ResponsiblePersonType,
  ReferenceDocumentType,
  AlertType,
  CommentType,
} from "features/opportunities/models";

export enum ActionTypes {
  UPDATE_FORM_ACTION = "UPDATE_FORM",
  REPLACE_FORM_ACTION = "REPLACE_FORM",
  REPLACE_OWNERSHIPS = "REPLACE_OWNERSHIPS",
  ADD_RESPONSIBLE_GROUP_ACTION = "ADD_RESPONSIBLE_GROUP",
  REMOVE_RESPONSIBLE_GROUP_ACTION = "REMOVE_RESPONSIBLE_GROUP",
  ADD_RESPONSIBLE_PERSON_ACTION = "ADD_RESPONSIBLE_PERSON",
  REMOVE_RESPONSIBLE_PERSON_ACTION = "REMOVE_RESPONSIBLE_PERSON",
  ADD_NAMED_ENTITY_COMPANY_ACTION = "ADD_NAMED_ENTITY_COMPANY",
  ADD_NAMED_ENTITY_ORIGIN_ACTION = "ADD_NAMED_ENTITY_ORIGIN",
  ADD_NAMED_ENTITY_THIRD_PARTY_ACTION = "ADD_NAMED_ENTITY_THRID_PARTY",
  REMOVE_NAMED_ENTITY_COMPANY_ACTION = "REMOVE_NAMED_ENTITY_COMPANY",
  REMOVE_NAMED_ENTITY_ORIGIN_ACTION = "REMOVE_NAMED_ENTITY_ORIGIN",
  REMOVE_NAMED_ENTITY_THIRD_PARTY_ACTION = "REMOVE_NAMED_ENTITY_THRID_PARTY",
  ADD_PARENT_OPPORTUNITY_ACTION = "ADD_PARENT_OPPORTUNITY",
  REMOVE_PARENT_OPPORTUNITY_ACTION = "REMOVE_PARENT_OPPORTUNITY",
  REPLACE_REFERENCE_DOCUMENTS = "REPLACE_REFERENCE_DOCUMENTS",
  REPLACE_ALERTS = "REPLACE_ALERTS",
  REPLACE_COMMENTS = "REPLACE_COMMENTS",
}

interface Payload<T> {
  name: string;
  value: T;
}

export type FormActionType =
  | { type: ActionTypes.UPDATE_FORM_ACTION; payload: Payload<unknown> }
  | { type: ActionTypes.REPLACE_OWNERSHIPS; payload: OwnershipType[] }
  | { type: ActionTypes.REPLACE_FORM_ACTION; payload: OpportunityType }
  | { type: ActionTypes.ADD_RESPONSIBLE_GROUP_ACTION; payload: ResponsibleGroupType }
  | { type: ActionTypes.REMOVE_RESPONSIBLE_GROUP_ACTION }
  | { type: ActionTypes.ADD_RESPONSIBLE_PERSON_ACTION; payload: ResponsiblePersonType }
  | { type: ActionTypes.REMOVE_RESPONSIBLE_PERSON_ACTION }
  | { type: ActionTypes.ADD_NAMED_ENTITY_COMPANY_ACTION; payload: NamedEntity }
  | { type: ActionTypes.ADD_NAMED_ENTITY_ORIGIN_ACTION; payload: NamedEntity }
  | { type: ActionTypes.ADD_NAMED_ENTITY_THIRD_PARTY_ACTION; payload: NamedEntity }
  | { type: ActionTypes.REMOVE_NAMED_ENTITY_COMPANY_ACTION }
  | { type: ActionTypes.REMOVE_NAMED_ENTITY_ORIGIN_ACTION }
  | { type: ActionTypes.REMOVE_NAMED_ENTITY_THIRD_PARTY_ACTION }
  | { type: ActionTypes.ADD_PARENT_OPPORTUNITY_ACTION; payload: ParentOpportunityType }
  | { type: ActionTypes.REMOVE_PARENT_OPPORTUNITY_ACTION }
  | { type: ActionTypes.REPLACE_REFERENCE_DOCUMENTS; payload: ReferenceDocumentType[] }
  | { type: ActionTypes.REPLACE_ALERTS; payload: AlertType[] }
  | { type: ActionTypes.REPLACE_COMMENTS; payload: CommentType[] };

export const opportunityReducer = (state: OpportunityType, action: FormActionType): OpportunityType => {
  switch (action.type) {
    case ActionTypes.UPDATE_FORM_ACTION:
      const { name, value } = action.payload;
      return {
        ...state,
        [name]: value,
      };
    case ActionTypes.REPLACE_FORM_ACTION:
      const newOpportunity = action.payload;
      return {
        ...opportunityInitialState,
        ...newOpportunity,
      };
    case ActionTypes.REPLACE_OWNERSHIPS:
      return {
        ...state,
        ownerships: action.payload,
      };
    case ActionTypes.ADD_RESPONSIBLE_GROUP_ACTION:
      return {
        ...state,
        responsibleGroup: action.payload,
      };
    case ActionTypes.REMOVE_RESPONSIBLE_GROUP_ACTION:
      return {
        ...state,
        responsibleGroup: undefined,
      };
    case ActionTypes.ADD_RESPONSIBLE_PERSON_ACTION:
      return {
        ...state,
        responsiblePerson: action.payload,
      };
    case ActionTypes.REMOVE_RESPONSIBLE_PERSON_ACTION:
      return {
        ...state,
        responsiblePerson: undefined,
      };
    case ActionTypes.ADD_NAMED_ENTITY_COMPANY_ACTION:
      return {
        ...state,
        company: action.payload,
      };
    case ActionTypes.ADD_NAMED_ENTITY_ORIGIN_ACTION:
      return {
        ...state,
        originName: action.payload,
      };
    case ActionTypes.ADD_NAMED_ENTITY_THIRD_PARTY_ACTION:
      return {
        ...state,
        thirdPartyName: action.payload,
      };
    case ActionTypes.REMOVE_NAMED_ENTITY_COMPANY_ACTION:
      return {
        ...state,
        company: undefined,
      };
    case ActionTypes.REMOVE_NAMED_ENTITY_ORIGIN_ACTION:
      return {
        ...state,
        originName: undefined,
      };
    case ActionTypes.REMOVE_NAMED_ENTITY_THIRD_PARTY_ACTION:
      return {
        ...state,
        thirdPartyName: undefined,
      };
    case ActionTypes.ADD_PARENT_OPPORTUNITY_ACTION:
      return {
        ...state,
        parentOpportunity: action.payload,
      };
    case ActionTypes.REMOVE_PARENT_OPPORTUNITY_ACTION:
      return {
        ...state,
        parentOpportunity: undefined,
      };
    case ActionTypes.REPLACE_REFERENCE_DOCUMENTS:
      return {
        ...state,
        referenceDocuments: action.payload,
      };
    case ActionTypes.REPLACE_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case ActionTypes.REPLACE_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    default:
      return state;
  }
};
