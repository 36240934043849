import { ComponentType, ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { PATH } from "@constants";
import { useAuthorization } from "context/AuthorizationContext";
import { CreateOpportunity } from "pages/Opportunities/CreateOpportunity";

interface AuthorizedComponentProps {
  component: ComponentType;
}

const removeEditFromUrl = (): string => {
  return window.location.pathname.replace("/edit", "");
};

export const AuthorizedComponent = ({ component: Component }: AuthorizedComponentProps): ReactElement => {
  const { isReadOnly } = useAuthorization();
  const navigate = useNavigate();

  useEffect(() => {
    if (isReadOnly) {
      const pathToNavigate = Component === CreateOpportunity ? PATH.HOME : removeEditFromUrl();
      navigate({
        pathname: pathToNavigate,
        search: window.location.search,
      });
    }
  }, [navigate, isReadOnly, Component]);

  return <Component />;
};
