import { useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchIcon from "@mui/icons-material/Search";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Link as MuiLink,
  alpha,
  styled,
  Divider,
  ClickAwayListener,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import { PROJECT, PATH, AppBarLocators } from "@constants";
import { UserProfile } from "components";
import { ReactComponent as RTlogo } from "assets/RT_2022_Monogram_Red-White_RGB.svg";
import { BasicSearch } from "features/search/components";

const Search = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  position: "relative",
  borderRadius: 20,
  backgroundColor: theme.palette.background.default,
  color: alpha(theme.palette.common.black, 0.75),
  marginLeft: 0,
  width: "35em",
  height: "30px",
  boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "35em",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export function MenuAppBar() {
  const theme = useTheme();
  const [showProfile, setShowProfile] = useState(false);
  const menuBarBgColor = "#1E2226";

  const toggleProfile = () => {
    setShowProfile((prevShowValue) => !prevShowValue);
  };

  const handleProfileClickAway = () => {
    setShowProfile(false);
  };

  return (
    <AppBar position="static" sx={{ flex: "0 1 56px", backgroundColor: menuBarBgColor }}>
      <Toolbar sx={{ [theme.breakpoints.up("xs")]: { minHeight: "56px" } }}>
        <Box sx={{ display: "flex" }}>
          <IconButton
            id="home-button"
            data-testid="home-button"
            size="large"
            aria-label="go home"
            aria-controls="menu-appbar"
            component={Link}
            to={PATH.HOME}
            sx={{ color: theme.palette.grey[500], p: 0 }}
          >
            <RTlogo width="2rem" height="2rem" />
          </IconButton>
        </Box>
        <Typography
          id="home-link"
          data-testid="home-link"
          variant="h6"
          noWrap
          component={Link}
          to={PATH.HOME}
          sx={{
            mr: 2,
            color: "inherit",
            textDecoration: "none",
            position: "relative",
            paddingLeft: "20px",
          }}
        >
          {PROJECT.NAME}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <BasicSearch />
          <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: theme.spacing(0.5, 0) }} />
          <MuiLink
            id={AppBarLocators.AdvancedSearch}
            data-testid={AppBarLocators.AdvancedSearch}
            fontSize=".875rem"
            padding={theme.spacing(1, 2, 1, 1)}
            component={Link}
            to={PATH.ADVANCED_SEARCH}
            state={{ preserveSearchResults: false }}
          >
            Advanced
          </MuiLink>
        </Search>
        <Box sx={{ flexGrow: 1 }} />
        <ClickAwayListener onClickAway={handleProfileClickAway}>
          <Box sx={{ flexGrow: 0 }}>
            <IconButton id="user-menu" data-testid="user-menu" onClick={toggleProfile} sx={{ p: 0 }}>
              <AccountCircleIcon sx={{ fontSize: "2rem", color: theme.palette.grey[500] }}></AccountCircleIcon>
            </IconButton>
            {showProfile && <UserProfile />}
          </Box>
        </ClickAwayListener>
      </Toolbar>
    </AppBar>
  );
}
