import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Mode, PATH } from "@constants";
import { OpportunityModel, OptionalString } from "types";
import { formToRequestModel } from "services";
import { useSnackBarContext, useConfirmationModalContext } from "context";
import { useAsync } from "hooks";
import { useOpportunityContext } from "features/opportunities/context";
import { MinimalFormData, OpportunityType } from "features/opportunities/models";
import { createOpportunity, editOpportunity } from "features/opportunities/services";

export function useSubmitOpportunityDispatcher(onSubmitted?: (id?: string) => void) {
  const { mode, parsedForm, formErrors, showAllFields, setFormErrors } = useOpportunityContext();
  const { canShow: hasModifiedChanged, setCanShow } = useConfirmationModalContext();
  const [response, run] = useAsync(mode === Mode.Create ? createOpportunity : editOpportunity);
  const navigate = useNavigate();
  const { setOpen, setSnackBarContent } = useSnackBarContext();
  const { opportunityId } = useParams();
  const disabled = useMemo(
    () => !hasModifiedChanged || Object.keys(formErrors).length > 0 || response.status === "loading",
    [formErrors, hasModifiedChanged, response.status]
  );

  const handleSubmitted = (id?: string) => {
    if (onSubmitted) {
      onSubmitted(id);
    } else {
      navigate(PATH.HOME);
    }
  };

  const submitClickHandler = () => {
    setCanShow(false);
    const requestData = getFormRequestData(parsedForm, showAllFields, opportunityId);
    mode === Mode.Create ? run(requestData) : run(opportunityId || "", requestData);
  };

  useEffect(() => {
    if (response.status !== "error") {
      return;
    }

    if (response.validationErrors) {
      setFormErrors((prev) => ({ ...prev, ...response.validationErrors }));
      return;
    }

    console.error("ERROR", response.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.error, response.validationErrors, response.status]);

  useEffect(() => {
    if (response.status !== "success") {
      return;
    }

    setSnackBarContent({
      message: "Opportunity has been saved",
      severity: "success",
    });

    setOpen(true);
    response.data ? handleSubmitted(response.data.id) : handleSubmitted(opportunityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data, response.status]);

  return { submitClickHandler, hasModifiedChanged, disabled };
}

const getFormRequestData = (
  formData: OpportunityType,
  showAllFieldsToggle: boolean,
  opportunityId: OptionalString
): OpportunityModel => {
  const requestData: MinimalFormData | OpportunityModel =
    !showAllFieldsToggle && !opportunityId
      ? {
          id: formData.id,
          opportunityCode: formData.opportunityCode,
          opportunityName: formData.opportunityName,
          opportunityTypeId: formData.opportunityTypeId,
          opportunityStatusId: formData.opportunityStatusId,
          responsiblePersonId: formData.responsiblePersonId,
          explorationStageId: formData.explorationStageId,
          explorationTypeId: formData.explorationTypeId,
          primaryCommodityId: formData.primaryCommodityId,
          originId: formData.originId,
          companyId: formData.companyId,
          longitude: formData.longitude,
          latitude: formData.latitude,
          countryId: formData.countryId,
          stateProvince: formData.stateProvince,
          rtxRegion: formData.rtxRegion,
          rtxSubRegion: formData.rtxSubRegion,
          spatialExtents: formData.spatialExtents,
          company: formData.companyId === 0 ? formData.company : undefined,
          restrictedFlag: formData.restrictedFlag,
        }
      : formToRequestModel(formData);

  return requestData as OpportunityModel;
};
