import { useEffect, useRef } from "react";
import { useLeafletContext } from "@react-leaflet/core";
import { LeafletContextInterface } from "@react-leaflet/core/lib/context";
import leaflet, { Map, Control } from "leaflet";
import * as L from "leaflet";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import "./EditControl.css";

type EventHandlerType = { [key: string]: string };

const eventHandlers: EventHandlerType = {
  onEdited: L.Draw.Event.EDITED,
  onDrawStart: L.Draw.Event.DRAWSTART,
  onDrawStop: L.Draw.Event.DRAWSTOP,
  onDrawVertex: L.Draw.Event.DRAWVERTEX,
  onEditStart: L.Draw.Event.EDITSTART,
  onEditMove: L.Draw.Event.EDITMOVE,
  onEditResize: L.Draw.Event.EDITRESIZE,
  onEditVertex: L.Draw.Event.EDITVERTEX,
  onEditStop: L.Draw.Event.EDITSTOP,
  onDeleted: L.Draw.Event.DELETED,
  onDeleteStart: L.Draw.Event.DELETESTART,
  onDeleteStop: L.Draw.Event.DELETESTOP,
};

interface EditControlProps extends Control.DrawConstructorOptions {
  leaflet?: {
    map: Map;
    layerContainer: {
      addLayer: (layer: any) => void;
      removeLayer: (layer: any) => void;
    };
  } | null;
  onCreated?: (v: L.DrawEvents.Created) => void;
  onMounted?: (e: L.Control.Draw) => void;
  onEdited?: (v: L.DrawEvents.Edited) => void;
  onDrawStart?: (v: L.DrawEvents.DrawStart) => void;
  onDrawStop?: (v: L.DrawEvents.DrawStop) => void;
  onDrawVertex?: (v: L.DrawEvents.DrawVertex) => void;
  onEditStart?: (v: L.DrawEvents.EditStart) => void;
  onEditMove?: (v: L.DrawEvents.EditMove) => void;
  onEditResize?: (v: L.DrawEvents.EditResize) => void;
  onEditVertex?: (v: L.DrawEvents.EditVertex) => void;
  onEditStop?: (v: L.DrawEvents.EditStop) => void;
  onDeleted?: (v: L.DrawEvents.Deleted) => void;
  onDeleteStart?: (v: L.DrawEvents.DeleteStart) => void;
  onDeleteStop?: (v: L.DrawEvents.DeleteStop) => void;
  [key: string]: any;
}

function EditControl(props: EditControlProps) {
  const context = useLeafletContext();
  const drawRef = useRef<Control.Draw>();

  const onDrawCreate: L.LeafletEventHandlerFn = (event: L.LeafletEvent) => {
    const { onCreated } = props;
    const parsedEvent = event as L.DrawEvents.Created;
    const container = context.layerContainer || context.map;
    container.addLayer(parsedEvent.layer);
    onCreated && onCreated(parsedEvent);
  };

  useEffect(() => {
    const { map } = context;
    const { onMounted } = props;

    for (const key in eventHandlers) {
      map.on(eventHandlers[key], (evt: leaflet.LeafletEvent) => {
        const handlers = Object.keys(eventHandlers).filter((handler) => eventHandlers[handler] === evt.type);
        if (handlers.length !== 1) {
          return;
        }

        const handler = handlers[0];
        props[handler] && props[handler](evt);
      });
    }

    map.on(leaflet.Draw.Event.CREATED, onDrawCreate);
    drawRef.current = createDrawElement(props, context);
    map.addControl(drawRef.current);
    onMounted && onMounted(drawRef.current);

    return () => {
      map.off(leaflet.Draw.Event.CREATED, onDrawCreate);

      for (const key in eventHandlers) {
        if (props[key]) {
          map.off(eventHandlers[key], props[key]);
        }
      }

      drawRef.current?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

function createDrawElement(props: EditControlProps, context: LeafletContextInterface) {
  const { layerContainer } = context;
  const { draw, edit, position } = props;
  const options: Control.DrawConstructorOptions = {
    edit: {
      ...edit,
      featureGroup: layerContainer as L.FeatureGroup,
    },
  };

  if (draw) {
    options.draw = { ...draw };
  }

  if (position) {
    options.position = position;
  }

  return new Control.Draw(options);
}

export { EditControl };
