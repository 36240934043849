import { arcGISClient } from "services";
import { GeocodeResult, GeocodeError } from "features/arcgis/models";

const arcgisGeocodeURL = process.env.REACT_APP_ARCGIS_GEOCODE_URL || "";
const arcgisGeocodeApiKey = process.env.REACT_APP_ARCGIS_APIKEY;

const reverseGeocode = (longitude: number, latitude: number) =>
  arcGISClient.get<GeocodeResult | GeocodeError>(arcgisGeocodeURL, {
    params: {
      location: `${longitude},${latitude}`,
      f: "json",
      token: arcgisGeocodeApiKey,
      langCode: "EN",
    },
  });

export { reverseGeocode };
