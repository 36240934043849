import dayjs, { Dayjs } from "dayjs";

import { DateFieldType, OptionalString } from "types";

export const DateMinValue: Dayjs = dayjs(new Date("1900/01/01"));
export const DateMaxValue: Dayjs = dayjs(new Date("2999/12/31"));

export const initializeDateField = (date?: string | null): DateFieldType => {
  if (!date) {
    return null;
  }

  const dayjsObject = dayjs(date);

  if (!dayjsObject.isValid()) {
    return null;
  }

  return dayjsObject;
};

export const safeConvertToISOString = (value: Dayjs | null) => {
  return value && value.isValid() ? value.toISOString() : undefined;
};

export const formatAsShortDate = (date: DateFieldType | OptionalString) => formatDate(date, "YYYY/MM/DD");

export const formatAsDateTime = (date: DateFieldType | OptionalString) => formatDate(date, "YYYY/MM/DD hh:mm:ss A");

const formatDate = (date: DateFieldType | OptionalString, format: string) => {
  if (!date) {
    return "";
  }

  if (typeof date === "string") {
    return initializeDateField(date)?.format(format) ?? "";
  }

  return date.format(format);
};
