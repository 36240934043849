export const AppBarLocators = {
  AdvancedSearch: "advanced-search",
  NavigationMenuButton: "navigation-menu-button",
  HomeMenuItemButton: "home-menu-item-button",
  HomeMenuItemLabel: "home-menu-item-label",
  MapMenuItemButton: "map-menu-item-button",
  MapMenuItemLabel: "map-menu-item-label",
  PowerBiMenuItemButton: "powerbi-menu-item-button",
  PowerBiMenuItemLabel: "powerbi-menu-item-label",
  ArcGisMenuItemButton: "arcgis-menu-item-button",
  ArcGisMenuItemLabel: "arcgis-menu-item-label",
  SupportMenuItemButton: "support-menu-item-button",
  SupportMenuItemLabel: "support-menu-item-label",
  BulkUploadMenuItemButton: "bulk-upload-menu-item-button",
  BulkUploadMenuItemLabel: "bulk-upload-menu-item-label",
};

export const CardHeaderLocators = {
  EditOpportunityButton: "edit-opportunity",
  UpsertOpportunityButton: "upsert-opportunity",
  CloseButton: "close-button",
  OpportunityName: "opportunity-name-label",
  ShowAllFields: "show-all-fields",
  OpportunityId: "opportunity-id-label",
  OpportunityLocation: "opportunity-location-label",
  CommentsButton: "comments-button",
  CommentsButtonTooltip: "comments-button-tooltip",
  CommentsBadge: "comments-badge",
  ReviewsComingSoon: "reviews-coming-soon",
  AlertsBadge: "alerts-badge",
  AlertsButton: "alerts-button",
  AlertsButtonTooltip: "alerts-button-tooltip",
  CopyLinkButton: "copy-link-button",
  CopyLinkButtonTooltip: "copy-link-button-tooltip",
  RestrictedCheckbox: "restricted-checkbox",
  RestrictedWarning: "restricted-warning",
  RestrictedLabel: "restricted-label",
  FavoriteTooltip: "favorite-tooltip",
  FavoriteButton: "favorite-button",
};

export const CardBodyLocators = {
  OpportunityName: "opportunity-name",
  OpportunityCode: "opportunity-code",
  AlternateNames: "alternate-names",
  AlternateNamesSelect: "alternate-names-select",
  Description: "description",
  OpportunityType: "opportunity-type",
  OpportunityTypeSelect: "opportunity-type-select",
  OpportunitySubType: "opportunity-sub-type",
  OpportunitySubTypeSelect: "opportunity-sub-type-select",
  OpportunityStatus: "status",
  OpportunityStatusSelect: "opportunity-status-select",
  ResponsiblePerson: "responsible-person",
  ResponsiblePersonSelect: "responsible-person-select",
  ResponsibleGroup: "responsible-group",
  ResponsibleGroupSelect: "responsible-group-select",
  ResponsibleGroupAdd: "responsible-group-name-add",
  ResponsibleGroupButtonCancel: "responsible-group-button-cancel",
  ResponsibleGroupButtonAdd: "responsible-group-button-add",
  Group: "group",
  PrimaryCommodity: "primary-commodity",
  PrimaryCommoditySelect: "primary-commodity-select",
  SecondaryCommodity: "secondary-commodity",
  SecondaryCommoditySelect: "secondary-commodity-select",
  OtherCommodity: "other-commodity",
  OtherCommodities: "other-commodities",
  OtherCommoditiesSelect: "other-commodities-select",
  Longitude: "longitude",
  Latitude: "latitude",
  Country: "country",
  StateProvince: "state-province",
  RtxRegion: "rtx-region",
  RtxSubRegion: "rtx-sub-region",
  CompanySelect: "company",
  CompanyId: "companyId",
  ThirdPartyNameId: "thirdPartyNameId",
  ThirdPartyNameSelect: "third-party-name-select",
  OriginNameId: "originNameId",
  OriginNameSelect: "origin-name-select",
  NamedEntityButtonCancel: "named-entity-button-cancel",
  NamedEntityButtonAdd: "named-entity-button-add",
  NamedEntityNameAdd: "named-entity-name-add",
  NamedEntityAddressAdd: "named-entity-address-add",
  NamedEntityContactInfoAdd: "named-entity-contact-info-add",
  NamedEntityTypeAdd: "named-entity-type-add",
  NamedEntityTypeHelperTextAdd: "named-entity-type-add-helper-text",
  ExplorationType: "exploration-type",
  ExplorationTypeSelect: "exploration-type-select",
  ExplorationStage: "exploration-stage",
  ExplorationStageSelect: "exploration-stage-select",
  StartDate: "start-date",
  EndDate: "end-date",
  DepositStage: "deposit-stage",
  DepositStageSelect: "deposit-stage-select",
  TestDate: "test-date",
  TestMethod: "test-method",
  TestMethodSelect: "test-method-select",
  DepositType: "deposit-type-input",
  LocationAccuracy: "accuracy",
  LocationAccuracySelect: "accuracy-select",
  Origin: "origin",
  OriginSelect: "origin-select",
  ParentOpportunity: "parent-opportunity",
  ParentOpportunitySelect: "parent-opportunity-select",
  FundingType: "funding-type",
  FundingTypeSelect: "funding-type-select",
  CommercialStatus: "commercial-status",
  CommercialStatusSelect: "commercial-status-select",
  ThirdPartyNotifiedDate: "third-party-notified-date",
  ConfidentialityAgreement: "confidentiality-agreement",
  SiteConditions: "site-conditions",
  PreviousWorkSummary: "previous-work-summary",
  WatchlistPriority: "watch-list-priority",
  MissingData: "missing-data",
  IncludeForMetrics: "include-for-metrics",
  Notes: "notes",
  LandfolioId: "landfolio-id",
  LandfolioIdValidateButton: "landfolio-id-validate-button",
  LandfolioIdValidateButtonTooltip: "landfolio-id-validate-button-tooltip",
  LandfolioIdValidIcon: "landfolio-id-valid-icon",
  LandfolioIdInvalidIcon: "landfolio-id-invalid-icon",
  OwnershipTable: "ownership-table",
};

export const CardBodySectionsLocators = {
  GeneralDetails: "general-details-section",
  Status: "status-section",
  Geological: "geological-section",
  Location: "location-section",
  Lineage: "lineage-section",
  Commercial: "commercial-section",
  Ownership: "ownership-section",
  AdditionalDetails: "additional-details-section",
  References: "references-section",
  Integration: "integration-section",
  Changelog: "changelog-section",
};

export const BasicSearchLocators = {
  NoResults: "search-no-results",
  SearchOpportunity: "search-opportunity",
  KeywordSearchInput: "keyword-search-input",
  LoadingResults: "search-loading-results",
  FilterField: "filter-search-field",
};

export const SearchResultLocators = {
  SearchResultsCloseButton: "search-results-close-button",
  SearchResults: "search-results",
  SearchResultsList: "search-result-list",
  NoResults: "search-result-list-no-results",
  LoadingResults: "search-result-list-loading",
  ItemName: "search-item-name",
  ItemAlternateNames: "search-item-alternate-name",
  ItemCommodities: "search-item-commodities",
  ItemLocation: "search-item-full-location",
  CollapseButton: "search-results-collapse",
  SearchResultsDrawer: "search-results-drawer",
  SearchResultsCollapseHeader: "search-results-collapse-header",
  SearchResultsPrefix: "search-results",
  ExportButton: "search-results-export-button",
  ExportButtonTooltip: "search-results-export-button-tooltip",
  SortButtonTooltip: "search-results-sort-button-tooltip",
};

export const ViewOpportunityValuesLocators = {
  OpportunityCode: "view-opportunity-code-value",
  OpportunityName: "view-opportunity-name-value",
  OpportunityAlternateNames: "view-opportunity-alternate-names-value",
  OpportunityDescription: "view-opportunity-description-value",
  Company: "view-opportunity-company-value",
  OpportunityType: "view-opportunity-type-value",
  OpportunitySubType: "view-opportunity-sub-type-value",
  OpportunityStatus: "view-opportunity-status-value",
  Group: "view-opportunity-group-value",
  ResponsiblePerson: "view-opportunity-responsible-person-value",
  ResponsibleGroup: "view-opportunity-responsible-group-value",
  ExplorationStage: "view-opportunity-exploration-stage-value",
  ExplorationType: "view-opportunity-exploration-type-value",
  StartDate: "view-opportunity-start-date-value",
  EndDate: "view-opportunity-end-date-value",
  TestMethod: "view-opportunity-test-method-value",
  TestDate: "view-opportunity-test-date-value",
  DepositStage: "view-opportunity-deposit-stage-value",
  PrimaryCommodity: "view-opportunity-primary-commodity-value",
  SecondaryCommodity: "view-opportunity-secondary-commodity-value",
  OtherCommodities: "view-opportunity-other-commodities-value",
  DepositTypeGroup: "view-opportunity-deposit-type-group-value",
  DepositTypeClass: "view-opportunity-deposit-type-class-value",
  DepositTypeSubclass: "view-opportunity-deposit-type-subclass-value",
  ParentOpportunity: "view-opportunity-parent-opportunity-value",
  Longitude: "view-opportunity-longitude-value",
  Latitude: "view-opportunity-latitude-value",
  LocationAccuracy: "view-opportunity-accuracy-value",
  Country: "view-opportunity-country-value",
  StateProvince: "view-opportunity-state-province-value",
  RtxRegion: "view-opportunity-rtx-region-value",
  RtxSubRegion: "view-opportunity-rtx-sub-region-value",
  Origin: "view-opportunity-origin-value",
  OriginName: "view-opportunity-origin-name-value",
  FundingType: "view-opportunity-funding-type-value",
  CommercialStatus: "view-opportunity-commercial-status-value",
  ThirdPartyName: "view-opportunity-third-party-name-value",
  ThirdPartyNotifiedDate: "view-opportunity-date-notified-value",
  ConfidentialityAgreement: "view-opportunity-confidentiality-agreement-value",
  WatchlistPriority: "view-opportunity-watch-list-priority-value",
  IncludeForMetrics: "view-opportunity-include-for-metrics-value",
  SiteConditions: "view-opportunity-site-conditions-value",
  PreviousWorkSummary: "view-opportunity-previous-work-summary-value",
  MissingData: "view-opportunity-missing-data-value",
  Notes: "view-opportunity-notes-value",
  ReferenceWebsite: "view-opportunity-reference-website-value",
  ReferenceSpreadsheet: "view-opportunity-reference-spreadsheet-value",
  ReferencePowerPoint: "view-opportunity-reference-powerpoint-value",
  LandfolioId: "view-opportunity-landfolio-id-value",
  CreatedBy: "view-opportunity-created-by-value",
  CreatedDate: "view-opportunity-created-date-value",
  UpdatedBy: "view-opportunity-updated-by-value",
  UpdatedDate: "view-opportunity-updated-date-value",
};

export const ViewOpportunitySectionsLocators = {
  GeneralDetails: "view-opportunity-general-details-section",
  Status: "view-opportunity-status-section",
  Location: "view-opportunity-location-section",
  Geological: "view-opportunity-geological-section",
  Lineage: "view-opportunity-lineage-section",
  Commercial: "view-opportunity-commercial-section",
  AdditionalDetails: "view-opportunity-additional-details-section",
  ReferenceDocuments: "view-opportunity-references-section",
  Ownership: "view-opportunity-ownership-section",
  Integration: "view-opportunity-integration-section",
  Changelog: "view-opportunity-change-log-section",
};

export const OwnershipLocators = {
  OwnershipSubtitle: "ownership-subtitle",
  DeleteOwnerships: "delete-ownerships",
  AddEditOwnership: "add-edit-ownership",
  OwnershipFieldsBox: "opportunity-ownership-fields",
  OwnerName: "opportunity-owner",
  OwnerNameSelect: "opportunity-owner-select",
  OwnershipShareRate: "ownership-share-rate",
  OwnershipFromDate: "ownership-from-date",
  OwnershipToDate: "ownership-to-date",
  OwnerNameColumn: "owner-name-column-value",
  ShareRateColumn: "share-rate-column-value",
  FromDateColumn: "from-date-column-value",
  ToDateColumn: "to-date-column-value",
  ViewOwnershipSection: "view-opportunity-ownership-section",
  ViewOwnershipRow: "view-ownership-row",
  OwnershipRow: "ownership-row",
};

export const AdvancedSearchLocators = {
  AdvancedSearchDrawer: "advanced-search-drawer",
  AdvancedSearchHeader: "advanced-search-header",
  AdvancedSearchCollapseHeader: "advanced-search-collapse-header",
  CollapseButton: "advanced-search-collapse",
  CloseButton: "advanced-search-close-button",
  SearchButton: "advanced-search-button",
  ClearButton: "advanced-search-clear-button",
  UserSearchName: "user-search-name",
  UserSearchNameTextfield: "user-search-name-textfield",
  SaveUserSearchButton: "save-user-search-button",
  DeleteUserSearchButton: "delete-user-search-button",
};

export const TooltipLocators = {
  Popper: "tooltip-popper",
  Container: "tooltip-container",
  OpportunityName: "tooltip-opportunity-name",
  OpportunityId: "tooltip-opportunity-id",
  OpportunityTypeAndStatus: "tooltip-opportunity-type-and-status",
  OpportunityLocation: "tooltip-opportunity-location",
  ResponsiblePerson: "tooltip-responsible-person",
  ExplorationStage: "tooltip-exploration-stage",
};

export const LandingPageLocators = {
  OpportunityKeyLocator: "opportunity",
  OpportunityLocator: "opportunity-",
  OpportunityNameLocator: "opportunity-name-",
  OpportunityNameTooltipLocator: "opportunity-name-tooltip-",
  OpportunityCountry: "opportunity-country-",
  OpportunityCommodity: "opportunity-commodity-",
  OpportunityCommodityTooltip: "opportunity-commodity-tooltip-",
  OpportunityFavoriteTooltip: "opportunity-favorite-tooltip",
  OpportunityFavoriteButton: "opportunity-favorite-button",
  OpportunityAlertDate: "opportunity-alert-date",
  OpportunityAlertTitle: "opportunity-alert-title",
  AlertsSectionLocator: "alerts-opportunities",
};

export const LegendControlLocators = {
  LegendLocator: "legend",
  LegendBtnLocator: "legend-btn",
  LegendUlWrapperLocator: "legend-ul-wrapper",
  LegendUlLocator: "legend-ul",
  LegendshapeClassName: "legend-type-shape",
  LegendSizeClassName: "legend-type-size",
};

export const ReferenceDocumentLocators = {
  Document: "reference-document-",
  DocumentList: "reference-document-list",
  Title: "reference-document-title-",
  Notes: "reference-document-notes-",
  AddButton: "add-new-reference-document-button",
  EditDeleteButton: "edit-delete-button-",
  EditDeleteMenu: "edit-delete-menu",
  DeleteMenuItem: "delete-menu-item",
  EditMenuItem: "edit-menu-item",
  NewDocument: "new-reference-document",
  NewTitle: "new-reference-document-title",
  NewTypeLabel: "new-reference-document-type-label",
  NewTypeSelect: "new-reference-document-type-select",
  NewUrl: "new-reference-document-url",
  NewNotes: "new-reference-document-notes",
  NewReferenceDialog: "add-new-reference-document-dialog",
};

export const AlertLocators = {
  PanelHeader: "alert",
  Alert: "alert-",
  AlertList: "alert-list",
  AlertDialogHeader: "alert-dialog-header",
  NewAlertDialog: "add-new-alert-dialog",
  NewAlert: "new-alert",
  NewTitle: "new-alert-title",
  NewDescription: "new-alert-description",
  NewAction: "new-alert-action",
  NewAlertDate: "new-alert-date",
  NewNotifyList: "new-notify-list",
  NewNotifyListSelect: "notified-users-select",
  Title: "alert-title",
  Description: "alert-description",
  Action: "alert-action",
  AlertDate: "alert-date",
  NotifyList: "notify-list",
  CancelButton: "cancel-add-edit-alert",
  SaveButton: "save-add-edit-alert",
  EditDeleteButton: "edit-delete-menu-alert",
};

export const ExtendedPanelLocators = {
  Panel: "-extended-panel",
  EditDeleteMenu: "-edit-delete-menu",
  DeleteMenuItem: "-delete-menu-item",
  EditMenuItem: "-edit-menu-item",
  AddButton: "-add-new-button",
  SortButton: "-sort-button",
  CloseButton: "-close-button",
};

export const CommentsLocators = {
  PanelHeader: "comment",
  Comment: "comment-",
  CommentList: "comment-list",
  AddEditCommentDialog: "add-edit-comment-dialog",
  AddEditCommentField: "add-edit-comment-field",
  CancelButton: "comment-cancel-button",
  SaveButton: "comment-save-button",
  CommentHeader: "comment-header",
  CommentContent: "comment-content",
  CommentUpdatedDate: "comment-updated-date",
  EditDeleteButton: "edit-delete-menu-comment",
};

export const BulkUploadLocators = {
  HeaderLabel: "bulk-upload-header-text",
  FilePicker: "bulk-upload-file-picker",
  FilePickerHelperText: "bulk-upload-file-picker-helper-text",
  TableNameLabel: "bulk-upload-table-name-label",
  TableNameSelect: "bulk-upload-table-name-select",
  TableNameOption: "bulk-upload-table-name-option-",
  UploadButton: "bulk-upload-submit-button",
  SuccessLabel: "bulk-upload-success-label",
  ErrorLabel: "bulk-upload-error-label",
  ErrorSummary: "bulk-upload-error-summary",
  ErrorSummaryAccordion: "bulk-upload-error-summary-accordion",
  ErrorResultBlock: "bulk-upload-result-block",
  TemplateButton: "bulk-upload-template-download-button",
  ErrorResultWrapper: "error-result-wrapper",
};
