import { FormEvent, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router-dom";

import { AdvancedSearchLocators, CardBodyLocators, PATH } from "@constants";
import { ValidationErrors } from "models";
import { useReferenceDataContext } from "context";
import { CollapsableCard } from "components";
import { getAllCommodities } from "features/opportunities/components";
import { Country, IdName } from "features/opportunities/models";
import { useAdvancedSearchContext } from "features/search/context/AdvancedSearchContext";
import { SearchOperatorType } from "features/search/models";

import { AdvancedSearchCriteriaAutocomplete } from "./AdvancedSearchCriteriaAutocomplete";
import { AdvancedSearchCriteriaOperator } from "./AdvancedSearchCriteriaOperator";
import { AdvancedSearchCriteriaTextfield } from "./AdvancedSearchCriteriaTextfield";
import { SaveSearch } from "./SaveSearch";

interface Props {
  formErrors: ValidationErrors;
  searchButtonHandler: () => void;
  clearButtonHandler: () => void;
}

export function AdvancedSearchCriteria({ formErrors, searchButtonHandler, clearButtonHandler }: Props) {
  const { referenceData } = useReferenceDataContext();
  const navigate = useNavigate();
  const commodities = getAllCommodities(referenceData?.commodityGroups);
  const { searchCriteria, clearSearchCriteria } = useAdvancedSearchContext();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    searchButtonHandler();
  };

  useEffect(() => {
    clearSearchCriteria();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CollapsableCard
      cardId={AdvancedSearchLocators.AdvancedSearchDrawer}
      cardHeaderId={AdvancedSearchLocators.AdvancedSearchHeader}
      cardHeaderText="Advanced Search"
      collapsedHeaderId={AdvancedSearchLocators.AdvancedSearchCollapseHeader}
      collapseButtonId={AdvancedSearchLocators.CollapseButton}
      closeButtonId={AdvancedSearchLocators.CloseButton}
      cancelButtonId={AdvancedSearchLocators.ClearButton}
      cancelButtonText="Clear"
      cancelButtonHandler={clearButtonHandler}
      submitButtonId={AdvancedSearchLocators.SearchButton}
      submitButtonText="Search"
      submitFormHandler={onSubmit}
      closeButtonHandler={() => {
        navigate(PATH.HOME);
      }}
    >
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid xs={12}>
          <SaveSearch />
        </Grid>
        <Grid xs={8}>
          <AdvancedSearchCriteriaTextfield
            id={CardBodyLocators.OpportunityCode}
            label="Opportunity Code"
            propKey="opportunityCode"
            value={searchCriteria.opportunityCode.value || ""}
            errorText={formErrors["searchQuery.opportunityCode"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="textfield"
            id={CardBodyLocators.OpportunityCode}
            propKey="opportunityCode"
            value={searchCriteria.opportunityCode.operator || SearchOperatorType.Contains}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaTextfield
            id={CardBodyLocators.OpportunityName}
            label="Name"
            propKey="opportunityName"
            value={searchCriteria.opportunityName.value || ""}
            errorText={formErrors["searchQuery.opportunityName"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="textfield"
            id={CardBodyLocators.OpportunityName}
            propKey="opportunityName"
            value={searchCriteria.opportunityName.operator || SearchOperatorType.Contains}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.OpportunityType}
            label="Type"
            options={referenceData?.opportunityTypes || []}
            propKey="opportunityType"
            selectedValues={searchCriteria.opportunityType.value || []}
            errorText={formErrors["searchQuery.opportunityType"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.OpportunityType}
            propKey="opportunityType"
            value={searchCriteria.opportunityType.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="string"
            id={CardBodyLocators.OpportunityStatus}
            label="Status"
            options={opportunityStatuses(referenceData?.opportunityStatuses) || []}
            propKey="opportunityStatus"
            selectedValues={searchCriteria.opportunityStatus.value || []}
            errorText={formErrors["searchQuery.opportunityStatus"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.OpportunityStatus}
            propKey="opportunityStatus"
            value={searchCriteria.opportunityStatus.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.ExplorationType}
            label="Exploration Type"
            options={referenceData?.explorationTypes || []}
            propKey="explorationType"
            selectedValues={searchCriteria.explorationType.value || []}
            errorText={formErrors["searchQuery.explorationType"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.ExplorationType}
            propKey="explorationType"
            value={searchCriteria.explorationType.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.ExplorationStage}
            label="Exploration Stage"
            options={referenceData?.explorationStages || []}
            propKey="explorationStage"
            selectedValues={searchCriteria.explorationStage.value || []}
            errorText={formErrors["searchQuery.explorationStage"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.ExplorationStage}
            propKey="explorationStage"
            value={searchCriteria.explorationStage.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.Country}
            label="Country"
            options={referenceData?.countries || []}
            propKey="country"
            selectedValues={searchCriteria.country.value || []}
            errorText={formErrors["searchQuery.country"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.Country}
            propKey="country"
            value={searchCriteria.country.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="string"
            id={CardBodyLocators.RtxRegion}
            label="RTX Region"
            options={rtxRegions(referenceData?.countries, "rtxRegion") || []}
            propKey="rtxRegion"
            selectedValues={searchCriteria.rtxRegion.value || []}
            errorText={formErrors["searchQuery.rtxRegion"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.RtxRegion}
            propKey="rtxRegion"
            value={searchCriteria.rtxRegion.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="string"
            id={CardBodyLocators.RtxSubRegion}
            label="RTX Subregion"
            options={rtxRegions(referenceData?.countries, "rtxSubRegion") || []}
            propKey="rtxSubRegion"
            selectedValues={searchCriteria.rtxSubRegion.value || []}
            errorText={formErrors["searchQuery.rtxSubRegion"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.RtxSubRegion}
            propKey="rtxSubRegion"
            value={searchCriteria.rtxSubRegion.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.PrimaryCommodity}
            label="Primary Commodity"
            options={commodities || []}
            propKey="primaryCommodity"
            selectedValues={searchCriteria.primaryCommodity.value || []}
            errorText={formErrors["searchQuery.primaryCommodity"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.PrimaryCommodity}
            propKey="primaryCommodity"
            value={searchCriteria.primaryCommodity.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.SecondaryCommodity}
            label="Secondary Commodity"
            options={commodities || []}
            propKey="secondaryCommodity"
            selectedValues={searchCriteria.secondaryCommodity.value || []}
            errorText={formErrors["searchQuery.secondaryCommodity"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.SecondaryCommodity}
            propKey="secondaryCommodity"
            value={searchCriteria.secondaryCommodity.operator || SearchOperatorType.In}
          />
        </Grid>

        <Grid xs={8}>
          <AdvancedSearchCriteriaAutocomplete
            type="number"
            id={CardBodyLocators.OtherCommodity}
            label="Other Commodities"
            options={commodities || []}
            propKey="otherCommodities"
            selectedValues={searchCriteria.otherCommodities.value || []}
            errorText={formErrors["searchQuery.otherCommodities"]}
          />
        </Grid>
        <Grid xs={4}>
          <AdvancedSearchCriteriaOperator
            type="autocomplete"
            id={CardBodyLocators.OtherCommodity}
            propKey="otherCommodities"
            value={searchCriteria.otherCommodities.operator || SearchOperatorType.In}
          />
        </Grid>
      </Grid>
    </CollapsableCard>
  );
}

const rtxRegions = (countries: Country[] | undefined, key: keyof Country) =>
  countries?.reduce<IdName<string>[]>((accumulator, currentValue) => {
    const propValue = currentValue[key]?.toString();

    if (!propValue) {
      return accumulator;
    }

    if (!accumulator.some((obj) => obj.name === propValue)) {
      accumulator.push({ id: propValue, name: propValue });
    }
    return accumulator;
  }, []) ?? [];

const opportunityStatuses = (opportunityStatuses: IdName[] | undefined) =>
  opportunityStatuses?.reduce<IdName<string>[]>((acc, curr) => {
    if (acc.findIndex((item) => item.name === curr.name) === -1) {
      acc.push({ id: curr.name, name: curr.name });
    }

    return acc;
  }, []) || [];
