import { useMemo } from "react";
import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DatePicker } from "@mui/x-date-pickers";

import { CardBodyLocators } from "@constants";
import { DateFieldType, PropsWithTestId } from "types";
import { nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import { ConditionalGrid, referenceDataMenuItems } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType } from "features/opportunities/models";

import { ExplorationStage } from "./Status/ExplorationStage";
import { ExplorationType } from "./Status/ExplorationType";
import { OpportunityStatus } from "./Status/OpportunityStatus";

export function StatusSection() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();

  return useMemo(() => {
    const nameof = nameOfFactory<OpportunityType>();

    const handleSelectionChange = (event: SelectChangeEvent<number>) => {
      updateField(event.target.name, event.target.value);
    };

    const handleDateChange = (name: string, value?: DateFieldType) => {
      updateField(name, value);
    };

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["opportunityStatusId"] ?? true}>
            <OpportunityStatus />
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["startDate"] ?? true}>
            <DatePicker
              label="Start Date"
              inputFormat="YYYY/MM/DD"
              value={opportunity.startDate}
              onChange={(newValue) => handleDateChange(nameof("startDate"), newValue)}
              renderInput={(params) => (
                <TextField
                  id={CardBodyLocators.StartDate}
                  {...params}
                  inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.StartDate }}
                  error={Boolean(opportunity.startDate && formErrors.startDate)}
                  helperText={opportunity.startDate && formErrors.startDate ? formErrors.startDate : undefined}
                />
              )}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["endDate"] ?? true}>
            <DatePicker
              label="End Date"
              inputFormat="YYYY/MM/DD"
              value={opportunity.endDate}
              onChange={(newValue) => handleDateChange(nameof("endDate"), newValue)}
              renderInput={(params) => (
                <TextField
                  id={CardBodyLocators.EndDate}
                  {...params}
                  inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.EndDate }}
                  error={Boolean(opportunity.endDate && formErrors.endDate)}
                  helperText={opportunity.endDate && formErrors.endDate ? formErrors.endDate : undefined}
                />
              )}
            />
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["explorationStageId"] ?? true}>
            <ExplorationStage />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["explorationTypeId"] ?? true}>
            <ExplorationType />
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["depositStageId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.DepositStage} data-testid={CardBodyLocators.DepositStage}>
                Deposit Stage
              </InputLabel>
              <Select
                labelId={CardBodyLocators.DepositStage}
                id={CardBodyLocators.DepositStageSelect}
                label="Deposit Stage"
                name={nameof("depositStageId")}
                onChange={handleSelectionChange}
                value={opportunity.depositStageId || 0}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.DepositStageSelect } as PropsWithTestId}
                error={Boolean(opportunity.depositStageId && formErrors.depositStageId)}
              >
                {referenceDataMenuItems(referenceData?.depositStages, "opp-deposit-stage-")}
              </Select>
              {Number(opportunity.depositStageId) > 0 && formErrors.depositStageId && (
                <FormHelperText>{formErrors.depositStageId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["testDate"] ?? true}>
            <DatePicker
              label="Test Date"
              inputFormat="YYYY/MM/DD"
              value={opportunity.testDate}
              onChange={(newValue) => handleDateChange(nameof("testDate"), newValue)}
              renderInput={(params) => (
                <TextField
                  id={CardBodyLocators.TestDate}
                  {...params}
                  inputProps={{ ...params.inputProps, "data-testid": CardBodyLocators.TestDate }}
                  error={Boolean(opportunity.testDate && formErrors.testDate)}
                  helperText={opportunity.testDate && formErrors.testDate ? formErrors.testDate : undefined}
                />
              )}
            />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["testMethodId"] ?? true}>
            <FormControl>
              <InputLabel id={CardBodyLocators.TestMethod} data-testid={CardBodyLocators.TestMethod}>
                Test Method
              </InputLabel>
              <Select
                labelId={CardBodyLocators.TestMethod}
                id={CardBodyLocators.TestMethodSelect}
                label="Test Method"
                name={nameof("testMethodId")}
                onChange={handleSelectionChange}
                value={opportunity.testMethodId || 0}
                SelectDisplayProps={{ "data-testid": CardBodyLocators.TestMethodSelect } as PropsWithTestId}
                error={Boolean(opportunity.testMethodId && formErrors.testMethodId)}
              >
                {referenceDataMenuItems(referenceData?.testMethods, "opp-test-method-")}
              </Select>
              {Number(opportunity.testMethodId) > 0 && formErrors.testMethodId && (
                <FormHelperText>{formErrors.testMethodId}</FormHelperText>
              )}
            </FormControl>
          </ConditionalGrid>
        </Grid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.startDate,
    opportunity.endDate,
    opportunity.testDate,
    opportunity.depositStageId,
    opportunity.testMethodId,
    referenceData?.depositStages,
    referenceData?.testMethods,
    formErrors.startDate,
    formErrors.endDate,
    formErrors.explorationStageId,
    formErrors.explorationTypeId,
    formErrors.depositStageId,
    formErrors.testDate,
    formErrors.testMethodId,
    opportunityAttributes,
  ]);
}
