import { Chip } from "@mui/material";

import { OpportunityType, ReferenceCommodityGroups } from "features/opportunities/models";

import { getCommoditiesArrayFromOpportunity } from "./commodityUtils";

export function chips(opportunity: OpportunityType, referenceData: ReferenceCommodityGroups[] | undefined) {
  let chipsValues = getCommoditiesArrayFromOpportunity(opportunity, referenceData);
  if (chipsValues.length > 0) {
    return chipsValues.map((chip, index) => <Chip key={"chip-" + index} data-testid={"chip-" + index} label={chip} />);
  }

  return <Chip id="test-chip" data-testid={"test-chip"} label="Gold" sx={{ opacity: 0 }} />;
}
