import { useEffect } from "react";

import { Mode } from "@constants";
import { useConfirmationModalContext } from "context";
import { useOpportunityContext } from "features/opportunities/context";

export function useSetScreenMode(mode: Mode) {
  const { setViewMode } = useOpportunityContext();
  const { setCanShow } = useConfirmationModalContext();

  useEffect(() => {
    setViewMode(mode);
    setCanShow(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);
}
