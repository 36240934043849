import { useMemo } from "react";
import Grid from "@mui/material/Unstable_Grid2";

import { CardBodyLocators } from "@constants";
import { nameOfFactory } from "services";
import { useReferenceDataContext } from "context";
import { NestedListAutoComplete, Criteria } from "components";
import { CommodityAutocomplete, ConditionalGrid, PrimaryCommodity } from "features/opportunities/components";
import { useOpportunityContext } from "features/opportunities/context";
import { OpportunityType, ReferenceDepositTypeGroup } from "features/opportunities/models";

export function GeologicalSection() {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, formErrors, opportunityAttributes, updateField } = useOpportunityContext();
  const criteria = {
    identifier: "id",
    labelProperty: "name",
    sortProperty: "displaySequence",
    children: ["depositTypeGroups", "depositTypeClasses", "depositTypeSubClasses"],
    childrenLabels: ["Deposit Group", "Deposit Class", "Deposit Subclass"],
  } as Criteria;
  var depositTypes: number[] = Object.assign(
    [],
    [opportunity.depositTypeGroupId, opportunity.depositTypeClassId, opportunity.depositTypeSubClassId]
  );
  depositTypes = depositTypes.filter((e) => e !== undefined && e !== 0);

  return useMemo(() => {
    const nameof = nameOfFactory<OpportunityType>();

    const handleNestedListData = (depositTypeIds: number[]) => {
      const [groupId, classId, subClassId] = depositTypeIds.reverse();

      updateField(nameof("depositTypeGroupId"), groupId || 0);
      updateField(nameof("depositTypeClassId"), classId || 0);
      updateField(nameof("depositTypeSubClassId"), subClassId || 0);
    };

    return (
      <Grid container spacing={2} alignItems={"flex-start"}>
        <Grid container xs={12}>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["primaryCommodityId"] ?? true}>
            <PrimaryCommodity />
          </ConditionalGrid>
          <ConditionalGrid xs={6} displayGrid={opportunityAttributes["secondaryCommodityId"] ?? true}>
            <CommodityAutocomplete
              id={CardBodyLocators.SecondaryCommodity}
              idSelect={CardBodyLocators.SecondaryCommoditySelect}
              label="Secondary Commodity"
              propKey="secondaryCommodityId"
              error={Boolean(opportunity.secondaryCommodityId && formErrors.secondaryCommodityId)}
              helperText={
                opportunity.secondaryCommodityId && formErrors.secondaryCommodityId
                  ? formErrors.secondaryCommodityId
                  : undefined
              }
            />
          </ConditionalGrid>
        </Grid>
        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["otherCommodities"] ?? true}>
          <CommodityAutocomplete
            id={CardBodyLocators.OtherCommodities}
            idSelect={CardBodyLocators.OtherCommoditiesSelect}
            label="Other Commodities"
            propKey="otherCommodities"
            multiple
            limitTags={3}
            error={Boolean(opportunity.otherCommodities && formErrors.otherCommodities)}
            helperText={
              opportunity.otherCommodities && formErrors.otherCommodities ? formErrors.otherCommodities : undefined
            }
          />
        </ConditionalGrid>

        <ConditionalGrid xs={12} displayGrid={opportunityAttributes["depositTypeGroupId"] ?? true}>
          <NestedListAutoComplete
            {...{
              dataList: referenceData.depositTypeGroups as ReferenceDepositTypeGroup[],
              criteria: criteria,
              selectedIds: depositTypes,
              handleNestedListData: handleNestedListData,
            }}
          ></NestedListAutoComplete>
        </ConditionalGrid>
      </Grid>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    opportunity.depositTypeClassId,
    opportunity.depositTypeGroupId,
    opportunity.depositTypeSubClassId,
    opportunity.otherCommodities,
    opportunity.secondaryCommodityId,
    formErrors.secondaryCommodityId,
    formErrors.otherCommodities,
    formErrors.depositTypeClassId,
    opportunityAttributes,
  ]);
}
