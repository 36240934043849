import { createContext, useState, useEffect, ReactNode, useContext, useCallback } from "react";
import { useMsal } from "@azure/msal-react";

import { authorizationRoles } from "constants/roles";

export type Roles = (typeof authorizationRoles)[keyof typeof authorizationRoles];

type AuthorizationContextType = {
  role: Roles;
  isReadOnly: boolean;
  isStandard: boolean;
  isPrivileged: boolean;
  isAdmin: boolean;
  setRole: (role: Roles) => void;
};

const groupRoleMappings = [
  { id: process.env.REACT_APP_AUTH_STANDARD_GUID, role: authorizationRoles.STANDARD_ROLE },
  { id: process.env.REACT_APP_AUTH_GUEST_GUID, role: authorizationRoles.READ_ONLY_ROLE },
  { id: process.env.REACT_APP_AUTH_ADMIN_GUID, role: authorizationRoles.ADMIN_ROLE },
  { id: process.env.REACT_APP_AUTH_PRIVILEGED_GUID, role: authorizationRoles.PRIVILEGED_ROLE },
];

export const AuthorizationContext = createContext<AuthorizationContextType>({
  role: authorizationRoles.READ_ONLY_ROLE,
  isReadOnly: true,
  isStandard: false,
  isPrivileged: false,
  isAdmin: false,
  setRole: () => {},
});

export const useAuthorization = () => useContext(AuthorizationContext);

interface Props {
  children: ReactNode;
}

export function AuthorizationProvider({ children }: Props) {
  const { accounts } = useMsal();

  const checkRoles = useCallback((): Roles => {
    const currentGroups = accounts[0].idTokenClaims?.groups as string[];
    const userRoles: string[] = [];
    currentGroups.forEach((id: string) => {
      const mapping = groupRoleMappings.find((item) => item.id === id);
      if (mapping) {
        userRoles.push(mapping.role);
      }
    });

    if (userRoles.includes(authorizationRoles.ADMIN_ROLE)) {
      return authorizationRoles.ADMIN_ROLE;
    }

    if (userRoles.includes(authorizationRoles.PRIVILEGED_ROLE)) {
      return authorizationRoles.PRIVILEGED_ROLE;
    }

    if (userRoles.includes(authorizationRoles.STANDARD_ROLE)) {
      return authorizationRoles.STANDARD_ROLE;
    }

    return authorizationRoles.READ_ONLY_ROLE;
  }, [accounts]);

  const [role, setRole] = useState<Roles>(checkRoles);

  useEffect(() => setRole(checkRoles), [checkRoles]);

  const isReadOnly = role === authorizationRoles.READ_ONLY_ROLE;
  const isStandard = role === authorizationRoles.STANDARD_ROLE;
  const isPrivileged = role === authorizationRoles.PRIVILEGED_ROLE;
  const isAdmin = role === authorizationRoles.ADMIN_ROLE;

  return (
    <AuthorizationContext.Provider value={{ role, isReadOnly, isStandard, isPrivileged, isAdmin, setRole }}>
      {children}
    </AuthorizationContext.Provider>
  );
}
