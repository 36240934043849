import { ChangeEvent, useEffect, useState } from "react";
import { createFilterOptions, FilterOptionsState, SelectChangeEvent } from "@mui/material";

import { CardBodyLocators, Mode } from "@constants";
import { mapIdsToName } from "services";
import { useReferenceDataContext } from "context";
import { useAsync } from "hooks";
import { NoErrors, useOpportunityContext } from "features/opportunities/context";
import { NamedEntity, OpportunityType, validateNamedEntity } from "features/opportunities/models";
import { ActionTypes, FormActionType } from "features/opportunities/reducers";
import { searchForNamedEntities } from "features/opportunities/services";

import { useFetch } from "./useFetch";
import { formatZodErrors } from "./useValidation";

export type ErrorType = {
  [Property in keyof NamedEntity]: string;
};

export function useSearchNamedEntities(selectionFieldIdName?: string) {
  const { referenceData } = useReferenceDataContext();
  const { opportunity, mode, updateField, dispatch } = useOpportunityContext();
  const [response, run] = useAsync(searchForNamedEntities);
  const [namedEntityOptionValue, setNamedEntityOptionValue] = useState<NamedEntity | null>(null);
  const [namedEntityInputValue, setNamedEntityInputValue] = useState("");
  const [namedEntityOptions, setNamedEntityOptions] = useState<NamedEntity[]>([]);
  const [namedEntityValue, setNamedEntityValue] = useState<NamedEntity | null>(null);
  const [open, toggleOpen] = useState(false);

  const namedEntityInitialValue = {
    namedEntityTypeId: 0,
    namedEntityType: "",
    name: "",
    contactInfo: "",
    address: "",
  };

  const [dialogValue, setDialogValue] = useState(namedEntityInitialValue);
  const [formErrors, setFormErrors] = useState<ErrorType>({} as ErrorType);
  const fetch = useFetch(run);
  const filter = createFilterOptions<NamedEntity>();

  const filterOptions = (options: NamedEntity[], params: FilterOptionsState<NamedEntity>) => {
    const filtered = filter(options, params);
    if (params.inputValue) {
      filtered.push({
        id: 0,
        inputValue: params.inputValue,
        name: `Add "${params.inputValue}"`,
        address: "",
        contactInfo: "",
        namedEntityTypeId: 0,
      });
    }

    return filtered;
  };

  const handleClose = () => {
    setDialogValue(namedEntityInitialValue);
    setFormErrors(NoErrors);
    toggleOpen(false);
  };

  useEffect(() => {
    if (response.data) {
      setNamedEntityOptions(response.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response.data]);

  useEffect(() => {
    if (namedEntityInputValue !== "") {
      fetch(namedEntityInputValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namedEntityInputValue]);

  useEffect(() => {
    const currentNamedEntity = getNamedEntity();
    if (currentNamedEntity) {
      setNamedEntityOptionValue(currentNamedEntity);
      setNamedEntityOptions(currentNamedEntity ? (prev) => [...prev, currentNamedEntity] : []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namedEntityValue, namedEntityInputValue]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setDialogValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setFormErrors(NoErrors);
  };

  const handleSelectionChange = (event: SelectChangeEvent<number>) => {
    setDialogValue({
      ...dialogValue,
      namedEntityTypeId: parseInt(event.target.value as string),
      namedEntityType: mapIdsToName(referenceData.namedEntityTypes, parseInt(event.target.value as string)),
    });
    setFormErrors(NoErrors);
  };

  const handleEntityChange = (_event: any, newValue: NamedEntity | null) => {
    if (!newValue?.inputValue && selectionFieldIdName) {
      updateField(selectionFieldIdName, newValue?.name);
      onValueChange(newValue);
      return;
    }

    toggleOpen(true);
    setDialogValue({
      name: newValue?.inputValue || "",
      namedEntityType: "",
      namedEntityTypeId: 0,
      address: "",
      contactInfo: "",
    });
  };

  const onValueChange = (newValue: NamedEntity | null) => {
    setNamedEntityOptions(newValue ? [newValue, ...namedEntityOptions] : namedEntityOptions);
    setNamedEntityOptionValue(newValue);
    if (selectionFieldIdName) {
      updateField(selectionFieldIdName as keyof OpportunityType, newValue?.id);
      updateNamedEntity(newValue);
    }
  };

  const handleSubmit = (_selectionFieldIdName: string) => {
    const newValue: NamedEntity = {
      id: 0,
      namedEntityTypeId: dialogValue.namedEntityTypeId,
      namedEntityType: dialogValue.namedEntityType,
      name: dialogValue.name,
      contactInfo: dialogValue.contactInfo,
      address: dialogValue.address,
    };

    const result = validateNamedEntity(newValue);

    if (!result.success) {
      const formattedErrors = formatZodErrors(result.error.issues);
      setFormErrors(formattedErrors);
      return;
    }

    onValueChange(newValue);
    handleClose();
  };

  useEffect(() => {
    if ((mode === Mode.Edit || mode === Mode.View) && selectionFieldIdName) {
      const currentNamedEntity = getNamedEntity();
      if (currentNamedEntity) {
        updateNamedEntity(currentNamedEntity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    namedEntityInputValue,
    opportunity?.company,
    opportunity?.originName,
    opportunity?.thirdPartyName,
    selectionFieldIdName,
    dispatch,
  ]);

  const getNamedEntity = () => {
    switch (selectionFieldIdName) {
      case CardBodyLocators.CompanyId:
        return opportunity.company;
      case CardBodyLocators.ThirdPartyNameId:
        return opportunity.thirdPartyName;
      case CardBodyLocators.OriginNameId:
        return opportunity.originName;
      default:
        return namedEntityValue;
    }
  };

  const updateNamedEntity = (newValue: NamedEntity | null) => {
    let action: FormActionType | undefined;
    switch (selectionFieldIdName) {
      case CardBodyLocators.CompanyId:
        setNamedEntityOptions(opportunity.company ? [opportunity.company, ...namedEntityOptions] : namedEntityOptions);
        action = newValue
          ? { type: ActionTypes.ADD_NAMED_ENTITY_COMPANY_ACTION, payload: newValue }
          : { type: ActionTypes.REMOVE_NAMED_ENTITY_COMPANY_ACTION };
        break;
      case CardBodyLocators.ThirdPartyNameId:
        setNamedEntityOptions(
          opportunity.thirdPartyName ? [opportunity.thirdPartyName, ...namedEntityOptions] : namedEntityOptions
        );
        action = newValue
          ? { type: ActionTypes.ADD_NAMED_ENTITY_THIRD_PARTY_ACTION, payload: newValue }
          : { type: ActionTypes.REMOVE_NAMED_ENTITY_THIRD_PARTY_ACTION };
        break;
      case CardBodyLocators.OriginNameId:
        setNamedEntityOptions(
          opportunity.originName ? [opportunity.originName, ...namedEntityOptions] : namedEntityOptions
        );
        action = newValue
          ? { type: ActionTypes.ADD_NAMED_ENTITY_ORIGIN_ACTION, payload: newValue }
          : { type: ActionTypes.REMOVE_NAMED_ENTITY_ORIGIN_ACTION };
        break;
      default:
        break;
    }
    if (action) {
      dispatch(action);
      setNamedEntityOptionValue(newValue);
    }
  };

  return {
    namedEntityOptionValue,
    namedEntityInputValue,
    namedEntityOptions,
    filterOptions,
    onValueChange,
    setNamedEntityValue,
    setNamedEntityInputValue,
    dialogValue,
    formErrors,
    handleSubmit,
    handleClose,
    open,
    toggleOpen,
    setDialogValue,
    handleInputChange,
    handleSelectionChange,
    handleEntityChange,
  };
}
